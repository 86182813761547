import {React, useContext, useState} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { Header } from '../../../components';
import { API_PORT } from '../../../API_PORT';
import LoadingScreen from '../../../components/LoadingScreen';
import { Link } from 'react-router-dom';
import {Button} from '../../../components';
import {FaStar} from 'react-icons/fa'
import './EvaluatePlatform.css'
import { useNavigate } from 'react-router-dom';

const EvaluatePlatform = () => {
    const [loadingscreen, setLoadingScreen] = useState({started: false});
    const [open, setOpen] = useState(false);
  
    const [texto, setTexto] = useState('');
  
    const userContext = useContext(UserContext);
    const token = userContext.value.access_token;
    const navigate = useNavigate();

    const [rating, setRating] = useState(null);
    const [hover] = useState(null);
  
    function responseNavigate(response){
      if(response.detail === "Evaluación realizada exitosamente"){
        alert("Su evaluación se ha enviado correctamente. Muchas gracias por calificar EcoKinesis.")
        navigate("/dashboard/home")
      }
      else{
        alert(response.detail)
      }
      setOpen(false);
      setLoadingScreen(prevState => {return {...prevState, started: false}});
    }
  
    const handleSubmit = (event) =>{
      event.preventDefault();
      if (texto !== "" && rating !== null){
        setOpen(true);
        setLoadingScreen(prevState => {return {...prevState, started: true}});
        fetch(API_PORT+"/admin/users_accounts/evaluate-plataform?token="+token, {
          method: 'POST',
          headers: {
               'Content-type': 'application/json'
          },
          body: JSON.stringify({
            "comments": texto,
            "evaluation": rating
          })
          }).then((res => res.json())).catch((error) => {
            console.log(error)
            alert("No se ha podido enviar tu calificación. Recarga tu sesión y vuelve a intentarlo.")}).then((response => {responseNavigate(response)}));
      }
      else{
        alert("Ingresa un mensaje y/o el número de estrellas para poder calificar la plataforma")
      }
    }
    
    return (
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-10'>
            <Link to="/dashboard/account" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver a mi perfil"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  
        <Header title="Calificar Plataforma"/>
        <h1 className='mb-3'>Ayudanos a mejorar EcoKinesis ingresando tu reseña, opiniones y/o problemas de la plataforma. Ingresa tu mensaje y selecciona un número de estrellas.</h1>
        <label>
        <textarea 
          placeholder='Escribe tu mensaje aquí...'
          style={{borderRadius: "5px", border: "1px solid", padding: "10px"}}
          className='mb-3 w-full'
          rows={10}
          cols={40}
          value={texto} // Valor del textarea
          onChange={(e) => setTexto(e.target.value)} // Función para actualizar el valor
        ></textarea>
        </label>
        <div className='mb-6 flex gap-3'>
            {[...Array(5)].map((_,index) => {
              const currenRating = index + 1;
                return(
                    <label>
                        <input type='radio' name='rating' value={currenRating} onClick={() => setRating(currenRating)}/>
                        <FaStar className='star' size={40}
                        color={currenRating <= (hover || rating) ? "#ffc107" : "grey"}/>
                    </label>
                )
            })}
        </div>
        <button style={{color:"white",backgroundColor:"#425df9", borderRadius:"10px", width:"140px", height:"50px"}} onClick={handleSubmit}>Enviar mensaje</button>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
      </div>
    )
}

export default EvaluatePlatform

/*
<div className='mt-6'>
            {[...Array(5)].map((_,index) => {
                const currenRating = index + 1;
                return(
                    <label>
                        <input type='radio' name='rating' value={currenRating} onClick={() => setRating(currenRating)}/>
                        <FaStar className='star' size={50}
                        color={currenRating <= (hover || rating) ? "#ffc107" : "#e4e59"}
                        onMouseEnter={()=> setHover(currenRating)}
                        onMouseLeave={()=> setHover(null)}/>
                    </label>
                )
            })}
        </div>
*/