import { React, useState, useContext} from 'react'
import { Button } from '../../../components';
import { Header } from '../../../components';
import { Link, useNavigate } from 'react-router-dom';

import email_icon from '../../../assets/email.png'
import password_icon from '../../../assets/password.png'
import { ChangeEmail } from '../../LoginPage/Validation';
import { UserContext } from '../../../contexts/UserContext';
import { API_PORT } from '../../../API_PORT';


const ModifyEmail = () => {
  const [values, setValues] = useState({
    "current_pass": "",
    "new_pass": ""
  });

  const userContext = useContext(UserContext);

  const token = userContext.value.access_token;


  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const link= API_PORT + "/admin/users_accounts/user/modify_email/?token="

const responsenavigate = (response) =>{
  if(response.detail === "Usuario modificado exitosamente"){
    alert("Su email ha sido cambiado exitosamente")
    navigate("/dashboard/account")
  }
  else{
    alert(response.detail)
  }
}

const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
};

const handleSubmit = (event) =>{
    event.preventDefault();
    setErrors(ChangeEmail(values));
    if(errors.current_pass === "" && errors.new_email === ""){
      fetch(link+token, {
          method: 'PATCH',
          headers: {
               'Content-type': 'application/json'
          },
          body: JSON.stringify(values)
          }).then((res => res.json()))
          .catch((error) => console.log(error))
          .then((response) => responsenavigate(response)
          );

  }
}

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header category="Mi Perfil" title="Cambiar Email"/>
      <div className='bg-white dark:text-gray-200 dark:bg-secondary-dark-bg h-auto rounded-xl w-full lg:w-80 p-8 pt-9 m-3 bg-hero-pattern bg-no-repeat bg-cover bg-center'>
        <div className='flex justify-between items-center'>
            <Link to="/dashboard/account" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  
      </div>

      <div className='header'>
        <h1>Ingresa tu contraseña actual y tu nuevo email para actualizar</h1>
      </div>
      <form action = "" onSubmit={handleSubmit}>
                <div className='inputs'>
                  <div className='input'>
                        <img src={password_icon} alt='' />
                        <label htmlFor='current_pass'></label>
                        <input type='string' placeholder='Contraseña actual' name='current_pass'
                        onChange={handleInput} className='form-control rounded-0'/>
                        {errors.current_pass && <span className='text-danger'>{errors.current_pass}</span>}
                    </div>
                    <div className='input'>
                        <img src={email_icon} alt='' />
                        <label htmlFor='new_email'></label>
                        <input type='string' placeholder='Email nuevo' name='new_email'
                        onChange={handleInput} className='form-control rounded-0'/>
                        {errors.new_email && <span className='text-danger'>{errors.new_email}</span>}
                    </div>
                </div>
                <div className='flex justify-center items-center gap-20 flex-wrap'>
                  <button className="submit" type="submit">Cambiar email</button>
                </div>     
        </form>

    </div>
  )
}

export default ModifyEmail