//Imports
import React, { useContext} from 'react';
import { Navigate, Outlet} from "react-router-dom";
import { UserContext } from '../contexts/UserContext';

//Detect if user is admin and logout if not
const AdminPrivate = () => {
  const userContext = useContext(UserContext);
  const isAdmin = userContext.value.user_type;
  return isAdmin === "admin" ? <Outlet/> : <Navigate to='/' />;
}

export default AdminPrivate;