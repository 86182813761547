import {React, useState, useContext, useRef, useEffect} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header } from '../../../../components';
import { useNavigate, useLocation } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import { Link } from 'react-router-dom';
import graphColors from '../../../../hook/graphColors';
import ModalLoader from '../../../../components/ModalLoader';
import LoadingScreen from '../../../../components/LoadingScreen';
import ecokinesis from "../../../../assets/ecokinesis.png"
import MUIDataTable from "mui-datatables";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import HorizontalTables from '../../../../hook/HorizontalTables';
import { API_PORT } from '../../../../API_PORT';
import anid from "../../../../assets/anid_rojo_azul.png"
import utfsm from "../../../../assets/utfsm.png"
import { Bar, Line, Doughnut, Radar } from "react-chartjs-2";
import { Chart as ChartJS, Title, BarElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, LineElement, Filler, Tooltip, Legend, ArcElement} from "chart.js";

ChartJS.register(Title, BarElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, LineElement, ArcElement, Filler, Tooltip, Legend)

const PredefinedScenaryPDF = () => {
  const [progress, setProgress] = useState(0);
  const [msg, setMsg] = useState(null);
  const [msgporcentage, setMsgPorcentage] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  const [usersReports, setUsersReports] = useState([])

  const userContext = useContext(UserContext);
  const user_type= userContext.value.user_type;
  const token = userContext.value.access_token;

  const navigate = useNavigate();
  const location = useLocation();

  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();

  //Define const of location
  const values = location.state.values
  const data = location.state.data
  const summary = location.state.summary
  const componentsmip = location.state.componentsmip
  const detailcompositionmip = location.state.detailcompositionmip
  const impactscontributionmip = location.state.impactscontributionmip
  const exportimportsmip = location.state.exportimportsmip
  const capitaldistributionmip = location.state.capitaldistributionmip
  const remunerationdistributionmip = location.state.remunerationdistributionmip
  const fiscalimpactmip = location.state.fiscalimpactmip
  const directemissionsmip = location.state.directemissionsmip
  const carbonfootprintmip= location.state.carbonfootprintmip
  const inducedemploymentmip = location.state.inducedemploymentmip
  const remunerationanalisismip = location.state.remunerationanalisismip
  const pibemploymentmip = location.state.pibemploymentmip
  const finaldemandmip = location.state.finaldemandmip
  const coins = location.state.coins

  const base = " (MIP Año Base)"

  const scenary_data = location.state.scenary_data
  const total_scenary = scenary_data.length;
  const scenary_options = Array.from({ length: total_scenary }, (_, i) => i + 1);

  //Indicators and graphic-table options
  const [appState, changeState] = useState({
    activeScenary: 1,
    scenaryObjects: scenary_options
  })

  function toggleActive(scenaryindex){
    if (scenaryindex != null){
      changeState({...appState, activeScenary: appState.scenaryObjects[scenaryindex]})
    } 
  }


  function toggleActiveStylesScenary(scenaryindex){
    if(appState.scenaryObjects[scenaryindex] === appState.activeScenary){
      return "box-active"
    }
    else {
      return "box-inactive"
    }
  }

  function ScenaryDisplay(number) {
    // Genera un array de botones
    const buttons = Array.from({ length: number }, (_, index) => (
      <button
        key={index}
        onClick={() => toggleActive(index)}
        className={`w-28 h-14 m-1 ${toggleActiveStylesScenary(index)}`}
        style={{ borderRadius: "10px", border: "1px solid", fontSize: "12px" }}
      >
        {scenary_data[index]}
      </button>
    ));

    return <div className="flex mr-auto">{buttons}</div>;
  }

 //Summary data
 var table_like_columns = [""]
 var table_like_data = []
 var star_plot_points = []
 var star_plot_data = []
 var star_plot_datasets = []

 //Economic Data
 var agregate_components_columns = []
 var agregate_components_data = []
 var agregate_components_datasets = []
 var porcentage_components_columns = []
 var porcentage_components_data = []
 var porcentage_components_datasets = []
 var porcentage_participation_columns = []
 var porcentage_participation_data = []
 var porcentage_participation_datasets = []
 var detail_compositions_columns = [""]
 var detail_compositions_data = []
 var pib_total_columns = []
 var pib_total_data = []
 var porcentual_direct_contribution_columns = []
 var porcentual_direct_contribution_data = []
 var porcentual_indirect_contribution_columns = []
 var porcentual_indirect_contribution_data = []
 var porcentual_total_contribution_columns = []
 var porcentual_total_contribution_data = []
 var export_imports_columns = []
 var export_imports_data = []
 var export_imports_datasets = []
 var capital_distribution_columns = []
 var capital_distribution_data = []
 var remuneration_distribution_columns = []
 var remuneration_distribution_data = []
 var fiscal_impacts_columns = [""]
 var fiscal_impacts_data = []
 var investment_columns = [""]
 var investment_data = []

 //Emissions Data
 var direct_emissions_columns = []
 var direct_emissions_data = []
 var carbon_footprint_columns = []
 var carbon_footprint_data = []

 var mip_porcentual_direct_contribution_columns = []
 var mip_porcentual_direct_contribution_data = []
 var mip_porcentual_indirect_contribution_columns = []
 var mip_porcentual_indirect_contribution_data = []
 var mip_porcentual_total_contribution_columns = []
 var mip_porcentual_total_contribution_data = []

 function exportimportconverter(value){
   if (value === "Exportaciones"){
     return("Exportaciones de bienes y servicios")
   }
   else if (value === "Importaciones"){
     return("Importaciones de bienes y servicios")
   }
   else {
     return(value)
   }
 }

 //Employment Data
 var induced_employment_columns = []
 var induced_employment_data = []
 var induced_employment_datasets = []
 var remuneration_analisis_columns = [""]
 var remuneration_analisis_data = []
 var historical_employees_columns = []
 var historical_employees_data = []
 var historical_employees_dataset = []
 var final_demand_columns = []
 var final_demand_data = []

  //Data variables
  const [name, setName] = useState({
    "pdf_name": "Escenario predefinido " + capitalize(values.country) + " " + values.pelp_year
  }); 

   const options={
    rowsPerPage: 30,
    selectableRows: "none",
    print: "false",
    download: "false",
    filter: "false",
    search: "false",
    resizableColumns: "true",
    responsive: "vertical",
    pagination: false,
    scroll: false,
    viewColumns: false,
  }

//Set the data on arrays
  //Summary
  if(appState.activeScenary!= null){
    data[appState.activeScenary-1][0].data.forEach((element) => {
      var y = capitalize(element.y_index)
      if (!table_like_columns.includes(y)){
        table_like_columns.push(capitalize(y));
      }
    })
    data[appState.activeScenary-1][0].data.forEach((element) => {
      var x = element.x_index
      var x2= element.x_index + base
      var new_array= [x].concat(Array(table_like_columns.length-1).fill(0))
      var new_array2= [x2].concat(Array(table_like_columns.length-1).fill(0))
      var flag = true
      table_like_data.forEach((element2) => {
        if (element2[0] === x){
          flag=false
        }
      })
      if (flag){
        table_like_data.push(new_array)
        table_like_data.push(new_array2)
      }
    })
    data[appState.activeScenary-1][0].data.forEach((element) => {
      var x = element.x_index
      var v= element.value
      var y= capitalize(element.y_index)
      table_like_data.forEach((element2,index2) =>{
        if (x === element2[0]){
          table_like_data[index2][table_like_columns.indexOf(y)] = v.toFixed(2)
        }
      })
    })
    summary.data?.forEach((element) => {
      var x = element.x_index + base
      var v= element.value
      var y= capitalize(element.y_index)
      table_like_data.forEach((element2,index2) =>{
        if (x === element2[0]){
          table_like_data[index2][table_like_columns.indexOf(y)] = v.toFixed(2)
        }
      })
    })

    data[appState.activeScenary-1][1].data.forEach((element) => {
      var y = element.y_index
      if (!star_plot_points.includes(y)){
        star_plot_points.push(y)
      }
    })
    data[appState.activeScenary-1][1].data.forEach((element) => {
      var x = element.x_index
      var flag= true
      var new_array= Array(star_plot_points.length).fill(0)
      star_plot_data.forEach((element2) => {
        if (element2.name === x){
          flag = false
        }
      })
      if (flag){
        star_plot_data.push({name:x, data: new_array})
      }
    })
    data[appState.activeScenary-1][1].data.forEach((element) => {
      var x = element.x_index
      var v= element.value
      var y= element.y_index
      star_plot_data.forEach((element2) => {
        if(x === element2.name){
          element2.data[star_plot_points.indexOf(y)] = v
        }
      })
    })
    star_plot_data?.forEach((element) => {
      star_plot_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name]), borderWidth: 1, borderColor: graphColors([element.name]), fill: false})
    })

    //Economics
    data[appState.activeScenary-1][2].coponentes_PIB_values.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2= capitalize(element.y_index) + base
    if(!agregate_components_columns.includes(y)){
      agregate_components_columns.push(y)
      agregate_components_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][2].coponentes_PIB_values.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(agregate_components_columns.length).fill(0)
    agregate_components_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      agregate_components_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][2].coponentes_PIB_values.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    agregate_components_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[agregate_components_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.componentsmip.coponentes_PIB_values.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index) + base
    agregate_components_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[agregate_components_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  agregate_components_data?.forEach((element) => {
    agregate_components_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  data[appState.activeScenary-1][2].componentes_PIB_por_sector.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if(!porcentage_components_columns.includes(y)){
      porcentage_components_columns.push(y)
      porcentage_components_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][2].componentes_PIB_por_sector.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(porcentage_components_columns.length).fill(0)
    porcentage_components_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      porcentage_components_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][2].componentes_PIB_por_sector.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    porcentage_components_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[porcentage_components_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.componentsmip.componentes_PIB_por_sector.data?.forEach((element) => {
    var x = capitalize(element.x_index) 
    var v= element.value
    var y= capitalize(element.y_index) + base
    porcentage_components_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[porcentage_components_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  porcentage_components_data?.forEach((element) => {
    porcentage_components_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  data[appState.activeScenary-1][2].componentes_PIB_por_componentes_valor_agregado.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var x2 = capitalize(element.x_index) + base
    if(!porcentage_participation_columns.includes(x)){
      porcentage_participation_columns.push(x)
      porcentage_participation_columns.push(x2)
    }
  })
  data[appState.activeScenary-1][2].componentes_PIB_por_componentes_valor_agregado.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var flag= true
    var new_array= Array(porcentage_participation_columns.length).fill(0)
    porcentage_participation_data.forEach((element2) => {
      if (element2.name === y){
        flag = false
      }
    })
    if (flag){
      porcentage_participation_data.push({name:y, data: new_array})
    }
  })
  data[appState.activeScenary-1][2].componentes_PIB_por_componentes_valor_agregado.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    porcentage_participation_data.forEach((element2) => {
      if(y === element2.name){
        element2.data[porcentage_participation_columns.indexOf(x)] = v.toFixed(2)
      }
    })
  })
  location.state.componentsmip.componentes_PIB_por_componentes_valor_agregado.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index) + base
    porcentage_participation_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[porcentage_participation_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  porcentage_participation_data?.forEach((element) => {
    porcentage_participation_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  data[appState.activeScenary-1][3].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!detail_compositions_columns.includes(y)){
      detail_compositions_columns.push(y)
    }
  })
  data[appState.activeScenary-1][3].data?.forEach((element) => {
    var x= capitalize(element.x_index)
    var x2 = capitalize(element.x_index) + base
    var new_array= [x].concat(Array(detail_compositions_columns.length-1).fill(0))
    var new_array2= [x2].concat(Array(detail_compositions_columns.length-1).fill(0))
    var flag= true
    detail_compositions_data.forEach((element2) => {
      if(x=== element2[0] || x2 === element2[0]){
        flag = false
      }
    })
    if(flag){
      detail_compositions_data.push(new_array)
      detail_compositions_data.push(new_array2)
    }
  })
  data[appState.activeScenary-1][3].data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    detail_compositions_data.forEach((element2) =>{
      if (x === element2[0]){
        element2[detail_compositions_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.detailcompositionmip.data?.forEach((element) => {
    var x = capitalize(element.x_index) + base
    var v= element.value
    var y= capitalize(element.y_index)
    detail_compositions_data.forEach((element2) =>{
      if (x === element2[0]){
        element2[detail_compositions_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  data[appState.activeScenary-1][4].PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if(!pib_total_columns.includes(y) && y!== "Total"){
      pib_total_columns.push(y)
      pib_total_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][4].PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(pib_total_columns.length).fill(0)
    pib_total_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag && x!== "Total"){
      pib_total_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][4].PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    pib_total_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[pib_total_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.impactscontributionmip.PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index) +base
    pib_total_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[pib_total_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  data[appState.activeScenary-1][4].DirectContributionPercentage.data?.forEach((element) => {
    porcentual_direct_contribution_columns.push(capitalize(element.x_index))
    porcentual_direct_contribution_data.push(element.value.toFixed(2))
  })
  location.state.impactscontributionmip.DirectContributionPercentage.data?.forEach((element) => {
    mip_porcentual_direct_contribution_columns.push(capitalize(element.x_index))
    mip_porcentual_direct_contribution_data.push(element.value.toFixed(2))
  })


  data[appState.activeScenary-1][4].InducedContributionPercentage.data?.forEach((element) => {
    porcentual_indirect_contribution_columns.push(capitalize(element.x_index))
    porcentual_indirect_contribution_data.push(element.value.toFixed(2))
  })
  location.state.impactscontributionmip.InducedContributionPercentage.data?.forEach((element) => {
    mip_porcentual_indirect_contribution_columns.push(capitalize(element.x_index))
    mip_porcentual_indirect_contribution_data.push(element.value.toFixed(2))
  })


  data[appState.activeScenary-1][4].TotalContributionPercentage.data?.forEach((element) => {
    porcentual_total_contribution_columns.push(capitalize(element.x_index))
    porcentual_total_contribution_data.push(element.value.toFixed(2))
  })
  location.state.impactscontributionmip.TotalContributionPercentage.data?.forEach((element) => {
    mip_porcentual_total_contribution_columns.push(capitalize(element.x_index))
    mip_porcentual_total_contribution_data.push(element.value.toFixed(2))
  })

  data[appState.activeScenary-1][5].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if(!export_imports_columns.includes(y)){
      export_imports_columns.push(y)
      export_imports_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][5].data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(export_imports_columns.length).fill(0)
    export_imports_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      export_imports_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][5].data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    export_imports_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[export_imports_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.exportimportsmip.data?.forEach((element) => {
    var x = exportimportconverter(capitalize(element.x_index))
    var v= element.value
    var y= capitalize(element.y_index) + base
    export_imports_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[export_imports_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  export_imports_data?.forEach((element) => {
    export_imports_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name]), borderWidth: 0, categoryPercentage: 0.8})
  })

  data[appState.activeScenary-1][7].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if (!capital_distribution_columns.includes(y)){
      capital_distribution_columns.push(y)
      capital_distribution_columns.push(y2)
      capital_distribution_data.push(element.value.toFixed(2))
      capital_distribution_data.push(0)
    }
  })
  location.state.capitaldistributionmip.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var v = element.value.toFixed(2)
    if (y === "Total"){
      y = "Total sector generación" + base
    } 
    else{
      y= y + base
    }
    capital_distribution_columns.forEach((element2, index) => {
      if(element2 === y){
        capital_distribution_data[index] = v
      }
    })
  })

  data[appState.activeScenary-1][8].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if (!remuneration_distribution_columns.includes(y)){
      remuneration_distribution_columns.push(y)
      remuneration_distribution_data.push(element.value.toFixed(2))
      remuneration_distribution_columns.push(y2)
      remuneration_distribution_data.push(0)
    }
  })
  location.state.remunerationdistributionmip.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var v = element.value.toFixed(2)
    if (y === "Total"){
      y = "Total sector generación" + base
    } 
    else{
      y= y + base
    }
    remuneration_distribution_columns.forEach((element2, index) => {
      if(element2 === y){
        remuneration_distribution_data[index] = v
      }
    })
  })

  data[appState.activeScenary-1][9].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!fiscal_impacts_columns.includes(y)){
      fiscal_impacts_columns.push(y)
    }
  })
  data[appState.activeScenary-1][9].data?.forEach((element) => {
    var x = element.x_index
    var x2 = element.x_index + base
    var new_array= [x].concat(Array(fiscal_impacts_columns.length-1).fill(0))
    var new_array2= [x2].concat(Array(fiscal_impacts_columns.length-1).fill(0))
    var flag= true
    fiscal_impacts_data.forEach((element2) => {
      if (element2[0] === x || element2[0] === x2){
        flag = false
      }
    })
    if (flag){
      fiscal_impacts_data.push(new_array)
      fiscal_impacts_data.push(new_array2)
    }
  })
  data[appState.activeScenary-1][9].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    fiscal_impacts_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        fiscal_impacts_data[index2][fiscal_impacts_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.fiscalimpactmip.data?.forEach((element) => {
    var x = element.x_index + base
    var v= element.value
    var y= capitalize(element.y_index)
    fiscal_impacts_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        fiscal_impacts_data[index2][fiscal_impacts_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  data[appState.activeScenary-1][10].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!investment_columns.includes(y)){
      investment_columns.push(y)
    }
  })
  data[appState.activeScenary-1][10].data?.forEach((element) => {
    var x = element.x_index
    var new_array= [x].concat(Array(investment_columns.length-1).fill(0))
    var flag= true
    investment_data.forEach((element2) => {
      if (element2[0] === x){
        flag = false
      }
    })
    if (flag){
      investment_data.push(new_array)
    }
  })
  data[appState.activeScenary-1][10].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    investment_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        investment_data[index2][investment_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  //Emissions
  data[appState.activeScenary-1][11].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if(!direct_emissions_columns.includes(y) && y !== "Total"){
      direct_emissions_columns.push(y)
      direct_emissions_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][11].data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(direct_emissions_columns.length).fill(0)
    direct_emissions_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      direct_emissions_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][11].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    direct_emissions_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[direct_emissions_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.directemissionsmip.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index) + base
    direct_emissions_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[direct_emissions_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  data[appState.activeScenary-1][12].data?.forEach((element) => {
    carbon_footprint_columns.push(capitalize(element.x_index))
    carbon_footprint_columns.push(capitalize(element.x_index) + base)
    carbon_footprint_data.push(element.value.toFixed(5))
    carbon_footprint_data.push(0)
  })
  location.state.carbonfootprintmip.data?.forEach((element) => {
    var x = capitalize(element.x_index) + base
    var v = element.value.toFixed(5)
    carbon_footprint_columns.forEach((element2, index) => {
      if(element2 === x){
        carbon_footprint_data[index] = v
      }
    })
  })


  //Employment
  data[appState.activeScenary-1][13].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if (!induced_employment_columns.includes(y)){
      induced_employment_columns.push(y)
      induced_employment_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][13].data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(induced_employment_columns.length).fill(0)
    induced_employment_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      induced_employment_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][13].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    induced_employment_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[induced_employment_columns.indexOf(y)] = Math.floor(v)
      }
    })
  })
  location.state.inducedemploymentmip.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index) + base
    induced_employment_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[induced_employment_columns.indexOf(y)] = Math.floor(v)
      }
    })
  })
  induced_employment_data?.forEach((element) => {
    induced_employment_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  data[appState.activeScenary-1][14].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!remuneration_analisis_columns.includes(y)){
      remuneration_analisis_columns.push(y)
    }
  })
  data[appState.activeScenary-1][14].data?.forEach((element) => {
    var x = element.x_index
    var x2 = element.x_index + base
    var new_array= [x].concat(Array(remuneration_analisis_columns.length-1).fill(0))
    var new_array2= [x2].concat(Array(remuneration_analisis_columns.length-1).fill(0))
    var flag= true
    remuneration_analisis_data.forEach((element2) => {
      if (element2[0] === x || element2[0] === x2){
        flag = false
      }
    })
    if (flag){
      remuneration_analisis_data.push(new_array)
      remuneration_analisis_data.push(new_array2)
    }
  })
  data[appState.activeScenary-1][14].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    remuneration_analisis_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        remuneration_analisis_data[index2][remuneration_analisis_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.remunerationanalisismip.data?.forEach((element) => {
    var x = element.x_index + base
    var v= element.value
    var y= capitalize(element.y_index)
    if (y === "Total"){
      y = "Total sector generación"
    }
    remuneration_analisis_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        remuneration_analisis_data[index2][remuneration_analisis_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  data[appState.activeScenary-1][15].data?.forEach((element) => {
    var x= element.x_index
    if(!historical_employees_columns.includes(x)){
      historical_employees_columns.push(x)
    } 
  })
  data[appState.activeScenary-1][15].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var flag= true
    var new_array= Array(historical_employees_columns.length).fill(0)
    historical_employees_data.forEach((element2) => {
      if (element2.name === y){
        flag = false
      }
    })
    if (flag){
      historical_employees_data.push({name:y, data: new_array})
    }
  })
  data[appState.activeScenary-1][15].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    historical_employees_data.forEach((element2) => {
      if(y === element2.name){
        element2.data[historical_employees_columns.indexOf(x)] = Math.floor(v)
      }
    })
  })
  historical_employees_data?.forEach((element) => {
    historical_employees_dataset.push({fill: true, label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  data[appState.activeScenary-1][17].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if(!final_demand_columns.includes(y)){
      final_demand_columns.push(y)
      final_demand_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][17].data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(final_demand_columns.length).fill(0)
    final_demand_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      final_demand_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][17].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    final_demand_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[final_demand_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.finaldemandmip.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index) + base
    final_demand_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[final_demand_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  }

  //Graph and tables options
  //Graph 2
  const ChartData1 = {
    labels: star_plot_points,
    datasets: star_plot_datasets
  };
  const graph_options1 = {
    plugins:{
      title: {
      display: true,
      text: data["0"]["1"].title,
      align: "center",
      },
  }};

  //Graph 3
  const ChartData2 = {
    labels: agregate_components_columns,
    datasets: agregate_components_datasets
  };
  const graph_options2 = {
    plugins:{
      title: {
      display: true,
      text: data["0"]["2"].coponentes_PIB_values.title,
      align: "center",
      },
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: coins[values.country], color:"black"}, stacked: true},
          y: {stacked: true}
      }};

//Graph 4
/*const ChartData3 = {
    labels: porcentage_components_columns,
    datasets: porcentage_components_datasets
  };
  const graph_options3 = {
    plugins:{
      title: {
      display: true,
      text: components.componentes_PIB_por_sector.title,
      align: "center",
      },
      
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: "Porcentaje de participación", color:"black"}, stacked: true, max:100},
          y: {stacked: true}
      }};
*/

//Graph 5
const ChartData4 = {
    labels: porcentage_participation_columns,
    datasets: porcentage_participation_datasets
  };
  const graph_options4 = {
    plugins:{
      title: {
      display: true,
      text: data["0"]["2"].componentes_PIB_por_componentes_valor_agregado.title,
      align: "center",
      },
      
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: "Porcentaje de participación", color:"black"}, stacked: true, max:100},
          y: {stacked: true}
      }};

      //Graph 7
  const ChartData5 = {
    labels: pib_total_columns,
    datasets: [
      {
      label: pib_total_data[0]?.name,
      data: pib_total_data[0].data,
      backgroundColor: graphColors([pib_total_data[0]?.name]),
      borderWidth: 0,
      },
      {
        label: pib_total_data[1]?.name,
        data: pib_total_data[1].data,
        backgroundColor: graphColors([pib_total_data[1]?.name]),
        borderWidth: 0,
        }
    ]
  };
  const graph_options5 = {
    plugins:{
      title: {
      display: true,
      text: data["0"]["4"].PIBsDirectosIndirectosTotales.title,
      align: "center",
      },
      
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: coins[values.country], color:"black"}, stacked: true},
          y: {stacked: true}
      }};

//Graph 8
const ChartData6 = {
    labels: porcentual_direct_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: porcentual_direct_contribution_data,
      backgroundColor: graphColors(porcentual_direct_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options6 = {
    plugins : {
      title: {
        display: true,
        text: data["0"]["4"].DirectContributionPercentage.title,
        align: "center",
      }}
  }
  const ChartData7 = {
    labels: mip_porcentual_direct_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: mip_porcentual_direct_contribution_data,
      backgroundColor: graphColors(mip_porcentual_direct_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options7 = {
    plugins : {
      title: {
        display: true,
        text: impactscontributionmip.DirectContributionPercentage.title + base,
        align: "center",
      }}
  }

  //Graph 9
  const ChartData8 = {
    labels: porcentual_indirect_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: porcentual_indirect_contribution_data,
      backgroundColor: graphColors(porcentual_indirect_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options8 = {
    plugins : {
      title: {
        display: true,
        text: data[appState.activeScenary-1][4].InducedContributionPercentage.title,
        align: "center",
      }}
  }
  const ChartData9 = {
    labels: mip_porcentual_indirect_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: mip_porcentual_indirect_contribution_data,
      backgroundColor: graphColors(mip_porcentual_indirect_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options9= {
    plugins : {
      title: {
        display: true,
        text: impactscontributionmip.InducedContributionPercentage.title + base,
        align: "center",
      }}
  }

  //Graph 10
  const ChartData10 = {
    labels: porcentual_total_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: porcentual_total_contribution_data,
      backgroundColor: graphColors(porcentual_total_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options10 = {
    plugins : {
      title: {
        display: true,
        text: data["0"]["4"].TotalContributionPercentage.title,
        align: "center",
      }}
  }
  const ChartData11 = {
    labels: mip_porcentual_total_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: mip_porcentual_total_contribution_data,
      backgroundColor: graphColors(mip_porcentual_total_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options11 = {
    plugins : {
      title: {
        display: true,
        text: impactscontributionmip.TotalContributionPercentage.title + base,
        align: "center",
      }}
  }

  //Graph 12
  const ChartData12 = {
    labels: export_imports_columns,
    datasets: export_imports_datasets}
  const graph_options12 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: "Exportaciones e importaciones ["+coins[values.country]+"]"}}
    },
    plugins : {
      title: {
        display: true,
        text: data["0"]["5"].title,
        align: "center",
      }
    }
  }

  //Graph 13
  const ChartData13 = {
    labels: capital_distribution_columns,
    datasets: [{
      label: data["0"]["7"].data[0].x_index,
      data: capital_distribution_data,
      backgroundColor: graphColors(capital_distribution_columns),
      borderWidth: 0,
      }]
  }
  const graph_options13 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías", color: "black"}},
      y: {title:{display: true, text: data["0"]["7"].data[0].x_index, color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: data["0"]["7"].title,
        align: "center",
      }
    }
  }

  //Graph 14
  const ChartData14 = {
    labels: remuneration_distribution_columns,
    datasets: [{
      label: data["0"]["8"].data[0].x_index,
      data: remuneration_distribution_data,
      backgroundColor: graphColors(remuneration_distribution_columns),
      borderWidth: 0,
      }]
  }
  const graph_options14= {
    scales: {
      x: {title:{display: true, text: "Tecnologías", color: "black"}},
      y: {title:{display: true, text: data["0"]["8"].data[0].x_index, color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: data["0"]["8"].title,
        align: "center",
      }
    }
  }

  //Graph 17
  const ChartData15 = {
    labels: direct_emissions_columns,
    datasets: [
      {
      label: direct_emissions_data[0]?.name,
      data: direct_emissions_data[0].data,
      backgroundColor: graphColors([direct_emissions_data[0]?.name]),
      borderWidth: 0,
      categoryPercentage: 0.7
      },
      {
        label: direct_emissions_data[1]?.name,
        data: direct_emissions_data[1].data,
        backgroundColor: graphColors([direct_emissions_data[1]?.name]),
        borderWidth: 0,
        categoryPercentage: 0.7
        }
    ]
  }
  const graph_options15= {
    scales: {
      x: {title:{display: true, text: "Actividades Económicas"}},
      y: {title:{display: true, text: "Emisiones Directas y Huella de Carbono [Mt CO2 eq]"}}
    },
    plugins : {
      title: {
        display: true,
        text: data["0"]["11"].title,
        align: "center",
      }
    }
  }

   //Graph 18
   const ChartData16 = {
    labels: carbon_footprint_columns,
    datasets: [{
      label: "[Kt CO2 eq/"+coins[values.country]+"]",
      data: carbon_footprint_data,
      backgroundColor: graphColors(carbon_footprint_columns),
      borderWidth: 0,
      }]
  }
  const graph_options16 = {
    scales: {
      x: {title:{display: true, text: "Actividades Económicas"}},
      y: {title:{display: true, text: "Huella de carbono sobre valor agregado "+"[Kt CO2 eq/"+coins[values.country]+"]"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: data["0"]["12"].title,
        align: "center",
      }
    }
  }

  //Graph 19
  const ChartData17 = {
    labels: induced_employment_columns,
    datasets: induced_employment_datasets
  };
  const graph_options17 = {
    plugins:{
      title: {
      display: true,
      text: data["0"]["13"].title,
      align: "center",
      }},
  indexAxis: 'x',
      scales: {
          x: {title: {display: "true", text: "Tecnologías", color:"black"}, stacked: true},
          y: {title: {display: "true", text: "Empleos", color:"black"}, stacked: true}
      }};

//Graph 21
 const ChartData18 = {
    labels: historical_employees_columns,
    datasets: historical_employees_dataset
  };
  const graph_options18 = {
    responsive: true,
    plugins:{
      title: {
      display: true,
      text: data["0"]["13"].title,
      align: "center",
      }},
      scales: {
          x: {title: {display: "true", text: "Años", color:"black", stacked: true}},
          y: {title: {display: "true", text: "Empleos directos", color:"black", stacked: true}}
      }};

//Graph 22
/*
const ChartData19 = {
    labels: pib_employment_columns,
    datasets: [{
      label: "PIB / Empleados [Miles de Millones moneda nacional]",
      data: pib_employment_data,
      backgroundColor: graphColors(pib_employment_columns),
      borderWidth: 0,
      }]
  }
  const graph_options19 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: "PIB / Empleados [Miles de Millones moneda nacional]"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: pibemployment.title,
        align: "center",
      }
    }
  }
    */

  //Graph 23
  const ChartData20 = {
    labels: final_demand_columns,
    datasets: [
      {label: final_demand_data[0]?.name,
      data: final_demand_data[0].data,
      backgroundColor: graphColors([final_demand_data[0]?.name]),
      borderWidth: 0,
      categoryPercentage: 0.8},
      {label: final_demand_data[1].name,
        data: final_demand_data[1].data,
        backgroundColor: graphColors([final_demand_data[1]?.name]),
        borderWidth: 0,
        categoryPercentage: 0.8},
    ]}
  const graph_options20 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: "Empleos/Demanda final[-/"+coins[values.country]+"]"}}
    },
    plugins : {
      title: {
        display: true,
        text: data["0"]["17"].title,
        align: "center",
      }
    }
  }




  const handleInput = (event) =>{
    setName(prev => ({...prev, [event.target.name]:event.target.value}))
  };


  const chartRefs = useRef([]);
  const pages = 21

  const responsereport = (response) => {
    if (response === true) {
        setMsg("¡Reporte guardado exitosamente!")
        alert("Se ha guardado tu reporte exitosamente. Revisa la sección de reportes de la barra izquierda para poder visualizarlo.")
      }
    else {
      alert("No se ha podido guardar el reporte.")
      setMsg("No se ha podido guardar tu reporte")
    }
    setMsgPorcentage(null);
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
  }

  useEffect(() => {
    fetch(API_PORT+'/admin/reports/user/get_reports_list/?token=' + token, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json()).then(data => {
        setUsersReports(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      });
    }, []);

    const SaveReport = async () =>{
      // Verifica si el nombre del reporte ya existe
      const reportExists = usersReports.some(report => report.report_name === name.pdf_name);
      if (reportExists) {
          alert("El nombre del reporte ya existe. Cambia el nombre de este por otro que no esté guardado en tu cuenta.");
          return; // Detiene la ejecución si el nombre ya existe
      }

      const pdf2 = new jsPDF();
      setIsModalOpen(true)
      setProgress(0); // Inicia el progreso en 0%
      setMsg("Generando el reporte. No cierres la ventana hasta completar el proceso.")
      setLoaderScreen(prevState => {
        return {...prevState, started: true}
      })
      for (let i = 0; i < pages; i++) {
        const chartRef = chartRefs.current[i];
        const canvas = await html2canvas(chartRef);
        const imageData = canvas.toDataURL('image/png');
        const pdfWidth = pdf2.internal.pageSize.getWidth()
        const pdfHeight = pdf2.internal.pageSize.getHeight()
        const imgWidth = canvas.width
        const imgHeight = canvas.height
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
        pdf2.addImage(imageData, 'PNG', 16, 16, imgWidth*ratio*0.85, imgHeight*ratio*0.85);
        if (i < pages - 1) {
          pdf2.addPage();
        }
        setProgress(((i + 1) / pages) * 100);
        setMsgPorcentage((((i + 1) / pages) * 100).toFixed(0) + " %");
        
      }
      const reportData = new FormData();
      reportData.append('report_name', name.pdf_name);
      reportData.append('report_type', 'Escenario Predefinido');
      reportData.append('report_description', 'Reporte Escenario Predefinido');
      reportData.append('file', new Blob([pdf2.output('blob')]), name.pdf_name+'.pdf');
      setMsgPorcentage(null);
      setMsg("Guardando el reporte en tu cuenta. No cierres la ventana hasta completar el proceso.")

      try {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${API_PORT}/admin/reports/user/save_report/?token=${token}`, true);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setProgress(percentComplete);
                setMsgPorcentage(`${percentComplete.toFixed(0)} %`);
            }
        };

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                console.log('Archivo subido con éxito');
                responsereport(true);
            } else {
                throw new Error('Error al guardar el reporte');
            }
        };

        xhr.onerror = () => {
            console.error('Ocurrió un error al intentar guardar el reporte');
            responsereport(false);
        };

        xhr.send(reportData);
    } catch (error) {
        console.error('Ocurrió un error al intentar guardar el reporte:', error);
        setMsgPorcentage(null);
        responsereport(false);
    } 
  }

  const generatePDF = async () => {
    const pdf = new jsPDF();
    setIsModalOpen(true)
    setProgress(0); // Inicia el progreso en 0%
    setMsg("Generando el reporte para ser descargado. No cierres la ventana hasta completar el proceso.")
    setLoaderScreen(prevState => {
      return {...prevState, started: true}
    })
    for (let i = 0; i < pages; i++) {
      const chartRef = chartRefs.current[i];
      const canvas = await html2canvas(chartRef);
      const imageData = canvas.toDataURL('image/png');
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      pdf.addImage(imageData, 'PNG', 16, 16, imgWidth*ratio*0.85, imgHeight*ratio*0.85);
      if (i < pages - 1) {
        pdf.addPage();
      }
      // Actualiza el progreso
      setProgress(((i + 1) / pages) * 100);
      setMsgPorcentage((((i + 1) / pages) * 100).toFixed(0) + " %");
    }
    setMsg("¡Reporte generado!")
    setMsgPorcentage(null);
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
    pdf.save(name.pdf_name);
  };


  function Render(user){
    if(user === "premium" || user === "admin"){
        return(
            <div>
                <div className='mt-10'><Header title="Selecciona un escenario y coloca el nombre de tu reporte"/></div>
                    <h1 className='mb-3'><strong>Selección de escenario</strong></h1>
                    <div className='flex mr-auto mb-9'>
                          {ScenaryDisplay(total_scenary)}
                        </div>
                    <div className='grid grid-cols-3 gap-3'>
                        <div className='columns-1'>
                            <h1 className='mb-3'><strong>Nombre del reporte</strong></h1>
                            <input type='string' placeholder={name.pdf_name} name='pdf_name' onChange={handleInput} className='form-control rounded-0 w-96 h-10 p-1' style={{border:"1px solid" ,borderRadius: "8px", color: "gray", backgroundColor:"white", padding:"6px"}}></input>
                        </div>
                    </div>
                    <div className='flex gap-5 mt-12'>
                        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={SaveReport}>Guardar Reporte</button>
                        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={generatePDF}>Descargar Reporte</button>
                    </div>
                    <div key={0} ref={(ref) => (chartRefs.current[0] = ref)} style={{border: "1px solid", width: "100%", padding: "30px", marginTop: "120px"}}>
                        <div className='flex justify-center items-center'>
                          <img style={{maxWidth:"320px", marginBottom:"30px"}} src={ecokinesis} alt=''/>
                        </div>
                        <h1 className='flex justify-center items-center'><strong>Resultados Reporte Escenario Predefinido</strong></h1>
                        <h1 className='flex justify-center items-center'>País Ingresado: {capitalize(values.country)}</h1>
                        <h1 className='flex justify-center items-center'>Año Ingresado: {values.year}</h1>
                        <h1 className='flex justify-center items-center'>Año PELP Ingresado: {values.pelp_year}</h1>
                        <h1 className='flex justify-center items-center'>Escenario a simular seleccionado: {scenary_data[appState.activeScenary-1]}</h1>
                        <h1 className='flex justify-center items-center'>Fecha de creación del reporte: {date}/{month}/{year}</h1>

                        <h1 className='flex justify-center items-center mt-12'>EcoKinesis Proyecto Fondef Idea ID22I10341 es propiedad de la Universidad Técnica Federico Santa María y ANID</h1>
                        <div className='flex justify-center items-center gap-10 mt-6'>
                          <img style={{maxWidth:"100px"}} src={anid} alt=''/>
                          <img style={{maxWidth:"150px"}} src={utfsm} alt=''/>
                        </div>
                        <h1 className='flex justify-center items-center mt-12'>Disclaimer: Este reporte ha sido generado automáticamente en base a los datos ingresados del usuario en la plataforma de EcoKinesis. EcoKinesis no se hace responsable del uso o vericidad de los datos en este reporte.</h1>
                    </div>
                    <div className="mt-10" key={1} ref={(ref) => (chartRefs.current[1] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Resumen de indicadores: La tabla muestra el resumen de indicadores para el escenario {scenary_data[appState.activeScenary-1]} {capitalize(values.country)} {values.pelp_year}. Esta se agrupa tecnologías para las columnas y para las filas se utilizan diferentes indicadores, cada uno con diferentes unidades de medida. Se incluyen los datos de indicadores tanto del escenario seleccionado como del año de la MIP base.</h1>
                        <div style={{maxWidth: "100%"}}>
                        <MUIDataTable
                            title={data["0"]["0"].title}
                            data={table_like_data}
                            columns={table_like_columns}
                            options={options}
                        ></MUIDataTable>
                        </div>
                    </div>
                    <div className="mt-10" key={2} ref={(ref) => (chartRefs.current[2] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Comparación de escenarios: El gráfico muestra una comparación normalizada (respecto al valor máximo de cada item) del escenario predefinido actual con respecto a los escenarios PELP.</h1>
                      <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
                        <Radar data={ChartData1} options={graph_options1}/>
                        </div>
                    </div>
                    <div className="mt-10" key={3} ref={(ref) => (chartRefs.current[3] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Componentes valor agregado: En el gráfico pueden verse las relaciones del valor agregado, específicamente, el aporte al PIB de diferentes tecnologías para los componentes de remuneraciones de asalariados, excedente bruto de importación e impuestos netos sobre la producción. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div style={{width: "1000px", height: "520px"}}>
                        {HorizontalTables(ChartData2, graph_options2)}
                      </div>
                    </div>
                    <div className="mt-10" key={4} ref={(ref) => (chartRefs.current[4] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Porcentaje participación sectores: En el gráfico pueden verse las relacionados del valor agregado, específicamente los porcentajes de participación de los componentes del valor agregado (remuneraciones de aslariados, excedente bruto de explotación, impuestos netos sobre la producción y valor agregado) para diferentes sectores. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div style={{width: "1000px", height: "520px"}}>
                        {HorizontalTables(ChartData4, graph_options4)}
                      </div>
                    </div>
                    <div className="mt-10" key={5} ref={(ref) => (chartRefs.current[5] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Detalle valor agregado: En esta tabla pueden observarse los detalles de demanda y valor agregado del sector de generación eléctrica. Las columnas de esta tabla corresponden a diferentes tipos de tecnologías, mientras que sus filas corresponden a los indicadores medidos en {coins[values.country]}. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                        <div style={{width: "100%"}}>
                        <MUIDataTable
                        title={data["0"]["3"].title}
                        data={detail_compositions_data}
                        columns={detail_compositions_columns}
                        options={options}
                        ></MUIDataTable></div>
                    </div>
                    <div className="mt-10" key={6} ref={(ref) => (chartRefs.current[6] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Impacto PIB directo e indirecto: En este gráfico se muestra el eimpacto directo, indirecto y total en el PIB. Las filas representan diferentes tecnologías de los sectores de generación, almacenamiento y H2, mientras que sus valores corresponden a los impactos medidos en {coins[values.country]}. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div style={{width: "1000px", height: "520px"}}>
                        {HorizontalTables(ChartData5, graph_options5)}
                      </div>
                    </div>
                    <div className="mt-10" key={7} ref={(ref) => (chartRefs.current[7] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Contribución porcentual PIB directo: En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB directo para el escenario {scenary_data[appState.activeScenary-1]} {capitalize(values.country)} {values.pelp_year}. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div className='flex'>
                            <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
                            <Doughnut data={ChartData6} options={graph_options6}/>
                            </div>
                            <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
                            <Doughnut data={ChartData7} options={graph_options7}/>
                            </div>
                        </div>
                    </div>
                    <div className="mt-10" key={8} ref={(ref) => (chartRefs.current[8] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Contribución porcentual PIB indirecto: En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB indirecto para el escenario {scenary_data[appState.activeScenary-1]} {capitalize(values.country)} {values.pelp_year}. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div className='flex'>
                        <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
                        <Doughnut data={ChartData8} options={graph_options8}/>
                        </div>
                        <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
                        <Doughnut data={ChartData9} options={graph_options9}/>
                        </div>
                    </div>
                    </div>
                    <div className="mt-10" key={9} ref={(ref) => (chartRefs.current[9] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Contribución porcentual PIB total: En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB total (sumando el directo e indirecto) para el escenario {scenary_data[appState.activeScenary-1]} {capitalize(values.country)} {values.pelp_year}. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div className='flex'>
                        <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
                        <Doughnut data={ChartData10} options={graph_options10}/>
                        </div>
                        <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
                        <Doughnut data={ChartData11} options={graph_options11}/>
                        </div>
                    </div>
                    </div>
                    <div className="mt-10" key={10} ref={(ref) => (chartRefs.current[10] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Exportaciones e importaciones: En este gráfico se muestran las exportaciones, importaciones y exportaciones netas de bienes y servicios para diferentes tecnologías del escenario {scenary_data[appState.activeScenary-1]} {capitalize(values.country)} {values.pelp_year} medidas en {coins[values.country]}. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div style={{width: "1200px", height: "500px"}}>
                        <Bar data={ChartData12} options={graph_options12}/>
                      </div>
                    </div>
                    <div className="mt-10" key={11} ref={(ref) => (chartRefs.current[11] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Distribución capital: En este gráfico se muestra el excedente bruto de capital medido en {coins[values.country]} de diferentes tecnologías para el escenario {scenary_data[appState.activeScenary-1]} {capitalize(location.state.values.country)} {values.pelp_year}. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData13} options={graph_options13}/>
                      </div>
                    </div>
                    <div className="mt-10" key={12} ref={(ref) => (chartRefs.current[12] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Distribución remuneraciones: En este gráfico se muestra el total de remuneraciones medido en millones de dólares de diferentes tecnologías para el escenario {scenary_data[appState.activeScenary-1]} {capitalize(location.state.values.country)} {values.pelp_year}. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData14} options={graph_options14}/>
                      </div>
                    </div>
                    <div className="mt-10" key={13} ref={(ref) => (chartRefs.current[13] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Impacto fiscal: En esta tabla se muestra un resumen del impacto fiscal para el escenario seleccionado y el año de la MIP base. Sus columnas corresponden a diferentes tecnologías, mientras que sus filas son diversos indicadores de impactos fiscales, cada uno con diferentes unidades de medidas.</h1>
                        <div style={{width: "100%"}}>
                        <MUIDataTable
                          title={data["0"]["9"].title}
                          data={fiscal_impacts_data}
                          columns={fiscal_impacts_columns}
                          options={options}
                          ></MUIDataTable></div>
                    </div>
                    <div className="mt-10" key={14} ref={(ref) => (chartRefs.current[14] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Requerimientos de inversión: En esta tabla se muestra los requerimientos de inversión de acuerdo con la capacidad instalada para el escenario {scenary_data[appState.activeScenary-1]} {capitalize(location.state.values.country)} {values.pelp_year}. Sus columnas corresponden a diferentes tecnologías, mientras que sus filas son diversos indicadores de inversión, cada uno con diferentes unidades de medidas.</h1>
                        <div style={{width: "100%"}}>
                        <MUIDataTable
                            title={data["0"]["10"].title}
                            data={investment_data}
                            columns={investment_columns}
                            options={options}
                            ></MUIDataTable></div>
                    </div>
                    <div className="mt-10" key={15} ref={(ref) => (chartRefs.current[15] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Emisiones directas y huella de carbono: El gráfico muestra las emisiones directas y la huella de carbono medidas en mega toneladas de CO2 equivalente para diferentes actividades económicas del escenario {scenary_data[appState.activeScenary-1]} {capitalize(location.state.values.country)} {values.pelp_year}. También se incluye la meta o objetivo para 2030. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div style={{width: "1200px", height: "560px"}}>
                        <Bar data={ChartData15} options={graph_options15}/>
                      </div>
                    </div>
                    <div className="mt-10" key={16} ref={(ref) => (chartRefs.current[16] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Huella de carbono sobre valor agregado: El gráfico muestra el ratio de huella de carbon sobre el valor agregado para diferentes tecnologías, es decir, la huella de carbono de cada sector por cada mil de millones de pesos de aporte al PIB. Esta se mida en kilo toneladas de CO2 equivalente dividido en {coins[values.country]}. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div style={{width: "1200px", height: "560px"}}>
                        <Bar data={ChartData16} options={graph_options16}/>
                      </div>
                    </div>
                    <div className="mt-10" key={17} ref={(ref) => (chartRefs.current[17] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Empleo directo e indirecto: En el gráfico se observa la cantidad total de empleos directos e indirectos generados para diferentes tecnologías del escenario {scenary_data[appState.activeScenary-1]} {capitalize(location.state.values.country)} {values.pelp_year}. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div style={{width: "1200px", height: "500px"}}>
                        <Bar data={ChartData17} options={graph_options17}/>
                      </div>
                    </div>
                    <div className="mt-10" key={18} ref={(ref) => (chartRefs.current[18] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Análisis de remuneraciones: En la tabla se muestra un análisis de remuneraciones del escenario {scenary_data[appState.activeScenary-1]} {capitalize(location.state.values.country)} {values.pelp_year}. Las columnas de esta tabla corresponden a diversos tipos de tecnologías y las filas corresponden a los indicadores, cada una con diferentes unidades de medida. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.</h1>
                      <div style={{width: "100%"}}>
                      <MUIDataTable
                        title={data["0"]["14"].title}
                        data={remuneration_analisis_data}
                        columns={remuneration_analisis_columns}
                        options={options}
                        ></MUIDataTable></div>
                    </div>
                    <div className="mt-10" key={19} ref={(ref) => (chartRefs.current[19] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Empleo histórico: El gráfico muestra el avance histórico de los empleos directos generados para diferentes tipos de tecnologías.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Line data={ChartData18} options={graph_options18}/>
                      </div>
                    </div>
                    
                    <div className="mt-10" key={20} ref={(ref) => (chartRefs.current[20] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Demanda final por tecnología: El gráfico muestra el ratio de número de empleos totales y directos generados divididos por la demanda final para diferentes tipos de tecnologías del escenario {scenary_data[appState.activeScenary-1]} {capitalize(location.state.values.country)} {values.pelp_year} y el año de la MIP base.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData20} options={graph_options20}/>
                      </div>
                    </div>


                  </div>
        )
    }
    else{
        return(
          <div className='mt-16'>
          <h1 className='flex justify-center mb-3'><strong>Contrata la suscripción	premium de EcoKinesis para poder guardar y descargar reportes</strong></h1>
          <div className='flex justify-center'>
              <div className='mb-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", maxWidth:"540px", maxHeight:"380px"}}>
                <div className='justify-center align-items-center text-center'>
                  <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
                  <div className="text-sm mt-3" style={{color:"gray"}}><strong>Contrata la suscripción premium de EcoKinesis para poder usar esta sección</strong></div>
                  <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", minWidth: "120px", maxWidth:"260px", height:"54px", marginTop:"10px", marginBottom: "10px"}} onClick={()=>{navigate("/dashboard/premium")}}>Contratar</button>
                </div>
              </div>
          </div>
      </div>
        )
    }
  }

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <button onClick={() => {navigate("/dashboard/simulations/main/predefined_scenary_results", {state: {values,summary, componentsmip, detailcompositionmip,impactscontributionmip, exportimportsmip, capitaldistributionmip, remunerationdistributionmip, fiscalimpactmip,directemissionsmip, carbonfootprintmip, inducedemploymentmip, remunerationanalisismip, pibemploymentmip, finaldemandmip, coins, data, scenary_data}})}} style={{color:"white",backgroundColor:"gray",borderRadius:"10px", width:"200px", height: "60px"}}>Volver a mi simulación</button>
      </div>  
      <Header title="Generar reporte simulación escenario predefinido"/>
      <p className='text-gray-500' style={{maxWidth: "85%"}}>En esta sección podrás guardar y descargar los resultados de tu simulación de escenario predefinido. Selecciona uno de los escenarios disponibles e ingresa el nombre de tu reporte. Si pulsas el botón de descarga, se descargará un PDF con tus resultados. Si pulsas la opción de guardar reporte, este se guardará en la zona de reportes. Un reporte no puede ser guardado si es que ya existe uno en tu cuenta con el mismo nombre. En la zona inferior verás la lista de tablas y gráficos de tu simulación que se almacenarán en tu reporte. Estas opciones sólo están disponible para usuarios que cuenten con la <Link to= "/dashboard/premium" className='text-blue-500'>suscripción premium de EcoKinesis</Link>.</p>
      {Render(user_type)}
      <ModalLoader msg={msg} msgporcentage={msgporcentage} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default PredefinedScenaryPDF


