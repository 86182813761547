import React, {useState} from 'react'
import './LoginSignup.css'
import email_icon from '../../assets/email.png'
import password from "../../assets/password.png"
import logo from '../../assets/white_logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { API_PORT } from '../../API_PORT'
import LoadingScreen from '../../components/LoadingScreen'

//Verify code for change user password
const VerifyCode = () => {
    const [loadingscreen, setLoadingScreen] = useState({started: false});
    const [open, setOpen] = useState(false);

    const [values, setValues] = useState({
        "email": "",
        "code": ""
    });

    const navigate = useNavigate();

    const handleInput = (event) =>{
        setValues(prev => ({...prev, [event.target.name]:event.target.value}))
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        const url = API_PORT + '/admin/users_accounts/verify-reset-code/';
        if(values.email !== "" || values.code !== " "){
            setOpen(true);
            setLoadingScreen(prevState => {return {...prevState, started: true}});
            try {
                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(values)
                });
          
                if (!response.ok) {
                  throw new Error('Error al solicitar el restablecimiento de contraseña');
                }
          
                const responseData = await response.json();
                console.log('Respuesta del servidor:', responseData);
                if(response.ok){
                    const token = responseData
                    navigate("/verify_code/change_pass", {state:{token}})
                }
              } catch (error) {
                console.error('Error al intentar restablecer la contraseña:', error.message);
                alert("Error, el código de verificación utilizado ha expirado o no es valido.")
                setOpen(false);
                setLoadingScreen(prevState => {return {...prevState, started: false}});
                // Aquí puedes manejar el error como necesites
              }
        }
        else{
            alert("No has ingresado el mail o código de recuperación")
        }
      };

  return (
    <div>
        <div className='container2 text-white'>
            <h1 style={{position:"absolute", left:"73%", transform: "translateX(-50%);"}} title='Volver a ecokinesis.cl'><Link to="https://ecokinesis.cl/"><strong>Volver al inicio</strong></Link></h1>
        </div>
        <div className='container bg-login'>
            <div className='header'>
                <img src={logo} className="logo"alt=''></img>
                <div className='text'>Verificar código</div>
                <div className='subtext white mb-6'>Ingresa tu correo electrónico y el código que recibiste. Si estos son correctos, podrás restablecer tu contraseña posteriormente</div>
            </div>
            <div className='container3a'>
                <form action = "" onSubmit={handleSubmit}>
                    <div className='inputs'>
                        <div className='input'>
                            <img src={email_icon} alt='' />
                            <label htmlFor='email'></label>
                            <input type='email' placeholder='Correo Electrónico' name='email'
                            onChange={handleInput} className='form-control rounded-0'/>
                        </div>
                        <div className='input'>
                            <img src={password} alt='' />
                            <label htmlFor='code'></label>
                            <input type='string' placeholder='Código de verificación' name='code'
                            onChange={handleInput} className='form-control rounded-0'/>
                        </div>
                    </div>
                    <div className='container4 justify-center'>
                        <button className="submit" type="submit">Restablecer contraseña</button>
                    </div>     
                </form>
            </div>
        </div>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default VerifyCode