import { React, useState, useContext} from 'react'
import { Button } from '../../../components';
import { Header } from '../../../components';
import { Link, useNavigate } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen';
import password_icon from '../../../assets/password.png'
import { ChangePass } from '../../LoginPage/Validation';
import { UserContext } from '../../../contexts/UserContext';
import { API_PORT } from '../../../API_PORT';
import { IoEye, IoEyeOff } from 'react-icons/io5'; // Importa los íconos


const ModifyPass = () => {
  const [loadingscreen, setLoadingScreen] = useState({started: false});
  const [open, setOpen] = useState(false);

  const [values, setValues] = useState({
    "current_pass": "",
    "new_pass": ""
  });

  const [showPassword, setShowPassword] = useState(false); // Estado para visibilidad de contraseña

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

  const userContext = useContext(UserContext);

  const token = userContext.value.access_token;


  const navigate = useNavigate();

  const [errors, setErrors] = useState({});

  const link= API_PORT+ "/admin/users_accounts/user/modify_pass/?token="

const responsenavigate = (response) =>{
  if(response.detail === "Usuario modificado exitosamente"){
    alert("Su contraseña ha sido cambiada exitosamente")
    navigate("/dashboard/account")
  }
  else{
    alert(response.detail)
  }
  setOpen(false);
  setLoadingScreen(prevState => {return {...prevState, started: false}});
}

const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
};

const handleSubmit = (event) =>{
    event.preventDefault();
    setErrors(ChangePass(values));
    if(errors.current_pass === "" && errors.new_pass === ""){
      setOpen(true);
      setLoadingScreen(prevState => {return {...prevState, started: true}});
      fetch(link+token, {
          method: 'PATCH',
          headers: {
               'Content-type': 'application/json'
          },
          body: JSON.stringify(values)
          }).then((res => res.json()))
          .catch((error) => {
            console.log(error)
            alert("No se ha podido cambiar tu contraseña. Recarga tu sesión o inténtalo nuevamente más tarde.")
          })
          .then((response) => responsenavigate(response)
          );

  }
}

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-10'>
            <Link to="/dashboard/account" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  
      <Header title="Cambiar Contraseña"/>
      <h1 className='mb-6'>Ingresa tu contraseña actual y una nueva contraseña para actualizar. La nueva contraseña debe cumplir con el formato de 8 dígitos como mínimo, 1 letra, 1 número y 1 mayúscula como mínimo.</h1>
      <form action = "" onSubmit={handleSubmit}>
                <div className='inputs'>
                  <div className='input'>
                        <img src={password_icon} alt='' />
                        <label htmlFor='current_pass'></label>
                        <input type={showPassword ? 'text' : 'password'} placeholder='Contraseña actual' name='current_pass'
                        onChange={handleInput} className='form-control rounded-0' style={{maxWidth:"160px"}}/>
                        {errors.current_pass && <span style={{fontSize: "10px", maxWidth: "80px"}}>{errors.current_pass}</span>}
                        <button 
                            type='button' 
                            onClick={togglePasswordVisibility}
                            title={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                            style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: "auto", marginRight: "auto" }} // Estilos para el botón
                        >
                            {showPassword ? <IoEyeOff size={20} /> : <IoEye size={20} />}
                        </button>
                    </div>
                    <div className='input'>
                        <img src={password_icon} alt='' />
                        <label htmlFor='new_pass'></label>
                        <input type={showPassword ? 'text' : 'password'} placeholder='Contraseña nueva' name='new_pass'
                        onChange={handleInput} className='form-control rounded-0' style={{maxWidth:"160px"}}/>
                        {errors.new_pass && <span style={{fontSize: "10px", maxWidth: "80px"}}>{errors.new_pass}</span>}
                        <button 
                            type='button' 
                            onClick={togglePasswordVisibility}
                            title={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                            style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: "auto", marginRight: "auto" }} // Estilos para el botón
                        >
                            {showPassword ? <IoEyeOff size={20} /> : <IoEye size={20} />}
                        </button>
                    </div>
                </div>
                <div className='flex justify-center items-center gap-20 flex-wrap'>
                  <button className="submit" type="submit" title='Pulsa doble click para cambiar tu contraseña'>Cambiar contraseña</button>
                </div>     
        </form>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>

    </div>
  )
}

export default ModifyPass