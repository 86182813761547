import {React} from 'react'
import { Header, Button} from '../../../../components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import capitalize from '../../../../hook/capitalize';

const SimulationOperationInformation = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const values = location.state.values;
    const configuration = location.state.configuration;
    const coins = location.state.coins;
    const date = location.state.date;
    const name = location.state.name;
    const time = location.state.time;

    function Encendido(costos){
        if(costos === true){return("Incluidos")}
        else{return("No incluidos")}
      }
    
      function Tiempo(tiempos){
        if(tiempos === true){return("Incluidos")}
        else{return("No incluidos")}
      }
    
      function Rampa(ramp){
        if(ramp === true){return("Incluidos")}
        else{return("No incluidos")}
      }
    

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
    <div className='flex justify-between items-center mb-6'>
          <Link to="/dashboard/simulations/main/simulation_operation_list" >
            <Button
              color="white"
              bgColor="gray"
              text="Volver a la lista de escenarios de operación simulada"
              borderRadius="10px"
              size="md"
            />
          </Link>
    </div> 
    <Header title="Datos de escenario de operación simulada"/>
    <h1 className='flex justify-center items-center mt-6'><strong>Datos {name}</strong></h1>
                        <h1 className='flex justify-center items-center'>País Ingresado: {capitalize(values.country)}</h1>
                        <h1 className='flex justify-center items-center'>Año PELP Ingresado: {values.pelp_year}</h1>
                        <h1 className='flex justify-center items-center'>Escenario Ingresado: {values.scenary}</h1>
                        <h1 className='flex justify-center items-center'>Fecha del escenario: {date}</h1>
                        <h1 className='flex justify-center items-center'>Tiempo de ejecución del escenario: {time}</h1>
    <h1 className='flex justify-center items-center mt-9'><strong>Valores Ingresados</strong></h1>
                        <h1 className='flex justify-center items-center mt-3'><strong>Configuración de Escenario</strong></h1>
                        <h1 className='flex justify-center items-center'>Solver: {configuration.Solver}</h1>
                        <h1 className='flex justify-center items-center'>Costos de encedido: {Encendido(configuration.CostosEncendido)}</h1>
                        <h1 className='flex justify-center items-center'>Restricciones de tiempo mínimo: {Tiempo(configuration.Mintime)}</h1>
                        <h1 className='flex justify-center items-center'>Restricciones de rampa: {Rampa(configuration.Ramp)}</h1>
                        <h1 className='flex justify-center items-center mt-4'><strong>Porcentajes de factores de escenario</strong></h1>
                        <h1 className='flex justify-center items-center'>Incremento precio combustible: {configuration.Combustible}%</h1>
                        <h1 className='flex justify-center items-center'>Incremento de eficiencia: {configuration.Eficiencia}%</h1>
                        <h1 className='flex justify-center items-center'>Disponibilidad Hídrica: {configuration.Hidrica}%</h1>
                        <h1 className='flex justify-center items-center'>Factor de Demanda: {configuration.FactorDemanda}%</h1>
                        <h1 className='flex justify-center items-center'>Factor de Demanda de H2: {configuration.DemandaH2}%</h1>

    <div className='flex justify-center items-center mt-24'><TooltipComponent content="Al pulsar este botón se enviarán los datos actuales en la zona de selección de datos del escenario de operación simulada. Una vez en esta zona oprime el botón de la zona inferior de 'Cargar los datos de la simulación seleccionada' para cargar estos datos."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={()=>{navigate("/dashboard/simulations/main/simulation_operation_selector2",{ state: { configuration, values, coins } })}}>Utilizar datos en escenario de operación simulada</button></TooltipComponent></div>
  </div>
  )
}

export default SimulationOperationInformation