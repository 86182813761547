import React from 'react'
import { Header } from '../../../components';
import check from "../../../assets//check.svg"
import { useNavigate } from 'react-router-dom';

const Premium = () => {
  const navigate = useNavigate()
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Premium"/>
      <h1 className='mb-3'>Descubre todo el potencial de EcoKinesis. Con la suscripción premium de EcoKinesis podrás desbloquear nuevas características para mejorar tu experiencia en la plataforma. Accede a nuevos tipos de simulaciones; guarda, descarga y compara tus resultados, entre otras características</h1>
      <h1 className='mb-10'>NOTA: La suscripción premium de EcoKinesis no está disponible para adquirir en estos momentos, pero puedes solicitarla usando la zona de servicios adicionales.
      </h1>
      <Header title="Características"/>
      <div className='flex'>
        <div>
          <div style={{backgroundColor: "#425df9", color: "white", borderRadius: "10px", margin: "0px", width:"300px", height:"360px", padding: "20px"}}>
            <p className='flex align-items-center mb-5'><img src={check} className='mr-3' alt=''></img><strong>Guarda y descarga en reportes los resultados de todas tus simulaciones</strong></p>
            <p className='flex align-items-center mb-5'><img src={check} className='mr-3' alt=''></img><strong>Acceso a simulaciones de operación simulada</strong></p>
            <p className='flex align-items-center mb-5'><img src={check} className='mr-3' alt=''></img><strong>Acceso a simulaciones de escenarios Leontieff</strong></p>
            <p className='flex align-items-center mb-5'><img src={check} className='mr-3' alt=''></img><strong>Acceso a descarga de datos de gráficos y tablas en CSV</strong></p>
          </div>
        </div>
        <div style={{marginLeft: "20%"}}>
          <div className='mb-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", maxWidth:"540px", maxHeight:"380px"}}>
            <div className='justify-center align-items-center text-center'>
              <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
              <div className="text-sm mt-3" style={{color:"gray"}}><strong>Utiliza la zona de servicios adicionales para solicitar la suscripción premium para tu cuenta de EcoKinesis</strong></div>
              <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", minWidth: "160px", maxWidth:"240px", height:"54px", marginTop:"10px", marginBottom: "10px"}} onClick={()=>{navigate("/dashboard/services")}}>Contratar</button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default Premium

/*
<div className='' style={{marginLeft: "360px"}}>
          <div className='mb-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", width:"540px", height:"180px"}}>
            <div className='justify-center align-items-center text-center'>
              <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
              <div className="text-4xl" style={{color:"blue"}}><strong>$4.99 USD</strong></div>
              <div className="text-sm"style={{color:"gray"}}><strong>Renovación mensual</strong></div>
              <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", width:"260px", height:"54px", marginTop:"10px"}}>Contratar</button>
            </div>
          </div>
        </div>
*/