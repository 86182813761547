import React from "react";

// Components.
import PersonalizedMessage from "../Charts/PersonalizedMessage";

//Not Found page
function NotFound() {
    return (
        <div className="text-white"><PersonalizedMessage
        title="Página no encontrada 😔"
        secondary="Vuelve a iniciar sesión en plataforma.ecokinesis.cl"
    /></div>
        
    );
}

export default NotFound;