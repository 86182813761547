import {React, useState, useEffect, useContext} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { Header} from '../../../components';
import {API_PORT} from "../../../API_PORT"
import Modal from '../../../components/Modal';
import { useNavigate } from 'react-router-dom';
import capitalize from '../../../hook/capitalize';
import LoadingScreen from '../../../components/LoadingScreen';

const ExpandedMIP = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const link= API_PORT + "/admin/database_information/get-avaible-MIPs?token="+token

  const { value } = useContext(UserContext);
  const selected_country = value?.country ?? "Sin asignar";
  const selected_year = value?.year ?? "Sin asignar";

  const [progress] = useState({started: false, pc: 0});
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [msg, setMsg] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  const [mips, setMips] = useState([])

  const navigate = useNavigate();

  const [values, setValues] = useState({
    "year": selected_year,
    "country": selected_country, 
});

  //MIP data
  const [calibratedmip, setcalibratedmip] = useState([])
  const [inverted, setinverted] = useState([])
  const [calibratedmipcomplete, setcalibratedmipcomplete] = useState([])
  const [summary, setsumary] = useState([])

  //Economic data
  const [components, setcomponents] = useState([])
  const [detailcomposition, setdetailcomposition] = useState([])
  const [impactscontribution, setimpactscontribution] = useState([])
  const [exportimports, setexportsimports] = useState([])
  const [ratefosilrenovable, setratefosilrenovable] = useState([])
  const [capitaldistribution, setcapitaldistribution] = useState([])
  const [remunerationdistribution, setremunerationdistribution] = useState([])
  const [fiscalimpact, setfiscalimpact] = useState([])
  const [productionmultipliers, setproductionmultipliers] = useState([])
  const [gwhcontribution, setgwhcontribution] = useState([])
  const [pibmultipliers, setpibmultipliers] = useState([])
  const [rentmultipliers, setrentmultipliers] = useState([])
  const [inversionmultipliers, setinversionmultipliers] = useState([])
  const [techimportance, settechimportance] = useState([])

  //Emissions
  const [directemissions, setdirectemissions] = useState([])
  const [carbonfootprint, setcarbonfootprint] = useState([])
  const [carbonsource, setcarbonsource] = useState([])
  const [carbonessh2, setcarbonessh2] = useState([])
  const [carbonfinaldemand, setcarbonfinaldemand] = useState([])
  const [carbonfinaldemandessh2, setcarbonfinaldemandessh2] = useState([])

  //Employment
  const [inducedemployment, setinducedemployment] = useState([])
  const [remunerationanalisis, setremunerationanalisis] = useState([])
  const [historicalemployees, sethistoricalemployees] = useState([])
  const [pibemployment, setpibemployment] = useState([])
  const [finaldemand, setfinaldemand] = useState([])
  const [employmentgwh, setemploymentgwh] = useState([])
  const [labormultipliers, setlabormultipliers] = useState([])

  //Coins
  const [coins, setCoins] = useState([]) 


  const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setMips(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      })
    }, []);

  var mip_values = mips.MIPbase
  var year_data= [{value:selected_year,label:selected_year}];
  var country_data= [{value:selected_country,label:capitalize(selected_country)}];

  mip_values?.forEach((element) =>
    {year_data.push({value: element[0], label: element[0]});
    country_data.push({value: element[1], label: capitalize(element[1])})}
  )

  const ExpandedMipResults = () => {
    //Mip Endpoints
    const calibrated_mip = API_PORT + "/expandedmip/mip/calibrated_MIP/resumed/" + values.country + "/" + values.year + "?token="+token
    const inverted_mip = API_PORT + "/expandedmip/mip/inverted/resumed/" + values.country + "/" + values.year + "?token="+token
    const calibrated_mip_complete = API_PORT + "/expandedmip/mip/calibrated_MIP/complete/" + values.country + "/" + values.year + "?token="+token
    const mip_summary = API_PORT + "/resumen/" + values.country + "/" + values.year + "?token="+token

    //Economics Endpoints
    const components_mip = API_PORT + "/expandedmip/economic/components/" + values.country + "/" + values.year + "?token="+token
    const detail_composition = API_PORT + "/expandedmip/economic/detail-composition/" + values.country + "/" + values.year + "?token="+token
    const impacts_contribution = API_PORT + "/expandedmip/economic/impacts-contribution/" + values.country + "/" + values.year + "?token="+token
    const export_imports = API_PORT + "/expandedmip/economic/export-imports/" + values.country + "/" + values.year + "?token="+token
    const rate_fosil = API_PORT + "/expandedmip/economic/rate-fosil-renovable/" + values.country + "/" + values.year + "?token="+token
    const capital_distribution = API_PORT + "/expandedmip/economic/capital-distribution/" + values.country + "/" + values.year + "?token="+token
    const remuneration_distribution = API_PORT + "/expandedmip/economic/remuneration-distribution/" + values.country + "/" + values.year + "?token="+token
    const fiscal_impact = API_PORT + "/expandedmip/economic/fiscal-impact/" + values.country + "/" + values.year + "?token="+token
    const production_multipliers = API_PORT + "/expandedmip/economic/production-multipliers/" + values.country + "/" + values.year + "?token="+token
    const gwh_contribution = API_PORT + "/expandedmip/economic/gwh-contribution/" + values.country + "/" + values.year + "?token="+token
    const pib_multipliers = API_PORT + "/expandedmip/economic/PIB-multipliers/" + values.country + "/" + values.year + "?token="+token
    const rent_multipliers= API_PORT + "/expandedmip/economic/multiplicadores-renta/" + values.country + "/" + values.year + "?token="+token
    const inversion_multipliers= API_PORT + "/expandedmip/economic/multiplicadores-inversion/" + values.country + "/" + values.year + "?token="+token
    const tech_importance= API_PORT + "/expandedmip/economic/tech-importance-according-multipliers/" + values.country + "/" + values.year + "?token="+token

    //Emissions Endpoints
    const direct_emissions = API_PORT + "/expandedmip/emissions/direct-emissions-and-carbon-footprint/" + values.country + "/" + values.year + "?token="+token
    const carbon_footprint = API_PORT + "/expandedmip/emissions/carbon-footprint-aggregate-value-ratio/" + values.country + "/" + values.year + "?token="+token
    const carbon_source = API_PORT + "/expandedmip/emissions/carbon-footprint-by-source/" + values.country + "/" + values.year + "?token="+token
    const carbon_essh2 = API_PORT + "/expandedmip/emissions/carbon-footprint-by-source-only-gen-essh2/" + values.country + "/" + values.year + "?token="+token
    const carbon_final_demand= API_PORT + "/expandedmip/emissions/carbon-footprint-by-final-demand-by-source/" + values.country + "/" + values.year + "?token="+token
    const carbon_final_demand_essh2 = API_PORT + "/expandedmip/emissions/carbon-footprint-by-final-demand-by-source-only-gen-essh2/" + values.country + "/" + values.year + "?token="+token

    //Empolyment Endpoints
    const induced_employment = API_PORT + "/expandedmip/labor/induced-and-direct-employment/" + values.country + "/" + values.year + "?token="+token
    const remuneration_analisis = API_PORT + "/expandedmip/labor/remuneration-analisis/" + values.country + "/" + values.year + "?token="+token
    const historical_employees = API_PORT + "/expandedmip/labor/historical-employees/" + values.country + "/" + values.year + "?token="+token
    const pib_employment = API_PORT + "/expandedmip/labor/pib-per-employment/" + values.country + "/" + values.year + "?token="+token
    const final_demand = API_PORT + "/expandedmip/labor/empleo-demanda-final/" + values.country + "/" + values.year + "?token="+token
    const employment_gwh= API_PORT + "/expandedmip/labor/employment-per-gwh/" + values.country + "/" + values.year + "?token="+token
    const labor_multipliers = API_PORT + "/expandedmip/labor/labor-multipliers/" + values.country + "/" + values.year + "?token="+token

    //Other endpoints
    const coin_endpoint = API_PORT + "/admin/database_information/get-coin-name?token=" + token

    if (values.country !== "Sin asignar" && values.year !== "Sin asignar"){
      setIsModalOpen(true)
      setMsg("Obteniendo los datos, no cierre esta pestaña hasta completar el proceso.")
      setLoaderScreen(prevState => {
        return {...prevState, started: true}
      })
      //Mip Fetch
      fetch(calibrated_mip, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcalibratedmip(data);})
      fetch(inverted_mip, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setinverted(data);})
      fetch(calibrated_mip_complete, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcalibratedmipcomplete(data);})
      fetch(mip_summary, {
        method: 'POST',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setsumary(data);})

      //Economics Fetch
      fetch(components_mip, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcomponents(data);})
      fetch(detail_composition, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setdetailcomposition(data);})
      fetch(impacts_contribution, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setimpactscontribution(data);})
      fetch(export_imports, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setexportsimports(data);})
      fetch(rate_fosil, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setratefosilrenovable(data);})
      fetch(capital_distribution, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcapitaldistribution(data);})
      fetch(remuneration_distribution, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setremunerationdistribution(data);})
      fetch(fiscal_impact, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setfiscalimpact(data);})
      fetch(production_multipliers, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setproductionmultipliers(data);})
      fetch(gwh_contribution, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setgwhcontribution(data);})
      fetch(pib_multipliers, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setpibmultipliers(data);})
      fetch(rent_multipliers, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setrentmultipliers(data);})
      fetch(inversion_multipliers, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setinversionmultipliers(data);})
      fetch(tech_importance, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {settechimportance(data);})

      //Emissions Fetch
      fetch(direct_emissions, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setdirectemissions(data);})
      fetch(carbon_footprint, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcarbonfootprint(data);})
      fetch(carbon_source, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcarbonsource(data);})
      fetch(carbon_essh2, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcarbonessh2(data);})
      fetch(carbon_final_demand, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcarbonfinaldemand(data);})
      fetch(carbon_final_demand_essh2, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcarbonfinaldemandessh2(data);})


      //Employment Fetch
      fetch(induced_employment, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setinducedemployment(data);})
      fetch(remuneration_analisis, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setremunerationanalisis(data);})
      fetch(historical_employees, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {sethistoricalemployees(data);})
      fetch(pib_employment, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setpibemployment(data);})
      fetch(final_demand, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setfinaldemand(data);})
      fetch(employment_gwh, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setemploymentgwh(data);})
      fetch(labor_multipliers, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setlabormultipliers(data);})

      //Other Endpoints
      fetch(coin_endpoint, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setCoins(data);})
    }
    else{
      alert("No has seleccionado un valor")
    }
  }

  const NavigateResults = () => {
    if (calibratedmip.title && inverted.title && calibratedmipcomplete.title && summary.title && components.description && detailcomposition.title && impactscontribution.description && exportimports.title && ratefosilrenovable.title && capitaldistribution.title && remunerationdistribution.title && fiscalimpact.title && productionmultipliers.title && gwhcontribution.title && pibmultipliers.title && rentmultipliers.title && inversionmultipliers.title && techimportance.title && directemissions.title && carbonfootprint.title && carbonsource.title && carbonessh2.title && carbonfinaldemand.title && carbonfinaldemandessh2.title && inducedemployment.title && remunerationanalisis.title && historicalemployees.title && pibemployment.title && finaldemand.title && employmentgwh.title && labormultipliers.title && coins[values.country]){
      alert("Se han obtenido los datos. Pulsa para continuar.")
      navigate("/dashboard/expanded_mip/results",{state: {values,calibratedmip, inverted, calibratedmipcomplete, summary, components, detailcomposition,impactscontribution, exportimports, ratefosilrenovable, capitaldistribution, remunerationdistribution, fiscalimpact, productionmultipliers, gwhcontribution, pibmultipliers, rentmultipliers, inversionmultipliers, techimportance, directemissions, carbonfootprint, carbonsource, carbonessh2, carbonfinaldemand, carbonfinaldemandessh2, inducedemployment, remunerationanalisis, historicalemployees, pibemployment, finaldemand, employmentgwh, labormultipliers, coins}})
    }
  }
  
  NavigateResults()

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="MIP Expandida"/>
      <p className='text-gray-500'>En esta sección podrás ver las tablas y gráficos de la MIP Expandida de EcoKinesis para los indicadores MIP, economía, empleo y emisiones. Ingresa un año y un país para ver posteriormente los resultados.</p>
      <div className='mt-10'><Header title="Selecciona 1 opción disponible para el año y país"/></div>
      <div className='grid grid-cols-3 gap-3'>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>Año</strong></h1>
            <label htmlFor='year'></label>
            <select type='string' name='year'
              onChange={handleInput} className='form-select rounded-0 w-60 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {year_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>País</strong></h1>
          <label htmlFor='country'></label>
            <select type='string' name='country'
              onChange={handleInput} className='form-select rounded-0 w-60 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {country_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
      </div>
      <div className='mt-44'>
        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={ExpandedMipResults}>Ver Resultados</button>
      </div>
      <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default ExpandedMIP