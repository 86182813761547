import { React, useState, useContext} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { Button } from '../../../components';
import { Header } from '../../../components';
import { Link, useNavigate } from 'react-router-dom';
import user_icon from '../../../assets/person.png'
import { API_PORT } from '../../../API_PORT';
import { useLocation } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen';


const DeleteUser = () => {
  const navigate = useNavigate();
  const location = useLocation()

  const [loadingscreen, setLoadingScreen] = useState({started: false});
  const [open, setOpen] = useState(false);

  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;

  const [values, setValues] = useState({
    "IdentifierType": "id",
    "Identifier": "ID de usuario"
  });

const responsenavigate = (response) =>{
  if(response.detail === "Usuario eliminado exitosamente"){
    alert("Se ha eliminado el usuario exitosamente")
    navigate("/dashboard/adminuser")
  }
  else{
    alert(response.detail)
  }
  setOpen(false);
  setLoadingScreen(prevState => {return {...prevState, started: false}});
}

const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
};

const handleSubmit = (event) =>{
    event.preventDefault();
    if(values.Identifier !== "ID de usuario" && values.Identifier !== ""){
      setOpen(true);
      setLoadingScreen(prevState => {return {...prevState, started: true}});
        fetch(API_PORT + '/admin/users_accounts/admin/delete_user/?token='+token, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(values)
            }).then((res => res.json()))
            .catch((error) => {
              console.log(error)
              alert("No se ha podido eliminar al usuario. Inténtalo nuevamente más tarde.")
              setOpen(false);
              setLoadingScreen(prevState => {return {...prevState, started: false}});})
            .then((response) => responsenavigate(response)
            );
    }
    else{
      alert("No has ingresado una ID")
    }
}

if(location.state?.user.id){
  values.Identifier= location.state.user.id
}

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-10'>
            <Link to="/dashboard/adminuser" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  
      <Header title="Eliminar usuario"/>

      <h1>Ingresa una ID de usuario valida para eliminar a este de la base de datos de EcoKinesis.</h1>
      <form action = "" onSubmit={handleSubmit}>
                <div className='inputs'>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='Identifier'></label>
                        <input type='string' placeholder={values.Identifier} name='Identifier'
                        onChange={handleInput} className='form-control rounded-0'/>
                    </div>
                </div>
                <div className='flex justify-center items-center gap-20 flex-wrap'>
                  <button className="submit bg-red" type="submit">Eliminar usuario</button>
                </div>     
        </form>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default DeleteUser