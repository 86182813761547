import {React,useState} from 'react'
import './LoginSignup.css'
import password from "../../assets/password.png"
import logo from '../../assets/white_logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { API_PORT } from '../../API_PORT'
import { useLocation } from 'react-router-dom'
import { NewPass } from './Validation'
import LoadingScreen from '../../components/LoadingScreen'
import { IoEye, IoEyeOff } from 'react-icons/io5'; // Importa los íconos

//Change Password of user. Needs verification code/token for this
const ChangePassword = () => {
    const [loadingscreen, setLoadingScreen] = useState({started: false});
    const [open, setOpen] = useState(false);

    const [values, setValues] = useState({
        "password": "",
        "password2": "",
    });

    const location = useLocation();
    const token = location.state?.token;
    const navigate = useNavigate();

    const [errors, setErrors] = useState({});

    const handleInput = (event) =>{
        setValues(prev => ({...prev, [event.target.name]:event.target.value}))
    };

    const [showPassword, setShowPassword] = useState(false); // Estado para visibilidad de contraseña

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors(NewPass(values));
        const url = API_PORT + '/admin/users_accounts/reset_password/?token=' + token;
        if(errors.password === ""){
            if(values.password === values.password2){
                setOpen(true);
            setLoadingScreen(prevState => {return {...prevState, started: true}});
            try {
                const response = await fetch(url, {
                  method: 'PATCH',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(values.password)
                });
          
                if (!response.ok) {
                  throw new Error('Error al solicitar el restablecimiento de contraseña');
                }
          
                const responseData = await response.json();
                console.log('Respuesta del servidor:', responseData);
                // Verifica la respuesta del servidor
                if (responseData.detail === 'Usuario modificado exitosamente') {
                    // Realiza la navegación a la página principal
                    alert("Se ha restablecido su contraseña exitosamente. Para ingresar a la plataforma de EcoKinesis deberá introducir su email y su nueva contraseña.")
                    navigate("/")
                } else {
                    // Maneja otros casos de respuesta del servidor si es necesario
                    console.log('Respuesta no esperada del servidor:', responseData);
                    alert(responseData.detail)
                    setOpen(false);
                    setLoadingScreen(prevState => {return {...prevState, started: false}});
                }
              } catch (error) {
                console.error('Error al intentar restablecer la contraseña:', error.message);
                // Aquí puedes manejar el error como necesites
              }
            }
            else{
                alert("Las contraseñas ingresadas no coinciden.")
            }
        }
      };

  return (
    <div>
        <div className='container2 text-white'>
            <h1 style={{position:"absolute", left:"73%", transform: "translateX(-50%);"}} title='Volver a ecokinesis.cl'><Link to="https://ecokinesis.cl/"><strong>Volver al inicio</strong></Link></h1>
        </div>
        <div className='container bg-login'>
            <div className='header'>
                <img src={logo} className="logo"alt=''></img>
                <div className='text'>Cambiar contraseña</div>
                <div className='subtext white mb-10'>Ingresa tu nueva contraseña para tu cuenta de EcoKinesis. Esta debe cumplir con el formato de por lo menos 8 dígitos, 1 letra, 1 mayúscula y 1 minúscula</div>
            </div>
            <div className='container3a'>
                <form action = "" onSubmit={handleSubmit}>
                    <div className='inputs'>
                        <div className='input'>
                            <img src={password} alt='' />
                            <label htmlFor='password'></label>
                            <input type={showPassword ? 'text' : 'password'} placeholder='Nueva contraseña' name='password'
                            onChange={handleInput} className='form-control rounded-0'/>
                            {errors.password && <span className='text-danger' style={{fontSize: "10px"}}>{errors.password}</span>}
                            <button 
                            type='button' 
                            onClick={togglePasswordVisibility}
                            title={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                            style={{ background: 'none', border: 'none', cursor: 'pointer' }} // Estilos para el botón
                        >
                            {showPassword ? <IoEyeOff size={20} /> : <IoEye size={20} />}
                        </button>
                        </div>
                        <div className='input'>
                            <img src={password} alt='' />
                            <label htmlFor='password'></label>
                            <input type={showPassword ? 'text' : 'password'} placeholder='Confirmar contraseña' name='password2'
                            onChange={handleInput} className='form-control rounded-0'/>
                            <button 
                            type='button' 
                            onClick={togglePasswordVisibility}
                            title={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                            style={{ background: 'none', border: 'none', cursor: 'pointer' }} // Estilos para el botón
                        >
                            {showPassword ? <IoEyeOff size={20} /> : <IoEye size={20} />}
                        </button>
                        </div>
                    </div>
                    <div className='container4 justify-center'>
                        <button className="submit" type="submit" title='Pulse doble click para cambiar la contraseña'>Cambiar contraseña</button>
                    </div>     
                </form>
            </div>
        </div>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default ChangePassword