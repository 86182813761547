//Imports
import React, { useEffect, useContext, useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai';
import { RiNotification3Line } from 'react-icons/ri';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { FiSettings } from 'react-icons/fi';
import { FiBookmark } from "react-icons/fi";
import avatar from '../data/avatar.jpg';
import { Notification, UserProfile } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { UserContext } from '../contexts/UserContext';
import { API_PORT } from '../API_PORT';
import Modal from "./Modal"
import Header from './Header';
import { useNavigate } from 'react-router-dom';

const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      >
      </span>
      {icon}
    </button>
  </TooltipComponent>
);

const Navbar = () => {
  const { activeMenu, setActiveMenu, isClicked, handleClick, screenSize, setScreenSize, currentColor, setThemeSettings, readedNotifications, setReadedNotifications } = useStateContext();
  
  const userContext = useContext(UserContext);

  const user = userContext.value.username

  const user_photo = userContext.value.user_photo;

  const navigate = useNavigate();

  const [progress] = useState({started: false, pc: 0});
  const [loaderscreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const token = userContext.value.access_token;
  const link= API_PORT + "/notifications/get-notification-list/?token="+token
  const [notifications, setNotifications] = useState([])

  //Actulization message
  const msg = 
  <div>
    <Header title="Historial de actualizaciones"/>
    <strong>Beta 1.4.1 - 11/09/2024</strong>
    <p>-Añadido la selección de año y país como opción en el menú principal</p>
    <p>-Añadido el sistema de notificaciones</p>
    <p>-Modificación en el funcionamiento de los escenarios predefinidos</p>
    <p>-Modificación en el funcionamiento de los escenarios de operación simulada</p>
    <p className='mb-6'>-Corrección de errores y mejoras en el rendimiento de la plataforma</p>

    <strong>Beta 1.2.2 - 08/08/2024</strong>
    <p>-Actualizaciones de todas las páginas del sitio web de ecokinesis.cl</p>
    <p>-Se ha cambiado el acceso a la plataforma de nombre usuario/contraseña a email/contraseña</p>
    <p>-Mejoras en el funcionamiento de los reportes </p>
    <p className='mb-6'>-Corrección de errores y mejoras en el rendimiento de la plataforma</p>

    <strong>Beta 1.1 - 16/07/2024</strong>
    <p>-Añadido el historial de actualizaciones en la zona superior derecha</p>
    <p>-Añadidas pantallas de carga entre secciones</p>
    <p>-Añadida la opción de convertir gráficos y tablas en CSV (sólo disponible para usuarios premium)</p>
    <p>-Añadida nueva opción para calificar la plataforma</p>
    <p className='mb-6'>-Solución de errores, corrección de textos en gráficos y tablas</p>

    <strong>Beta 1.0 - 27/06/2024</strong>
    <p>-Añadida la primera versión de EcoKinesis con las zonas de Base de Datos, MIP Expandida, Simulaciones, Reportes, Perfil y Servicios Adicionales</p>
  </div>

  //Auto window resize
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize)
  },[]);

  useEffect(() => {
    if(screenSize <= 960){
      setActiveMenu(false);
    } else{
      setActiveMenu(true);
    }
  }, [screenSize]);

  useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setNotifications(data);
      })
    },[link]);

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  //Avatar photo render on screen
  function avatarRender(){
    if (user_photo=== null){return(avatar)}
    else{return(API_PORT+user_photo)}
  }
  

const hasUnreadNotifications = notifications.some(notification => !notification.readed);

if(hasUnreadNotifications){
  setReadedNotifications(true)
}
else{
  setReadedNotifications(false)
} 


  //Return Navbar annd items
  return (
    <div className='flex justify-between p-2 md:mx-6 relative'>
      <NavButton title="Abrir/Cerrar Menú" customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu />}/>
      <div className='flex'>
        <button style={{width: "300px", height: "40px", borderRadius: "20px", color:"white", backgroundColor:"orange", marginRight: "20px"}} onClick={()=> navigate("/dashboard/account/evaluate_platform")}><strong>Califica la plataforma, haz click aquí</strong></button>
        
        <TooltipComponent content="Perfil" position='BottomCenter'>
          <div
            className='flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg'
            onClick={() => handleClick('userProfile')}
          >
            <p><span className='text-gray-400 font-bold ml-1 text-14'>Hola, {user}</span></p>
            <MdKeyboardArrowDown  className='text-gray-400 text-14'/>
            <img className='rounded-full w-8 h-8' style={{border: "2px solid", borderColor: currentColor}} src={avatarRender()} alt="user-profile"/>
          </div>
        </TooltipComponent>
        {readedNotifications ? (
        <NavButton
          title="Notificaciones"
          dotColor="rgb(254, 201, 15)"
          customFunc={() => handleClick('notification')}
          color={currentColor}
          icon={<RiNotification3Line />}
        />
      ) : (
        <NavButton
          title="Notificaciones"
          customFunc={() => handleClick('notification')}
          color={currentColor}
          icon={<RiNotification3Line />}
        />
      )}
        {isClicked.notification && (<Notification />)}
        <NavButton title="Historial de Actualizaciones" customFunc={()=> setIsModalOpen(true)} color={currentColor} icon={<FiBookmark/>}/>
        <NavButton title="Ajustes" customFunc={() => setThemeSettings(true)} color={currentColor} icon={<FiSettings/>}/>

        {isClicked.userProfile && (<UserProfile />)}
        <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      </div>
    </div>
  )
};

export default Navbar

/*
<NavButton title="Notificationes" dotColor="rgb(254, 201, 15)" customFunc={() => handleClick('notification')} color={currentColor} icon={<RiNotification3Line />} />
{isClicked.notification && (<Notification />)}
*/