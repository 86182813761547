import {React, useState, useContext, useRef, useEffect} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { Header} from '../../../components';
import { useNavigate, useLocation } from 'react-router-dom';
import capitalize from '../../../hook/capitalize';
import { Link } from 'react-router-dom';
import graphColors from '../../../hook/graphColors';
import ModalLoader from '../../../components/ModalLoader';
import ecokinesis from "../../../assets/ecokinesis.png"
import MUIDataTable from "mui-datatables";
import { Bar } from "react-chartjs-2";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import HorizontalTables from '../../../hook/HorizontalTables';
import { API_PORT } from '../../../API_PORT';
import DynamicTable from '../../../hook/DynamicTable';
import anid from "../../../assets/anid_rojo_azul.png"
import utfsm from "../../../assets/utfsm.png"
import LoadingScreen from '../../../components/LoadingScreen';

const GeneratePDFDatabase = () => {
  const [progress, setProgress] = useState(0);
  const [msg, setMsg] = useState(null);
  const [msgporcentage, setMsgPorcentage] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  const [usersReports, setUsersReports] = useState([])

  const userContext = useContext(UserContext);
  const user_type= userContext.value.user_type;
  const token = userContext.value.access_token;

  const navigate = useNavigate();
  const location = useLocation();

  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();

  //Define const of location
  const values = location.state.values
  const miporiginal = location.state.miporiginal
  const employment = location.state.employment
  const electric = location.state.electric
  const electricgenerationcosts = location.state.electricgenerationcosts
  const electriccapacity = location.state.electriccapacity
  const electricgeneration = location.state.electricgeneration
  const electrictotal = location.state.electrictotal
  const directemissions = location.state.directemissions
  const emissionsfactor = location.state.emissionsfactor
  const electricitydestiny = location.state.electricitydestiny
  const carbonfootprint = location.state.carbonfootprint
  const importedcarbon = location.state.importedcarbon

  const miporiginal_columns = location.state.miporiginal_columns
  const miporiginal_data = location.state.miporiginal_data
  const employment_columns = location.state.employment_columns
  const employment_data = location.state.employment_data
  const electric_columns = location.state.electric_columns
  const electric_data = location.state.electric_data
  const electric_capacity_columns = location.state.electric_capacity_columns
  const electric_capacity_data = location.state.electric_capacity_data
  const electric_generation_costs_columns = location.state.electric_generation_costs_columns
  const electric_generation_costs_data = location.state.electric_generation_costs_data
  const electric_generation_columns = location.state.electric_generation_columns
  const electric_generation_data = location.state.electric_generation_data
  const electric_total_columns = location.state.electric_total_columns
  const electric_total_data = location.state.electric_total_data
  const direct_emissions_columns = location.state.direct_emissions_columns
  const direct_emissions_data = location.state.direct_emissions_data
  const emissions_factor_columns = location.state.emissions_factor_columns
  const emissions_factors_data = location.state.emissions_factors_data
  const electricity_destiny_columns = location.state.electricity_destiny_columns
  const electricity_destiny_datasets = location.state.electricity_destiny_datasets
  const carbon_footprint_columns = location.state.carbon_footprint_columns
  const carbon_footprint_datasets = location.state.carbon_footprint_datasets
  const imported_carbon_columns = location.state.imported_carbon_columns
  const imported_carbon_data = location.state.imported_carbon_data
  const coins = location.state.coins

  //Data variables
  const options1={
    rowsPerPage: 19,
    selectableRows: "none",
    print: "false",
    download: "false",
    filter: "false",
    search: "false",
    viewColumns: "false",
    pagination: "false",
    resizableColumns: "true"
  }

  //Table 1
  var columns1 = []
  miporiginal_columns.forEach((element) => {
    columns1.push({
      name: element,
      label: element,
      options: {
        setCellProps: () => ({ style: { padding: 4, fontSize: 10 }}),
        setCellHeaderProps: () => ({ style: { padding: 4, fontSize: 10 }}),
      }
    })
  })

  //Table 9
  var columns2 = []
  electric_generation_columns.forEach((element) => {
    columns2.push({
      name: element,
      label: element,
      options: {
        setCellProps: () => ({ style: { padding: 4, fontSize: 12 }}),
        setCellHeaderProps: () => ({ style: { padding: 4, fontSize: 12 }}),
      }
    })
  })

  //Graph 3
  var em_col = []
      electric_columns.forEach((element) => {
        if (element && element !== "Total generación" && element !== "Total almacenamiento" && element !== "H2"){
          em_col.push(element)
        }
      })
      var em_data = []
      electric_data[0].forEach((element,index) => {
        if (index !== 0 && electric_columns[index] !== "Total generación" && electric_columns[index] !== "Total almacenamiento" && electric_columns[index] !== "H2"){
          em_data.push(element)
        }
      })
      const ChartData1 = {
        labels: em_col,
        datasets: [{
          label: "Generación [GWh]",
          data: em_data,
          backgroundColor: graphColors(em_col),
          borderWidth: 0
        }]
      }
      const graph_options1 = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Generación [GWh]"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.electric.title,
            align: "center",
          }
        }
      }

  //Graph 5
  var em_col2 = []
      electric_generation_costs_columns.forEach((element) => {
        if (element !== ""){
          em_col2.push(element)
        }
      })
      var em_data2 = []
      electric_generation_costs_data[0].forEach((element,index) => {
        if (index !== 0){
          em_data2.push(element)
        }
      })
      const ChartData2 = {
        labels: em_col2,
        datasets: [{
          label: location.state.electricgenerationcosts.data[0].x_index,
          data: em_data2,
          backgroundColor: graphColors(em_col2),
          borderWidth: 0
        }]
      }
      const graph_options2 = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: location.state.electricgenerationcosts.data[0].x_index}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.electricgenerationcosts.title,
            align: "center",
          }
        }
      }
  
  //Graph 8
  var em_col3 = []
      electric_capacity_columns.forEach((element) => {
        if (element && element !== "Total capacidad generación" && element !=="Total capacidad almacenamiento"){
          em_col3.push(element)
        }
      })
      var em_data3 = []
      electric_capacity_data[0].forEach((element,index) => {
        if (index !== 0 && electric_capacity_columns[index] !== "Total capacidad generación" && electric_capacity_columns[index] !== "Total capacidad almacenamiento"){
          em_data3.push(element)
        }
      })
      const ChartData3 = {
        labels: em_col,
        datasets: [{
          label: "Capacidad [MW]",
          data: em_data,
          backgroundColor: graphColors(em_col),
          borderWidth: 0
        }]
      }
      const graph_options3 = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Capacidad [MW]"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.electriccapacity.title,
            align: "center",
          }
        }
      }
  
  //Graph 11
  var em_col4= employment_columns.slice(1)
      var em_data4 = employment_data[0].slice(1)
      const ChartData4 = {
        labels: em_col4,
        datasets: [{
          label: "N° de Empleados",
          data: em_data4,
          backgroundColor: graphColors(em_col4),
          borderWidth: 0
        }]
      }
      const graph_options4 = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "N° de Empleados"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: "N° de Empleados por tecnología",
            align: "center",
            }
        }
      }
  
  //Graph 12
  var em_col5= employment_columns.slice(1)
      var em_data5 = employment_data[1].slice(1)
      const ChartData5 = {
        labels: em_col5,
        datasets: [{
          label: "Remuneraciones ["+coins[values.country]+"]",
          data: em_data5,
          backgroundColor: graphColors(em_col5),
          borderWidth: 0
        }]
      }
      const graph_options5 = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Remuneraciones ["+coins[values.country]+"]"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: "Remuneraciones pagadas por tecnologías",
            align: "center",
            }
        }
      }
  
  //Graph 13
  const ChartData6 = {
    labels: direct_emissions_data,
    datasets: [{
      label: "Emisiones directas [Ton CO2eq/"+coins[values.country]+"]",
      data: direct_emissions_columns,
      backgroundColor: graphColors(direct_emissions_data),
      borderWidth: 0
    }]
  }
  const graph_options6 = {
    indexAxis: 'x',
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: "Emisiones directas [Ton CO2eq/"+coins[values.country]+"]"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: "Emisiones directas por tecnologías",
        align: "center",
        }
    }
  };

  //Graph 14
  const ChartData7 = {
    labels: emissions_factors_data,
    datasets: [{
      label: "Emisiones de CO2 por energía producida [Ton CO2/ GWh]",
      data: emissions_factor_columns,
      backgroundColor: graphColors(emissions_factors_data),
      borderWidth: 0
    }]
  }
  const graph_options7 = {
    indexAxis: 'x',
    scales: {
      x: {title:{display: true, text: "Tecnologías", color: "black"}},
      y: {title:{display: true, text: "Emisiones de CO2 por energía producida [Ton CO2/ GWh]", color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: "Emisiones de CO2 por energía producida para diferentes tecnologías",
        align: "center",
        color: "black"
        }
    }
  };

  //Graph 15
  const ChartData8 = {
    labels: electricity_destiny_columns,
    datasets: electricity_destiny_datasets
  };
  const graph_options8 = {
    plugins:{
      title: {
      display: true,
      text: "Destino de electricidad",
      align: "center",
      },
      
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: "Porcentaje de participación", color:"black"}, stacked: true, max: 100},
          y: {stacked: true}
      }};

  const [name, setName] = useState({
    "pdf_name": "Base de datos " + capitalize(values.country) + " " + values.year ,
  }); 

  //Graph 16
  const ChartData9 = {
    labels: carbon_footprint_columns,
    datasets: carbon_footprint_datasets
  };
  const graph_options9 = {
    plugins:{
      title: {
      display: true,
      text: location.state.carbonfootprint.title,
      align: "center",
      },
      
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: "Huella de carbono [MTon CO2 eq.]", color:"black"}, stacked: true},
          y: {stacked: true}
      }};

//Graph 17
const ChartData10 = {
  labels: imported_carbon_data,
  datasets: [{
    label: "Huella de carbono importada [Mt CO2 eq]",
    data: imported_carbon_columns,
    backgroundColor: graphColors(imported_carbon_data),
    borderWidth: 0
  }]
}
const graph_options10 = {
  indexAxis: 'x',
  scales: {
    x: {title:{display: true, text: "Actividad económica", color: "black"}},
    y: {title:{display: true, text: "Huella de carbono importada [Mt CO2 eq]", color: "black"}}
  },
  plugins : {
    legend: {display: false},
    title: {
      display: true,
      text: location.state.importedcarbon.title,
      align: "center",
      color: "black"
      }
  }
};


useEffect(() => {
  fetch(API_PORT+'/admin/reports/user/get_reports_list/?token=' + token, {
    method: 'GET',
    headers: {
      'Content-type': 'application/json'
    }
    })
    .then(res => res.json()).then(data => {
      setUsersReports(data);
      setOpen(false);
      setLoadingScreen(prevState => {return {...prevState, started: false}});
    });
  }, [token]);

const handleInput = (event) =>{
    setName(prev => ({...prev, [event.target.name]:event.target.value}))
  };

    

  const chartRefs = useRef([]);
  const pages = 18

  const responsereport = (response) => {
    if (response === true) {
        setMsg("¡Reporte guardado exitosamente!")
        alert("Se ha guardado tu reporte exitosamente. Revisa la sección de reportes de la barra izquierda para poder visualizarlo.")
      }
    else {
      alert("No se ha podido guardar el reporte.")
      setMsg("No se ha podido guardar tu reporte")
    }
    setMsgPorcentage(null);
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
  }

  const SaveReport = async () =>{
      // Verifica si el nombre del reporte ya existe
      const reportExists = usersReports.some(report => report.report_name === name.pdf_name);
      if (reportExists) {
          alert("El nombre del reporte ya existe. Cambia el nombre de este por otro que no esté guardado en tu cuenta.");
          return; // Detiene la ejecución si el nombre ya existe
      }

      const pdf2 = new jsPDF();
      setIsModalOpen(true)
      setProgress(0); // Inicia el progreso en 0%
      setMsg("Generando el reporte. No cierres la ventana hasta completar el proceso.")
      setLoaderScreen(prevState => {
        return {...prevState, started: true}
      })
      for (let i = 0; i < pages; i++) {
        const chartRef = chartRefs.current[i];
        const canvas = await html2canvas(chartRef);
        const imageData = canvas.toDataURL('image/png');
        const pdfWidth = pdf2.internal.pageSize.getWidth()
        const pdfHeight = pdf2.internal.pageSize.getHeight()
        const imgWidth = canvas.width
        const imgHeight = canvas.height
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
        pdf2.addImage(imageData, 'PNG', 16, 16, imgWidth*ratio*0.85, imgHeight*ratio*0.85);
        if (i < pages - 1) {
          pdf2.addPage();
        }
        setProgress(((i + 1) / pages) * 100);
        setMsgPorcentage((((i + 1) / pages) * 100).toFixed(0) + " %");
        
      }
      const reportData = new FormData();
      reportData.append('report_name', name.pdf_name);
      reportData.append('report_type', 'Base de Datos');
      reportData.append('report_description', 'Reporte Base de Datos');
      reportData.append('file', new Blob([pdf2.output('blob')]), name.pdf_name+'.pdf');
      setMsgPorcentage(null);
      setMsg("Guardando el reporte en tu cuenta. No cierres la ventana hasta completar el proceso.")

      try {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${API_PORT}/admin/reports/user/save_report/?token=${token}`, true);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setProgress(percentComplete);
                setMsgPorcentage(`${percentComplete.toFixed(0)} %`);
            }
        };

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                responsereport(true);
            } else {
                throw new Error('Error al guardar el reporte');
            }
        };

        xhr.onerror = () => {
            console.error('Ocurrió un error al intentar guardar el reporte');
            responsereport(false);
        };

        xhr.send(reportData);
    } catch (error) {
        console.error('Ocurrió un error al intentar guardar el reporte:', error);
        setMsgPorcentage(null);
        responsereport(false);
    } 
  }

  const generatePDF = async () => {
    const pdf = new jsPDF();
    setIsModalOpen(true)
    setProgress(0); // Inicia el progreso en 0%
    setMsg("Generando el reporte para ser descargado. No cierres la ventana hasta completar el proceso.")
    setLoaderScreen(prevState => {
      return {...prevState, started: true}
    })
    for (let i = 0; i < pages; i++) {
      const chartRef = chartRefs.current[i];
      const canvas = await html2canvas(chartRef);
      const imageData = canvas.toDataURL('image/png');
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      pdf.addImage(imageData, 'PNG', 16, 16, imgWidth*ratio*0.85, imgHeight*ratio*0.85);
      if (i < pages - 1) {
        pdf.addPage();
      }
      // Actualiza el progreso
      setProgress(((i + 1) / pages) * 100);
      setMsgPorcentage((((i + 1) / pages) * 100).toFixed(0) + " %");
    }
    setMsg("¡Reporte generado!")
    setMsgPorcentage(null);
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
    pdf.save(name.pdf_name);
  };


  function Render(user){
    if(user === "premium" || user === "admin"){
        return(
            <div>
                <div className='mt-10'><Header title="Coloca el nombre de tu reporte"/></div>
                    <div className='grid grid-cols-3 gap-3'>
                        <div className='columns-1'>
                            <h1 className='mb-3'><strong>Nombre del reporte</strong></h1>
                            <input type='string' placeholder={name.pdf_name} name='pdf_name' onChange={handleInput} className='form-control rounded-0 w-60 h-10 p-1' style={{border:"1px solid" ,borderRadius: "8px", color: "gray", backgroundColor:"white"}}></input>
                        </div>
                    </div>
                    <div className='flex gap-5 mt-16'>
                        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={SaveReport}>Guardar Reporte</button>
                        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={generatePDF}>Descargar Reporte</button>
                    </div>
                    <div key={0} ref={(ref) => (chartRefs.current[0] = ref)} style={{border: "1px solid", width: "100%", padding: "30px", marginTop: "120px"}}>
                        <div className='flex justify-center items-center'>
                          <img style={{maxWidth:"320px", marginBottom:"30px"}} src={ecokinesis} alt=''/>
                        </div>
                        <h1 className='flex justify-center items-center'><strong>Resultados Reporte Base de Datos</strong></h1>
                        <h1 className='flex justify-center items-center'>País Ingresado: {capitalize(values.country)}</h1>
                        <h1 className='flex justify-center items-center'>Año Ingresado: {values.year}</h1>
                        <h1 className='flex justify-center items-center'>Fecha de creación del reporte: {date}/{month}/{year}</h1>

                        <h1 className='flex justify-center items-center mt-12'>EcoKinesis Proyecto Fondef Idea ID22I10341 es propiedad de la Universidad Técnica Federico Santa María y ANID</h1>
                        <div className='flex justify-center items-center gap-10 mt-6'>
                          <img style={{maxWidth:"100px"}} src={anid} alt=''/>
                          <img style={{maxWidth:"150px"}} src={utfsm} alt=''/>
                        </div>
                        <h1 className='flex justify-center items-center mt-10'>Fuentes Generales: Banco Central, CNE, INE</h1>
                        <h1 className='flex justify-center items-center'>Disclaimer: Este reporte ha sido generado automáticamente en base a los datos ingresados del usuario en la plataforma de EcoKinesis. EcoKinesis no se hace responsable del uso o vericidad de los datos en este reporte.</h1>
                    </div>
                    <div className="mt-10" key={1} ref={(ref) => (chartRefs.current[1] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>MIP Resumida: La tabla muestra la MIP o Matriz Insumo Producto del año y país ingresado desarrollada por el banco central. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores corresponden a la moneda nacional medida en {coins[values.country]}. Fuente: {location.state.miporiginal.source}.</h1>
                        <div style={{maxWidth: "100%"}}>
                          <DynamicTable title={miporiginal.title} columns={miporiginal_columns} rows={miporiginal_data} cellSize="small"/> 
                        </div>
                    </div>
                    <div className="mt-10" key={2} ref={(ref) => (chartRefs.current[2] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Tabla Generación Eléctrica: La tabla muestra los datos sobre la generación eléctrica medidas en GW por hora para el año y país seleccionados. Las columnas corresponden a los tipos de tecnologías y los valores de almacenamiento de corto y largo plazo junto al total de estos. Fuente: {location.state.electric.source}.</h1>
                      <div style={{width: "100%"}}>
                        <MUIDataTable
                        title={electric.title}
                        data={electric_data}
                        columns={electric_columns}
                        options={options1}
                        ></MUIDataTable>
                      </div>
                      
                    </div>
                    <div className="mt-10" key={3} ref={(ref) => (chartRefs.current[3] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Gráfico Generación Eléctrica: El gráfico muestra la generación eléctrica medidas en GW por hora para los diferentes tipos de tecnología junto al almacenamiento corto y largo plazo para el año y país seleccionados. Fuente: {electric.source}.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData1} options={graph_options1}/>
                      </div>
                    </div>
                    <div className='mt-10'key={4} ref={(ref) => (chartRefs.current[4] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Tabla Generación Eléctrica Costos: La tabla muestra los datos sobre la generación electrica para el año base considerada para obtener la desagregación de costos medidas en GW por hora para el año y país seleccionados. Las columnas corresponden a los tipos de tecnologías. Fuente: {location.state.electric.source}.</h1>
                      <div style={{width: "100%"}}>
                        <MUIDataTable
                          title={electricgenerationcosts.title}
                          data={electric_generation_costs_data}
                          columns={electric_generation_costs_columns}
                          options={options1}
                        ></MUIDataTable>
                      </div>
                    </div>
                    <div className='mt-10' key={5} ref={(ref) => (chartRefs.current[5] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Gráfico Generación Eléctrica Costos: El gráfico muestra la generación electrica para el año base considerada para obtener la desagregación de costos medidas en GW por hora para los diferentes tipos de tecnologías. Fuente: {location.state.electric.source}.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData2} options={graph_options2}/>
                      </div>
                    </div>
                    <div className='mt-10'key={6} ref={(ref) => (chartRefs.current[6] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Tabla Capacidad Eléctrica: La tabla muestra los datos sobre la capacidad eléctrica instalada medidas en MW para el año y país seleccionados. Las columnas corresponden a los tipos de tecnologías y los valores de almacenamiento de corto y largo plazo junto al total de estos. Fuente: {location.state.electriccapacity.source}.</h1>
                      <div style={{maxWidth: "100%"}}>
                        <MUIDataTable
                        title={electriccapacity.title}
                        data={electric_capacity_data}
                        columns={electric_capacity_columns}
                        options={options1}
                      
                        ></MUIDataTable>
                      </div>
                    </div>
                    <div className='mt-10' key={7} ref={(ref) => (chartRefs.current[7] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Gráfico Capacidad Eléctrica: El gráfico muestra la generación eléctrica medidas en GW por hora para los diferentes tipos de tecnología junto al almacenamiento corto y largo plazo para el año y país seleccionados. Fuente: {electriccapacity.source}.</h1>
                      <div style={{width: "1000px", height: "450px"}} >
                        <Bar data={ChartData3} options={graph_options3}/>
                      </div>
                    </div>
                    <div className='mt-10'key={8} ref={(ref) => (chartRefs.current[8] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Características Generación: La tabla muestra las características de las tecnologías específicas consideradas para realizar la desagregación del sector eléctrico del año y país seleccionados. Las columnas corresponden a las tecnologías, mientras que las filas corresponden a las características de costos y/o precios, cada una con diferentes unidades de medidas. Fuente: {electricgeneration.source}.</h1>
                      <div style={{maxWidth: "100%"}}>
                        <MUIDataTable
                        title={electricgeneration.title}
                        data={electric_generation_data}
                        columns={columns2}
                        options={options1}
                        ></MUIDataTable>
                      </div>
                    </div>
                    <div className='mt-10'key={9} ref={(ref) => (chartRefs.current[9] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Costos totales y porcentajes: La tabla muestra las cantidades, costos de los insumos y la participación de los costos de insumos para diferentes tecnologías consideradas para realizar la desagregación del sector eléctrico para el año y país seleccionados. Las columnas corresponden a las tecnologías, mientras que las filas corresponden a las cantidades, costos y participaciones, cada una con diferentes unidades de medidas. Fuente: {location.state.electrictotal.source}.</h1>
                      <div style={{maxWidth: "100%"}}>
                        <MUIDataTable
                        title={electrictotal.title}
                        data={electric_total_data}
                        columns={electric_total_columns}
                        options={options1}
                        ></MUIDataTable>
                      </div>
                    </div>
                    <div className='mt-10'key={10} ref={(ref) => (chartRefs.current[10] = ref)}> 
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Datos de Empleo: La tabla muestra los datos de empleo utilizados para calcular los multiplicadores e indicadores relacionados con el empleo para el país y año ingresados. Las columnas de la tabla corresponden a los tipos de tecnologías, mientras que las filas corresponden al número de empleados y las remuneraciones pagadas medidas en {coins[values.country]}. Fuente: {employment.source}.</h1>
                      <div style={{maxWidth: "100%"}}><MUIDataTable
                      title={employment.title}
                      data={employment_data}
                      columns={employment_columns}
                      options={options1}
                      ></MUIDataTable></div>
                    </div>
                    <div className='mt-10' key={11} ref={(ref) => (chartRefs.current[11] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>N° de Empleados: El gráfico muestra el número de empleados existentes para cada tecnología del año y país ingresados. Fuente: {location.state.employment.source}.</h1>
                      <div style={{width: "1200px", height: "560px"}} >
                        <Bar data={ChartData4} options={graph_options4}/>
                      </div>
                    </div>
                    <div className='mt-10' key={12} ref={(ref) => (chartRefs.current[12] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Remuneraciones pagadas: El gráfico muestra el total de remuneraciones pagadas a los empleados medida en {coins[values.country]} para cada tecnología del año y país ingresados. Fuente: {location.state.employment.source}.</h1>
                      <div style={{width: "1200px", height: "560px"}}>
                        <Bar data={ChartData5} options={graph_options5}/>
                      </div>
                    </div>
                    <div className="mt-10" key={13} ref={(ref) => (chartRefs.current[13] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Emisiones Directas: El gráfico indica las emisiones en toneladas de Co2 equivalentes generadas por {coins[values.country]} de producción para diferentes tipos de tecnologías. Fuente: {location.state.directemissions.source}.</h1>
                      <div style={{width: "1200px", height: "560px"}}>
                        <Bar data={ChartData6} options={graph_options6}/>
                      </div>
                    </div>
                    <div className='mt-10' key={14} ref={(ref) => (chartRefs.current[14] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Factores de Emisión: El gráfico muestra los factores de emisiones del año y país seleccionados, es decir, las emisiones de CO2 generadas medidas en toneladas por GWh de energía producida para las tecnologías de generación. Fuente: {location.state.emissionsfactor.source}.</h1>
                      <div style={{width: "1200px", height: "560px"}}>
                        <Bar data={ChartData7} options={graph_options7}/>
                      </div>
                    </div>
                    <div className='mt-10' key={15} ref={(ref) => (chartRefs.current[15] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Destino Electricidad: El gráfico muestra el destino de la energía por tipo de tecnología de generación del año y país seleccionados. Cada fila del gráfico corresponde a una tecnología de generación y sus valores corresponden al porcentaje de destino eléctrico para cada área, siendo la suma de todos estos un 100%. Fuente: {electricitydestiny.source}.</h1>
                      <div style={{width: "1000px", height: "560px"}}>
                        {HorizontalTables(ChartData8, graph_options8)}
                      </div>
                    </div>
                    <div className='mt-10' key={16} ref={(ref) => (chartRefs.current[16] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Huella Demanda: El gráfico muestra la huella de carbono por demanda final para el año y país seleccionado. Cada fila del gráfico corresponde a un sector de origen de emisión de CO2, mientras que sus valores corresponden a la cantidad total de emisiones medidas en Mega Toneladas de CO2 equivalente para diferentes demandas. Fuente: {carbonfootprint.source}.</h1>
                      <div style={{width: "1000px", height: "560px"}}>
                        {HorizontalTables(ChartData9, graph_options9)}
                      </div>
                    </div>
                    <div className='mt-10' key={17} ref={(ref) => (chartRefs.current[17] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Huella Importada: El gráfico muestra la huella de carbono importada y el destino de la actividad económica para el año y país seleccionado. Cada columna del gráfico corresponde a una actividad económica, mientras que sus valores corresponden a la huella de carbono importada medida en mega toneladas de CO2 equivalente. Fuente: {importedcarbon.source}.</h1>
                      <div style={{width: "1000px", height: "560px"}}>
                        <Bar data={ChartData10} options={graph_options10}/>
                      </div>
                    </div>
                  </div>
        )
    }
    else{
        return(
          <div className='mt-16'>
          <h1 className='flex justify-center mb-3'><strong>Contrata la suscripción	premium de EcoKinesis para poder guardar y descargar reportes</strong></h1>
          <div className='flex justify-center'>
              <div className='mb-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", maxWidth:"540px", maxHeight:"380px"}}>
                <div className='justify-center align-items-center text-center'>
                  <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
                  <div className="text-sm mt-3" style={{color:"gray"}}><strong>Contrata la suscripción premium de EcoKinesis para poder usar esta sección</strong></div>
                  <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", minWidth: "120px", maxWidth:"260px", height:"54px", marginTop:"10px", marginBottom: "10px"}} onClick={()=>{navigate("/dashboard/premium")}}>Contratar</button>
                </div>
              </div>
          </div>
      </div>
        )
    }
  }


  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <button onClick={() => {navigate("/dashboard/database/results", {state: {values, miporiginal, employment, electric, electricgenerationcosts, electriccapacity, electricgeneration, electrictotal, directemissions, emissionsfactor, electricitydestiny, carbonfootprint, importedcarbon, coins}})}} style={{color:"white",backgroundColor:"gray",borderRadius:"10px", width:"200px", height: "60px"}}>Volver a mi resultado de Base de Datos</button>
      </div>  
      <Header title="Generar reporte base de datos"/>
      <p className='text-gray-500' style={{maxWidth: "85%"}}>En esta sección podrás guardar y descargar los resultados de la base de datos seleccionada. Ingresa el nombre de tu reporte. Si pulsas el botón de descarga, se descargará un PDF con tus resultados. Si pulsas la opción de guardar reporte, este se guardará en la zona de reportes. Un reporte no puede ser guardado si es que ya existe uno en tu cuenta con el mismo nombre. En la zona inferior verás la lista de tablas y gráficos de la Base de Datos que se almacenarán en tu reporte. Estas opciones sólo están disponible para usuarios que cuenten con la <Link to= "/dashboard/premium" className='text-blue-500'>suscripción premium de EcoKinesis</Link>.</p>
      {Render(user_type)}
      <ModalLoader msg={msg} msgporcentage={msgporcentage} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default GeneratePDFDatabase

/* 
<DynamicTable title={miporiginal.title} columns={miporiginal_columns} rows={miporiginal_data} cellSize="small"/>

<PDFDownloadLink document={<DatabasePDF/>} fileName={name.pdf_name}><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3">Descargar Reporte</button></PDFDownloadLink>  

  //Generate Chart Images
  const ChartJsImage = require('chartjs-to-image');
    // Generate the chart
    const chart = new ChartJsImage();
    chart.setConfig({
        type: 'bar',
        data: { labels: ['Hello world', 'Foo bar'], datasets: [{ label: 'Foo', data: [1, 2] }] },
    });

    <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Image src={ecokinesis} style={{display:"flex", width: "50%", height:"auto"}}/>
                    <Text style={styles.title_text}>Reporte de Base de Datos {capitalize(values.country)} {values.year} EcoKinesis</Text>
                    <Text style={styles.title_text}>{name.pdf_name}</Text>
                    <Text style={styles.title_text}>{username}</Text>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <Text style={styles.global_text}>Resultados MIP Expandida</Text>
                </View>
            </Page>
            <Page size="A4" style={styles.page}>
              <View style={styles.section}>
                <ChartComponent data={ChartData} options={config}/>
              </View>
            </Page>
*/