import {React, useEffect, useContext, useState} from 'react'
import { Header} from '../../../components';
import { UserContext } from '../../../contexts/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import { API_PORT } from '../../../API_PORT';
import MUIDataTable from "mui-datatables";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import download from "../../../assets/download_black.svg"
import deletesvg from "../../../assets/delete.svg"
import ModalLoader from '../../../components/ModalLoader';
import LoadingScreen from '../../../components/LoadingScreen';

const Reports = () => {
  const [progress, setProgress] = useState(0);
  const [msg, setMsg] = useState(null);
  const [msgporcentage, setMsgPorcentage] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const user_type = userContext.value.user_type;
  const navigate = useNavigate();

  const [usersReports, setUsersReports] = useState([])

  const options={
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15],
    selectableRows: "none",
    tableBodyMaxHeight: "400px",
    print: "false",
    download: "false",
    filter: "false",
    viewColumns: "false",
    responsive: "vertical"
  }

  const users_reports_columns = ["Reporte","Tipo reporte","Fecha de creación","Acciones"]
  var users_reports_data = []

  useEffect(() => {
    fetch(API_PORT+'/admin/reports/user/get_reports_list/?token=' + token, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json()).then(data => {
        setUsersReports(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      });
    }, [token]);

  usersReports?.forEach((user,index) => {
      users_reports_data.push([user.report_name, user.report_type, user.report_date, 
          <div className='flex gap-5'>
          <TooltipComponent content="Descargar reporte"><button onClick={() => {downloadReport(user.report_name)}}><img src={download} className='w-6 h-6' alt="Descargar reporte"></img></button></TooltipComponent>
          <TooltipComponent content="Eliminar reporte"><button onClick={() => {deleteReport(user.report_name,index)}}><img src={deletesvg} className='w-6 h-6' alt="Eliminar reporte"></img></button></TooltipComponent>
        </div>
        ])
    }
  )
  

  function downloadReport(report_name){
    setIsModalOpen(true);
    setMsg("Descargando el reporte. No cierres la ventana hasta completar el proceso.");
    setLoaderScreen(prevState => ({ ...prevState, started: true }));
    setProgress(0); // Inicia el progreso en 0%

    const xhr = new XMLHttpRequest();
    const url = `https://api.ecokinesis.cl/admin/reports/user/get_report/?token=${token}`;

    xhr.open('POST', url, true);
    xhr.responseType = 'blob';
    
    // Configura las cabeceras
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onprogress = (event) => {
        if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
            setProgress(percentComplete);
            setMsgPorcentage(`${percentComplete.toFixed(0)} %`);
        }
    };

    xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
            const url = window.URL.createObjectURL(new Blob([xhr.response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${report_name}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setMsg("¡Reporte Descargado!");
        } else {
            console.error(`Error ${xhr.status}: ${xhr.statusText}`);
            setMsg("Este reporte ya fue eliminado preeviamente");
            throw new Error(`Error ${xhr.status}: ${xhr.statusText}`);
        }
    };

    xhr.onerror = () => {
        console.error('Error de red:', xhr.statusText);
        alert("Este reporte no se encuentra disponible");
        setMsg("No se pudo descargar el reporte");
    };

    xhr.onloadend = () => {
        setLoaderScreen(prevState => ({ ...prevState, started: false }));
        setProgress(100); // Completa el progreso al finalizar
        setMsgPorcentage(null);
    };

    // Enviar la solicitud con el cuerpo adecuado
    xhr.send(JSON.stringify(report_name));
  }

  function deleteReport(report_name,index){
    setIsModalOpen(true);
    setMsg("Eliminando el reporte. No cierres la ventana hasta completar el proceso.");
    setLoaderScreen(prevState => ({ ...prevState, started: true }));
    setProgress(0); // Inicia el progreso en 0%

    // Simular progreso (opcional, ya que la operación DELETE no tiene un progreso real)
    const simulateProgress = setInterval(() => {
        setProgress(prev => {
            const newProgress = Math.min(prev + 20, 100); // Aumenta el progreso hasta 100%
            setMsgPorcentage(`${newProgress.toFixed(0)} %`);
            if (newProgress >= 100) clearInterval(simulateProgress);
            return newProgress;
        });
    }, 500); // Actualiza el progreso cada 500 ms

    fetch(`${API_PORT}/admin/reports/user/delete_report/?token=${token}`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(report_name),
    })
    .then(response => response.json())
    .then(response => {
        if (response.detail === "Reporte eliminado exitosamente") {
            setUsersReports(prevReports =>
              prevReports.filter((_, i) => i !== index)
            );
            alert(`El reporte "${report_name}" ha sido eliminado`);
            setMsg("El reporte ha sido eliminado exitosamente.");
            setMsgPorcentage(null);
        } else {
            alert("Este reporte ya fue eliminado preeviamente.");
            setMsg("El reporte ya ha sido eliminado preeviamente.");
        }
    })
    .catch(error => {
        console.error('Error al eliminar el reporte:', error);
        alert("No se pudo eliminar el reporte");
        setMsg("No se pudo eliminar el reporte");
    })
    .finally(() => {
        setLoaderScreen(prevState => ({ ...prevState, started: false }));
        setProgress(100); // Completa el progreso al finalizar
        setMsgPorcentage(null);
    });
  }


  function ReportRender(user){
    if(user === "premium" || user === "admin"){
      return(
        <div className='mt-16'>
          <div><MUIDataTable
            data={users_reports_data}
            columns={users_reports_columns}
            options={options}
            ></MUIDataTable>
          </div>
        </div>
      )

    }
    else{
      return(
        <div className='mt-16'>
        <h1 className='flex justify-center mb-3'><strong>Contrata la suscripción	premium de EcoKinesis para acceder a los reportes</strong></h1>
        <div className='flex justify-center'>
            <div className='mb-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", maxWidth:"540px", maxHeight:"380px"}}>
              <div className='justify-center align-items-center text-center'>
                <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
                <div className="text-sm mt-3" style={{color:"gray"}}><strong>Contrata la suscripción premium de EcoKinesis para poder usar esta sección</strong></div>
                <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", minWidth: "120px", maxWidth:"260px", height:"54px", marginTop:"10px", marginBottom: "10px"}} onClick={()=>{navigate("/dashboard/premium")}}>Contratar</button>
              </div>
            </div>
        </div>
    </div>
      )
  }
  }

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Reportes"/>
      <p className='text-gray-500'>En esta sección podrás descargar todos los reportes que hayas guardado de la base datos, la MIP expandida o las simulaciones de EcoKinesis. Sólo puedes visualizar tus reportes en caso de contar con la <Link to= "/dashboard/premium" className='text-blue-500'>suscripción premium de EcoKinesis</Link>.</p>
      {ReportRender(user_type)}
      <ModalLoader msg={msg} msgporcentage={msgporcentage} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default Reports