import {React, useEffect, useState, useContext} from 'react';
import { Button } from '../../../components';
import { Header } from '../../../components';
import { Link } from 'react-router-dom';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { API_PORT } from '../../../API_PORT';
import { UserContext } from '../../../contexts/UserContext';
import MUIDataTable from "mui-datatables";
import deletesvg from "../../../assets/delete.svg"

const AccountNotifications = () => {

  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const link= API_PORT + "/notifications/get-notification-list/?token="+token
  const link2 = API_PORT + "/notifications/delete-notification/"
  const [notifications, setNotifications] = useState([])

  const options={
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15],
    selectableRows: "none",
    tableBodyMaxHeight: "400px",
    print: "false",
    download: "false",
    filter: "false",
    viewColumns: "false",
    responsive: "vertical"
  }

  useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setNotifications(data);
      }).catch((error) => {
        console.log(error)
        alert("No se han podido obtener tus notificaciones, vuelve a intentarlo.")
      });
    }, [link]);

    var notification_list  = [];

    notifications?.forEach((element, index) => {
      notification_list.push([element.id, element.message, element.notification_date, 
          <div className='flex gap-5'>
          <TooltipComponent content="Eliminar notificación"><button onClick={()=>{handleDelete(index,element.id)}}><img src={deletesvg} className='w-6 h-6' alt="Eliminar reporte"></img></button></TooltipComponent>
        </div>
        ])
    }
  )

  const DeleteNotification = (index) => {
    fetch(link2+index+"?token="+token, {
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        alert(data.detail)
      }).catch((error) => {
        console.log(error)
      });
  }

  const handleDelete = (index,id) => {
    setNotifications(prevNotifications =>
      prevNotifications.filter((_, i) => i !== index)
    );
    DeleteNotification(id)
  };

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/account" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver a mi perfil"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <Header title="Notificaciones"/>
      <p className='text-gray-500'>En esta sección podrás ver toda la lista de notificaciones de tu cuenta de EcoKinesis. Si pulsas el botón de borrar, esta se eliminará permanentemente de tu lista de notificaciones.</p>
      <div className='mt-6'>
          <div><MUIDataTable
            data={notification_list}
            columns={["ID Notificación","Notificación","Fecha notificación","Eliminar"]}
            options={options}
            ></MUIDataTable>
          </div>
        </div>
    </div>
  )
}

export default AccountNotifications