import {React, useContext} from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Header, Button } from '../../../../components';
import { useStateContext } from '../../../../contexts/ContextProvider';
import { UserContext } from '../../../../contexts/UserContext';
import mip from "../../../../assets/list_FILL0_wght300_GRAD0_opsz24.svg"

const LeontieffScenary = () => {
    const { currentColor }= useStateContext();

    const userContext = useContext(UserContext);

  const user_type= userContext.value.user_type;

  const navigate = useNavigate()

  function ScenaryRender(user){
    if(user === "premium"  || user === "admin"){
        return(
            <div className='grid grid-cols-6 gap-3 mt-10'>

      <div className='max-w-52'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={mip} width="80px" height="80px" className='mt-3' alt='mip'/>
              <div className='text-gray-400 text-xs'><strong>Shock demanda final directa</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="direct_final_demand_selector1" >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Simular"
                  borderRadius="10px"
                  size="md"
                  width="max-w-52"
                />
            </Link>
          </div>
          <div className='max-w-52'>
            Insertar valores estimados.
          </div>
        </div>

        <div className='max-w-52'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={mip} width="80px" height="80px" className='mt-3' alt='mip'/>
              <div className='text-gray-400 text-xs'><strong>Porcentaje demanda final</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="percentage_demand_set_selector1" >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Simular"
                  borderRadius="10px"
                  size="md"
                  width="max-w-52"
                />
            </Link>
          </div>
          <div className='max-w-52'>
          Insertar porcentajes de cambio.
          </div>
        </div>

        <div className='max-w-52'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={mip} width="80px" height="80px" className='mt-3' alt='bd'/>
              <div className='text-gray-400 text-xs'><strong>Modelo de precios</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="price_model_selector1" >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Simular"
                  borderRadius="10px"
                  size="md"
                  width="max-w-52"
                />
            </Link>
          </div>
          <div className='max-w-52'>
            Insertar porcentajes de cambio para una lista de factores primarios seleccionados.
          </div>
        </div>

        <div className='max-w-52'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={mip} width="80px" height="80px" className='mt-3' alt='bd'/>
              <div className='text-gray-400 text-xs'><strong>Conjunto demanda y precios</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="direct_demand_set_selector1" >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Simular"
                  borderRadius="10px"
                  size="md"
                  width="max-w-52"
                />
            </Link>
          </div>
          <div className='max-w-52'>
            Insertar valores estimados para la demanda y factor de cambio de los precios de los factores primarios seleccionados.
          </div>
        </div>

        <div className='max-w-52'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={mip} width="80px" height="80px" className='mt-3' alt='bd'/>
              <div className='text-gray-400 text-xs'><strong>Demanda y precios en porcentaje</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="price_demand_model_selector1" >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Simular"
                  borderRadius="10px"
                  size="md"
                  width="max-w-52"
                />
            </Link>
          </div>
          <div className='max-w-52'>
            Insertar porcentajes de cambio en la demanda final y los precios de la factores primarios seleccionados.
          </div>
        </div>

        <div className='max-w-52'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={mip} width="80px" height="80px" className='mt-3' alt='bd'/>
              <div className='text-gray-400 text-xs'><strong>Cambio precio de producto</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="change_product_price_selector1" >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Simular"
                  borderRadius="10px"
                  size="md"
                  width="max-w-52"
                />
            </Link>
          </div>
          <div className='max-w-52'>
            Insertar porcentajes de cambio en la demanda final y los precios del producto seleccionado.
          </div>
        </div>

      </div>
        )
    }
    else{
        return(
            <div className='mt-16'>
                <h1 className='flex justify-center mb-3'><strong>Contrata la suscripción	premium de EcoKinesis para desbloquear las simulaciones de Escenarios Leontieff</strong></h1>
                <div className='flex justify-center'>
                    <div className='mb-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", maxWidth:"540px", maxHeight:"380px"}}>
                      <div className='justify-center align-items-center text-center'>
                        <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
                        <div className="text-sm mt-3" style={{color:"gray"}}><strong>Contrata la suscripción premium de EcoKinesis para poder usar esta sección</strong></div>
                        <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", minWidth: "120px", maxWidth:"260px", height:"54px", marginTop:"10px", marginBottom: "10px"}} onClick={()=>{navigate("/dashboard/premium")}}>Contratar</button>
                      </div>
                    </div>
                </div>
            </div>
        )
    }
  }


  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/simulations" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver a Simulaciones"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <Header title="Escenarios Leontieff"/>
      <p className='text-gray-500'>En esta sección podrás simular tus propios escenarios en base a cambios de la demanda final y precios. Selecciona una de las 6 opciones disponibles para realizar una simulación. Sigue los pasos hasta obtener los resultados. Las simulaciones de Escenarios Leontieff sólo están disponibles para usuarios que cuenten con la <Link to= "/dashboard/premium" className='text-blue-500'>suscripción premium</Link> de EcoKinesis.</p>
      {ScenaryRender(user_type)}
    </div>
  )
}

export default LeontieffScenary