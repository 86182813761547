//Imports
import {React, useState, useEffect, useContext} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { Header} from '../../../components';
import {API_PORT} from "../../../API_PORT"
import Modal from '../../../components/Modal';
import { useNavigate } from 'react-router-dom';
import capitalize from '../../../hook/capitalize';
import LoadingScreen from '../../../components/LoadingScreen';
import { useLocation } from 'react-router-dom';

//Database function
const Database = () => {
  //Obtain year, country and others
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const { value } = useContext(UserContext);
  const location = useLocation();
  const values_data = location.state?.values;
  const selected_country = values_data ? values_data.country : value?.country ?? "Sin asignar";
  const selected_year = values_data ? values_data.year : value?.year ?? "Sin asignar";
  const link= API_PORT + "/admin/database_information/get-avaible-MIPs?token="+token
  

  //Loading screen elements
  const [progress] = useState({started: false, pc: 0});
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [msg, setMsg] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  const navigate = useNavigate();

  //Database graphic and table elements
  const [mips, setMips] = useState([])

  const [miporiginal, setmiporiginal] = useState([])

  const [employment, setemployment] = useState([])

  const [electric, setelectric] = useState([])
  const [electricgenerationcosts, setelectricgenerationcosts] = useState([])
  const [electriccapacity, setelectriccapacity] = useState([])
  const [electricgeneration, setelectricgeneration] = useState([])
  const [electrictotal, setelectrictotal] = useState([])

  const [directemissions, setdirectemissions] = useState([])
  const [emissionsfactor, setemissionsfactor] = useState([])
  const [electricitydestiny, setelectricitydestiny] = useState([])
  const [carbonfootprint, setcarbonfootprint] = useState([])
  const [importedcarbon, setimportedcarbon] = useState([])

  //Coins
const [coins, setCoins] = useState([]) 

  //Year and country selected
  const [values, setValues] = useState({
    "year": selected_year,
    "country": selected_country, 
});

//Update values function
 const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  //Get year and country list function
  useEffect(() => {

    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setMips(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      }).catch(error => {
        // Manejo del error de conexión u otros errores
        console.error('Error al realizar la solicitud:', error);
        alert("No se han podido obtener los datos. Recarga la página o inténtalo nuevamente más tarde.");
        setOpen(false);
        setLoadingScreen(prevState => ({ ...prevState, started: false }));
    })
    }, [link]);

  //Save and update year and country data
  var mip_values = mips.MIPbase
  var year_data= [{value:selected_year,label:selected_year}];
  var country_data= [{value:selected_country,label:capitalize(selected_country)}];
  mip_values?.forEach((element) =>
    {year_data.push({value: element[0], label: element[0]});
    country_data.push({value: element[1], label: capitalize(element[1])})}
  )

  //Generate results of database function
  const DatabaseResults = () => {
    //Mip Endpoints
    const mip = API_PORT + "/database/miporiginal_MIP/resumed/" + values.country + "/" + values.year + "?token="+token

    //Employement Endpoints
    const db_employment = API_PORT + "/database/labor/employment/" + values.country + "/" + values.year  + "?token="+token

    //Electric Endpoints
    const electricity = API_PORT + "/database/electric-sector/electric-generation/" + values.country + "/" + values.year  + "?token="+token
    const electric_generation_costs = API_PORT + "/database/electric-sector/electric-generation-for-costs/" + values.country + "/" + values.year  + "?token="+token
    const electric_capacity = API_PORT + "/database/electric-sector/electric-capacity/" + values.country + "/" + values.year  + "?token="+token
    const electric_generation = API_PORT + "/database/electric-sector/electric-generation-characteristics/" + values.country + "/" + values.year  + "?token="+token
    const electric_total = API_PORT + "/database/electric-sector/electric-generation-total-costs-and-percentage-participations/" + values.country + "/" + values.year  + "?token="+token

    //Emissions Endpoints
    const direct_emissions= API_PORT + "/database/emissions/direct-emissions/" + values.country + "/" + values.year  + "?token="+token
    const emissions_factor = API_PORT + "/database/emissions/emission-factors/" + values.country + "/" + values.year  + "?token="+token
    const electricity_destiny = API_PORT + "/database/emissions/electricity-destiny/" + values.country + "/" + values.year  + "?token="+token
    const carbon_footprint = API_PORT + "/database/emissions/carbon-footprint-per-final-demand/" + values.country + "/" + values.year  + "?token="+token
    const imported_carbon = API_PORT + "/database/emissions/imported-carbon-footprint/" + values.country + "/" + values.year  + "?token="+token

    //Other endpoints
    const coin_endpoint = API_PORT + "/admin/database_information/get-coin-name?token=" + token

    if (values.country !== "Sin asignar" && values.year !== "Sin asignar"){
      setIsModalOpen(true)
      setMsg("Obteniendo los datos. No cierres la venta hasta completar el proceso.")
      setLoaderScreen(prevState => {
        return {...prevState, started: true}
      })
      //Fetch all endpoints
      fetch(mip, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setmiporiginal(data);
        })
      fetch(db_employment, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setemployment(data);
        })
      fetch(electricity, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setelectric(data);
        })
      fetch(electric_generation_costs, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setelectricgenerationcosts(data);
        })
      fetch(electric_capacity, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setelectriccapacity(data);
        })
      fetch(electric_generation, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setelectricgeneration(data);
        })
      fetch(electric_total, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setelectrictotal(data);
        })
      fetch(direct_emissions, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setdirectemissions(data);
        })
      fetch(emissions_factor, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setemissionsfactor(data);
        })
      fetch(electricity_destiny, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setelectricitydestiny(data);
        })
        fetch(carbon_footprint, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json'
          }
          })
          .then(res => res.json())
          .then(data => {
            setcarbonfootprint(data);
          })
        fetch(imported_carbon, {
          method: 'GET',
          headers: {
            'Content-type': 'application/json'
          }
          })
          .then(res => res.json())
          .then(data => {
            setimportedcarbon(data);
          })
        fetch(coin_endpoint, {
          method: 'GET',
          headers: {'Content-type': 'application/json'}
          }).then(res => res.json()).then(data => {setCoins(data);})
    }
    else{
      alert("No has seleccionado un valor")
    }
  }

  //Function to go results if all data is obtained
  const NavigateResults = () => {
    if (miporiginal.title && employment.title && electriccapacity.title && electricgenerationcosts.title && electric.title && electricgeneration.title && electrictotal.title && directemissions.title && emissionsfactor.title && electricitydestiny.title && carbonfootprint.title && importedcarbon.title && coins[values.country]){
      alert("Se han obtenido los datos. Pulsa aceptar para continuar.")
      navigate("/dashboard/database/results", {state: {values, miporiginal, employment, electric, electricgenerationcosts, electriccapacity, electricgeneration, electrictotal, directemissions, emissionsfactor, electricitydestiny, carbonfootprint, importedcarbon, coins}});
    }
  }
  NavigateResults()

  //Main page render
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Base de datos"/>
      <p className='text-gray-500'>En esta sección podrás ver las tablas y gráficos de la base de datos de EcoKinesis para los indicadores MIP, sector eléctrico, empleo y emisiones. Ingresa un año y un país para ver posteriormente los datos.</p>
      <div className='mt-10'><Header title="Selecciona 1 opción disponible para el año y país"/></div>
      <div className='grid grid-cols-3 gap-3'>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>Año</strong></h1>
            <label htmlFor='year'></label>
            <select type='string' name='year'
              onChange={handleInput} className='form-select rounded-0 w-60 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {year_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>País</strong></h1>
          <label htmlFor='country'></label>
            <select type='string' name='country'
              onChange={handleInput} className='form-select rounded-0 w-60 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {country_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
      </div>
      <div className='mt-44'>
        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3 w-60 " onClick={DatabaseResults}>Ver Datos</button>
      </div>
      <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default Database