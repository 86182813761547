import {React, useState, useContext} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header, Button } from '../../../../components';
import { API_PORT } from '../../../../API_PORT';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import Modal from '../../../../components/Modal';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const PriceDemandModelSelector2 = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const navigate = useNavigate();
  const location = useLocation()

  const Input = styled(MuiInput)`
  `;

  const values = location.state.values
  const coins = location.state.coins

  const [technologies, setTechnologies] = useState({
    "Fosil": 0,
    "Hidraulica": 0,
    "BiomasaBiogas": 0,
    "Solar": 0,
    "Eolica": 0,
    "Geotermia": 0,
    "AlmacenamientoLargo": 0,
    "AlmacenamientoCorto": 0,
    "H2": 0,

    "FosilPorcentage": 0,
    "HidraulicaPorcentage": 0,
    "BiomasaBiogasPorcentage": 0,
    "SolarPorcentage": 0,
    "EolicaPorcentage": 0,
    "GeotermiaPorcentage": 0,
    "AlmacenamientoLargoPorcentage": 0,
    "AlmacenamientoCortoPorcentage": 0,
    "H2Porcentage": 0,

    "InputModel": "importaciones precios cif"
  });

  const [progress, setProgress] = useState({started: false, pc: 0});
  const [msg, setMsg] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [pricedemandmodel, setpricedemandmodel] = useState([])

  const handleInput = (event) =>{
    setTechnologies(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  const PriceDemandModelResults = () => {
    const price_demand_model= API_PORT + "/scene/quantitative_and_price_models/quantitative-and-price-model/percentage-demand-set/" + values.country + "/" + values.year + "?token="+token
    const new_body = {
      "PrimaryFactorInputModel": technologies.InputModel,
      "FinalDemandPercentageInput": {
        "fósil": technologies.Fosil,
        "hidráulica": technologies.Hidraulica,
        "biomasa y biogas": technologies.BiomasaBiogas,
        "solar": technologies.Solar,
        "eólica": technologies.Eolica,
        "geotermia": technologies.Geotermia,
        "almacenamiento largo plazo": technologies.AlmacenamientoLargo,
        "almacenamiento corto plazo": technologies.AlmacenamientoCorto,
        "H2": technologies.H2
      },
      "Unities": technologies.Unities,
      "ScenarioPrimaryFactorPercentagePriceIncrease": {
        "fósil": technologies.FosilPorcentage,
        "hidráulica": technologies.HidraulicaPorcentage,
        "biomasa y biogas": technologies.BiomasaBiogasPorcentage,
        "solar": technologies.SolarPorcentage,
        "eólica": technologies.EolicaPorcentage,
        "geotermia": technologies.GeotermiaPorcentage,
        "almacenamiento largo plazo": technologies.AlmacenamientoLargoPorcentage,
        "almacenamiento corto plazo": technologies.AlmacenamientoCortoPorcentage,
        "H2": technologies.H2Porcentage
      }
    }

    setIsModalOpen(true)
    setMsg("Calculando la simulación, no cierre esta pestaña hasta completar el proceso.")
    setLoaderScreen(prevState => {return {...prevState, started: true}});

    fetch(price_demand_model, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(new_body)
    }).then(res => res.json()).then(data => {setpricedemandmodel(data);})
  }

  const NavigateResults = () => {
    if(pricedemandmodel.MIP_base_year?.title){
      alert("Se han calculados los datos de la simulación. Pulsa para continuar.")
      navigate("/dashboard/simulations/main/leontieff_scenary/price_demand_model_results", {state: {technologies,values,pricedemandmodel, coins}})
    }
  }

  function UpdateValues(){
    if(location.state?.technologies){
      setTechnologies({...technologies, Fosil : location.state.technologies.Fosil, Hidraulica: location.state.technologies.Hidraulica, BiomasaBiogas: location.state.technologies.BiomasaBiogas, Solar: location.state.technologies.Solar, Eolica: location.state.technologies.Eolica, Geotermia: location.state.technologies.Geotermia, AlmacenamientoCorto: location.state.technologies.AlmacenamientoCorto, AlmacenamientoLargo: location.state.technologies.AlmacenamientoLargo, H2: location.state.technologies.H2, FosilPorcentage : location.state.technologies.FosilPorcentage, HidraulicaPorcentage: location.state.technologies.HidraulicaPorcentage, BiomasaBiogasPorcentage: location.state.technologies.BiomasaBiogasPorcentage, SolarPorcentage: location.state.technologies.SolarPorcentage, EolicaPorcentage: location.state.technologies.EolicaPorcentage, GeotermiaPorcentage: location.state.technologies.GeotermiaPorcentage, AlmacenamientoCortoPorcentage: location.state.technologies.AlmacenamientoCortoPorcentage, AlmacenamientoLargoPorcentage: location.state.technologies.AlmacenamientoLargoPorcentage, H2Porcentage: location.state.technologies.H2Porcentage})  
    }
  }

  function UpdatePreviousValues(){
    if (location.state?.technologies){
      return(
        <TooltipComponent content='Al pulsar este botón se cargarán los valores de la última simulación realizada'><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={() => {UpdateValues()}}>Cargar los datos de la última simulación realizada</button></TooltipComponent>
      )
    }
    else{
      return(<div>
      </div>)
    }
  }

  NavigateResults()

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/simulations/main/leontieff_scenary/price_demand_model_selector1" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver a la selección de país y años"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <Header title={"Modelo demanda y precios en porcentaje " + capitalize(values.country) + " " + values.year}/>
      <p className='text-gray-500'>En esta sección podrás generar tus propias simulaciones de escenarios Leontieff para modelos de demanda y precio en porcentaje. Ingresa los datos de factores de entrada, porcentajes de demanda final y porcentajes de factores de precios desde el -100% al 1000% usando los deslizadores o ingresándolos manualmente; luego pulsa el botón de la parte inferior para ver los resultados.</p>

      <div className='mt-10'><Header title="Factores de entrada"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <label htmlFor='InputModel'></label>
          <select type='string' placeholder='' name='InputModel' onChange={handleInput}  className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'>
            <option value="importaciones precios cif">Importaciones precios CIF</option>
            <option value="impuestos sobre productos">Impuestos sobre productos</option>
            <option value="derechos de importación">Derechos de importación</option>
            <option value="remuneraciones de asalariados">Remuneraciones de asalariados</option>
            <option value="excedente bruto de explotación">Excedente bruto de explotación</option>
            <option value="impuestos netos sobre la producción">Impuestos netos sobre la producción</option>
          </select>
        </div>
      </div>

      <div className='mt-10'><Header title="Porcentajes de demanda final"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <h1 className='mb-3'>Fósil: {technologies.Fosil}%</h1>
          <Input type='string' value={technologies.Fosil} name='Fosil' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Fosil" min={-100} max={1000} aria-label="input-slider" value={technologies.Fosil} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Hidráulica: {technologies.Hidraulica}%</h1>
          <Input type='string' value={technologies.Hidraulica} name='Hidraulica' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Hidraulica" min={-100} max={1000} aria-label="input-slider" value={technologies.Hidraulica} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Biomasa y Biogas: {technologies.BiomasaBiogas}%</h1>
          <Input type='string' value={technologies.BiomasaBiogas} name='BiomasaBiogas' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="BiomasaBiogas" min={-100} max={1000} aria-label="input-slider" value={technologies.BiomasaBiogas} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Solar: {technologies.Solar}%</h1>
          <Input type='string' value={technologies.Solar} name='Solar' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Solar" min={-100} max={1000} aria-label="input-slider" value={technologies.Solar} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Eólica: {technologies.Eolica}%</h1>
          <Input type='string' value={technologies.Eolica} name='Eolica' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Eolica" min={-100} max={1000} aria-label="input-slider" value={technologies.Eolica} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Geotermia: {technologies.Geotermia}%</h1>
          <Input type='string' value={technologies.Geotermia} name='Geotermia' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Geotermia" min={-100} max={1000} aria-label="input-slider" value={technologies.Geotermia} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize: "16px"}}>Almacenamiento Largo Plazo: {technologies.AlmacenamientoLargo}%</h1>
          <Input type='string' value={technologies.AlmacenamientoLargo} name='AlmacenamientoLargo' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="AlmacenamientoLargo" min={-100} max={1000} aria-label="input-slider" value={technologies.AlmacenamientoLargo} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
        <h1 className='mb-3' style={{fontSize: "16px"}}>Almacenamiento Corto Plazo: {technologies.AlmacenamientoCorto}%</h1>
          <Input type='string' value={technologies.AlmacenamientoCorto} name='AlmacenamientoCorto' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="AlmacenamientoCorto" min={-100} max={1000} aria-label="input-slider" value={technologies.AlmacenamientoCorto} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h3 className='mb-3'>H2: {technologies.H2}%</h3>
          <Input type='string' value={technologies.H2} name='H2' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="H2" min={-100} max={1000} aria-label="input-slider" value={technologies.H2} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
      </div>

      <div className='mt-10'><Header title="Porcentajes de factores de precio"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <h1 className='mb-3'>Fósil: {technologies.FosilPorcentage}%</h1>
          <Input type='string' value={technologies.FosilPorcentage} name='FosilPorcentage' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="FosilPorcentage" min={-100} max={1000} aria-label="input-slider" value={technologies.FosilPorcentage} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Hidráulica: {technologies.HidraulicaPorcentage}%</h1>
          <Input type='string' value={technologies.HidraulicaPorcentage} name='HidraulicaPorcentage' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="HidraulicaPorcentage" min={-100} max={1000} aria-label="input-slider" value={technologies.HidraulicaPorcentage} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' >Biomasa y Biogas: {technologies.BiomasaBiogasPorcentage}%</h1>
          <Input type='string' value={technologies.BiomasaBiogasPorcentage} name='BiomasaBiogasPorcentage' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="BiomasaBiogasPorcentage" min={-100} max={1000} aria-label="input-slider" value={technologies.BiomasaBiogasPorcentage} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Solar: {technologies.SolarPorcentage}%</h1>
          <Input type='string' value={technologies.SolarPorcentage} name='SolarPorcentage' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="SolarPorcentage" min={-100} max={1000} aria-label="input-slider" value={technologies.SolarPorcentage} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Eólica: {technologies.EolicaPorcentage}%</h1>
          <Input type='string' value={technologies.EolicaPorcentage} name='EolicaPorcentage' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="EolicaPorcentage" min={-100} max={1000} aria-label="input-slider" value={technologies.EolicaPorcentage} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Geotermia: {technologies.GeotermiaPorcentage}%</h1>
          <Input type='string' value={technologies.GeotermiaPorcentage} name='GeotermiaPorcentage' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="GeotermiaPorcentage" min={-100} max={1000} aria-label="input-slider" value={technologies.GeotermiaPorcentage} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize: "16px"}}>Almacenamiento Largo Plazo: {technologies.AlmacenamientoLargoPorcentage}%</h1>
          <Input type='string' value={technologies.AlmacenamientoLargoPorcentage} name='AlmacenamientoLargoPorcentage' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="AlmacenamientoLargoPorcentage" min={-100} max={1000} aria-label="input-slider" value={technologies.AlmacenamientoLargoPorcentage} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
        <h1 className='mb-3' style={{fontSize: "16px"}}>Almacenamiento Corto Plazo: {technologies.AlmacenamientoCortoPorcentage}%</h1>
          <Input type='string' value={technologies.AlmacenamientoCortoPorcentage} name='AlmacenamientoCortoPorcentage' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="AlmacenamientoCortoPorcentage" min={-100} max={1000} aria-label="input-slider" value={technologies.AlmacenamientoCortoPorcentage} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h3 className='mb-3'>H2: {technologies.H2Porcentage}%</h3>
          <Input type='string' value={technologies.H2Porcentage} name='H2Porcentage' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="H2Porcentage" min={-100} max={1000} aria-label="input-slider" value={technologies.H2Porcentage} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
      </div>

      <div className='mt-12 flex gap-5'>
        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={PriceDemandModelResults}>Ver Resultados</button>
        {UpdatePreviousValues()}
      </div>
      <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>

    </div>
  )
}

export default PriceDemandModelSelector2