import {React, useState, useContext} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header, Button } from '../../../../components';
import { API_PORT } from '../../../../API_PORT';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import Modal from '../../../../components/Modal';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const ChangeProductPriceSelector2 = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const navigate = useNavigate();
  const location = useLocation()

  const Input = styled(MuiInput)`
  `;

  const values = location.state.values
  const coins = location.state.coins

  const [technologies, setTechnologies] = useState({
    "Product": "Precio electricidad",
    "Percentage": 0
  });

  const [progress] = useState({started: false, pc: 0});
  const [msg, setMsg] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [changeproductprice, setchangeproductprice] = useState([])

  const handleInput = (event) =>{
    setTechnologies(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  const ChangeProductPriceResults = () => {
    const change_product_price= API_PORT + "/scene/quantitative_and_price_models/change-product-price/" + values.country + "/" + values.year + "?token="+token
    const new_body = {
      "IntermediateProductPriceInputModel": technologies.Product,
      "percentage_increase": technologies.Percentage
    }

    setIsModalOpen(true)
    setMsg("Calculando la simulación, no cierre esta pestaña hasta completar el proceso.")
    setLoaderScreen(prevState => {return {...prevState, started: true}});

    fetch(change_product_price, {
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(new_body)
    }).then(res => res.json()).then(data => {setchangeproductprice(data);})
  }

  function UpdateValues(){
    if(location.state?.technologies){
      setTechnologies({...technologies, Percentage: location.state.technologies.Percentage})  
    }
  }

  function UpdatePreviousValues(){
    if (location.state?.technologies){
      return(
        <TooltipComponent content='Al pulsar este botón se cargarán los valores de la última simulación realizada'><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={() => {UpdateValues()}}>Cargar los datos de la última simulación realizada</button></TooltipComponent>
      )
    }
    else{
      return(<div>
      </div>)
    }
  }

  const NavigateResults = () => {
    if(changeproductprice.MIP_base_year?.title){
      alert("Se han calculados los datos de la simulación. Pulsa para continuar.")
      navigate("/dashboard/simulations/main/leontieff_scenary/change_product_price_results", {state: {technologies,values,changeproductprice, coins}})
    }
  }

  NavigateResults()

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/simulations/main/leontieff_scenary/change_product_price_selector1" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver a la selección de país y años"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <Header title={"Cambio precio de producto " + capitalize(values.country) + " " + values.year}/>
      <p className='text-gray-500'>En esta sección podrás generar tus propias simulaciones de escenarios Leontieff para cambios de precio de productos. Selecciona el producto y el porcentajes de cambio desde el -100% al 1000% usando los deslizadores o ingresándolos manualmente; luego pulsa el botón de la parte inferior para ver los resultados.</p>
      <div className='mt-10'><Header title="Producto con precio a modificar"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <select type='string' placeholder='' name='Product' onChange={handleInput}  className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'>
            <option value="Precio electricidad">Electricidad</option>
            <option value="Precio del cobre">Cobre</option>
            <option value="Precio del petróleo">Petróleo</option>
            <option value="Precio H2">H2</option>
            <option value="Precio transporte">Transporte</option>
            <option value="Precio productos agricolas">Productos Agrícolas</option>
          </select>
        </div>
      </div>


      <div className='mt-10'><Header title="Variación porcentual del producto"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <h1 className='mb-9'>Variación: {technologies.Percentage}%</h1>
          <Input type='string' value={technologies.Percentage} name='Percentage' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Percentage" min={-100} max={1000} aria-label="input-slider" value={technologies.Percentage} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div> 
      </div>

      <div className='mt-12 flex gap-5'>
        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={ChangeProductPriceResults}>Ver Resultados</button>
        {UpdatePreviousValues()}
      </div>
      <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>

    </div>
  )
}

export default ChangeProductPriceSelector2