//Imports
import {React , useContext, useState, useEffect} from 'react';
import { UserContext } from '../contexts/UserContext';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Link} from 'react-router-dom';
import { Button } from '.';
import { userProfileData } from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';
import avatar from '../data/avatar.jpg';
import { API_PORT } from '../API_PORT';

//User profile on Navbar render
const UserProfile = () => {
  const { setIsClicked, initialState, currentColor } = useStateContext();
  const [users, setUsers] = useState([])

  //Backend elements
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const user_photo = userContext.value.user_photo;
  const link= API_PORT +"/admin/users_accounts/user/get_data/?token="

  //User Type
  function user_typing(user_type){
    if (user_type === "basic"){
      return "Usuario Básico"
    }
    if (user_type === "premium"){
      return "Usuario Premium"
    }
    if (user_type === "admin"){
      return "Administrador"
    }
    else {
      return ""
    }
  }

  //Profile photo render
  function avatarRender(){
    if (user_photo=== null){return(avatar)}
    else{return(API_PORT+user_photo)}
  }

  //Get user data
  useEffect(() => {
    fetch(link+token, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setUsers(data);
      })
    }, []);

  //Sign out function
  const signOut = () => {
    userContext.setValue({logged: false});
    userContext.setValue({access_token: ""});
    userContext.setValue({username: "", email: "", first_name: "", last_name: "", user_type: "", user_photo: "", country: "Sin asignar", year: "Sin asignar"});
  }

  //Main render
  return (
    <div className="nav-item absolute right-1 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96 border-1">
      <div className="flex justify-between items-center">
        <p className="font-semibold text-lg dark:text-gray-200"><strong>Mi Perfil</strong></p>
        <TooltipComponent content="Cerrar">
          <Button
            icon={<MdOutlineCancel />}
            color="rgb(153, 171, 180)"
            bgHoverColor="light-gray"
            size="2xl"
            borderRadius="50%"
          />
        </TooltipComponent>
      </div>
      <div className="flex gap-5 items-center mt-6 border-color border-b-1 pb-6">
        <img
          className="rounded-full h-24 w-24"
          src={avatarRender()}
          alt="user-profile"
        />
        <div>
          <p className="font-semibold text-xl dark:text-gray-200"> {users.username} </p>
          <p className="text-gray-500 text-sm dark:text-gray-400">{user_typing(users.user_type)}</p>
          <p className="text-gray-500 text-sm font-semibold dark:text-gray-400">{users.email}</p>
        </div>
      </div>
      <div>
        {userProfileData.map((item, index) => (
          <div key={index} className="flex gap-5 border-b-1 border-color p-4 hover:bg-light-gray cursor-pointer  dark:hover:bg-[#42464D]">
            <Link to= {item.link} onClick={() => setIsClicked(initialState)}>
              <button
                type="button"
                style={{ color: item.iconColor, backgroundColor: item.iconBg }}
                className=" text-xl rounded-lg p-3 hover:bg-light-gray"
              >
                {item.icon}
              </button>

              <div>
                <p className="font-semibold dark:text-gray-200 ">{item.title}</p>
                <p className="text-gray-500 text-sm dark:text-gray-400"> {item.desc} </p>
              </div>
              </Link>
          </div>
        ))}
      </div>
      <div className="mt-5">
        <Link to="/" onClick={signOut}>
         <Button
            color="white"
            bgColor={currentColor}
            text="Cerrar Sesión"
            borderRadius="10px"
            width="full"
          />
        </Link>
      </div>
    </div>

  );
};

export default UserProfile;