import {React, useState, useEffect, useContext} from 'react'
import { Link } from 'react-router-dom';
import { Header, Button } from '../../../../components';
import capitalize from '../../../../hook/capitalize';
import { API_PORT } from '../../../../API_PORT';
import { UserContext } from '../../../../contexts/UserContext';
import MUIDataTable from 'mui-datatables';
import LoadingScreen from '../../../../components/LoadingScreen';

const AvailableCoins = () => {
    const userContext = useContext(UserContext);
    const token = userContext.value.access_token;
  
    const coin_columns = ["País","Moneda"]
    var coin_data= [];

    const [loadingscreen, setLoadingScreen] = useState({started: true});
    const [open, setOpen] = useState(true);
  
    const options={
      rowsPerPage: 10,
      rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15,20,25,30],
      selectableRows: "none",
      tableBodyMaxHeight: "400px",
      print: "false",
      download: "false",
      filter: "false",
      viewColumns: "false",
      responsive: "vertical"
    }
  
  
    const [coins, setCoins] = useState([])
  
    const link= API_PORT + "/admin/database_information/get-coin-name?token="+token
  
    useEffect(() => {
      fetch(link, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setCoins(data);
          setOpen(false);
          setLoadingScreen(prevState => {return {...prevState, started: false}});
        }).catch(error => {
          // Manejo del error de conexión u otros errores
          console.error('Error al realizar la solicitud:', error);
          alert("No se han podido obtener los datos. Recarga la página o inténtalo nuevamente más tarde.");
          setOpen(false);
          setLoadingScreen(prevState => ({ ...prevState, started: false }));
      })
      }, [link]);
  
    var coin_values = coins
  
    for (let pais in coin_values) {
        // Obtener el valor del diccionario para este país
        let valor = coin_values[pais];
        
        // Almacenar el valor en la lista correspondiente
        coin_data.push([capitalize(pais),valor]);
      }
  
    return (
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
          <div className='flex justify-between items-center mb-10'>
              <Link to="/dashboard/adminbd" >
                <Button
                  color="white"
                  bgColor="gray"
                  text="Volver"
                  borderRadius="10px"
                  size="md"
                />
              </Link>
          </div>  
        <Header title="Lista de monedas nacionales disponibles"/>
        <div className='flexd'>
            <div style={{maxHeight:"100%"}}>
              <MUIDataTable
              title="Lista de monedas nacionales"
              data={coin_data}
              columns={coin_columns}
              options={options}
              ></MUIDataTable>
            </div>
        </div>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
      </div>
    )
}

export default AvailableCoins