import {React, useEffect, useContext, useState} from 'react'
import { Header, Button} from '../../../components';
import { UserContext } from '../../../contexts/UserContext';
import { Link} from 'react-router-dom';
import { API_PORT } from '../../../API_PORT';
import MUIDataTable from "mui-datatables";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import download from "../../../assets/download_black.svg"
import deletesvg from "../../../assets/delete.svg"
import LoadingScreen from '../../../components/LoadingScreen';
import ModalLoader from '../../../components/ModalLoader';

const AdminReports = () => {
  const [progress, setProgress] = useState(0);
  const [msg, setMsg] = useState(null);
  const [msgporcentage, setMsgPorcentage] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;

  const [usersReports, setUsersReports] = useState([])

  const options={
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15],
    selectableRows: "none",
    tableBodyMaxHeight: "400px",
    print: "false",
    download: "false",
    filter: "false",
    viewColumns: "false",
    responsive: "vertical"
  }

  const users_reports_columns = ["ID Usuario", "Nombre usuario","Nombre Reporte","Tipo reporte","Fecha de creación","Acciones"]
  var users_reports_data = []

  useEffect(() => {
    fetch(API_PORT+'/admin/reports/admin/get_all_reports/?token=' + token, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json()).then(data => {
        setUsersReports(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      });
    }, [token]);


  usersReports?.forEach((user,index) => {
      users_reports_data.push([user.user_id, user.user_name, user.report_name, user.report_type, user.report_date, 
          <div className='flex gap-5'>
          <TooltipComponent content="Descargar reporte"><button onClick={() => {downloadReport(user.user_id,user.report_name)}}><img src={download} className='w-6 h-6' alt="Descargar reporte"></img></button></TooltipComponent>
          <TooltipComponent content="Eliminar reporte"><button onClick={() => {deleteReport(user.user_id,user.report_name, index)}}><img src={deletesvg} className='w-6 h-6' alt="Eliminar reporte"></img></button></TooltipComponent>
        </div>
        ])
    }
  )

  function downloadReport(user_id, report_name) {
    setIsModalOpen(true);
    setMsg("Descargando el reporte. No cierres la ventana hasta completar el proceso.");
    setLoaderScreen(prevState => ({ ...prevState, started: true }));
    setProgress(0); // Inicia el progreso en 0%

    const xhr = new XMLHttpRequest();
    const url = `${API_PORT}/admin/reports/admin/get_report/?token=${token}`;

    console.log('Request URL:', url);
    console.log('Request Body:', JSON.stringify({ user_id, report_name }));

    xhr.open('POST', url, true);
    xhr.responseType = 'blob';

    // Configura las cabeceras
    xhr.setRequestHeader('Accept', 'application/json');
    xhr.setRequestHeader('Content-Type', 'application/json');

    xhr.onprogress = (event) => {
        if (event.lengthComputable) {
            const percentComplete = (event.loaded / event.total) * 100;
            setProgress(percentComplete);
            setMsgPorcentage(`${percentComplete.toFixed(0)} %`);
        }
    };

    xhr.onload = () => {
        if (xhr.status >= 200 && xhr.status < 300) {
            const url = window.URL.createObjectURL(new Blob([xhr.response]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${report_name}.pdf`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            setMsg("¡Reporte Descargado!");
        } else {
            console.error(`Error ${xhr.status}: ${xhr.statusText}`);
            setMsg("Este reporte ya fue eliminado previamente");
            throw new Error(`Error ${xhr.status}: ${xhr.statusText}`);
        }
    };

    xhr.onerror = () => {
        console.error('Error de red:', xhr.statusText);
        alert("Este reporte no se encuentra disponible");
        setMsg("No se pudo descargar el reporte");
    };

    xhr.onloadend = () => {
        setLoaderScreen(prevState => ({ ...prevState, started: false }));
        setProgress(100); // Completa el progreso al finalizar
        setMsgPorcentage(null);
    };

    // Enviar la solicitud con el cuerpo adecuado
    xhr.send(JSON.stringify({ user_id, report_name }));
}

function deleteReport(id,report_name,index){
  setIsModalOpen(true)
  setMsg("Eliminando el reporte. No cierres la venta hasta completar el proceso.")
  setLoaderScreen(prevState => {
      return {...prevState, started: true}
    })
  fetch(API_PORT+'/admin/reports/admin/delete_report/?token=' + token, {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json'
    },
    body: JSON.stringify({
      "user_id": id,
      "report_name": report_name
    })
    }).then((res => res.json()))
    .catch((error) => console.log(error))
    .then((response) => {
      if(response.detail === "Reporte eliminado exitosamente"){
        setUsersReports(prevReports =>
          prevReports.filter((_, i) => i !== index)
        );
        alert("El reporte "+ report_name + " ha sido eliminado");
        setMsg("El reporte ha sido eliminado exitosamente.")
        setLoaderScreen(prevState => {
            return {...prevState, started: false}
          })
      }
      else{
        alert("Este reporte ya fue eliminado")
        setMsg("El reporte ya ha sido eliminado.")
        setLoaderScreen(prevState => {
            return {...prevState, started: false}
          })
      }
    })
}
  

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-10'>
            <Link to="/dashboard/adminuser" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  
      <Header title="Lista de reportes"/>
      <div className='mt-16'>
          <div><MUIDataTable
            data={users_reports_data}
            columns={users_reports_columns}
            options={options}
            ></MUIDataTable>
          </div>
        </div>
        <ModalLoader msg={msg} msgporcentage={msgporcentage} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default AdminReports