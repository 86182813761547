import {React, useState, useEffect, useContext} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header, Button } from '../../../../components';
import { API_PORT } from '../../../../API_PORT';
import { useNavigate, Link } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import LoadingScreen from '../../../../components/LoadingScreen';

const PriceDemandModelSelector1 = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;

  const { value } = useContext(UserContext);
  const selected_country = value?.country ?? "Sin asignar";
  const selected_year = value?.year ?? "Sin asignar";

  const link= API_PORT + "/admin/database_information/get-avaible-MIPs?token="+token
  const coin_endpoint = API_PORT + "/admin/database_information/get-coin-name?token=" + token
    const navigate = useNavigate();
    const [mips, setMips] = useState([])
    const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  const [values, setValues] = useState({
    "year": selected_year,
    "country": selected_country, 
});

    //Coins
    const [coins, setCoins] = useState([]) 

    const handleInput = (event) =>{
        setValues(prev => ({...prev, [event.target.name]:event.target.value}))
      };

    useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setMips(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      })
     fetch(coin_endpoint, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setCoins(data);})
    }, []);

    var mip_values = mips.MIPbase
    var year_data= [{value:selected_year,label:selected_year}];
    var country_data= [{value:selected_country,label:capitalize(selected_country)}];

    mip_values?.forEach((element) =>
        {year_data.push({value: element[0], label: element[0]});
        country_data.push({value: element[1], label: capitalize(element[1])})}
    )

    const PriceDemandModelSelector = () => {
        if (values.country !== "Sin asignar" && values.year !== "Sin asignar" && coins[values.country]){
            navigate("/dashboard/simulations/main/leontieff_scenary/price_demand_model_selector2", {state: {values, coins}})
        }
        else {
            alert("No has ingresado los datos requeridos.")
        }
      }

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/simulations/main/leontieff_scenary" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver a Escenarios Leontieff"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <Header title="Modelos de demanda y precios en porcentaje"/>
      <p className='text-gray-500'>En esta sección podrás generar tus propias simulaciones de escenarios Leontieff para modelos de demanda y precios en porcentaje. Ingresa un país y un año para pasar luego a ingresar los datos de tecnologías.</p>
      <div className='mt-10'><Header title="Selecciona 1 opción de año y país"/></div>
      <div className='grid grid-cols-3 gap-3'>
      <div className='columns-1'>
          <h1 className='mb-3'><strong>País</strong></h1>
          <label htmlFor='country'></label>
            <select type='string' name='country'
              onChange={handleInput} className='form-select rounded-0 min-w-52 max-w-60 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {country_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>Año MIP</strong></h1>
            <label htmlFor='year'></label>
            <select type='string' name='year'
              onChange={handleInput} className='form-select rounded-0 min-w-52 max-w-60 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {year_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
      </div>
      <div className='mt-40'>
        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={PriceDemandModelSelector}>Pasar a ingresar datos</button>
      </div>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default PriceDemandModelSelector1