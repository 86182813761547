import React from "react";
import "./Modal.css";

export default function ModalLoader({ isOpen, closeModal, msg, msgporcentage, progress, loaderscreen }) {
  if(isOpen) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  if (!isOpen) return null;

  const ProgressBar = ({ progress }) => {
    return (
        <div style={{ width: '100%', backgroundColor: '#e0e0e0', borderRadius: '4px', overflow: 'hidden' }}>
            <div style={{
                width: `${progress}%`,
                backgroundColor: '#425df9',
                height: '10px',
                transition: 'width 0.3s ease'
            }}></div>
        </div>
    );
};

  //Use modal for loading screens beetween algorithms transitions
  return (
    <>
        <div className="modal">
          <div className="overlay"></div>
          <div className="modal-content">
            <button className="close-modal mb-4" onClick={closeModal}>
              Cerrar
            </button>
            <div className='flex justify-center items-center gap-20 flex-wrap mt-12'>
                {loaderscreen.started && <ProgressBar progress={progress} />}
                {msgporcentage && <strong>{msgporcentage}</strong>}
                {msg && <span>{msg}</span>}
            </div>
          </div>
        </div>
    </>
  );
}

//                {progress.started && <progress className="blue" max="100" value={progress.pc}></progress>}