//Imports
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend} from "chart.js";
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend)

//Use function to return HorinzontalDatatables
function HorizontalTables(data,options){
  return (
    <div>
        <Bar data={data} options={options}></Bar>
    </div>
  )
}

export default HorizontalTables