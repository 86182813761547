import {React, useState, useEffect, useContext} from 'react'
import { Link } from 'react-router-dom';
import { Header, Button } from '../../../../components';
import capitalize from '../../../../hook/capitalize';
import { API_PORT } from '../../../../API_PORT';
import { UserContext } from '../../../../contexts/UserContext';
import MUIDataTable from 'mui-datatables';
import LoadingScreen from '../../../../components/LoadingScreen';

const AvailablePELP = () => {
  const [pelps, setPelps] = useState([])
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;

  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  const link= API_PORT + "/admin/database_information/get-avaible-PELP-scenarios?token="+token

  const year_columns = ["Años"]

  const scenary_columns = ["Escenarios"]

  const technologies_columns = ["Tecnologías"]

  var year_data= [
  ];

  var scenary_data  = [
  ];

  var technologies_data  = [
  ]

  const options={
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15,20,25,30],
    selectableRows: "none",
    tableBodyMaxHeight: "400px",
    print: "false",
    download: "false",
    filter: "false",
    viewColumns: "false",
    responsive: "vertical"
  }

  useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setPelps(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      })
    }, []);

  var year_values = pelps.years
  var scenary_values = pelps.scenarios
  var technologies_values = pelps.technologies

  year_values?.sort()
  year_values?.forEach((element) =>
    {year_data.push([element])}
  )

  scenary_values?.forEach((element) =>
    {scenary_data.push([element])}
  )

  technologies_values?.forEach((element) =>
    {technologies_data.push([capitalize(element)])}
  )

  console.log(year_data)


  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-10'>
            <Link to="/dashboard/adminbd" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  
      <Header title="Escenarios PELPs disponibles"/>
      <div className='grid grid-cols-3 gap-3'>
        <div style={{maxHeight:"1/4"}}>
              <MUIDataTable
              data={year_data}
              columns={year_columns}
              options={options}
              ></MUIDataTable>
            </div>
            <div style={{maxHeight:"1/4"}}>
              <MUIDataTable
              data={scenary_data}
              columns={scenary_columns}
              options={options}
              ></MUIDataTable>
            </div>
            <div style={{maxHeight:"1/4"}}>
              <MUIDataTable
              data={technologies_data}
              columns={technologies_columns}
              options={options}
              ></MUIDataTable>
            </div>
      </div>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default AvailablePELP