import {React, useState, useContext, useEffect} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header} from '../../../../components';
import { API_PORT } from '../../../../API_PORT';
import { useNavigate, useLocation } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import Modal from '../../../../components/Modal';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';


const PercentageDemandSetSelector2 = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const navigate = useNavigate();
  const location = useLocation()

  const values = location.state.values
  const coins = location.state.coins

  const Input = styled(MuiInput)`
  `;

  const [technologies, setTechnologies] = useState({
    "Fosil": 0,
    "Hidraulica": 0,
    "BiomasaBiogas": 0,
    "Solar": 0,
    "Eolica": 0,
    "Geotermia": 0,
    "AlmacenamientoLargo": 0,
    "AlmacenamientoCorto": 0,
    "H2": 0,

    "FosilExport": 0,
    "HidraulicaExport": 0,
    "BiomasaBiogasExport": 0,
    "SolarExport": 0,
    "EolicaExport": 0,
    "GeotermiaExport": 0,
    "AlmacenamientoLargoExport": 0,
    "AlmacenamientoCortoExport": 0,
    "H2Export": 0
  });

  const [progress, setProgress] = useState({started: false, pc: 0});
  const [msg, setMsg] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loaderscreen, setLoaderScreen] = useState({started: false});

  const [percentagedemandset, setpercentagedemandset] = useState([])

  const handleInput = (event) =>{
    setTechnologies(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  const hasNullValue = () => {
    return Object.values(technologies).some(value => value === "");
  };

  const removeLeadingZerosFromAll = () => {
    setTechnologies(prevTechnologies => {
      const updatedTechnologies = Object.keys(prevTechnologies).reduce((acc, key) => {
        const value = prevTechnologies[key].toString();
  
        // Mantener "0" como está
        if (value === "0") {
          acc[key] = "0";
        } 
        // Eliminar ceros iniciales, dejando solo un "0" si es necesario
        else if (value.startsWith('0') && value.includes('.')) {
          acc[key] = value.replace(/^0+/, '0'); // Eliminar todos los ceros pero dejar un "0" si hay decimal
        } 
        // Eliminar ceros solo si el número es mayor que 0 y tiene ceros iniciales
        else if (value.startsWith('0') && !value.startsWith('0.')) {
          acc[key] = value.replace(/^0+/, '');
        } 
        else {
          acc[key] = value; // Mantener el valor original si no se requiere cambio
        }
  
        return acc;
      }, {});
      
      return updatedTechnologies;
    });
  };

  const replaceCommasWithDots = () => {
    setTechnologies(prevTechnologies => {
      const updatedTechnologies = Object.keys(prevTechnologies).reduce((acc, key) => {
        acc[key] = prevTechnologies[key].toString().replace(/,/g, '.'); // Reemplazar todas las comas por puntos
        return acc;
      }, {});
  
      return updatedTechnologies;
    });
  };


  const PercentageDemandSetSelector = () => {
    if(hasNullValue()){
      alert("Uno de los campos se encuentra vacío. Rellena todos los campos para poder realizar la simulación.")
      return;
    }
    removeLeadingZerosFromAll();
    replaceCommasWithDots();
    const percentage_demand_set= API_PORT + "/scene/quantitative_and_price_models/quantitative-model/percentage-demand-set/" + values.country + "/" + values.year + "?token="+token
    const new_body = {
      "InternalFinalDemandInput": {
        "fósil": technologies.Fosil,
        "hidráulica": technologies.Hidraulica,
        "biomasa y biogas": technologies.BiomasaBiogas,
        "solar": technologies.Solar,
        "eólica": technologies.Eolica,
        "geotermia": technologies.Geotermia,
        "almacenamiento largo plazo": technologies.AlmacenamientoLargo,
        "almacenamiento corto plazo": technologies.AlmacenamientoCorto,
        "H2": technologies.H2
      },
      "ExportsFinalDemandInput": {
        "fósil": technologies.FosilExport,
        "hidráulica": technologies.HidraulicaExport,
        "biomasa y biogas": technologies.BiomasaBiogasExport,
        "solar": technologies.SolarExport,
        "eólica": technologies.EolicaExport,
        "geotermia": technologies.GeotermiaExport,
        "almacenamiento largo plazo": technologies.AlmacenamientoLargoExport,
        "almacenamiento corto plazo": technologies.AlmacenamientoCortoExport,
        "H2": technologies.H2Export
      }
    }

    setIsModalOpen(true)
    setMsg("Calculando la simulación, no cierre esta pestaña hasta completar el proceso.")
    setLoaderScreen(prevState => {return {...prevState, started: true}});

    fetch(percentage_demand_set, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(new_body)
    }).then(res => res.json()).then(data => {setpercentagedemandset(data);})
  }

  useEffect(()=>{
    if(location.state?.technologies){
      setTechnologies({...technologies, Fosil : location.state.technologies.Fosil, Hidraulica: location.state.technologies.Hidraulica, BiomasaBiogas: location.state.technologies.BiomasaBiogas, Solar: location.state.technologies.Solar, Eolica: location.state.technologies.Eolica, Geotermia: location.state.technologies.Geotermia, AlmacenamientoCorto: location.state.technologies.AlmacenamientoCorto, AlmacenamientoLargo: location.state.technologies.AlmacenamientoLargo, H2: location.state.technologies.H2, FosilExport : location.state.technologies.FosilExport, HidraulicaExport: location.state.technologies.HidraulicaExport, BiomasaBiogasExport: location.state.technologies.BiomasaBiogasExport, SolarExport: location.state.technologies.SolarExport, EolicaExport: location.state.technologies.EolicaExport, GeotermiaExport: location.state.technologies.GeotermiaExport, AlmacenamientoCortoExport: location.state.technologies.AlmacenamientoCortoExport, AlmacenamientoLargoExport: location.state.technologies.AlmacenamientoLargoExport, H2Export: location.state.technologies.H2Export});
      console.log(technologies)
    }
  },[location.state?.technologies])

  function make0(){
    setTechnologies({...technologies, Fosil : 0, Hidraulica: 0, BiomasaBiogas: 0, Solar: 0, Eolica: 0, Geotermia: 0, AlmacenamientoCorto: 0, AlmacenamientoLargo: 0, H2: 0, FosilExport : 0, HidraulicaExport: 0, BiomasaBiogasExport: 0, SolarExport: 0, EolicaExport: 0, GeotermiaExport: 0, AlmacenamientoCortoExport: 0, AlmacenamientoLargoExport: 0, H2Export: 0}) 
  };



  const NavigateResults = () => {
    if(percentagedemandset.MIP_base_year?.title){
      alert("Se han calculados los datos de la simulación. Pulsa para continuar.")
      navigate("/dashboard/simulations/main/leontieff_scenary/percentage_demand_set_results", {state: {technologies,values,percentagedemandset, coins}})
    }
  }

  NavigateResults()

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
          <button className="w-72" style={{borderRadius: "10px", backgroundColor: "gray", color: "white", padding: "16px"}} onClick={() => {navigate("/dashboard/simulations/main/leontieff_scenary/percentage_demand_set_selector1", {state: {values}})}}>Volver a la selección de país y año</button>
      </div>  
      <Header title={"Porcentaje demanda final " + capitalize(values.country) + " " + values.year}/>
      <p className='text-gray-500'>En esta sección podrás generar tus propias simulaciones de escenarios Leontieff para porcentajes de demanda final. Ingresa los datos de demanda final interna y de exportación en porcentajes desde el -100% al 1000% usando los deslizadores o ingresándolos manualmente; luego pulsa el botón de la parte inferior para ver los resultados.</p>

      <div className='mt-10'><Header title="Demanda final interna"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6'>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Fósil: {technologies.Fosil}%</h1>
          <Input type='string' value={technologies.Fosil} name='Fosil' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Fosil" min={-100} max={1000} aria-label="input-slider" value={technologies.Fosil} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Hidráulica: {technologies.Hidraulica}%</h1>
          <Input type='string' value={technologies.Hidraulica} name='Hidraulica' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Hidraulica" min={-100} max={1000} aria-label="input-slider" value={technologies.Hidraulica} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Biomasa y Biogas: {technologies.BiomasaBiogas}%</h1>
          <Input type='string' value={technologies.BiomasaBiogas} name='BiomasaBiogas' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="BiomasaBiogas" min={-100} max={1000} aria-label="input-slider" value={technologies.BiomasaBiogas} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Solar: {technologies.Solar}%</h1>
          <Input type='string' value={technologies.Solar} name='Solar' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Solar" min={-100} max={1000} aria-label="input-slider" value={technologies.Solar} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Eólica: {technologies.Eolica}%</h1>
          <Input type='string' value={technologies.Eolica} name='Eolica' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Eolica" min={-100} max={1000} aria-label="input-slider" value={technologies.Eolica} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Geotermia: {technologies.Geotermia}%</h1>
          <Input type='string' value={technologies.Geotermia} name='Geotermia' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Geotermia" min={-100} max={1000} aria-label="input-slider" value={technologies.Geotermia} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Almacenamiento Largo Plazo: {technologies.AlmacenamientoLargo}%</h1>
          <Input type='string' value={technologies.AlmacenamientoLargo} name='AlmacenamientoLargo' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="AlmacenamientoLargo" min={-100} max={1000} aria-label="input-slider" value={technologies.AlmacenamientoLargo} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
        <h1 className='mb-3' style={{fontSize:"12px"}}>Almacenamiento Corto Plazo: {technologies.AlmacenamientoCorto}%</h1>
          <Input type='string' value={technologies.AlmacenamientoCorto} name='AlmacenamientoCorto' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="AlmacenamientoCorto" min={-100} max={1000} aria-label="input-slider" value={technologies.AlmacenamientoCorto} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h3 className='mb-3' style={{fontSize:"12px"}}>H2: {technologies.H2}%</h3>
          <Input type='string' value={technologies.H2} name='H2' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="H2" min={-100} max={1000} aria-label="input-slider" value={technologies.H2} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
      </div>


      <div className='mt-10'><Header title="Demanda final exportación"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6'>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Fósil: {technologies.FosilExport}%</h1>
          <Input type='string' value={technologies.FosilExport} name='FosilExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="FosilExport" min={-100} max={1000} aria-label="input-slider" value={technologies.FosilExport} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Hidráulica: {technologies.HidraulicaExport}%</h1>
          <Input type='string' value={technologies.HidraulicaExport} name='HidraulicaExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="HidraulicaExport" min={-100} max={1000} aria-label="input-slider" value={technologies.HidraulicaExport} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Biomasa y Biogas: {technologies.BiomasaBiogasExport}%</h1>
          <Input type='string' value={technologies.BiomasaBiogasExport} name='BiomasaBiogasExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="BiomasaBiogasExport" min={-100} max={1000} aria-label="input-slider" value={technologies.BiomasaBiogasExport} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Solar: {technologies.SolarExport}%</h1>
          <Input type='string' value={technologies.SolarExport} name='SolarExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="SolarExport" min={-100} max={1000} aria-label="input-slider" value={technologies.SolarExport} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Eólica: {technologies.EolicaExport}%</h1>
          <Input type='string' value={technologies.EolicaExport} name='EolicaExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="EolicaExport" min={-100} max={1000} aria-label="input-slider" value={technologies.EolicaExport} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Geotermia: {technologies.GeotermiaExport}%</h1>
          <Input type='string' value={technologies.GeotermiaExport} name='GeotermiaExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="GeotermiaExport" min={-100} max={1000} aria-label="input-slider" value={technologies.GeotermiaExport} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3' style={{fontSize:"12px"}}>Almacenamiento Largo Plazo: {technologies.AlmacenamientoLargoExport}%</h1>
          <Input type='string' value={technologies.AlmacenamientoLargoExport} name='AlmacenamientoLargoExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="AlmacenamientoLargoExport" min={-100} max={1000} aria-label="input-slider" value={technologies.AlmacenamientoLargoExport} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
        <h1 className='mb-3' style={{fontSize:"12px"}}>Almacenamiento Corto Plazo: {technologies.AlmacenamientoCortoExport}%</h1>
          <Input type='string' value={technologies.AlmacenamientoCortoExport} name='AlmacenamientoCortoExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="AlmacenamientoCortoExport" min={-100} max={1000} aria-label="input-slider" value={technologies.AlmacenamientoCortoExport} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h3 className='mb-3' style={{fontSize:"12px"}}>H2: {technologies.H2Export}%</h3>
          <Input type='string' value={technologies.H2Export} name='H2Export' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -100,
              max: 1000,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="H2Export" min={-100} max={1000} aria-label="input-slider" value={technologies.H2Export} step={1} valueLabelDisplay="auto" marks={[{value: -100, label: '-100%'},{value: 1000,label: '1000%'}]} onChange={handleInput}/></div>
        </div>
      </div>
      
      <div className='mt-16 flex gap-5'>
        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3 w-60" onClick={PercentageDemandSetSelector}>Ver Resultados</button>
        <TooltipComponent content="Al pulsar este botón, se borrarán todos los datos del escenario y pasarán a ser 0."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3 w-60" onClick={() => {make0()}}>Borrar datos</button></TooltipComponent>
      </div>
      <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
    </div>
  )
}

export default PercentageDemandSetSelector2

/*
<input type='string' placeholder='0' name='Fosil' onChange={handleInput} className='form-control rounded-0 w-24 h-10' style={{border:"1px solid" ,borderRadius: "8px", color: "gray", backgroundColor:"white"}}></input> 
<input name="Fosil" onChange={handleInput} className='form-control rounded-0 w-24 h-10'></input>
*/