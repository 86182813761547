import {React, useContext, useState} from 'react'
import { Link } from 'react-router-dom';
import { Header, Button } from '../../../../components';
import DataTable from 'react-data-table-component';
import Modal from '../../../../components/Modal';
import { UserContext } from '../../../../contexts/UserContext';
import { API_PORT } from '../../../../API_PORT';
import notification from "../../../../assets/help.png"
import { TooltipComponent } from '@syncfusion/ej2-react-popups';


const Scenarios = () => {
  const country_options = [
    { value: "", label: "Selecciona el país"},
    { value: "chile", label: "Chile"}]
  ;

  const year_options = [
    { value: 0, label: "Selecciona el año"},
    { value: 2020, label: "2020"}]
  ; 

  const [values, setValues] = useState({
    "country_name": "",
    "year": "",
    "yearBaseMip": "",
  });

  const [progress, setProgress] = useState({started: false, pc: 0});
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [msg, setMsg] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const userContext = useContext(UserContext);

  const token = userContext.value.access_token;

  const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  const [file, setFile] = useState();

  const handleFileUpload = (event) => {
    setFile(event.target.files[0])
  }

  const responsenavigate = (response) => {
    if (response.state === "tablas ESS_H2 info pobladas") {
      setMsg("Carga de archivo completada")
      alert("Se ha subido correctamente el archivo")
    }
    else if (response.state === "Datos de generación y capacidad PELP cargados") {
      setMsg("Carga de archivo completada")
      alert("Se ha subido correctamente el archivo")
    }
    else if (response.state === "Expansión y calibración ESS+H2 realizada, multiplicadores matriz años base ESS+H2 obtenidos") {
      setMsg("Expansión y calibración completada")
      alert("Se ha realizado la expansión y calibración de ESS + H2 exitosamente")
      //navigate("/dashboard/adminbd/calibration")
    }
    else if (response.state === "tablas limpias") {
      setMsg("Tablas eliminadas")
      alert("Se han eliminado correctamente todas las tablas de la bases de datos de los escenarios")
    }
    else {
      console.log(response)
      setMsg("Proceso fallido")
      alert(response.detail)
    }
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
  }

  function handleSubmit({endpoint,file_type}){
    var new_link = API_PORT+endpoint+values.country_name+"/"+values.year+"?token="+token;
    if (file_type === "PELP_excel_input"){
      new_link= API_PORT+endpoint+values.country_name+"?token="+token
    }
    if (endpoint === "/admin/scenarios/do-ESS-H2-expansion/"){
      new_link = API_PORT+endpoint+values.country_name+"/"+values.yearBaseMip+"?token="+token;
      setIsModalOpen(true)
      setMsg("Realizando expansión y calibración. No cierre esta ventana hasta completar el proceso.")
      setLoaderScreen(prevState => {
        return {...prevState, started: true}
      })
      fetch(new_link, {
          method: 'POST',
          headers: {
            "Content-Type":"applicaton/json"
          }
          }).then((res => res.json()))
          .catch((error) => console.error(error))
          .then((response) => responsenavigate(response));

    }
    else if (endpoint === "/admin/scenarios/clear-scenarios-tables"){
      new_link = API_PORT + endpoint+"?token="+token;
      setIsModalOpen(true)
        setMsg("Eliminando las tablas. No cierre esta ventana hasta completar el proceso.")
        setLoaderScreen(prevState => {
          return {...prevState, started: true}
        })
        fetch(new_link, {
            method: 'DELETE',
            headers: {
                "Content-Type":"application/json"
            }
            }).then((res => res.json()))
            .catch((error) => console.error(error))
            .then((response) => responsenavigate(response));
    }
    else if (file){
          const fd = new FormData()
          fd.append(file_type, file)
          setIsModalOpen(true)
          setMsg("Subiendo el archivo. No cierre esta ventana hasta completar el proceso.")
          setLoaderScreen(prevState => {
            return {...prevState, started: true}
          })
          fetch(new_link, {
            method: 'POST',
            body: fd,
            headers: undefined
            }).then((res => res.json()))
            .catch((error) => console.error(error))
            .then((response) => responsenavigate(response));
      }
    else {
        setMsg("No se ha cargado el archivo o seleccionado un campo")
      }
  }

  const columns = [
    {
      name: "Nombre",
      selector: row => row.name
    },
    {name: "País",
    selector: row => row.country},
    {name: "Año",
    selector: row => row.year},
    {name: "Archivo",
    selector: row => row.action},
    {name: "Acción",
    selector: row => row.upload}
  ];

  const data = [
    {id: 1,
    name: <div className='flex gap-2'>
    <TooltipComponent content="En esta zona se debe cargar el archivo '10_datos_ESS_H2_V4.xlsx' que contiene toda la información del escenario necesitado. El proceso de carga puede demorar varios minutos, por lo que una vez que pulse el botón para subir los archivos, espere a que el proceso finalize antes de salir de la pantalla para evitar errores."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
    ESS y H2
  </div>,
    country: 
      <select type='string' placeholder='País' name='country_name'
      onChange={handleInput} className='form-select rounded-0'>
      {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
    year: <select type='integer' placeholder='Año' name='year'
      onChange={handleInput} className='form-select rounded-0'>
      {year_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
    action: <input type="file"accept=".xlsx"onChange={handleFileUpload}/>,
    upload: <button className="submit blue" onClick={()=> handleSubmit({endpoint: "/admin/scenarios/save-ESS-H2-info/", file_type: "ESS_H2_info"})}>Subir</button>},

    {id: 2,
      name: <div className='flex gap-2'>
      <TooltipComponent content="En esta zona se debe cargar el archivo'11_PELP_V4.xlsx', el cual guarda los datos relacionados con la PELP (planificación eléctrica de largo plazo) en la base de datos. El proceso de carga puede demorar varios minutos, por lo que una vez que pulse el botón para subir los archivos, espere a que el proceso finalize antes de salir de la pantalla para evitar errores."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
      PELP
    </div>,
      country: 
        <select type='string' placeholder='País' name='country_name'
        onChange={handleInput} className='form-select rounded-0'>
        {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
      year: "",
      action: <input type="file"accept=".xlsx"onChange={handleFileUpload}/>,
      upload: <button className="submit blue" onClick={()=> handleSubmit({endpoint: "/admin/scenarios/save-PELP/", file_type: "PELP_excel_input"})}>Subir</button>},

      {id: 3,
        name: <div className='flex gap-2'>
        <TooltipComponent content="En esta zona se realiza la inclusión del hidrógeno y el almacenamiento (ESS) y se obtiene la matriz MIP ESS+H2.IMPORTANTE: El proceso de inclusión puede demorar varios minutos, por lo que una vez que pulse el botón, espere a que el proceso finalize antes de salir de la pantalla para evitar errores."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
        Inclusión ESS y H2
      </div>,
        country: 
          <select type='string' placeholder='País' name='country_name'
          onChange={handleInput} className='form-select rounded-0'>
          {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
        year: <select type='integer' placeholder='Año' name='yearBaseMip'
          onChange={handleInput} className='form-select rounded-0'>
          {year_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
        action: "",
        upload: <button className="submit bg-green" onClick={()=> handleSubmit({endpoint: "/admin/scenarios/do-ESS-H2-expansion/"})}>Realizar Inclusión</button>},
      
        {id: 4,
          name: <div className='flex gap-2'>
          <TooltipComponent content="Esta zona eliminar todas las tablas de la base de datos relacionadas a los escenarios, por lo que de hacerlo deben volver a subirse en su totalidad."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
          Eliminar escenario
        </div>,
          country: "",
          year: "",
          action: "",
          upload: <button className="submit bg-red" onClick={()=> handleSubmit({endpoint: "/admin/scenarios/clear-scenarios-tables"})}>Eliminar</button>},
  ]

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/adminbd" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <Header title="Subir archivos de escenarios"/>
      <div className='mt-5'>
        <DataTable 
          columns = {columns}
          data= {data}
        >
        </DataTable>
        <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      </div>
    </div>
  )
}

export default Scenarios