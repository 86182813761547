import React, {useState} from 'react'
import './LoginSignup.css'
import email_icon from '../../assets/email.png'
import logo from '../../assets/white_logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { RecoverValidation } from './Validation'
import { API_PORT } from '../../API_PORT'
import LoadingScreen from '../../components/LoadingScreen'

//Send recover code for already register emails
const Recover = () => {
    const [loadingscreen, setLoadingScreen] = useState({started: false});
    const [open, setOpen] = useState(false);

    const [values, setValues] = useState({
        "email": "",
    });

    const navigate = useNavigate();

    const [errors, setErrors] = useState({});

    const handleInput = (event) =>{
        setValues(prev => ({...prev, [event.target.name]:event.target.value}))
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setErrors(RecoverValidation(values));
        const url = API_PORT + '/admin/users_accounts/request_reset_password_from_email/';
        if(errors.email === ""){
            setOpen(true);
            setLoadingScreen(prevState => {return {...prevState, started: true}});
            try {
                const response = await fetch(url, {
                  method: 'POST',
                  headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  },
                  body: JSON.stringify(values.email)
                });
          
                if (!response.ok) {
                  throw new Error('Error al solicitar el restablecimiento de contraseña');
                }
                const responseData = await response.json();
                console.log('Respuesta del servidor:', responseData);
                // Verifica la respuesta del servidor
                if (responseData.detail === 'Código de recuperación enviado al correo') {
                    // Realiza la navegación a la página principal
                    alert("Se ha enviado un código de recuperación a tu email. Revisa tu carpeta de spam si no encuentras el email.")
                    navigate("/")
                } else {
                    // Maneja otros casos de respuesta del servidor si es necesario
                    console.log('Respuesta no esperada del servidor:', responseData);
                    alert(responseData.detail)
                    setOpen(false);
                    setLoadingScreen(prevState => {return {...prevState, started: false}});
                }
              } catch (error) {
                console.error('Error al intentar restablecer la contraseña:', error.message);
                alert(error.message + ', el correo no existe o no ha sido verificado en la base de datos')
                setOpen(false);
                setLoadingScreen(prevState => {return {...prevState, started: false}});
                // Aquí puedes manejar el error como necesites
              }
        }
      };

  return (
    <div>
        <div className='container2 text-white'>
            <h1 style={{position:"absolute", left:"73%", transform: "translateX(-50%);"}} title='Volver a ecokinesis.cl'><Link to="https://ecokinesis.cl/"><strong>Volver al inicio</strong></Link></h1>
        </div>
        <div className='container bg-login'>
            <div className='header'>
                <img src={logo} className="logo"alt=''></img>
                <div className='text'>Recuperar Contraseña</div>
                <div className='subtext white mb-10'>Ingresa tu correo electrónico y recibirás un enlace de y código de verificación para recuperar tu contraseña</div>
            </div>
            <div className='container3a'>
                <form action = "" onSubmit={handleSubmit}>
                    <div className='inputs'>
                        <div className='input'>
                            <img src={email_icon} alt='' />
                            <label htmlFor='email'></label>
                            <input type='email' placeholder='Correo Electrónico' name='email'
                            onChange={handleInput} className='form-control rounded-0'/>
                            {errors.email && <span className='text-danger' style={{fontSize: "10px"}}>{errors.email}</span>}
                        </div>
                    </div>
                    <div className='container4'>
                        <button className="submit" type="submit" title='Pulse doble click para recuperar su contraseña'>Recuperar contraseña</button>
                        <button className="submit gray" onClick={() => navigate("/")}>Volver</button>
                    </div>     
                </form>
            </div>
        </div>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default Recover