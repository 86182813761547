function ProfesionLevelText(level){
    if (level.toString() === "1"){
        return("Academia")
    }
    else if (level.toString() === "2"){
        return("Sector Público")
    }
    else if (level.toString() === "3"){
        return("Sector Privado")
    }
    else if (level.toString() === "4"){
        return("Organización no gubernamental")
    }
    else {
        return("Sin definir")
    }

  }

export default ProfesionLevelText