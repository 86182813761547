import React from "react";
import "./Modal.css";
import loader from "../assets/blue_loading.svg"

export default function Modal({ isOpen, closeModal, msg, progress, loaderscreen }) {
  if(isOpen) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  if (!isOpen) return null;

  //Use modal for loading screens beetween algorithms transitions
  return (
    <>
        <div className="modal">
          <div className="overlay"></div>
          <div className="modal-content">
            <button className="close-modal mb-4" onClick={closeModal}>
              Cerrar
            </button>
            <div className='flex justify-center items-center gap-20 flex-wrap mt-12'>
                {loaderscreen.started && <img src={loader} alt=''></img>}
                {msg && <span>{msg}</span>}
            </div>
          </div>
        </div>
    </>
  );
}

//                {progress.started && <progress className="blue" max="100" value={progress.pc}></progress>}