import React from 'react'
import { Link } from 'react-router-dom';
import { Header, Button } from '../../../components';
import { useStateContext } from '../../../contexts/ContextProvider';
import bd_logo from '../../../assets/bd_logo.svg';
import mip from "../../../assets/list_FILL0_wght300_GRAD0_opsz24.svg"


const AdminBD = () => {
  const { currentColor }= useStateContext();

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <Header title="Gestión de BBDD"/>
      <div className='grid grid-cols-4 gap-4"'>
        <div className='max-w-64'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={mip} width="80px" height="80px" className='mt-3' alt=''/>
              <div className='text-gray-400'><strong>Calibración</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="/dashboard/adminbd/calibration" >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Subir archivos"
                  borderRadius="10px"
                  size="md"
                  width="max-w-64"
                />
            </Link>
          </div>
          <div className='max-w-64'>
          Sección para subir archivos a la base de datos de los años bases y realizar calibraciones necesarias para el funcionamiento de la plataforma.
          </div>
        </div>

        <div className='max-w-64'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={mip} width="80px" height="80px" className='mt-3' alt=''/>
              <div className='text-gray-400'><strong>Escenarios</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="/dashboard/adminbd/scenarios" >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Subir archivos"
                  borderRadius="10px"
                  size="md"
                  width="max-w-64"
                />
            </Link>
          </div>
          <div className='max-w-64'>
            Sección para subir los archivos de escenarios y realizar la inclusión necesaria para el funcionamiento de la plataforma.
          </div>
        </div>

        <div className='max-w-64'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={mip} width="80px" height="80px" className='mt-3' alt=''/>
              <div className='text-gray-400'><strong>MSO</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="/dashboard/adminbd/mso" >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Subir archivos"
                  borderRadius="10px"
                  size="md"
                  width="max-w-64"
                />
            </Link>
          </div>
          <div className='max-w-64'>
          Sección para subir y calibrar los archivos MSO necesarios para el funcionamiento de la plataforma.
          </div>
        </div>

        <div className='max-w-64'>
            <div className='h-32 bg-light-gray rounded-lg'>
              <div className='grid grid-cols-1 justify-items-center'>
                <img src={mip} width="80px" height="80px" className='mt-3' alt=''/>
                <div className='text-gray-400'><strong>Resetear Base de Datos</strong></div>
              </div>
            </div>
            <div className="flex justify-center items-center mb-4">
              <Link to="/dashboard/adminbd/resetbd" >
                  <Button
                    color="white"
                    bgColor={currentColor}
                    text="Resetear"
                    borderRadius="10px"
                    size="md"
                    width="max-w-64"
                  />
              </Link>
            </div>
            <div className='max-w-64'>
              Sección para resetear toda la base de datos de EcoKinesis.
            </div>
          </div>
        </div>
      <div className='mt-14 grid grid-cols-4 gap-4"'>
        <div className='max-w-64'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={bd_logo} width="80px" height="80px" className='mt-3' alt=''/>
              <div className='text-gray-400'><strong>Lista de MIP</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="/dashboard/adminbd/availablemip" >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Ver lista"
                  borderRadius="10px"
                  size="md"
                  width="max-w-64"
                />
            </Link>
          </div>
          <div className='max-w-64'>
              Sección para ver la lista de MIP disponibles dentro de la base de datos.
          </div>
        </div>

        <div className='max-w-64'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={bd_logo} width="80px" height="80px" className='mt-3' alt=''/>
              <div className='text-gray-400'><strong>Lista de PELP</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="/dashboard/adminbd/availablepelp" >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Ver lista"
                  borderRadius="10px"
                  size="md"
                  width="max-w-64"
                />
            </Link>
          </div>
          <div className='max-w-64'>
            Sección para observar la lista de PELP disponibles en la base de datos.
          </div>
        </div>

          <div className='max-w-64'>
          <div className='h-32 bg-light-gray rounded-lg'>
            <div className='grid grid-cols-1 justify-items-center'>
              <img src={bd_logo} width="80px" height="80px" className='mt-3' alt=''/>
              <div className='text-gray-400'><strong>Lista de Monedas</strong></div>
            </div>
          </div>
          <div className="flex justify-center items-center mb-4">
            <Link to="/dashboard/adminbd/coin_list" >
                <Button
                  color="white"
                  bgColor={currentColor}
                  text="Ver Lista"
                  borderRadius="10px"
                  size="md"
                  width="max-w-64"
                />
            </Link>
          </div>
          <div className='max-w-64'>
          Sección para observar la lista de monedas nacionales disponibles en la base de datos.
          </div>
        </div>

      </div>
    </div>
  )
}

export default AdminBD