//Imports
import React, { createContext, useState, useEffect, useCallback } from "react";

const time = (60*60*2) * 1000 //Change total time of session (2 hours)

// User values default
export const defaultUserContext = {
    email: "",
    username: "",
    first_name: "",
    last_name: "",
    user_type: "",
    access_token: "",
    user_photo: "",
    logged: false,
    country: "Sin asignar",
    year: "Sin asignar"
};

// Define contexts
export const UserContext = createContext({
    value: defaultUserContext,
    setValue: () => {},
    logout: () => {},
});

export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(() => {
        const storedUser = sessionStorage.getItem("userContext");
        return storedUser ? JSON.parse(storedUser) : defaultUserContext;
    });

    let inactivityTimeout = null;

    // Función para cerrar sesión
    const logout = useCallback(() => {
        alert('Su sesión ha expirado, por favor vuelva a iniciar sesión para ingresar a la plataforma.');
        setUser(defaultUserContext);
        sessionStorage.removeItem("userContext");
    }, []);

    // Función para actualizar el usuario
    const updateUser = (newValues) => {
        setUser((prevState) => {
            const updatedUser = { ...prevState, ...newValues };
            sessionStorage.setItem("userContext", JSON.stringify(updatedUser));
            return updatedUser;
        });
    };

    // Iniciar temporizador de inactividad cuando el usuario inicie sesión
    useEffect(() => {
        if (user.logged) {
            // Temporizador de X segundos para pruebas
            inactivityTimeout = setTimeout(() => {
                logout(); // Cierra sesión cuando el tiempo se acabe
            }, time); // X segundos

            return () => {
                clearTimeout(inactivityTimeout); // Limpiar el temporizador si el usuario cierra sesión o cambia
            };
        }
    }, [user.logged, logout]);

    return (
        <UserContext.Provider value={{ value: user, setValue: updateUser, logout }}>
            {children}
        </UserContext.Provider>
    );
};
