//Imports
import React, { useContext} from 'react';
import { Navigate, Outlet} from "react-router-dom";
import { UserContext } from '../contexts/UserContext';

//Detect if user is logged
const PrivateRoute = () => {
  const userContext = useContext(UserContext);
  const logged = userContext.value.logged;
	return logged ?  <Outlet />: <Navigate to='/' />;
}

export default PrivateRoute;