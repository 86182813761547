import {React, useEffect, useState, useContext} from 'react';
import { MdOutlineCancel } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Button } from '.';
import { API_PORT } from '../API_PORT';
import { UserContext } from '../contexts/UserContext';
import { Link, useNavigate } from 'react-router-dom';
import loader from "../assets/blue_loading.svg";
import { useStateContext } from '../contexts/ContextProvider';

const Notification = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const navigate = useNavigate();
  const link= API_PORT + "/notifications/get-notification-list/?token="+token
  const link2 = API_PORT + "/notifications/mark-notification-as-readed/"

  const [notifications, setNotifications] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const {initialState, setIsClicked, setReadedNotifications} = useStateContext();

  useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    })
    .then(res => res.json())
    .then(data => {
      setNotifications(data);
      setIsLoading(false); 
    })
    .catch(error => {
      console.error('Error fetching data:', error);
      setIsLoading(false); 
    });
  }, [link]);

    var notifications2 = notifications
    var notification_list  = [];

    const Readed = (index) => {
      fetch(link2+index+"?token="+token, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
        })
    }

    const handleDelete = (index,id) => {
      setNotifications(prevNotifications =>
        prevNotifications.filter((_, i) => i !== index)
      );
      Readed(id)
    };
    
    notifications2?.forEach((element) =>
      {if(!element.readed){
        notification_list.push(element)
      }}
    )

    function TotalNotifications(index){
      if(index > 5){
        return("5+")
      }
      else{
        return(index)
      }
    }

  function NavigateMenu(data){
      if(data === "Simulación MSO completada"){
        return(<button title='Pulsa para ir a la lista de escenarios de operación simulada' className='text-blue-500' onClick={()=>{navigate("/dashboard/simulations/main/simulation_operation_list", setIsClicked(initialState))}}>{data}</button>)
      }
      return(data)
    }

  if(notification_list > 0){
    setReadedNotifications(true);
  }

  return (
    <div className="nav-item absolute right-10 md:right-10 top-16 bg-white dark:bg-[#42464D] p-8 rounded-lg w-96 border-2">
      <div className="flex justify-between items-center">
        <div className="flex gap-3">
          <p className="font-semibold text-lg dark:text-gray-200"><strong>Notificaciones sin leer ({TotalNotifications(notification_list.length)})</strong></p>
        </div>
        <TooltipComponent content="Cerrar">
          <Button icon={<MdOutlineCancel />} color="rgb(153, 171, 180)" bgHoverColor="light-gray" size="2xl" borderRadius="50%" />
        </TooltipComponent>
      </div>
      {isLoading ? (
        <div className='flex items-center justify-center'><img src={loader} alt="Loading..." /></div> 
      ) : ( 
        <div>
          {notification_list.slice(0, 5).map((notification, index) => (
            <div
              key={index}
              className="flex items-center leading-8 gap-5 border-b border-color p-3"
            >
              <div className="flex-grow">
                <p className="font-semibold dark:text-gray-200">{NavigateMenu(notification.title)}</p>
                <p className="text-gray-500 text-sm dark:text-gray-400">{notification.message}</p>
              </div>
              <TooltipComponent content="Marcar como leída">
                <button
                  color="rgb(153, 171, 180)"
                  bgHoverColor="light-gray"
                  size="2xl"
                  borderRadius="50%"
                  onClick={() => handleDelete(index, notification.id)}
                >
                  <MdOutlineCancel className='w-4 h-4'/>
                </button>
              </TooltipComponent>
            </div>
          ))}
     </div>
      )}
    <div className='mt-3 flex justify-center items-center'>
            <Link to="/dashboard/account/notifications">
              <Button className
                color="white"
                bgColor="#425df9"
                text="Ver todas las notificaciones"
                borderRadius="10px"
                size="md"
                width="60"
              />
            </Link>
          </div>
    </div>
  );
};

export default Notification;