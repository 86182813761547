//Imports
import React, { useEffect, useContext, useState } from 'react'
import { AiOutlineMenu } from 'react-icons/ai';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { FiSettings } from 'react-icons/fi';
import { FiBookmark } from "react-icons/fi";
import avatar from '../data/avatar.jpg';
import { Notification, UserProfile } from '.';
import { useStateContext } from '../contexts/ContextProvider';
import { UserContext } from '../contexts/UserContext';
import { API_PORT } from '../API_PORT';
import { useNavigate } from 'react-router-dom';
import NotificationButton from './NotificationButton';
import UpdateView from './UpdateView';

//Custom Button
const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={() => customFunc()}
      style={{ color }}
      className="relative text-xl rounded-full p-3 hover:bg-light-gray"
    >
      <span
        style={{ background: dotColor }}
        className="absolute inline-flex rounded-full h-2 w-2 right-2 top-2"
      >
      </span>
      {icon}
    </button>
  </TooltipComponent>
);

//Navbar function
const Navbar = () => {
  const { activeMenu, setActiveMenu, isClicked, handleClick, screenSize, setScreenSize, currentColor, setThemeSettings, setReadedNotifications } = useStateContext();
  const userContext = useContext(UserContext);
  const user = userContext.value.username
  const user_photo = userContext.value.user_photo;
  const navigate = useNavigate();
  const token = userContext.value.access_token;
  const link= API_PORT + "/notifications/get-notification-list/?token="+token
  const [notifications, setNotifications] = useState([])

  //Auto window resize
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize)
  },[setScreenSize]);

  useEffect(() => {
    if(screenSize <= 960){
      setActiveMenu(false);
    } else{
      setActiveMenu(true);
    }
  }, [setActiveMenu, screenSize]);

  //Get notifications
  useEffect(() => {
    const fetchNotifications = () => {
      fetch(link, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
      })
      .then(res => res.json())
      .then(data => {
        setNotifications(data)
      })
      .catch(error => {
        console.log(error);
      });
    };  

    // Llama a la función inmediatamente al montarse el componente
    fetchNotifications();

    // Configura el intervalo para llamar a la función cada 1 minuto (60000 ms)
    const intervalId = setInterval(fetchNotifications, 60000*2);

    // Limpia el intervalo cuando el componente se desmonte
    return () => clearInterval(intervalId);
  }, [link]);

  //Set notifications
  useEffect(()=>{
    const hasUnreadNotifications = notifications.some(notification => !notification.readed);
    if(hasUnreadNotifications){
        setReadedNotifications(true)
    }
    else{
      setReadedNotifications(false)
    }
  },[notifications])

  const handleActiveMenu = () => setActiveMenu(!activeMenu);

  //Avatar photo render on screen
  function avatarRender(){
    if (user_photo=== null){return(avatar)}
    else{return(API_PORT+user_photo)}
  }


  //Return Navbar annd items
  return (
    <div className='flex justify-between p-2 md:mx-6 relative'>
      <NavButton title="Abrir/Cerrar Menú" customFunc={handleActiveMenu} color={currentColor} icon={<AiOutlineMenu />}/>
      <div className='flex'>
        <button style={{width: "300px", height: "40px", borderRadius: "20px", color:"white", backgroundColor:"orange", marginRight: "20px"}} onClick={()=> navigate("/dashboard/account/evaluate_platform")}><strong>Califica la plataforma, haz click aquí</strong></button>
        <TooltipComponent content="Mi perfil" position='BottomCenter'>
          <div
            className='flex items-center gap-2 cursor-pointer p-1 hover:bg-light-gray rounded-lg'
            onClick={() => handleClick('userProfile')}
          >
            <p><span className='text-gray-400 font-bold ml-1 text-14'>Hola, {user}</span></p>
            <MdKeyboardArrowDown  className='text-gray-400 text-14'/>
            <img className='rounded-full w-8 h-8' style={{border: "2px solid", borderColor: currentColor}} src={avatarRender()} alt="user-profile"/>
          </div>
        </TooltipComponent>
        <NotificationButton/>
        {isClicked.notification && (<Notification />)}
        <NavButton title="Historial de actualizaciones" customFunc={()=> handleClick('update')} color={currentColor} icon={<FiBookmark/>}/>
        {isClicked.update && (<UpdateView/>)}
        {isClicked.userProfile && (<UserProfile />)}
        <NavButton title="Ajustes" customFunc={() => setThemeSettings(true)} color={currentColor} icon={<FiSettings/>}/>
      </div>
    </div>
  )
};

export default Navbar
