import {React, useState, useContext} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import FullScreen from '../../../../components/FullScreen';
import capitalize from '../../../../hook/capitalize';
import graphColors from '../../../../hook/graphColors';
import filedownload from "../../../../assets/file.svg"
import download from "../../../../assets/download_black.svg"
import expand from "../../../../assets/expand.svg"
import edit from "../../../../assets/edit.svg"
import save from "../../../../assets/save_black.svg"
import MUIDataTable from "mui-datatables";
import HorizontalTables from '../../../../hook/HorizontalTables';
import { Bar, Line, Doughnut, Radar } from "react-chartjs-2";
import { CSVLink } from 'react-csv';
import { Chart as ChartJS, Title, BarElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, LineElement, Filler, Tooltip, Legend, ArcElement} from "chart.js";

ChartJS.register(Title, BarElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, LineElement, ArcElement, Filler, Tooltip, Legend)

const PredefinedScenaryResults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userContext = useContext(UserContext);
  const user_type= userContext.value.user_type;

  //Define const of location
  const values = location.state.values
  const data = location.state.data
  const scenary_data = location.state.scenary_data
  const summary = location.state.summary
  const componentsmip = location.state.componentsmip
  const detailcompositionmip = location.state.detailcompositionmip
  const impactscontributionmip = location.state.impactscontributionmip
  const exportimportsmip = location.state.exportimportsmip
  const capitaldistributionmip = location.state.capitaldistributionmip
  const remunerationdistributionmip = location.state.remunerationdistributionmip
  const fiscalimpactmip = location.state.fiscalimpactmip
  const directemissionsmip = location.state.directemissionsmip
  const carbonfootprintmip= location.state.carbonfootprintmip
  const inducedemploymentmip = location.state.inducedemploymentmip
  const remunerationanalisismip = location.state.remunerationanalisismip
  const pibemploymentmip = location.state.pibemploymentmip
  const finaldemandmip = location.state.finaldemandmip
  const coins = location.state.coins

  //Colums and data
  const base = " (MIP Año Base)"

  function CSVRender(user){
    if (user === "premium" || user === "admin"){
      return(<div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button title='Pulsa para descargar la tabla o gráfico actual en formato CSV. Esta opción sólo está disponible para usuarios premium'><CSVLink data={CSVData()} filename={CSVTitle()}><img src={filedownload} className='w-10 h-10' alt=''/>Convertir a CSV</CSVLink></button></div>)
    }
    else{
      return(<div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button title='Pulsa para descargar la tabla o gráfico actual en formato CSV. Esta opción sólo está disponible para usuarios premium' onClick={()=>{alert("La opción de descargar CSV sólo está disponible para usuarios premium. Adquiere la suscripción premium de EcoKinesis para poder usar esta función")}}><img src={filedownload} className='w-10 h-10' alt=''/>Convertir a CSV</button></div>)
    }
  }

  function options_type(user){
    if(user === "admin" || user === "premium"){
      return({
        rowsPerPage: 10,
        rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15,20],
        selectableRows: "none",
        tableBodyMaxHeight: "400px",
        print: "false",
        download: "false",
        filter: "false",
        responsive: "standard"
      })
    }
    else{
      return({
        rowsPerPage: 10,
        rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15,20],
        selectableRows: "none",
        tableBodyMaxHeight: "400px",
        print: "false",
        download: "false",
        filter: "false",
        responsive: "standard"
      })
    }
  }

  function options_type2(user){
    if(user === "admin" || user === "premium"){
      return({
        rowsPerPage: 6,
        rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,11,12],
        selectableRows: "none",
        tableBodyMaxHeight: "400px",
        print: "false",
        download: "false",
        filter: "false",
        responsive: "standard"
      })
    }
    else{
      return({
        rowsPerPage: 6,
        rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,11,12],
        selectableRows: "none",
        tableBodyMaxHeight: "400px",
        print: "false",
        download: "false",
        filter: "false",
        responsive: "standard"
      })
    }
  }

  function options_type3(user){
    if(user === "admin" || user === "premium"){
      return({
        rowsPerPage: 10,
        rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10],
        selectableRows: "none",
        tableBodyMaxHeight: "320px",
        print: "false",
        download: "false",
        filter: "false",
        responsive: "standard"
      })
    }
    else{
      return({
        rowsPerPage: 10,
        rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10],
        selectableRows: "none",
        tableBodyMaxHeight: "320px",
        print: "false",
        download: "false",
        filter: "false",
        responsive: "standard"
      })
    }
  }

  const total_scenary = scenary_data.length;
  const scenary_options = Array.from({ length: total_scenary }, (_, i) => i + 1);

  //Indicators and graphic-table options
  const [appState, changeState] = useState({
    activeObject: null,
    activeGraphic: null,
    activeScenary: null,
    objects: [1,2,3,4],
    graphicObjects: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15],
    scenaryObjects: scenary_options
  })

  function toggleActive(index,graphicindex, scenaryindex){
    if (index === 0 || index === 1 || index === 2 || index===3){
      changeState({...appState, activeObject: appState.objects[index]})
    }
    if (graphicindex === 0 || graphicindex === 1 || graphicindex === 2 || graphicindex === 3 || graphicindex === 4 || graphicindex === 5 || graphicindex === 6 || graphicindex === 7 || graphicindex === 8 || graphicindex === 9 || graphicindex === 10 || graphicindex === 11 || graphicindex === 12 || graphicindex === 13 || graphicindex === 14 || graphicindex === 15 || graphicindex === 16 || graphicindex === 17 || graphicindex === 18){
      changeState({...appState, activeGraphic: appState.graphicObjects[graphicindex]})
    }
    if (scenaryindex === 0 || scenaryindex === 1 || scenaryindex === 2 || scenaryindex === 3 || scenaryindex === 4){
      changeState({...appState, activeScenary: appState.scenaryObjects[scenaryindex]})
    } 
  }
  
  function toggleActiveStyles(index){
    if(appState.objects[index] === appState.activeObject){
      return "box-active"
    }
    else {
      return "box-inactive"
    }
  }

  function toggleActiveStylesGraph(graphicindex){
    if(appState.graphicObjects[graphicindex] === appState.activeGraphic){
      return "box-active"
    }
    else {
      return "box-inactive"
    }
  }

  function toggleActiveStylesScenary(scenaryindex){
    if(appState.scenaryObjects[scenaryindex] === appState.activeScenary){
      return "box-active"
    }
    else {
      return "box-inactive"
    }
  }

  //Summary data
  var table_like_columns = [""]
  var table_like_data = []
  var star_plot_points = []
  var star_plot_data = []
  var star_plot_datasets = []

  //Economic Data
  var agregate_components_columns = []
  var agregate_components_data = []
  var agregate_components_datasets = []
  var porcentage_components_columns = []
  var porcentage_components_data = []
  var porcentage_components_datasets = []
  var porcentage_participation_columns = []
  var porcentage_participation_data = []
  var porcentage_participation_datasets = []
  var detail_compositions_columns = [""]
  var detail_compositions_data = []
  var pib_total_columns = []
  var pib_total_data = []
  var porcentual_direct_contribution_columns = []
  var porcentual_direct_contribution_data = []
  var porcentual_indirect_contribution_columns = []
  var porcentual_indirect_contribution_data = []
  var porcentual_total_contribution_columns = []
  var porcentual_total_contribution_data = []
  var export_imports_columns = []
  var export_imports_data = []
  var export_imports_datasets = []
  var rate_fosil_columns = []
  var rate_fosil_data = []
  var capital_distribution_columns = []
  var capital_distribution_data = []
  var remuneration_distribution_columns = []
  var remuneration_distribution_data = []
  var fiscal_impacts_columns = [""]
  var fiscal_impacts_data = []
  var investment_columns = [""]
  var investment_data = []

  //Emissions Data
  var direct_emissions_columns = []
  var direct_emissions_data = []
  var carbon_footprint_columns = []
  var carbon_footprint_data = []

  var mip_porcentual_direct_contribution_columns = []
  var mip_porcentual_direct_contribution_data = []
  var mip_porcentual_indirect_contribution_columns = []
  var mip_porcentual_indirect_contribution_data = []
  var mip_porcentual_total_contribution_columns = []
  var mip_porcentual_total_contribution_data = []

  function exportimportconverter(value){
    if (value === "Exportaciones"){
      return("Exportaciones de bienes y servicios")
    }
    else if (value === "Importaciones"){
      return("Importaciones de bienes y servicios")
    }
    else {
      return(value)
    }
  }

  //Employment Data
  var induced_employment_columns = []
  var induced_employment_data = []
  var induced_employment_datasets = []
  var remuneration_analisis_columns = [""]
  var remuneration_analisis_data = []
  var historical_employees_columns = []
  var historical_employees_data = []
  var historical_employees_dataset = []
  var pib_employment_columns = []
  var pib_employment_data = []
  var final_demand_columns = []
  var final_demand_data = []



  //Set the data on arrays
  //Summary
  if(appState.activeScenary!= null){
    data[appState.activeScenary-1][0].data.forEach((element) => {
      var y = capitalize(element.y_index)
      if (!table_like_columns.includes(y)){
        table_like_columns.push(capitalize(y));
      }
    })
    data[appState.activeScenary-1][0].data.forEach((element) => {
      var x = element.x_index
      var x2= element.x_index + base
      var new_array= [x].concat(Array(table_like_columns.length-1).fill(0))
      var new_array2= [x2].concat(Array(table_like_columns.length-1).fill(0))
      var flag = true
      table_like_data.forEach((element2) => {
        if (element2[0] === x){
          flag=false
        }
      })
      if (flag){
        table_like_data.push(new_array)
        table_like_data.push(new_array2)
      }
    })
    data[appState.activeScenary-1][0].data.forEach((element) => {
      var x = element.x_index
      var v= element.value
      var y= capitalize(element.y_index)
      table_like_data.forEach((element2,index2) =>{
        if (x === element2[0]){
          table_like_data[index2][table_like_columns.indexOf(y)] = v.toFixed(2)
        }
      })
    })
    summary.data?.forEach((element) => {
      var x = element.x_index + base
      var v= element.value
      var y= capitalize(element.y_index)
      table_like_data.forEach((element2,index2) =>{
        if (x === element2[0]){
          table_like_data[index2][table_like_columns.indexOf(y)] = v.toFixed(2)
        }
      })
    })

    data[appState.activeScenary-1][1].data.forEach((element) => {
      var y = element.y_index
      if (!star_plot_points.includes(y)){
        star_plot_points.push(y)
      }
    })
    data[appState.activeScenary-1][1].data.forEach((element) => {
      var x = element.x_index
      var flag= true
      var new_array= Array(star_plot_points.length).fill(0)
      star_plot_data.forEach((element2) => {
        if (element2.name === x){
          flag = false
        }
      })
      if (flag){
        star_plot_data.push({name:x, data: new_array})
      }
    })
    data[appState.activeScenary-1][1].data.forEach((element) => {
      var x = element.x_index
      var v= element.value
      var y= element.y_index
      star_plot_data.forEach((element2) => {
        if(x === element2.name){
          element2.data[star_plot_points.indexOf(y)] = v
        }
      })
    })
    star_plot_data?.forEach((element) => {
      star_plot_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name]), borderWidth: 1, borderColor: graphColors([element.name]), fill: false})
    })

    //Economics
    data[appState.activeScenary-1][2].coponentes_PIB_values.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2= capitalize(element.y_index) + base
    if(!agregate_components_columns.includes(y)){
      agregate_components_columns.push(y)
      agregate_components_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][2].coponentes_PIB_values.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(agregate_components_columns.length).fill(0)
    agregate_components_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      agregate_components_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][2].coponentes_PIB_values.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    agregate_components_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[agregate_components_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.componentsmip.coponentes_PIB_values.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index) + base
    agregate_components_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[agregate_components_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  agregate_components_data?.forEach((element) => {
    agregate_components_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  data[appState.activeScenary-1][2].componentes_PIB_por_sector.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if(!porcentage_components_columns.includes(y)){
      porcentage_components_columns.push(y)
      porcentage_components_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][2].componentes_PIB_por_sector.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(porcentage_components_columns.length).fill(0)
    porcentage_components_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      porcentage_components_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][2].componentes_PIB_por_sector.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    porcentage_components_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[porcentage_components_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.componentsmip.componentes_PIB_por_sector.data?.forEach((element) => {
    var x = capitalize(element.x_index) 
    var v= element.value
    var y= capitalize(element.y_index) + base
    porcentage_components_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[porcentage_components_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  porcentage_components_data?.forEach((element) => {
    porcentage_components_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  data[appState.activeScenary-1][2].componentes_PIB_por_componentes_valor_agregado.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var x2 = capitalize(element.x_index) + base
    if(!porcentage_participation_columns.includes(x)){
      porcentage_participation_columns.push(x)
      porcentage_participation_columns.push(x2)
    }
  })
  data[appState.activeScenary-1][2].componentes_PIB_por_componentes_valor_agregado.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var flag= true
    var new_array= Array(porcentage_participation_columns.length).fill(0)
    porcentage_participation_data.forEach((element2) => {
      if (element2.name === y){
        flag = false
      }
    })
    if (flag){
      porcentage_participation_data.push({name:y, data: new_array})
    }
  })
  data[appState.activeScenary-1][2].componentes_PIB_por_componentes_valor_agregado.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    porcentage_participation_data.forEach((element2) => {
      if(y === element2.name){
        element2.data[porcentage_participation_columns.indexOf(x)] = v.toFixed(2)
      }
    })
  })
  location.state.componentsmip.componentes_PIB_por_componentes_valor_agregado.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index) + base
    porcentage_participation_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[porcentage_participation_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  porcentage_participation_data?.forEach((element) => {
    porcentage_participation_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  data[appState.activeScenary-1][3].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if(!detail_compositions_columns.includes(y)){
      detail_compositions_columns.push(y)
    }
  })
  data[appState.activeScenary-1][3].data?.forEach((element) => {
    var x= capitalize(element.x_index)
    var x2 = capitalize(element.x_index) + base
    var new_array= [x].concat(Array(detail_compositions_columns.length-1).fill(0))
    var new_array2= [x2].concat(Array(detail_compositions_columns.length-1).fill(0))
    var flag= true
    detail_compositions_data.forEach((element2) => {
      if(x=== element2[0] || x2 === element2[0]){
        flag = false
      }
    })
    if(flag){
      detail_compositions_data.push(new_array)
      detail_compositions_data.push(new_array2)
    }
  })
  data[appState.activeScenary-1][3].data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    detail_compositions_data.forEach((element2) =>{
      if (x === element2[0]){
        element2[detail_compositions_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.detailcompositionmip.data?.forEach((element) => {
    var x = capitalize(element.x_index) + base
    var v= element.value
    var y= capitalize(element.y_index)
    detail_compositions_data.forEach((element2) =>{
      if (x === element2[0]){
        element2[detail_compositions_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  data[appState.activeScenary-1][4].PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if(!pib_total_columns.includes(y) && y!== "Total"){
      pib_total_columns.push(y)
      pib_total_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][4].PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(pib_total_columns.length).fill(0)
    pib_total_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag && x!== "Total"){
      pib_total_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][4].PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    pib_total_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[pib_total_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.impactscontributionmip.PIBsDirectosIndirectosTotales.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index) +base
    pib_total_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[pib_total_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  data[appState.activeScenary-1][4].DirectContributionPercentage.data?.forEach((element) => {
    porcentual_direct_contribution_columns.push(capitalize(element.x_index))
    porcentual_direct_contribution_data.push(element.value.toFixed(2))
  })
  location.state.impactscontributionmip.DirectContributionPercentage.data?.forEach((element) => {
    mip_porcentual_direct_contribution_columns.push(capitalize(element.x_index))
    mip_porcentual_direct_contribution_data.push(element.value.toFixed(2))
  })


  data[appState.activeScenary-1][4].InducedContributionPercentage.data?.forEach((element) => {
    porcentual_indirect_contribution_columns.push(capitalize(element.x_index))
    porcentual_indirect_contribution_data.push(element.value.toFixed(2))
  })
  location.state.impactscontributionmip.InducedContributionPercentage.data?.forEach((element) => {
    mip_porcentual_indirect_contribution_columns.push(capitalize(element.x_index))
    mip_porcentual_indirect_contribution_data.push(element.value.toFixed(2))
  })


  data[appState.activeScenary-1][4].TotalContributionPercentage.data?.forEach((element) => {
    porcentual_total_contribution_columns.push(capitalize(element.x_index))
    porcentual_total_contribution_data.push(element.value.toFixed(2))
  })
  location.state.impactscontributionmip.TotalContributionPercentage.data?.forEach((element) => {
    mip_porcentual_total_contribution_columns.push(capitalize(element.x_index))
    mip_porcentual_total_contribution_data.push(element.value.toFixed(2))
  })

  data[appState.activeScenary-1][5].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if(!export_imports_columns.includes(y)){
      export_imports_columns.push(y)
      export_imports_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][5].data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var flag= true
    var new_array= Array(export_imports_columns.length).fill(0)
    export_imports_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      export_imports_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][5].data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    export_imports_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[export_imports_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.exportimportsmip.data?.forEach((element) => {
    var x = exportimportconverter(capitalize(element.x_index))
    var v= element.value
    var y= capitalize(element.y_index) + base
    export_imports_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[export_imports_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  export_imports_data?.forEach((element) => {
    export_imports_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name]), borderWidth: 0, categoryPercentage: 0.8})
  })

  data[appState.activeScenary-1][7].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if (!capital_distribution_columns.includes(y)){
      capital_distribution_columns.push(y)
      capital_distribution_columns.push(y2)
      capital_distribution_data.push(element.value.toFixed(2))
      capital_distribution_data.push(0)
    }
  })
  location.state.capitaldistributionmip.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var v = element.value.toFixed(2)
    if (y === "Total"){
      y = "Total sector generación" + base
    } 
    else{
      y= y + base
    }
    capital_distribution_columns.forEach((element2, index) => {
      if(element2 === y){
        capital_distribution_data[index] = v
      }
    })
  })

  data[appState.activeScenary-1][8].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if (!remuneration_distribution_columns.includes(y)){
      remuneration_distribution_columns.push(y)
      remuneration_distribution_data.push(element.value.toFixed(2))
      remuneration_distribution_columns.push(y2)
      remuneration_distribution_data.push(0)
    }
  })
  location.state.remunerationdistributionmip.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var v = element.value.toFixed(2)
    if (y === "Total"){
      y = "Total sector generación" + base
    } 
    else{
      y= y + base
    }
    remuneration_distribution_columns.forEach((element2, index) => {
      if(element2 === y){
        remuneration_distribution_data[index] = v
      }
    })
  })

  data[appState.activeScenary-1][9].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!fiscal_impacts_columns.includes(y)){
      fiscal_impacts_columns.push(y)
    }
  })
  data[appState.activeScenary-1][9].data?.forEach((element) => {
    var x = element.x_index
    var x2 = element.x_index + base
    var new_array= [x].concat(Array(fiscal_impacts_columns.length-1).fill(0))
    var new_array2= [x2].concat(Array(fiscal_impacts_columns.length-1).fill(0))
    var flag= true
    fiscal_impacts_data.forEach((element2) => {
      if (element2[0] === x || element2[0] === x2){
        flag = false
      }
    })
    if (flag){
      fiscal_impacts_data.push(new_array)
      fiscal_impacts_data.push(new_array2)
    }
  })
  data[appState.activeScenary-1][9].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    fiscal_impacts_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        fiscal_impacts_data[index2][fiscal_impacts_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.fiscalimpactmip.data?.forEach((element) => {
    var x = element.x_index + base
    var v= element.value
    var y= capitalize(element.y_index)
    fiscal_impacts_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        fiscal_impacts_data[index2][fiscal_impacts_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  data[appState.activeScenary-1][10].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!investment_columns.includes(y)){
      investment_columns.push(y)
    }
  })
  data[appState.activeScenary-1][10].data?.forEach((element) => {
    var x = element.x_index
    var new_array= [x].concat(Array(investment_columns.length-1).fill(0))
    var flag= true
    investment_data.forEach((element2) => {
      if (element2[0] === x){
        flag = false
      }
    })
    if (flag){
      investment_data.push(new_array)
    }
  })
  data[appState.activeScenary-1][10].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    investment_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        investment_data[index2][investment_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  //Emissions
  data[appState.activeScenary-1][11].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if(!direct_emissions_columns.includes(y) && y !== "Total"){
      direct_emissions_columns.push(y)
      direct_emissions_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][11].data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(direct_emissions_columns.length).fill(0)
    direct_emissions_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      direct_emissions_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][11].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    direct_emissions_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[direct_emissions_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.directemissionsmip.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index) + base
    direct_emissions_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[direct_emissions_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  data[appState.activeScenary-1][12].data?.forEach((element) => {
    carbon_footprint_columns.push(capitalize(element.x_index))
    carbon_footprint_columns.push(capitalize(element.x_index) + base)
    carbon_footprint_data.push(element.value.toFixed(5))
    carbon_footprint_data.push(0)
  })
  location.state.carbonfootprintmip.data?.forEach((element) => {
    var x = capitalize(element.x_index) + base
    var v = element.value.toFixed(5)
    carbon_footprint_columns.forEach((element2, index) => {
      if(element2 === x){
        carbon_footprint_data[index] = v
      }
    })
  })


  //Employment
  data[appState.activeScenary-1][13].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if (!induced_employment_columns.includes(y)){
      induced_employment_columns.push(y)
      induced_employment_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][13].data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(induced_employment_columns.length).fill(0)
    induced_employment_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      induced_employment_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][13].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    induced_employment_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[induced_employment_columns.indexOf(y)] = Math.floor(v)
      }
    })
  })
  location.state.inducedemploymentmip.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index) + base
    induced_employment_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[induced_employment_columns.indexOf(y)] = Math.floor(v)
      }
    })
  })
  induced_employment_data?.forEach((element) => {
    induced_employment_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  data[appState.activeScenary-1][14].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!remuneration_analisis_columns.includes(y)){
      remuneration_analisis_columns.push(y)
    }
  })
  data[appState.activeScenary-1][14].data?.forEach((element) => {
    var x = element.x_index
    var x2 = element.x_index + base
    var new_array= [x].concat(Array(remuneration_analisis_columns.length-1).fill(0))
    var new_array2= [x2].concat(Array(remuneration_analisis_columns.length-1).fill(0))
    var flag= true
    remuneration_analisis_data.forEach((element2) => {
      if (element2[0] === x || element2[0] === x2){
        flag = false
      }
    })
    if (flag){
      remuneration_analisis_data.push(new_array)
      remuneration_analisis_data.push(new_array2)
    }
  })
  data[appState.activeScenary-1][14].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    remuneration_analisis_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        remuneration_analisis_data[index2][remuneration_analisis_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.remunerationanalisismip.data?.forEach((element) => {
    var x = element.x_index + base
    var v= element.value
    var y= capitalize(element.y_index)
    if (y === "Total"){
      y = "Total sector generación"
    }
    remuneration_analisis_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        remuneration_analisis_data[index2][remuneration_analisis_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  data[appState.activeScenary-1][15].data?.forEach((element) => {
    var x= element.x_index
    if(!historical_employees_columns.includes(x)){
      historical_employees_columns.push(x)
    } 
  })
  data[appState.activeScenary-1][15].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var flag= true
    var new_array= Array(historical_employees_columns.length).fill(0)
    historical_employees_data.forEach((element2) => {
      if (element2.name === y){
        flag = false
      }
    })
    if (flag){
      historical_employees_data.push({name:y, data: new_array})
    }
  })
  data[appState.activeScenary-1][15].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    historical_employees_data.forEach((element2) => {
      if(y === element2.name){
        element2.data[historical_employees_columns.indexOf(x)] = Math.floor(v)
      }
    })
  })
  historical_employees_data?.forEach((element) => {
    historical_employees_dataset.push({fill: true, label: element.name, data: element.data, backgroundColor: graphColors([element.name])})
  })

  data[appState.activeScenary-1][17].data?.forEach((element) => {
    var y = capitalize(element.y_index)
    var y2 = capitalize(element.y_index) + base
    if(!final_demand_columns.includes(y)){
      final_demand_columns.push(y)
      final_demand_columns.push(y2)
    }
  })
  data[appState.activeScenary-1][17].data?.forEach((element) => {
    var x = element.x_index
    var flag= true
    var new_array= Array(final_demand_columns.length).fill(0)
    final_demand_data.forEach((element2) => {
      if (element2.name === x){
        flag = false
      }
    })
    if (flag){
      final_demand_data.push({name:x, data: new_array})
    }
  })
  data[appState.activeScenary-1][17].data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    final_demand_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[final_demand_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  location.state.finaldemandmip.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index) + base
    final_demand_data.forEach((element2) => {
      if(x === element2.name){
        element2.data[final_demand_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  }


  //Display graphics and tables function
  function GraphicDisplay(){
    if(appState.activeObject === 1 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      return(<div style={{maxWidth: "1600px"}}>
        <MUIDataTable
        title={location.state.data["0"]["0"].title}
        data={table_like_data}
        columns={table_like_columns}
        options={options_type(user_type)}
        ></MUIDataTable></div>
      )
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      const ChartData2 = {
        labels: star_plot_points,
        datasets: star_plot_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.data["0"]["1"].title,
          align: "center",
          },
      }};
      return(
        <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
          <Radar data={ChartData2} options={options}/>
        </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      const ChartData2 = {
        labels: agregate_components_columns,
        datasets: agregate_components_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.data["0"]["2"].coponentes_PIB_values.title,
          align: "center",
          },
      },
      indexAxis: 'y',
          scales: {
              x: {title: {display: "true", text: coins[values.country], color:"gray"}, stacked: true},
              y: {stacked: true}
          }};
      return(
      <div style={{width: "55vw"}}>
        {HorizontalTables(ChartData2, options)}
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 3 && appState.activeScenary !== null){
      const ChartData2 = {
        labels: porcentage_participation_columns,
        datasets: porcentage_participation_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.data["0"]["2"].componentes_PIB_por_componentes_valor_agregado.title,
          align: "center",
          },
          
      },
      indexAxis: 'y',
          scales: {
              x: {title: {display: "true", text: "Porcentaje de participación", color:"black"}, stacked: true, max:100},
              y: {stacked: true}
          }};
      return(
      <div style={{width: "55vw"}}>
        {HorizontalTables(ChartData2, options)}
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 4 && appState.activeScenary !== null){
      return(<div style={{maxWidth: "1600px"}}><MUIDataTable
        title={location.state.data["0"]["3"].title}
        data={detail_compositions_data}
        columns={detail_compositions_columns}
        options={options_type(user_type)}
        ></MUIDataTable></div>
      )
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 5 && appState.activeScenary !== null){
      const ChartData2 = {
        labels: pib_total_columns,
        datasets: [
          {
          label: pib_total_data[0].name,
          data: pib_total_data[0].data,
          backgroundColor: graphColors([pib_total_data[0].name]),
          borderWidth: 0,
          },
          {
            label: pib_total_data[1].name,
            data: pib_total_data[1].data,
            backgroundColor: graphColors([pib_total_data[1].name]),
            borderWidth: 0,
            },
        ]
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.data["0"]["4"].PIBsDirectosIndirectosTotales.title,
          align: "center",
          },
          
      },
      indexAxis: 'y',
          scales: {
              x: {title: {display: "true", text: coins[values.country], color:"black"}, stacked: true},
              y: {stacked: true}
          }};
      return(
      <div style={{width: "55vw"}}>
        {HorizontalTables(ChartData2, options)}
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 6 && appState.activeScenary !== null){
      const ChartData = {
        labels: porcentual_direct_contribution_columns,
        datasets: [{
          label: "Contribución porcentual",
          data: porcentual_direct_contribution_data,
          backgroundColor: graphColors(porcentual_direct_contribution_columns),
          borderWidth: 1
        } ]
      }
      const options = {
        plugins : {
          title: {
            display: true,
            text: location.state.data["0"]["4"].DirectContributionPercentage.title,
            align: "center",
          }}
      }
      const ChartData2 = {
        labels: mip_porcentual_direct_contribution_columns,
        datasets: [{
          label: "Contribución porcentual",
          data: mip_porcentual_direct_contribution_data,
          backgroundColor: graphColors(mip_porcentual_direct_contribution_columns),
          borderWidth: 1
        } ]
      }
      const options2 = {
        plugins : {
          title: {
            display: true,
            text: location.state.data["0"]["4"].DirectContributionPercentage.title + base,
            align: "center",
          }}
      }
      
      return(
      <div className='flex'>
        <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
          <Doughnut data={ChartData} options={options}/>
        </div>
        <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
          <Doughnut data={ChartData2} options={options2}/>
        </div>
      </div>
      )
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 7 && appState.activeScenary !== null){
      const ChartData = {
        labels: porcentual_indirect_contribution_columns,
        datasets: [{
          label: "Contribución porcentual",
          data: porcentual_indirect_contribution_data,
          backgroundColor: graphColors(porcentual_indirect_contribution_columns),
          borderWidth: 1
        } ]
      }
      const options = {
        plugins : {
          title: {
            display: true,
            text: location.state.data["0"]["4"].InducedContributionPercentage.title,
            align: "center",
          }}
      }
      const ChartData2 = {
        labels: mip_porcentual_indirect_contribution_columns,
        datasets: [{
          label: "Contribución porcentual",
          data: mip_porcentual_indirect_contribution_data,
          backgroundColor: graphColors(mip_porcentual_indirect_contribution_columns),
          borderWidth: 1
        } ]
      }
      const options2 = {
        plugins : {
          title: {
            display: true,
            text: location.state.data["0"]["4"].InducedContributionPercentage.title + base,
            align: "center",
          }}
      }
      return(
      <div className='flex'>
        <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
          <Doughnut data={ChartData} options={options}/>
        </div>
        <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
          <Doughnut data={ChartData2} options={options2}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 8 && appState.activeScenary !== null){
      const ChartData = {
        labels: porcentual_total_contribution_columns,
        datasets: [{
          label: "Contribución porcentual",
          data: porcentual_total_contribution_data,
          backgroundColor: graphColors(porcentual_total_contribution_columns),
          borderWidth: 1
        } ]
      }
      const options = {
        plugins : {
          title: {
            display: true,
            text: location.state.data["0"]["4"].TotalContributionPercentage.title,
            align: "center",
          }}
      }
      const ChartData2 = {
        labels: mip_porcentual_total_contribution_columns,
        datasets: [{
          label: "Contribución porcentual",
          data: mip_porcentual_total_contribution_data,
          backgroundColor: graphColors(mip_porcentual_total_contribution_columns),
          borderWidth: 1
        } ]
      }
      const options2 = {
        plugins : {
          title: {
            display: true,
            text: location.state.data["0"]["4"].TotalContributionPercentage.title + base,
            align: "center",
          }}
      }
      return(
      <div className='flex'>
        <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
          <Doughnut data={ChartData} options={options}/>
        </div>
        <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
          <Doughnut data={ChartData2} options={options2}/>
        </div>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 9 && appState.activeScenary !== null){
      const ChartData = {
        labels: export_imports_columns,
        datasets: export_imports_datasets}
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Exportaciones e importaciones ["+coins[values.country]+"]"}}
        },
        plugins : {
          title: {
            display: true,
            text: location.state.data["0"]["5"].title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}
        />
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 10 && appState.activeScenary !== null){
      const ChartData = {
        labels: rate_fosil_columns,
        datasets: [{
          label: "Importaciones combustibles fosiles [MMLCP]",
          data: rate_fosil_data,
          backgroundColor: graphColors(rate_fosil_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Año MIP Base", color: "black"}},
          y: {title:{display: true, text: "Importaciones combustibles fosiles [MMLCP]", color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.data["0"]["6"].title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 11 && appState.activeScenary !== null){
      const ChartData = {
        labels: capital_distribution_columns,
        datasets: [{
          label: location.state.data["0"]["7"].data[0].x_index,
          data: capital_distribution_data,
          backgroundColor: graphColors(capital_distribution_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías", color: "black"}},
          y: {title:{display: true, text: location.state.data["0"]["7"].data[0].x_index, color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.data["0"]["7"].title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 12 && appState.activeScenary !== null){
      const ChartData = {
        labels: remuneration_distribution_columns,
        datasets: [{
          label: location.state.data["0"]["8"].x_index,
          data: remuneration_distribution_data,
          backgroundColor: graphColors(remuneration_distribution_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías", color: "black"}},
          y: {title:{display: true, text: location.state.data["0"]["8"].data[0].x_index, color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.data["0"]["8"].title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 13 && appState.activeScenary !== null){
      return(<div style={{maxWidth: "1600px"}}><MUIDataTable
        title={location.state.data["0"]["9"].title}
        data={fiscal_impacts_data}
        columns={fiscal_impacts_columns}
        options={options_type2(user_type)}
        ></MUIDataTable></div>
      )
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 14 && appState.activeScenary !== null){
      return(<div style={{maxWidth: "1600px"}}><MUIDataTable
        title={location.state.data["0"]["10"].title}
        data={investment_data}
        columns={investment_columns}
        options={options_type(user_type)}
        ></MUIDataTable></div>
      )
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      const ChartData = {
        labels: direct_emissions_columns,
        datasets: [
          {
          label: direct_emissions_data[0].name,
          data: direct_emissions_data[0].data,
          backgroundColor: graphColors([direct_emissions_data[0].name]),
          borderWidth: 0,
          categoryPercentage: 0.7
          },
          {
            label: direct_emissions_data[1].name,
            data: direct_emissions_data[1].data,
            backgroundColor: graphColors([direct_emissions_data[1].name]),
            borderWidth: 0,
            categoryPercentage: 0.7
            }
        ]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Actividades Económicas", color: "black"}},
          y: {title:{display: true, text: "Emisiones Directas y Huella de Carbono [Mt CO2 eq]", color: "black"}}
        },
        plugins : {
          title: {
            display: true,
            text: location.state.data["0"]["11"].title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}
        />
      </div>)
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      const ChartData = {
        labels: carbon_footprint_columns,
        datasets: [{
          label: "[Kt CO2 eq/"+coins[values.country]+"]",
          data: carbon_footprint_data,
          backgroundColor: graphColors(carbon_footprint_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Actividades Económicas"}},
          y: {title:{display: true, text: "Huella de carbono sobre valor agregado "+"[Kt CO2 eq/"+coins[values.country]+"]"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.data["0"]["12"].title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      const ChartData2 = {
        labels: induced_employment_columns,
        datasets: induced_employment_datasets
      };
      const options2 = {
        plugins:{
          title: {
          display: true,
          text: location.state.data["0"]["13"].title,
          align: "center",
          }},
      indexAxis: 'x',
          scales: {
              x: {title: {display: "true", text: "Tecnologías", color:"black"}, stacked: true},
              y: {title: {display: "true", text: "Empleos", color:"black"}, stacked: true}
          }};
      return(
        <div style={{width: "55vw"}}>
          <Bar data={ChartData2} options={options2}/>
        </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      return(<div style={{maxWidth: "1600px"}}><MUIDataTable
        title={location.state.data["0"]["14"].title}
        data={remuneration_analisis_data}
        columns={remuneration_analisis_columns}
        options={options_type3(user_type)}
        ></MUIDataTable></div>
      )
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 3 && appState.activeScenary !== null){
      const ChartData2 = {
        labels: historical_employees_columns,
        datasets: historical_employees_dataset
      };
      const options = {
        responsive: true,
        plugins:{
          title: {
          display: true,
          text: location.state.data["0"]["15"].title,
          align: "center",
          }},
          scales: {
              x: {title: {display: "true", text: "Años", color:"black", stacked: true}},
              y: {title: {display: "true", text: "Empleos directos", color:"black", stacked: true}}
          }};
      return(
        <div style={{width: "55vw"}}>
          <Line data={ChartData2} options={options}/>
        </div>)
    }
    /*
    else if(appState.activeObject === 4 && appState.activeGraphic === 4){
      const ChartData = {
        labels: pib_employment_columns,
        datasets: [{
          label: "PIB / Empleados [MMCLP]",
          data: pib_employment_data,
          backgroundColor: graphColors(pib_employment_columns),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "PIB / Empleados [MMCLP"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.pibemployment.title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "1100px", height: "500px"}}>
        <Bar data={ChartData} options={options}/>
      </div>)
    } 
    */
    else if(appState.activeObject === 4 && appState.activeGraphic === 5 && appState.activeScenary !== null){
      const ChartData = {
        labels: final_demand_columns,
        datasets: [
          {label: final_demand_data[0].name,
          data: final_demand_data[0].data,
          backgroundColor: graphColors([final_demand_data[0].name]),
          borderWidth: 0,
          categoryPercentage: 0.8},
          {label: final_demand_data[1].name,
            data: final_demand_data[1].data,
            backgroundColor: graphColors([final_demand_data[1].name]),
            borderWidth: 0,
            categoryPercentage: 0.8},
        ]}
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Empleos/Demanda final[-/"+coins[values.country]+"]"}}
        },
        plugins : {
          title: {
            display: true,
            text: location.state.data["0"]["17"].title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}
        />
      </div>)
    }
    else{return(<h1></h1>)}
  }
  


  function CSVData(){
    if(appState.activeObject === 1 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      var array_data = []
      table_like_data.forEach((element) =>{
        table_like_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Indicadores','Tecnologías','Valor']].concat(array_data))
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      var array_data = []
      star_plot_points.forEach((element,index) =>{
        var array_data2 = [element]
        star_plot_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      var column_array = ['Indicadores']
      star_plot_data.forEach((element)=>{
        column_array.push(element.name)
      })
      return([column_array].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      var array_data = []
      agregate_components_columns.forEach((element,index) =>{
        var array_data2 = [element]
        agregate_components_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      return([['Tecnologías',agregate_components_data[0].name + " [" + coins[values.country]+"]",agregate_components_data[1].name + " [" + coins[values.country]+"]",agregate_components_data[2].name + " [" + coins[values.country]+"]"]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 3 && appState.activeScenary !== null){
      var array_data = []
      porcentage_participation_columns.forEach((element,index) =>{
        var array_data2 = [element]
        porcentage_participation_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      var column_array = ['Elementos valor agregado']
      porcentage_participation_data.forEach((element)=>{
        column_array.push('Porcentaje ' + element.name)
      })
      return([column_array].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 4 && appState.activeScenary !== null){
      var array_data = []
      detail_compositions_data.forEach((element) =>{
        detail_compositions_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Detalle valor agregado','Tecnologías',coins[values.country]]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 5 && appState.activeScenary !== null){
      var array_data = []
      pib_total_columns.forEach((element,index) =>{
        var array_data2 = [element]
        pib_total_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      return([['Tecnologías',pib_total_data[0].name + " ["+coins[values.country]+"]",pib_total_data[1].name+ " ["+coins[values.country]+"]"]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 6 && appState.activeScenary !== null){
      var array_data = []
      porcentual_direct_contribution_columns.forEach((element,index) =>{
        array_data.push([element, porcentual_direct_contribution_data[index]])
      })
      return([['Tecnologías','Contribución porcentual']].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 7){
      var array_data = []
      porcentual_indirect_contribution_columns.forEach((element,index) =>{
        array_data.push([element, porcentual_indirect_contribution_data[index]])
      })
      return([['Tecnologías','Contribución porcentual']].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 8 && appState.activeScenary !== null){
      var array_data = []
      porcentual_total_contribution_columns.forEach((element,index) =>{
        array_data.push([element, porcentual_total_contribution_data[index]])
      })
      return([['Tecnologías','Contribución porcentual']].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 9 && appState.activeScenary !== null){
      var array_data = []
      export_imports_columns.forEach((element,index) =>{
          var array_data2 = [element]
          export_imports_data.forEach((element2)=>{array_data2.push(element2.data[index])})
          array_data.push(array_data2)
      })
      return([['Tecnologías',export_imports_data[0].name + ' ['+coins[values.country]+"]",export_imports_data[1].name + ' ['+coins[values.country]+"]",export_imports_data[2].name + ' ['+coins[values.country]+"]"]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 11 && appState.activeScenary !== null){
      var array_data = []
      capital_distribution_columns.forEach((element,index) =>{
        array_data.push([element,capital_distribution_data[index]])
      })
      return([['Tecnologías',location.state.capitaldistribution.data[0].x_index]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 12 && appState.activeScenary !== null){
      var array_data = []
      remuneration_distribution_columns.forEach((element,index) =>{
        array_data.push([element,remuneration_distribution_data[index]])
      })
      return([['Tecnologías',location.state.remunerationdistribution.data[0].x_index]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 13 && appState.activeScenary !== null){
      var array_data = []
      fiscal_impacts_data.forEach((element) =>{
        fiscal_impacts_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Indicadores','Tecnologías','Valor']].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 14 && appState.activeScenary !== null){
      var array_data = []
      investment_data.forEach((element) =>{
        investment_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Requerimientos','Tecnologías','Valor']].concat(array_data))
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      var array_data = []
      direct_emissions_columns.forEach((element,index) =>{
          var array_data2 = [element]
          direct_emissions_data.forEach((element2)=>{array_data2.push(element2.data[index])})
          array_data.push(array_data2)
      })
      return([['Actividades económicas',direct_emissions_data[0].name,direct_emissions_data[1].name]].concat(array_data))
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      var array_data = []
      carbon_footprint_columns.forEach((element,index) =>{
        array_data.push([element,carbon_footprint_data[index]])
      })
      return([['Actividades económicas',"Huella de carbono sobre valor agregado "+"[Kt CO2 eq/"+coins[values.country]+"]"]].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      var array_data = []
      induced_employment_columns.forEach((element,index) =>{
        var array_data2 = [element]
        induced_employment_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      return([['Tecnologías',induced_employment_data[0].name,induced_employment_data[1].name]].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      var array_data = []
      remuneration_analisis_data.forEach((element) =>{
        remuneration_analisis_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Indicadores','Tecnologías','Valor']].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 3 && appState.activeScenary !== null){
      var array_data = []
      historical_employees_columns.forEach((element,index) =>{
        var array_data2 = [element]
        historical_employees_data.forEach((element2)=>{array_data2.push(element2.data[index])})
        array_data.push(array_data2)
      })
      var column_array = ['Año']
      historical_employees_data.forEach((element)=>{
        column_array.push(element.name)
      })
      return([column_array].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 5 && appState.activeScenary !== null){
      var array_data = []
      final_demand_columns.forEach((element,index) =>{
          var array_data2 = [element]
          final_demand_data.forEach((element2)=>{array_data2.push(element2.data[index])})
          array_data.push(array_data2)
      })
      return([['Tecnologías',final_demand_data[0].name,final_demand_data[1].name]].concat(array_data))
    }
   
    else{
      return([[""]])
    }
  }



  function CSVTitle(){
    if(appState.activeObject === 1 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      return(data["0"]["0"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      return(data["0"]["1"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      return(data["0"]["2"].coponentes_PIB_values.title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      return(data["0"]["2"].componentes_PIB_por_sector.title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 3 && appState.activeScenary !== null){
      return(data["0"]["2"].componentes_PIB_por_componentes_valor_agregado.title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 4 && appState.activeScenary !== null){
      return(data["0"]["3"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 5 && appState.activeScenary !== null){
      return(data["0"]["4"].PIBsDirectosIndirectosTotales.title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 6 && appState.activeScenary !== null){
      return(data["0"]["4"].DirectContributionPercentage.title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 7 && appState.activeScenary !== null){
      return(data["0"]["4"].InducedContributionPercentage.title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 8 && appState.activeScenary !== null){
      return(data["0"]["4"].TotalContributionPercentage.title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 9 && appState.activeScenary !== null){
      return(data["0"]["5"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 11 && appState.activeScenary !== null){
      return(data["0"]["7"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 12 && appState.activeScenary !== null){
      return(data["0"]["8"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 13 && appState.activeScenary !== null){
      return(data["0"]["9"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 14 && appState.activeScenary !== null){
      return(data["0"]["10"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      return(data["0"]["11"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      return(data["0"]["12"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      return(data["0"]["13"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      return(data["0"]["14"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 3 && appState.activeScenary !== null){
      return(data["0"]["15"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 5 && appState.activeScenary !== null){
      return(data["0"]["17"].title+ " " + scenary_data[appState.activeScenary-1] + " " + capitalize(values.country) + " "+ values.pelp_year + ".csv")
    }
    else{
      return("")
    }
  }


  function ScenaryDisplay(number) {
    // Genera un array de botones
    const buttons = Array.from({ length: number }, (_, index) => (
      <button
        key={index}
        onClick={() => toggleActive(null,null,index)}
        className={`w-28 h-14 m-1 ${toggleActiveStylesScenary(index)}`}
        style={{ borderRadius: "10px", border: "1px solid", fontSize: "12px" }}
      >
        {scenary_data[index]}
      </button>
    ));

    return <div className="flex ml-auto">{buttons}</div>;
  }


  function ButtonsDisplay(){
    if(appState.activeObject === 1){
      return(
        <div className="ml-auto"> 
          <button onClick={() => {toggleActive(null, 0,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid"}}>Resumen de Indicadores</button>
          <button onClick={() => {toggleActive(null, 1,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid"}}>Comparación de Escenarios</button>
        </div>
      )
    }
    // <button onClick={() => {toggleActive(null, 1)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Porcentaje participación componentes</button>
    else if(appState.activeObject === 2){
      return(
        <div className='ml-auto'>
          <div style={{marginLeft: "40px", display: "flex", overflow: "auto", scrollSnapType: "x-mandatory", scrollPadding: "0", height: " 82px", width: "460px"}}> 
            <button onClick={() => {toggleActive(null, 0,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"14px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Componentes valor agregado</button>
            <button onClick={() => {toggleActive(null, 2,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(2)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Porcentaje participación sectores</button>
            <button onClick={() => {toggleActive(null, 4,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(4)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Impacto PIB directo e indirecto</button>
            <button onClick={() => {toggleActive(null, 5,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(5)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Contribución porcentual PIB directo</button>
            <button onClick={() => {toggleActive(null, 6,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(6)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Contribución porcentual PIB indirecto</button>
            <button onClick={() => {toggleActive(null, 7,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(7)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Contribución porcentual PIB total</button>
            <button onClick={() => {toggleActive(null, 8,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(8)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"14px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Exportaciones e importaciones</button>
            <button onClick={() => {toggleActive(null, 10,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(10)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"14px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Distribución capital</button>
            <button onClick={() => {toggleActive(null, 11,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(11)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"14px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Distribución remuneraciones</button>
          </div>
          <div style={{marginLeft: "40px", display: "flex", overflow: "auto", scrollSnapType: "x-mandatory", scrollPadding: "0", height: " 82px", width: "460px"}}> 
            <button onClick={() => {toggleActive(null, 3,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(3)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Detalle valor agregado</button>
            <button onClick={() => {toggleActive(null, 12,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(12)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"14px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Impacto fiscal</button>
            <button onClick={() => {toggleActive(null, 13,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(13)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"14px", scrollSnapAlign:"start", flex:"0 0 25%"}}>Requerimientos de inversión</button>
          </div>
        </div>
      )
    }
    else if(appState.activeObject === 3){
      return(
        <div className='flex ml-auto'>
          <button onClick={() => {toggleActive(null, 0,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Emisiones directas y huella de carbono</button>
          <button onClick={() => {toggleActive(null, 1,null)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "11px"}}>Huella de carbono sobre valor agregado</button>
        </div>
      )
    }
    /* 
    <button onClick={() => {toggleActive(null, 3)}} className={`w-20 h-14 m-1 ${toggleActiveStylesGraph(3)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "14px"}}>Indicadores de empleo</button>
    */
    else if(appState.activeObject === 4){
      return(
        <div className='flex ml-auto'> 
          <button onClick={() => {toggleActive(null, 0,null)}} className={`w-20 h-14 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"11px"}}>Empleo directo e indirecto</button>
          <button onClick={() => {toggleActive(null, 1,null)}} className={`w-20 h-14 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"10px"}}>Análisis de remuneraciones</button>
          <button onClick={() => {toggleActive(null, 2,null)}} className={`w-20 h-14 m-1 ${toggleActiveStylesGraph(2)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "11px"}}>Empleo histórico</button>
          <button onClick={() => {toggleActive(null, 4,null)}} className={`w-20 h-14 m-1 ${toggleActiveStylesGraph(4)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "11px"}}>Demanda final por tecnología</button>
        </div>
      )
    }
    else{
      return(<div></div>)
    }
  }

  function TextDisplay(){
    if (appState.activeObject === 1 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      return("Resumen de indicadores: La tabla muestra el resumen de indicadores para el escenario predefinido " + scenary_data[appState.activeScenary-1] + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + ". Esta se agrupa tecnologías para las columnas y para las filas se utilizan diferentes indicadores, cada uno con diferentes unidades de medida. Se incluyen los datos de indicadores tanto del escenario seleccionado como del año de la MIP base.")
    }
    else if (appState.activeObject === 1 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      return("Comparación de escenarios: El gráfico muestra una comparación normalizada (respecto al valor máximo de cada item) del escenario predefinido acutal con respecto a los escenarios PELP.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      return("Componentes valor agregado: En el gráfico pueden verse las relaciones del valor agregado, específicamente, el aporte al PIB de diferentes tecnologías para los componentes de remuneraciones de asalariados, excedente bruto de importación e impuestos netos sobre la producción. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    /* 
    else if (appState.activeObject === 2 && appState.activeGraphic === 2){
      return("Porcentaje participación componentes: En el gráfico pueden verse las relaciones del valor agregado, específicamente, los porcentajes de participación de diferentes tecnologías para los componentes de remuneraciones de asalariados, excedente bruto de importación e impuestos netos sobre la producción. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    */
    
    else if (appState.activeObject === 2 && appState.activeGraphic === 3 && appState.activeScenary !== null){
      return("Porcentaje participación sectores: En el gráfico pueden verse las relacionados del valor agregado, específicamente los porcentajes de participación de los componentes del valor agregado (remuneraciones de aslariados, excedente bruto de explotación, impuestos netos sobre la producción y valor agregado) para diferentes sectores. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 4 && appState.activeScenary !== null){
      return("Detalle valor agregado: En esta tabla pueden observarse los detalles de demanda y valor agregado del sector de generación eléctrica. Las columnas de esta tabla corresponden a diferentes tipos de tecnologías, mientras que sus filas corresponden a los indicadores medidos en "+coins[values.country]+". Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 5 && appState.activeScenary !== null){
      return("Impacto PIB directo e indirecto: En este gráfico se muestra el impacto directo, indirecto y total en el PIB. Las filas representan diferentes tecnologías de los sectores de generación, almacenamiento y H2, mientras que sus valores corresponden a los impactos medidos en "+coins[values.country]+". Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 6 && appState.activeScenary !== null){
      return("Contribución porcentual PIB directo: En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB directo para el escenario predefinido " + scenary_data[appState.activeScenary-1] + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + ". Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 7 && appState.activeScenary !== null){
      return("Contribución porcentual PIB indirecto: En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB indirecto para el escenario predefinido " + scenary_data[appState.activeScenary-1] + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + ". Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 8 && appState.activeScenary !== null){
      return("Contribución porcentual PIB total: En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB total (sumando el directo e indirecto) para el escenario predefinido " + scenary_data[appState.activeScenary-1] + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + ". Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 9 && appState.activeScenary !== null){
      return("Exportaciones e importaciones: En este gráfico se muestran las exportaciones, importaciones y exportaciones netas de bienes y servicios para diferentes tecnologías del escenario predefinido " + scenary_data[appState.activeScenary-1] + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + " medidas en "+coins[values.country]+". Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 11 && appState.activeScenary !== null){
      return("Distribución capital: En este gráfico se muestra el excedente bruto de capital medido en "+coins[values.country]+" de diferentes tecnologías para el escenario predefinido " + scenary_data[appState.activeScenary-1] + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + ". Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 12 && appState.activeScenary !== null){
      return("Distribución remuneraciones: En este gráfico se muestra el total de remuneraciones medido en millones de dólares de diferentes tecnologías para el escenario predefinido " + scenary_data[appState.activeScenary-1] + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + ". Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 13 && appState.activeScenary !== null){
      return("Impacto fiscal: En esta tabla se muestra un resumen del impacto fiscal para el escenario seleccionado y el año de la MIP base. Sus columnas corresponden a diferentes tecnologías, mientras que sus filas son diversos indicadores de impactos fiscales, cada uno con diferentes unidades de medidas.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 14 && appState.activeScenary !== null){
      return("Requerimientos de inversión: En esta tabla se muestra los requerimientos de inversión de acuerdo con la capacidad instalada para el escenario predefinido " + scenary_data[appState.activeScenary-1] + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + ". Sus columnas corresponden a diferentes tecnologías, mientras que sus filas son diversos indicadores de inversión, cada uno con diferentes unidades de medidas.")
    }
    else if (appState.activeObject === 3 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      return("Emisiones directas y huella de carbono: El gráfico muestra las emisiones directas y la huella de carbono medidas en mega toneladas de CO2 equivalente para diferentes actividades económicas del escenario predefinido " + scenary_data[appState.activeScenary-1] + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + ". También se incluye la meta o objetivo para 2030. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 3 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      return("Huella de carbono sobre valor agregado: El gráfico muestra el ratio de huella de carbon sobre el valor agregado para diferentes tecnologías, es decir, la huella de carbono de cada sector por cada mil de millones de pesos de aporte al PIB. Esta se mida en kilo toneladas de CO2 equivalente dividido en "+coins[values.country]+". Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 4 && appState.activeGraphic === 1 && appState.activeScenary !== null){
      return("Empleo directo e indirecto: En el gráfico se observa la cantidad total de empleos directos e indirectos generados para diferentes tecnologías del escenario predefinido " + scenary_data[appState.activeScenary-1] + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + ". Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 4 && appState.activeGraphic === 2 && appState.activeScenary !== null){
      return("Análisis de remuneraciones: En la tabla se muestra un análisis de remuneraciones del escenario predefinido " + scenary_data[appState.activeScenary-1] + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + ". Las columnas de esta tabla corresponden a diversos tipos de tecnologías y las filas corresponden a los indicadores, cada una con diferentes unidades de medida. Se incluyen los datos tanto para el escenario seleccionado como para el año de la MIP base.")
    }
    else if (appState.activeObject === 4 && appState.activeGraphic === 3 && appState.activeScenary !== null){
      return("Empleo histórico: El gráfico muestra el avance histórico de los empleos directos generados para diferentes tipos de tecnologías.")
    }
    /*
    else if (appState.activeObject === 4 && appState.activeGraphic === 4){
      return("PIB por empleo: El gráfico muestra el PIB promedio generado por empleados medido en miles de millones de pesos para diferentes tipos de tecnologías del escenario" + location.state.values.scenary + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + " y el año de la MIP base.")
    } 
    */
    else if (appState.activeObject === 4 && appState.activeGraphic === 5 && appState.activeScenary !== null){
      return("Demanda final por tecnología: El gráfico muestra el ratio de número de empleos totales y directos generados divididos por la demanda final para diferentes tipos de tecnologías del escenario predefinido " + scenary_data[appState.activeScenary-1] + " " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year + " y el año de la MIP base.")
    }
    else{
      return("Selecciona uno de los 4 indicadores de la zona superior (Resumen, Economía, Emisiones y Empleo). Posteriormente selecciona uno de los módulos disponibles en la zona derecha junto a uno de los escenarios disponibles para poder ver los gráficos o tablas del año de la simulación de escenario predefinido seleccionado.")
    }
  }

  function TitleDisplay(){
    if (appState.activeObject === 1){
      return("Resumen escenario predefinido "  + capitalize(location.state.values.country) + " " + location.state.values.pelp_year)
    }
    else if (appState.activeObject === 2){
      return("Indicadores económicos escenario predefinido " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year)
    }
    else if (appState.activeObject === 3){
      return("Indicadores de emisiones escenario predefinido "+ capitalize(location.state.values.country) + " " + location.state.values.pelp_year)
    }
    if (appState.activeObject === 4){
      return("Indicadores de empleo escenario predefinido  " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year)
    }
    else{
      return("Escenario predefinido " + capitalize(location.state.values.country) + " " + location.state.values.pelp_year)
    }
  }

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex'> 
        <div className='w-3/5 h-14 grid grid-cols-4 gap-x-3 p-2' style={{backgroundColor: "white", border: "1px solid", borderRadius:"10px", borderColor: "gray"}}>
          <button onClick={() => {toggleActive(0)}} className={`max-h-10 max-w-36 rounded-lg ${toggleActiveStyles(0)}`}>Resumen</button>
          <button onClick={() => {toggleActive(1)}} className={`max-h-10 max-w-36 rounded-lg ${toggleActiveStyles(1)}`}>Economía</button>
          <button onClick={() => {toggleActive(2)}} className={`max-h-10 max-w-36 rounded-lg ${toggleActiveStyles(2)}`}>Emisiones</button>
          <button onClick={() => {toggleActive(3)}} className={`max-h-10 max-w-36 rounded-lg ${toggleActiveStyles(3)}`}>Empleo</button>
        </div>
        <div className='grid grid-cols-5 ml-auto w-1/4 gap-x-4'>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {setIsModalOpen(true)}} title='Pulsa para abrir la tabla o gráfico actual en pantalla completa'><img src={expand} className='w-10 h-10' alt=''/>Expandir pantalla</button></div>

          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/simulations/main/predefined_scenary_reports",{state: {summary, componentsmip, detailcompositionmip,impactscontributionmip, exportimportsmip, capitaldistributionmip, remunerationdistributionmip, fiscalimpactmip, directemissionsmip, carbonfootprintmip,inducedemploymentmip, remunerationanalisismip, pibemploymentmip, finaldemandmip, scenary_data, data, values, coins}})}} title='Pulsa para ir al menú de guardado y descarga de reportes. Esta opción sólo está disponible para usuarios premium'><img src={save} className='w-10 h-10' alt=''/>Guardar reporte</button></div>

          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/simulations/main/predefined_scenary_reports",{state: {summary, componentsmip, detailcompositionmip,impactscontributionmip, exportimportsmip, capitaldistributionmip, remunerationdistributionmip, fiscalimpactmip, directemissionsmip, carbonfootprintmip,inducedemploymentmip, remunerationanalisismip, pibemploymentmip, finaldemandmip, scenary_data, data, values, coins}})}} title='Pulsa para ir al menú de guardado y descarga de reportes. Esta opción sólo está disponible para usuarios premium'><img src={download} className='w-10 h-10' alt=''/>Descargar reporte</button></div>

          {CSVRender(user_type)}

          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/simulations/main/predefined_scenary_selector")}} title='Pulsa para volver a la pantalla de selección de datos'><img src={edit} className='w-10 h-10' alt=''/>Editar variables</button></div>
        </div>
      </div>
      <div className='mt-2 mb-1'><p className='text-3xl font-extrabold tracking-tight text-slate-900'>{TitleDisplay()}</p></div>
      <div>
        <div className='flex'>
          <h4 className='font-bold text-gray-400 w-3/5'>{TextDisplay()}</h4>
          <div className="ml-auto">
            {ButtonsDisplay()}
          </div>
        </div>
        <div className='flex ml-auto'>
          {ScenaryDisplay(total_scenary)}
        </div>
      </div>
      <div className='mt-3 mb-12' style={{maxWidth:"1460px", maxHeight: "490px"}}>
        {GraphicDisplay()}
      </div>
      <FullScreen isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} item={GraphicDisplay()}/>
    </div>
  )
}

export default PredefinedScenaryResults
