import {React, useState, useContext} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header, Button } from '../../../../components';
import { API_PORT } from '../../../../API_PORT';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Modal from '../../../../components/Modal';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const BasicScenarySelector1 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;

  const values = location.state.values;
  const coins = location.state.coins;

  const [technologies, setTechnologies] = useState({
    "Fosil": 0,
    "Hidraulica": 0,
    "BiomasaBiogas": 0,
    "Solar": 0,
    "Eolica": 0,
    "Geotermia": 0,
    "AlmacenamientoLargo": 0,
    "AlmacenamientoCorto": 0,
    "H2": 0,
    "FosilCapacity": 0,
    "HidraulicaCapacity": 0,
    "BiomasaBiogasCapacity": 0,
    "SolarCapacity": 0,
    "EolicaCapacity": 0,
    "GeotermiaCapacity": 0,
    "AlmacenamientoLargoCapacity": 0,
    "AlmacenamientoCortoCapacity": 0,
  });

  const [progress, setProgress] = useState({started: false, pc: 0});
  const [msg, setMsg] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  //Variables para almacenar los endpoints
  //Resumen
  const [tablelike, settablelike] = useState([])
  const [starplot, setstarplot] = useState([])

  const [summary, setsumary] = useState([])

  //Indicadores económicos
  const [components,setcomponents] = useState([])
  const [detailcomposition, setdetailcomposition] = useState([])
  const [impactscontribution, setimpactscontributions] = useState([])
  const [exportimports, setimportexports] = useState([])
  const [ratefosilrenovable, setratefosilrenovable] = useState([])
  const [capitaldistribution, setcapitaldistribution] = useState([])
  const [remunerationdistribution, setremunerationdistribution] = useState([])
  const [fiscalimpact, setfiscalimpact] = useState([])
  const [investment, setinvestment] = useState([])

  const [componentsmip, setcomponentsmip] = useState([])
  const [detailcompositionmip, setdetailcompositionmip] = useState([])
  const [impactscontributionmip, setimpactscontributionmip] = useState([])
  const [exportimportsmip, setexportsimportsmip] = useState([])
  const [capitaldistributionmip, setcapitaldistributionmip] = useState([])
  const [remunerationdistributionmip, setremunerationdistributionmip] = useState([])
  const [fiscalimpactmip, setfiscalimpactmip] = useState([])

  //Indicadores de emisiones
  const [directemissions,setdirectemissions] = useState([])
  const [carbonfootprint,setcarbonfootprint] = useState([])

  const [directemissionsmip, setdirectemissionsmip] = useState([])
  const [carbonfootprintmip, setcarbonfootprintmip] = useState([])

  //Indicadores de Empleo
  const [inducedemployment, setinducedemployment] = useState([])
  const [remunerationanalisis, setremunerationanalisis] = useState([])
  const [historicalemployees, sethistoricalemployees] = useState([])
  const [pibemployment, setpibemployment] = useState([])
  const [finaldemand, setfinaldemand] = useState([]);

  const [inducedemploymentmip, setinducedemploymentmip] = useState([])
  const [remunerationanalisismip, setremunerationanalisismip] = useState([])
  const [pibemploymentmip, setpibemploymentmip] = useState([])
  const [finaldemandmip, setfinaldemandmip] = useState([])

  const handleInput = (event) =>{
    setTechnologies(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  const BasicScenaryResults = () => {
    //Summary Endpoints
    const table_like = API_PORT + "/scene/basic_scenario/resume/Resumen-Tablelike/" + values.country + "/" + values.year + "?token="+token
    const star_plot = API_PORT + "/scene/basic_scenario/resume/Resumen-StarPlot-PELP/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token

    const mip_summary = API_PORT + "/resumen/" + values.country + "/" + values.year + "?token="+token

    //Economics Endpoints
    const components_scenary = API_PORT + "/scene/basic_scenario/economic/components/" + values.country + "/" + values.year + "?token="+token
    const detail_composition = API_PORT + "/scene/basic_scenario/economic/detail-composition/" + values.country + "/" + values.year + "?token="+token
    const impacts_contribution = API_PORT + "/scene/basic_scenario/economic/impacts-contribution/" + values.country + "/" + values.year + "?token="+token
    const export_imports = API_PORT + "/scene/basic_scenario/economic/export-imports/" + values.country + "/{yearMIP}/{yearPELP}?year=" + values.year + "&token="+token
    const rate_fosil_renovable = API_PORT + "/scene/basic_scenario/economic/rate-fosil-renovable/" + values.country + "/" + values.year + "?token="+token
    const capital_distribution = API_PORT + "/scene/basic_scenario/economic/capital-distribution/" + values.country + "/" + values.year + "?token="+token
    const remuneration_distribution = API_PORT + "/scene/basic_scenario/economic/remuneration-distribution/" + values.country + "/" + values.year + "?token="+token
    const fiscal_impact = API_PORT + "/scene/basic_scenario/economic/fiscal-impact/" + values.country + "/" + values.year + "?token="+token
    const investment_scenary = API_PORT + "/scene/basic_scenario/economic/Investment-requirements/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token //Revisar

    const components_mip = API_PORT + "/expandedmip/economic/components/" + values.country + "/" + values.year + "?token="+token
    const detail_composition_mip = API_PORT + "/expandedmip/economic/detail-composition/" + values.country + "/" + values.year + "?token="+token
    const impacts_contribution_mip = API_PORT + "/expandedmip/economic/impacts-contribution/" + values.country + "/" + values.year + "?token="+token
    const export_imports_mip = API_PORT + "/expandedmip/economic/export-imports/" + values.country + "/" + values.year + "?token="+token
    const capital_distribution_mip = API_PORT + "/expandedmip/economic/capital-distribution/" + values.country + "/" + values.year + "?token="+token
    const remuneration_distribution_mip = API_PORT + "/expandedmip/economic/remuneration-distribution/" + values.country + "/" + values.year + "?token="+token
    const fiscal_impact_mip = API_PORT + "/expandedmip/economic/fiscal-impact/" + values.country + "/" + values.year + "?token="+token

    //Emissions Endpoints
    const direct_emissions = API_PORT + "/scene/basic_scenario/emission/direct-emissions-and-carbon-footprint/" + values.country + "/" + values.year + "?token="+token
    const carbon_footprint = API_PORT + "/scene/basic_scenario/emission/carbon-footprint-aggregate-value-ratio/" + values.country + "/" + values.year + "?token="+token

    const direct_emissions_mip = API_PORT + "/expandedmip/emissions/direct-emissions-and-carbon-footprint/" + values.country + "/" + values.year + "?token="+token
    const carbon_footprint_mip = API_PORT + "/expandedmip/emissions/carbon-footprint-aggregate-value-ratio/" + values.country + "/" + values.year + "?token="+token

    //Empolyment Endpoints
    const induced_employment = API_PORT + "/scene/basic_scenario/labor/induced-and-direct-employment/" + values.country + "/" + values.year + "?token="+token
    const remuneration_analisis = API_PORT + "/scene/basic_scenario/labor/remuneration-analisis/" + values.country + "/" + values.year + "?token="+token
    const historical_employees = API_PORT + "/scene/basic_scenario/labor/historical-employees/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token//Revisar
    const pib_employment = API_PORT + "/scene/basic_scenario/labor/pib-per-employment/" + values.country + "/" + values.year + "?token="+token
    const final_demand = API_PORT + "/scene/basic_scenario/labor/empleo-demanda-final/" + values.country + "/" + values.year + "?token="+token

    const induced_employment_mip = API_PORT + "/expandedmip/labor/induced-and-direct-employment/" + values.country + "/" + values.year + "?token="+token
    const remuneration_analisis_mip = API_PORT + "/expandedmip/labor/remuneration-analisis/" + values.country + "/" + values.year + "?token="+token
    const pib_employment_mip = API_PORT + "/expandedmip/labor/pib-per-employment/" + values.country + "/" + values.year + "?token="+token
    const final_demand_mip = API_PORT + "/expandedmip/labor/empleo-demanda-final/" + values.country + "/" + values.year + "?token="+token

    //Define Body
    const tablelike_body = {
      "GenereationBasicScenariosInputModel": {
        "fósil": technologies.Fosil,
        "hidráulica": technologies.Hidraulica,
        "biomasa y biogas": technologies.BiomasaBiogas,
        "solar": technologies.Solar,
        "eólica": technologies.Eolica,
        "geotermia": technologies.Geotermia,
        "almacenamiento largo plazo": technologies.AlmacenamientoLargo,
        "almacenamiento corto plazo": technologies.AlmacenamientoCorto,
        "H2": technologies.H2
      },
      "CapacityBasicScenariosInputModel": {
        "fósil": technologies.FosilCapacity,
        "hidráulica": technologies.HidraulicaCapacity,
        "biomasa y biogas": technologies.BiomasaBiogasCapacity,
        "solar": technologies.SolarCapacity,
        "eólica": technologies.EolicaCapacity,
        "geotermia": technologies.GeotermiaCapacity,
        "almacenamiento largo plazo": technologies.AlmacenamientoLargoCapacity,
        "almacenamiento corto plazo": technologies.AlmacenamientoCortoCapacity,
      }
    }

    const basic_scenary_body = {
      "fósil": technologies.Fosil,
      "hidráulica": technologies.Hidraulica,
      "biomasa y biogas": technologies.BiomasaBiogas,
      "solar": technologies.Solar,
      "eólica": technologies.Eolica,
      "geotermia": technologies.Geotermia,
      "almacenamiento largo plazo": technologies.AlmacenamientoLargo,
      "almacenamiento corto plazo": technologies.AlmacenamientoCorto,
      "H2": technologies.H2
    }

    const basic_scenary_body_no_H2 = {
      "fósil": technologies.Fosil,
      "hidráulica": technologies.Hidraulica,
      "biomasa y biogas": technologies.BiomasaBiogas,
      "solar": technologies.Solar,
      "eólica": technologies.Eolica,
      "geotermia": technologies.Geotermia,
      "almacenamiento largo plazo": technologies.AlmacenamientoLargo,
      "almacenamiento corto plazo": technologies.AlmacenamientoCorto
    }

    setIsModalOpen(true)
    setMsg("Generando la simulación, no cierre esta pestaña hasta completar el proceso.")
    setLoaderScreen(prevState => {return {...prevState, started: true}});

    //Summary Fetch
    fetch(table_like, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(tablelike_body)
    }).then(res => res.json()).then(data => {settablelike(data);})
    fetch(star_plot, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(tablelike_body)
    }).then(res => res.json()).then(data => {setstarplot(data);})

    fetch(mip_summary, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'POST',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setsumary(data);})

    //Economics Fetch
    fetch(components_scenary, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(basic_scenary_body)
    }).then(res => res.json()).then(data => {setcomponents(data);})
  fetch(detail_composition, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(basic_scenary_body)
    }).then(res => res.json()).then(data => {setdetailcomposition(data);})
  fetch(impacts_contribution, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(basic_scenary_body)
    }).then(res => res.json()).then(data => {setimpactscontributions(data);})
  fetch(export_imports, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(basic_scenary_body)
    }).then(res => res.json()).then(data => {setimportexports(data);})
  fetch(rate_fosil_renovable, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(basic_scenary_body)
    }).then(res => res.json()).then(data => {setratefosilrenovable(data);})
  fetch(capital_distribution, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(basic_scenary_body)
    }).then(res => res.json()).then(data => {setcapitaldistribution(data);})
  fetch(remuneration_distribution, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(basic_scenary_body)
    }).then(res => res.json()).then(data => {setremunerationdistribution(data);})
  fetch(fiscal_impact, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(basic_scenary_body)
    }).then(res => res.json()).then(data => {setfiscalimpact(data);})
  fetch(investment_scenary, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(basic_scenary_body_no_H2)
    }).then(res => res.json()).then(data => {setinvestment(data);})

    fetch(components_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcomponentsmip(data);})
      fetch(detail_composition_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setdetailcompositionmip(data);})
      fetch(impacts_contribution_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setimpactscontributionmip(data);})
      fetch(export_imports_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setexportsimportsmip(data);})
      fetch(capital_distribution_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcapitaldistributionmip(data);})
      fetch(remuneration_distribution_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setremunerationdistributionmip(data);})
      fetch(fiscal_impact_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setfiscalimpactmip(data);})
  
   //Emissions Fetch
   fetch(direct_emissions, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
   method: 'POST',
   headers: {'Content-type': 'application/json'},
   body: JSON.stringify(basic_scenary_body)
   }).then(res => res.json()).then(data => {setdirectemissions(data);})
  fetch(carbon_footprint, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
   method: 'POST',
   headers: {'Content-type': 'application/json'},
   body: JSON.stringify(basic_scenary_body)
   }).then(res => res.json()).then(data => {setcarbonfootprint(data);})

   fetch(direct_emissions_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setdirectemissionsmip(data);})
  fetch(carbon_footprint_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcarbonfootprintmip(data);})

   //Employment Fetch
   fetch(induced_employment, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
   method: 'POST',
   headers: {'Content-type': 'application/json'},
   body: JSON.stringify(basic_scenary_body)
   }).then(res => res.json()).then(data => {setinducedemployment(data);})
 fetch(remuneration_analisis, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
   method: 'POST',
   headers: {'Content-type': 'application/json'},
   body: JSON.stringify(basic_scenary_body)
   }).then(res => res.json()).then(data => {setremunerationanalisis(data);})
 fetch(historical_employees, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
   method: 'POST',
   headers: {'Content-type': 'application/json'},
   body: JSON.stringify(basic_scenary_body)
   }).then(res => res.json()).then(data => {sethistoricalemployees(data);})
 fetch(pib_employment, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
   method: 'POST',
   headers: {'Content-type': 'application/json'},
   body: JSON.stringify(basic_scenary_body)
   }).then(res => res.json()).then(data => {setpibemployment(data);})
 fetch(final_demand, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
   method: 'POST',
   headers: {'Content-type': 'application/json'},
   body: JSON.stringify(basic_scenary_body)
   }).then(res => res.json()).then(data => {setfinaldemand(data);})

   fetch(induced_employment_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setinducedemploymentmip(data);})
    fetch(remuneration_analisis_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setremunerationanalisismip(data);})
    fetch(pib_employment_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setpibemploymentmip(data);})
    fetch(final_demand_mip, {onUploadProgress: (progressEvent) => {setProgress(prevState => {return {...prevState, pc: progressEvent.progress+8}})},
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setfinaldemandmip(data);})
  }

  function UpdateValues(){
    if(location.state?.technologies){
      setTechnologies({...technologies, Fosil : location.state.technologies.Fosil, Hidraulica: location.state.technologies.Hidraulica, BiomasaBiogas: location.state.technologies.BiomasaBiogas, Solar: location.state.technologies.Solar, Eolica: location.state.technologies.Eolica, Geotermia: location.state.technologies.Geotermia, AlmacenamientoCorto: location.state.technologies.AlmacenamientoCorto, AlmacenamientoLargo: location.state.technologies.AlmacenamientoLargo, H2: location.state.technologies.H2, FosilCapacity : location.state.technologies.FosilCapacity, HidraulicaCapacity: location.state.technologies.HidraulicaCapacity, BiomasaBiogasCapacity: location.state.technologies.BiomasaBiogasCapacity, SolarCapacity: location.state.technologies.SolarCapacity, EolicaCapacity: location.state.technologies.EolicaCapacity, GeotermiaCapacity: location.state.technologies.GeotermiaCapacity, AlmacenamientoCortoCapacity: location.state.technologies.AlmacenamientoCortoCapacity, AlmacenamientoLargoCapacity: location.state.technologies.AlmacenamientoLargoCapacity})  
    }
    else if(location.state?.pelpgeneration){
      setTechnologies({...technologies, Fosil : location.state.pelpgeneration['fósil'].toFixed(2), Hidraulica: location.state.pelpgeneration["hidráulica"].toFixed(2), BiomasaBiogas: location.state.pelpgeneration["biomasa y biogas"].toFixed(2), Solar: location.state.pelpgeneration["solar"].toFixed(2), Eolica: location.state.pelpgeneration["eólica"].toFixed(2), Geotermia: location.state.pelpgeneration["geotermia"].toFixed(2), AlmacenamientoCorto: location.state.pelpgeneration["almacenamiento corto plazo"].toFixed(2), AlmacenamientoLargo: location.state.pelpgeneration["almacenamiento largo plazo"].toFixed(2), H2: location.state.pelpgeneration['H2'].toFixed(2), FosilCapacity : location.state.pelpcapacity['fósil'].toFixed(2), HidraulicaCapacity: location.state.pelpcapacity["hidráulica"].toFixed(2), BiomasaBiogasCapacity: location.state.pelpcapacity["biomasa y biogas"].toFixed(2), SolarCapacity: location.state.pelpcapacity["solar"].toFixed(2), EolicaCapacity: location.state.pelpcapacity["eólica"].toFixed(2), GeotermiaCapacity: location.state.pelpcapacity["geotermia"].toFixed(2), AlmacenamientoCortoCapacity: location.state.pelpcapacity["almacenamiento corto plazo"].toFixed(2), AlmacenamientoLargoCapacity: location.state.pelpcapacity["almacenamiento largo plazo"].toFixed(2)})  
    }
    else if(location.state?.mso_export){
      setTechnologies({...technologies, Fosil: location.state.mso_export.Fosil, Hidraulica: location.state.mso_export.Hidraulica, BiomasaBiogas: location.state.mso_export.BiomasaBiogas, Solar: location.state.mso_export.Solar, Eolica: location.state.mso_export.Eolica, Geotermia: location.state.mso_export.Geotermia, AlmacenamientoCorto: location.state.mso_export.AlmacenamientoCorto, AlmacenamientoLargo: location.state.mso_export.AlmacenamientoLargo, H2: location.state.mso_export.H2, FosilCapacity: location.state.mso_export.FosilCapacity, HidraulicaCapacity: location.state.mso_export.HidraulicaCapacity, BiomasaBiogasCapacity: location.state.mso_export.BiomasaBiogasCapacity, SolarCapacity: location.state.mso_export.SolarCapacity, EolicaCapacity: location.state.mso_export.EolicaCapacity, GeotermiaCapacity: location.state.mso_export.GeotermiaCapacity, AlmacenamientoCortoCapacity: location.state.mso_export.AlmacenamientoCortoCapacity, AlmacenamientoLargoCapacity: location.state.mso_export.AlmacenamientoLargoCapacity
      })
    }
  };


  function UpdatePreviousValues(){
    if (location.state?.technologies){
      return(
        <div className='flex gap-5'>
          <TooltipComponent content="Pulsa este botón para cargar los datos de la última simulación realizada en tus datos actuales."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={() => {UpdateValues()}}>Cargar los datos de la última simulación realizada</button></TooltipComponent>
          <TooltipComponent content="Al pulsar este botón, podrás cargar los datos de un escenario predefinido en el escenario personalizado actual. Selecciona un tipo de escenario y luego pulsa 'Cargar los datos del escenario predefinido' en esta misma pantalla."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={() => {navigate("/dashboard/simulations/main/basic_scenary_import", {state: {values, coins}})}}>Importar datos de un escenario predefinido</button></TooltipComponent>
        </div>
      )
    }
    else if (location.state?.pelpgeneration){
      return(<TooltipComponent content="Pulsa este botón para cargar los datos del escenario predefinido seleccionado en el escenario personalizado actual."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={() => {UpdateValues()}}>Cargar los datos del escenario predefinido</button></TooltipComponent>)
    }
    else if (location.state?.mso_export){
      return(<div className='flex gap-5'>
          <TooltipComponent content="Pulsa este botón para cargar los datos de la simulación de operación realizada en tus datos actuales."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={() => {UpdateValues()}}>Cargar los datos del escenario de operación simulada</button></TooltipComponent>
          <TooltipComponent content="Al pulsar este botón, podrás cargar los datos de un escenario predefinido en el escenario personalizado actual. Selecciona un tipo de escenario y luego pulsa 'Cargar los datos del escenario predefinido' en esta misma pantalla."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={() => {navigate("/dashboard/simulations/main/basic_scenary_import", {state: {values, coins}})}}>Importar datos de un escenario predefinido</button></TooltipComponent>
        </div>)
    }
    else{
      return(<TooltipComponent content="Al pulsar este botón, podrás cargar los datos de un escenario predefinido en el escenario personalizado actual. Selecciona un tipo de escenario y luego pulsa 'Cargar los datos del escenario predefinido' en esta misma pantalla."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={() => {navigate("/dashboard/simulations/main/basic_scenary_import", {state: {values, coins}})}}>Importar datos de un escenario predefinido</button></TooltipComponent>)
    }
  }

  const NavigateResults = () => {
    if(tablelike.title && starplot.title && summary.title && components.description && detailcomposition.title && impactscontribution.description && exportimports.title && ratefosilrenovable.title && capitaldistribution.title && remunerationdistribution.title && fiscalimpact.title && investment.title && componentsmip.description && detailcompositionmip.title && impactscontributionmip.description && exportimportsmip.title && capitaldistributionmip.title && remunerationdistributionmip.title && fiscalimpactmip.title && directemissions.title && carbonfootprint.title && directemissionsmip.title && carbonfootprintmip.title && inducedemployment.title && remunerationanalisis.title && historicalemployees.title && pibemployment.title && finaldemand.title && inducedemploymentmip.title && remunerationanalisismip.title && pibemploymentmip.title && finaldemandmip.title && coins[values.country]){
      alert("Se ha calculado la simulación. Pulsa para continuar.")
      navigate("/dashboard/simulations/main/basic_scenary_results", {state: {technologies,values,tablelike,starplot,summary, components, detailcomposition,impactscontribution, exportimports, ratefosilrenovable, capitaldistribution, remunerationdistribution, fiscalimpact, componentsmip, detailcompositionmip,impactscontributionmip, exportimportsmip, capitaldistributionmip, remunerationdistributionmip, fiscalimpactmip, investment,directemissions, carbonfootprint,directemissionsmip, carbonfootprintmip,inducedemployment, remunerationanalisis, historicalemployees, pibemployment, finaldemand, inducedemploymentmip, remunerationanalisismip, pibemploymentmip, finaldemandmip, coins}})
    }
  }
    

  NavigateResults()

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/simulations/main/basic_scenary_selector_1" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver a la selección de país y años"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <Header title={"Escenario Personalizado " + values.pelp_year}/>
      <p className='text-gray-500'>En esta sección podrás generar tus propias simulaciones de escenarios personalizados. Ingresa los datos de generación y capacidad instalada por tecnología para ver los resultados.</p>
      <div className='mt-10'><Header title="Tecnologías de Generación (GWh)"/></div>
      <h1 className='mb-3'><strong>Generación por Tecnología (GWh)</strong></h1>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 mb-9'>
          <div className='column-span-1'>
          <h3 className='mb-3'>Fósil</h3>
          <input type='number' placeholder={technologies.Fosil} name='Fosil' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h3 className='mb-3'>Hidráulica</h3>
          <input type='number' placeholder={technologies.Hidraulica} name='Hidraulica' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h3 className='mb-3'>Biomasa y Biogas</h3>
          <input type='number' placeholder={technologies.BiomasaBiogas} name='BiomasaBiogas' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h3 className='mb-3'>Solar</h3>
          <input type='number' placeholder={technologies.Solar} name='Solar' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h3 className='mb-3'>Eólica</h3>
          <input type='number' placeholder={technologies.Eolica} name='Eolica' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Geotermia</h1>
          <input type='number' placeholder={technologies.Geotermia} name='Geotermia' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
          <div className='column-span-1'>
            <h3 className='mb-3'>Almacenamiento Largo Plazo</h3>
            <input type='number' placeholder={technologies.AlmacenamientoLargo} name='AlmacenamientoLargo' className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
          </div>
          <div className='column-span-1'>
            <h3 className='mb-3'>Almacenamiento Corto Plazo</h3>
            <input type='number' placeholder={technologies.AlmacenamientoCorto} name='AlmacenamientoCorto' className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
          </div>
        <div className='column-span-1'>
          <h3 className='mb-3'>H2</h3>
          <input type='number' placeholder={technologies.H2} name='H2' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
      </div>
      <h1 className='mb-3'><strong>Inyección a la red por tecnologías de almacenamiento (GWh)</strong></h1>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5 mb-9'>
          <div className='column-span-1'>
            <h3 className='mb-3'>Almacenamiento Largo Plazo</h3>
            <input type='number' placeholder={technologies.AlmacenamientoLargo} name='AlmacenamientoLargo' className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
          </div>
          <div className='column-span-1'>
            <h3 className='mb-3'>Almacenamiento Corto Plazo</h3>
            <input type='number' placeholder={technologies.AlmacenamientoCorto} name='AlmacenamientoCorto' className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
          </div>
      </div>
      <h1 className='mb-3'><strong>Producción de H2 (GWh)</strong></h1>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <h3 className='mb-3'>H2</h3>
          <input type='number' placeholder={technologies.H2} name='H2' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
      </div>

      <div className='mt-10'><Header title="Capacidad instalada por tecnología (MW)"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <h1 className='mb-3'>Fósil</h1>
          <input type='number' placeholder={technologies.FosilCapacity} name='FosilCapacity' onChange={handleInput} className='form-control rounded-0 h-10 w-full border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Hidráulica</h1>
          <input type='number' placeholder={technologies.HidraulicaCapacity} name='HidraulicaCapacity' onChange={handleInput} className='form-control rounded-0 h-10 w-full border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Biomasa y Biogas</h1>
          <input type='number' placeholder={technologies.BiomasaBiogasCapacity} name='BiomasaBiogasCapacity' onChange={handleInput} className='form-control rounded-0 h-10 w-full border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Solar</h1>
          <input type='number' placeholder={technologies.SolarCapacity} name='SolarCapacity' onChange={handleInput} className='form-control rounded-0 h-10 w-full border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Eólica</h1>
          <input type='number' placeholder={technologies.EolicaCapacity} name='EolicaCapacity' onChange={handleInput} className='form-control rounded-0 h-10 w-full border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Geotermia</h1>
          <input type='number' placeholder={technologies.GeotermiaCapacity} name='GeotermiaCapacity' onChange={handleInput} className='form-control rounded-0 h-10 w-full border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Almacenamiento Largo Plazo</h1>
          <input type='number' placeholder={technologies.AlmacenamientoLargoCapacity} name='AlmacenamientoLargoCapacity' onChange={handleInput} className='form-control rounded-0 h-10 w-full border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Almacenamiento Corto Plazo</h1>
          <input type='number' placeholder={technologies.AlmacenamientoCortoCapacity} name='AlmacenamientoCortoCapacity' onChange={handleInput} className='form-control rounded-0 h-10 w-full border border-gray-300 px-3 py-2'></input>
        </div>
      </div>
      
      <div className='mt-16 flex gap-5'>
        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={BasicScenaryResults}>Ver Resultados</button>
        {UpdatePreviousValues()}
      </div>
      <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
    </div>
  )
}

export default BasicScenarySelector1
