import {React, useState, useContext} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { useLocation, useNavigate } from 'react-router-dom';
import MUIDataTable from "mui-datatables";
import { Bar } from "react-chartjs-2";
import { Chart as ChartJS, Title, BarElement, CategoryScale, LinearScale, Tooltip, Legend} from "chart.js";
import capitalize from '../../../hook/capitalize'
import graphColors from '../../../hook/graphColors';
import HorizontalTables from '../../../hook/HorizontalTables';
import FullScreen from '../../../components/FullScreen';
import filedownload from "../../../assets/file.svg"
import download from "../../../assets/download_black.svg"
import expand from "../../../assets/expand.svg"
import edit from "../../../assets/edit.svg"
import save from "../../../assets/save_black.svg"
import { CSVLink } from 'react-csv';
ChartJS.register(Title, BarElement, CategoryScale, LinearScale, Tooltip, Legend)


const ResultsDatabase = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const userContext = useContext(UserContext);
  const user_type= userContext.value.user_type;

  function CSVRender(user){
    if (user === "premium" || user === "admin"){
      return(<div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button title='Pulsa para descargar la tabla o gráfico actual en formato CSV. Esta opción sólo está disponible para usuarios premium'><CSVLink data={CSVData()} filename={CSVTitle()}><img src={filedownload}  className='w-10 h-10' alt=''/>Convertir a CSV</CSVLink></button></div>)
    }
    else{
      return(<div className='justify-center items-center text-center ' style={{fontSize: "10px"}}><button title='Pulsa para descargar la tabla o gráfico actual en formato CSV. Esta opción sólo está disponible para usuarios premium' onClick={()=>{alert("La opción de descargar CSV sólo está disponible para usuarios premium. Adquiere la suscripción premium de EcoKinesis para poder usar esta función")}}><img src={filedownload} className='w-10 h-10' alt=''/>Convertir a CSV</button></div>)
    }
  }

  function options_type(user){
    if(user === "admin" || user === "premium"){
      return({
        rowsPerPage: 10,
        rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19],
        selectableRows: "none",
        tableBodyMaxHeight: "400px",
        print: "false",
        download: "false",
        filter: "false",
        responsive: "vertical",
      })
    }
    else{
      return({
        rowsPerPage: 10,
        rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19],
        selectableRows: "none",
        tableBodyMaxHeight: "400px",
        print: "false",
        download: "false",
        filter: "false",
        responsive: "vertical",
      })
    }
  }

  function options_type2(user){
    if(user === "admin" || user === "premium"){
      return({
        rowsPerPage: 10,
        rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19],
        selectableRows: "none",
        print: "false",
        download: "false",
        filter: "false",
        tableBodyMaxHeight: "300px",
        responsive: "vertical",
      })
    }
    else{
      return({
        rowsPerPage: 10,
        rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19],
        selectableRows: "none",
        print: "false",
        download: "false",
        filter: "false",
        tableBodyMaxHeight: "300px",
        responsive: "vertical",
      })
    }
  }

  //Indicators and graphi-table options
  const [appState, changeState] = useState({
    activeObject: null,
    activeGraphic: null,
    objects: [1,2,3,4],
    graphicObjects: [1,2,3,4,5,6,7,8]
  })

  function toggleActive(index,graphicindex){
    if (index === 0 || index === 1 || index === 2 || index===3){
      changeState({...appState, activeObject: appState.objects[index]})
    }
    if (graphicindex === 0 || graphicindex === 1 || graphicindex === 2 || graphicindex === 3 || graphicindex === 4 || graphicindex === 5 || graphicindex === 6 || graphicindex === 7 ){
      changeState({...appState, activeGraphic: appState.graphicObjects[graphicindex]})
    }
    
  }
  function toggleActiveStyles(index){
    if(appState.objects[index] === appState.activeObject){
      return "box-active"
    }
    else {
      return "box-inactive"
    }
  }

  function toggleActiveStylesGraph(graphicindex){
    if(appState.graphicObjects[graphicindex] === appState.activeGraphic){
      return "box-active"
    }
    else {
      return "box-inactive"
    }
  }

  //Define const of location
  const values = location.state.values
  const miporiginal = location.state.miporiginal
  const employment = location.state.employment
  const electric = location.state.electric
  const electricgenerationcosts = location.state.electricgenerationcosts
  const electriccapacity = location.state.electriccapacity
  const electricgeneration = location.state.electricgeneration
  const electrictotal = location.state.electrictotal
  const directemissions = location.state.directemissions
  const emissionsfactor = location.state.emissionsfactor
  const electricitydestiny = location.state.electricitydestiny
  const carbonfootprint = location.state.carbonfootprint
  const importedcarbon = location.state.importedcarbon
  const coins = location.state.coins

  /*Columns and data*/
  var miporiginal_columns = [""]
  var miporiginal_data = []
  var employment_columns = [""]
  var employment_data = []
  var electric_columns = [""]
  var electric_data = [["Generación [GWh]"]]
  var electric_capacity_columns = [""]
  var electric_generation_costs_columns = [""]
  var electric_generation_costs_data = [["Generación [GWh]"]]
  var electric_capacity_data = [["Capacidad [MW]"]]
  var electric_generation_columns= [""]
  var electric_generation_data= []
  var electric_total_columns= [""]
  var electric_total_data= []
  var direct_emissions_data = []
  var direct_emissions_columns = []
  var emissions_factors_data = []
  var emissions_factor_columns = []
  var electricity_destiny_columns = []
  var electricity_destiny_data = []
  var electricity_destiny_datasets = []
  var carbon_footprint_columns = []
  var carbon_footprint_data = []
  var carbon_footprint_datasets = []
  var imported_carbon_columns = []
  var imported_carbon_data = []
  

  /*Set col data*/
  /*MIP*/
  location.state.miporiginal.col_order?.forEach((element,index) => {
    miporiginal_columns.push(capitalize(element));
  })
  location.state.miporiginal.row_order?.forEach((element,index) => {
    var new_array= [capitalize(element)].concat(Array(miporiginal_columns.length-1).fill(null))
    miporiginal_data.push(new_array);
  })
  location.state.miporiginal.data?.forEach((element,index) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    miporiginal_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        miporiginal_data[index2][miporiginal_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  /*Employment*/
  location.state.employment.data?.forEach((element,index) => {
    var y= capitalize(element.y_index)
    if (!employment_columns.includes(y)){
      employment_columns.push(y)
    }
  })
  location.state.employment.data?.forEach((element,index) => {
    var new_array=[element.x_index].concat(Array(employment_columns.length-1).fill(0))
    var flag= true
    employment_data.forEach((element2,index) => {
      if (element.x_index === element2[0]){
        flag = false
      }
    })
    if (flag){
      employment_data.push(new_array)
    }
  })
  location.state.employment.data?.forEach((element,index) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    employment_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        if(x === "Número de empleados"){
          employment_data[index2][employment_columns.indexOf(y)] = v
        }
        else{
          employment_data[index2][employment_columns.indexOf(y)] = v.toFixed(2)
        }
      }
    })
  })

  /*Electric*/
  location.state.electric.data?.forEach((element) => {
    var v = element.value
    var y = capitalize(element.y_index)
    electric_columns.push(y)
    electric_data[0].push(v.toFixed(2))
  })

  location.state.electricgenerationcosts.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!electric_generation_costs_columns.includes(y)){
      electric_generation_costs_columns.push(y)
      electric_generation_costs_data[0].push(element.value.toFixed(2))
    }
  })

  location.state.electriccapacity.data?.forEach((element) => {
    var v = element.value
    var y = capitalize(element.y_index)
    electric_capacity_columns.push(y)
    electric_capacity_data[0].push(v.toFixed(2))
  })

  location.state.electricgeneration.data?.forEach((element) => {
    var y= capitalize(element.y_index)
    if (!electric_generation_columns.includes(y)){
      electric_generation_columns.push(y)
    }
  })
  location.state.electricgeneration.data?.forEach((element) => {
    var new_array=[element.x_index].concat(Array(electric_generation_columns.length-1).fill(0))
    var flag= true
    electric_generation_data.forEach((element2) => {
      if (element.x_index === element2[0]){
        flag = false
      }
    })
    if (flag){
      electric_generation_data.push(new_array)
    }
  })
  location.state.electricgeneration.data?.forEach((element,index) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    electric_generation_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        electric_generation_data[index2][electric_generation_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.electrictotal.data?.forEach((element,index) => {
    var y= capitalize(element.y_index)
    if (!electric_total_columns.includes(y)){
      electric_total_columns.push(y)
    }
  })
  location.state.electrictotal.data?.forEach((element,index) => {
    var new_array=[element.x_index].concat(Array(electric_total_columns.length-1).fill(0))
    var flag= true
    electric_total_data.forEach((element2,index) => {
      if (element.x_index === element2[0]){
        flag = false
      }
    })
    if (flag){
      electric_total_data.push(new_array)
    }
  })
  location.state.electrictotal.data?.forEach((element,index) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    electric_total_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        electric_total_data[index2][electric_total_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })
  

  /*Emissions*/
  location.state.directemissions.data?.forEach((element) =>{
    direct_emissions_data.push(capitalize(element.x_index))
    direct_emissions_columns.push(element.value.toFixed(2))
  })

  location.state.emissionsfactor.data?.forEach((element) =>{
    emissions_factors_data.push(capitalize(element.y_index))
    emissions_factor_columns.push(element.value.toFixed(2))
  })

  location.state.electricitydestiny.data?.forEach((element) => {
    var y= element.y_index
    var x= element.x_index
    if (!electricity_destiny_columns.includes(y)){
      electricity_destiny_columns.push(y)
    }
    var flag= true
    electricity_destiny_data.forEach((element2) => {
      if (element2.name === x){
        flag= false
      }
    })
    if(flag && x!== "Total"){
      electricity_destiny_data.push({name: x, data: []})
    }
  })
  location.state.electricitydestiny.data?.forEach((element, index) => {
    var v = element.value
    var x = element.x_index
    electricity_destiny_data.forEach((element2, index2) => {
      if(x=== element2.name){
        element2.data.push(v.toFixed(2))
      }
    })
  })
  electricity_destiny_data?.forEach((element) => {
    electricity_destiny_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name]), maxBarThickness: 48})
  })

  location.state.carbonfootprint.data?.forEach((element) => {
    var y= element.y_index
    var x= element.x_index
    if (!carbon_footprint_columns.includes(y)){
      carbon_footprint_columns.push(y)
    }
    var flag= true
    carbon_footprint_data.forEach((element2) => {
      if (element2.name === x){
        flag= false
      }
    })
    if(flag){
      carbon_footprint_data.push({name: x, data: []})
    }
  })
  location.state.carbonfootprint.data?.forEach((element) => {
    var v = element.value
    var x = element.x_index
    carbon_footprint_data.forEach((element2) => {
      if(x=== element2.name){
        element2.data.push(v.toFixed(2))
      }
    })
  })
  carbon_footprint_data?.forEach((element) => {
    carbon_footprint_datasets.push({label: element.name, data: element.data, backgroundColor: graphColors([element.name]), maxBarThickness: 48})
  })

  location.state.importedcarbon.data?.forEach((element) =>{
    imported_carbon_data.push(capitalize(element.x_index))
    imported_carbon_columns.push(element.value.toFixed(2))
  })



  function GraphicDisplay(){
    if(appState.activeObject === 1 && appState.activeGraphic === 1){
      return(
        <div className='mt-3' style={{maxWidth: "1600px"}}><MUIDataTable
        title={location.state.miporiginal.title}
        data={miporiginal_data}
        columns={miporiginal_columns}
        options={options_type(user_type)}
        className="custom-table"
        ></MUIDataTable></div>
      )
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 1){
      return(<div className='mt-3'>
          <MUIDataTable
          title={location.state.electric.title}
          data={electric_data}
          columns={electric_columns}
          options={options_type(user_type)}
          ></MUIDataTable>
        </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 2){
      return(<div className='mt-3'>
          <MUIDataTable
          title={location.state.electricgenerationcosts.title}
          data={electric_generation_costs_data}
          columns={electric_generation_costs_columns}
          options={options_type(user_type)}
          ></MUIDataTable>
        </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 3){
      return(<div className='mt-3'>
        <MUIDataTable
        title={location.state.electriccapacity.title}
        data={electric_capacity_data}
        columns={electric_capacity_columns}
        options={options_type(user_type)}
        ></MUIDataTable>
        </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 4){
      return(<div style={{maxWidth: "1600px"}}>
        <MUIDataTable
        title={location.state.electricgeneration.title}
        data={electric_generation_data}
        columns={electric_generation_columns}
        options={options_type2(user_type)}
        ></MUIDataTable>
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 5){
      return(<MUIDataTable
        title={location.state.electrictotal.title}
        data={electric_total_data}
        columns={electric_total_columns}
        options={options_type(user_type)}
        ></MUIDataTable>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 6){
      var em_col = []
      electric_columns.forEach((element) => {
        if (element && element !== "Total generación" && element !== "Total almacenamiento" && element !== "H2"){
          em_col.push(element)
        }
      })
      var em_data = []
      electric_data[0].forEach((element,index) => {
        if (index !== 0 && electric_columns[index] !== "Total generación" && electric_columns[index] !== "Total almacenamiento" && electric_columns[index] !== "H2"){
          em_data.push(element)
        }
      })
      const ChartData = {
        labels: em_col,
        datasets: [{
          label: "Generación [GWh]",
          data: em_data,
          backgroundColor: graphColors(em_col),
          borderWidth: 0
        }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Generación [GWh]"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.electric.title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}
        />
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 7){
      var em_col = []
      electric_generation_costs_columns.forEach((element) => {
        if (element !== ""){
          em_col.push(element)
        }
      })
      var em_data = []
      electric_generation_costs_data[0].forEach((element,index) => {
        if (index !== 0){
          em_data.push(element)
        }
      })
      const ChartData = {
        labels: em_col,
        datasets: [{
          label: location.state.electricgenerationcosts.data[0].x_index,
          data: em_data,
          backgroundColor: graphColors(em_col),
          borderWidth: 0
        }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: location.state.electricgenerationcosts.data[0].x_index}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.electricgenerationcosts.title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}
        />
      </div>)
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 8){
      var em_col = []
      electric_capacity_columns.forEach((element) => {
        if (element && element !== "Total capacidad generación" && element !=="Total capacidad almacenamiento"){
          em_col.push(element)
        }
      })
      var em_data = []
      electric_capacity_data[0].forEach((element,index) => {
        if (index !== 0 && electric_capacity_columns[index] !== "Total capacidad generación" && electric_capacity_columns[index] !== "Total capacidad almacenamiento"){
          em_data.push(element)
        }
      })
      const ChartData = {
        labels: em_col,
        datasets: [{
          label: "Capacidad [MW]",
          data: em_data,
          backgroundColor: graphColors(em_col),
          borderWidth: 0
        }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Capacidad [MW]"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.electriccapacity.title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 1){
      return(<div className='mt-3'> 
        <MUIDataTable
        title={location.state.employment.title}
        data={employment_data}
        columns={employment_columns}
        options={options_type(user_type)}
        ></MUIDataTable>
      </div>)
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 2){
      var em_col= employment_columns.slice(1)
      var em_data = employment_data[0].slice(1)
      const ChartData = {
        labels: em_col,
        datasets: [{
          label: "N° de Empleados",
          data: em_data,
          backgroundColor: graphColors(em_col),
          borderWidth: 0
        }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "N° de Empleados"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: "N° de Empleados por tecnología",
            align: "center",
            }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 3){
      var em_col= employment_columns.slice(1)
      var em_data = employment_data[1].slice(1)
      const ChartData = {
        labels: em_col,
        datasets: [{
          label: "Remuneraciones" + coins[values.country],
          data: em_data,
          backgroundColor: graphColors(em_col),
          borderWidth: 0
        }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Remuneraciones" + coins[values.country]}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: "Remuneraciones pagadas por tecnologías",
            align: "center",
            }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 1){
      const ChartData = {
        labels: direct_emissions_data,
        datasets: [{
          label: "Emisiones directas [Ton CO2eq/" + coins[values.country] + "]",
          data: direct_emissions_columns,
          backgroundColor: graphColors(direct_emissions_data),
          borderWidth: 0
        }]
      }
      const config = {
        indexAxis: 'x',
        scales: {
          x: {title:{display: true, text: "Tecnologías"}},
          y: {title:{display: true, text: "Emisiones directas [Ton CO2eq/" + coins[values.country] + "]"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: "Emisiones directas por tecnologías",
            align: "center",
            }
        }
      };
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={config}/>
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 2){
      const ChartData = {
        labels: emissions_factors_data,
        datasets: [{
          label: "Emisiones de CO2 por energía producida [Ton CO2/ GWh]",
          data: emissions_factor_columns,
          backgroundColor: graphColors(emissions_factors_data),
          borderWidth: 0
        }]
      }
      const config = {
        indexAxis: 'x',
        scales: {
          x: {title:{display: true, text: "Tecnologías", color: "black"}},
          y: {title:{display: true, text: "Emisiones de CO2 por energía producida [Ton CO2/ GWh]", color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: "Emisiones de CO2 por energía producida para diferentes tecnologías",
            align: "center",
            color: "black"
            }
        }
      };
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={config}
        />
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 3){
      const ChartData2 = {
        labels: electricity_destiny_columns,
        datasets: electricity_destiny_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: "Destino de electricidad",
          align: "center",
          },
          
      },
      indexAxis: 'y',
          scales: {
              x: {title: {display: "true", text: "Porcentaje de participación", color:"black"}, stacked: true, max: 100},
              y: {stacked: true}
          }};
      return(
      <div style={{width: "55vw"}}>
        {HorizontalTables(ChartData2, options)}
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 4){
      const ChartData2 = {
        labels: carbon_footprint_columns,
        datasets: carbon_footprint_datasets
      };
      const options = {
        plugins:{
          title: {
          display: true,
          text: location.state.carbonfootprint.title,
          align: "center",
          },
          
      },
      indexAxis: 'y',
          scales: {
              x: {title: {display: "true", text: "Huella de carbono [MTon CO2 eq.]", color:"black"}, stacked: true},
              y: {stacked: true}
          }};
      return(
      <div style={{width: "55vw"}}>
        {HorizontalTables(ChartData2, options)}
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 5){
      const ChartData = {
        labels: imported_carbon_data,
        datasets: [{
          label: "Huella de carbono importada [Mt CO2 eq]",
          data: imported_carbon_columns,
          backgroundColor: graphColors(imported_carbon_data),
          borderWidth: 0
        }]
      }
      const config = {
        indexAxis: 'x',
        scales: {
          x: {title:{display: true, text: "Actividad económica"}},
          y: {title:{display: true, text: "Huella de carbono importada [Mt CO2 eq]"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.importedcarbon.title,
            align: "center",

            }
        }
      };
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={config}/>
      </div>)
    }
    else{
      return(<div></div>)
    }
  }


  function CSVData(){
    if(appState.activeObject === 1 && appState.activeGraphic === 1){
      var array_data = []
      miporiginal_data.forEach((element,index) =>{
        miporiginal_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      //const new_array = [miporiginal_columns].concat(miporiginal_data)
      return([['Actividades económicas X','Actividades económicas Y',coins[values.country]]].concat(array_data))
    }
    else if((appState.activeObject === 2 && appState.activeGraphic === 1) || (appState.activeObject === 2 && appState.activeGraphic === 6)){
      var array_data = []
      electric_data.forEach((element) =>{
        electric_columns.forEach((element2,index2)=>{
          if(index2 !== 0){array_data.push([element2,element[index2]])}
        })
      })
      return([['Tecnologías',electric_data[0][0]]].concat(array_data))
    }
    else if((appState.activeObject === 2 && appState.activeGraphic === 2) || (appState.activeObject === 2 && appState.activeGraphic === 7)){
      var array_data = []
      electric_generation_costs_data.forEach((element) =>{
        electric_generation_costs_columns.forEach((element2,index2)=>{
          if(index2 !== 0){array_data.push([element2,element[index2]])}
        })
      })
      return([['Tecnologías',electric_generation_costs_data[0][0]]].concat(array_data))
    }
    else if((appState.activeObject === 2 && appState.activeGraphic === 3) || (appState.activeObject === 2 && appState.activeGraphic === 8)){
      var array_data = []
      electric_capacity_data.forEach((element) =>{
        electric_capacity_columns.forEach((element2,index2)=>{
          if(index2 !== 0){array_data.push([element2,element[index2]])}
        })
      })
      return([['Tecnologías',electric_capacity_data[0][0]]].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 4){
      var array_data = []
      electric_generation_data.forEach((element) =>{
        electric_generation_columns.forEach((element2,index2)=>{
          if(index2 !== 0){array_data.push([element[0],element2,element[index2]])}
        })
      })
      return([['Características','Tecnologías','Valor']].concat(array_data))
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 5){
      var array_data = []
      electric_total_data.forEach((element) =>{
        electric_total_columns.forEach((element2,index2)=>{
          if(index2 !== 0){array_data.push([element[0],element2,element[index2]])}
        })
      })
      return([['Cantidades','Tecnologías','Valor']].concat(array_data))
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 1){
      var array_data = []
      employment_data.forEach((element) =>{
        employment_columns.forEach((element2,index2)=>{
          if(index2 !== 0){array_data.push([element[0],element2,element[index2]])}
        })
      })
      return([['Datos','Tecnologías','Valor']].concat(array_data))
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 2){
      var array_data = []
      employment_data.forEach((element,index) =>{
        if(index === 0){
          employment_columns.forEach((element2,index2)=>{
            if(index2 !== 0){array_data.push([element2,element[index2]])}
          })
        }
      })
      return([['Tecnologías',employment_data[0][0]]].concat(array_data))
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 3){
      var array_data = []
      employment_data.forEach((element,index) =>{
        if(index === 1){
          employment_columns.forEach((element2,index2)=>{
            if(index2 !== 0){array_data.push([element2,element[index2]])}
          })
        }
      })
      return([['Tecnologías',employment_data[1][0]]].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 1){
      var array_data = []
      direct_emissions_columns.forEach((element,index) =>{
        array_data.push([direct_emissions_data[index],element])
      })
      return([['Tecnologías',"Emisiones directas [Ton CO2eq/"+coins[values.country]+"]",]].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 2){
      var array_data = []
      emissions_factor_columns.forEach((element,index) =>{
        array_data.push([emissions_factors_data[index],element])
      })
      return([['Tecnologías',"Emisiones de CO2 por energía producida [Ton CO2/GWh]",]].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 3){
      var array_data = []
      electricity_destiny_columns.forEach((element, index) =>{
        electricity_destiny_data.forEach((element2)=>{
          array_data.push([element,element2.name,element2.data[index]])
        })
      })
      return([['Destino de electricidad','Tecnologías','Porcentaje de destino']].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 4){
      var array_data = []
      carbon_footprint_columns.forEach((element, index) =>{
        carbon_footprint_data.forEach((element2)=>{
          array_data.push([element,element2.name,element2.data[index]])
        })
      })
      return([['Sector de origen de emisión','Tipo de emisión','[MT CO2 eq]']].concat(array_data))
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 5){
      var array_data = []
      imported_carbon_columns.forEach((element,index) =>{
        array_data.push([imported_carbon_data[index],element])
      })
      return([['Actividad económica',"Huella de carbono importada [Mt CO2 eq]",]].concat(array_data))
    }
    else{
      return([[""]])
    }
  }



  function CSVTitle(){
    if(appState.activeObject === 1 && appState.activeGraphic === 1){
      return(location.state.miporiginal.title+".csv")
    }
    else if((appState.activeObject === 2 && appState.activeGraphic === 1) || (appState.activeObject === 2 && appState.activeGraphic === 6)){
      return(location.state.electric.title+".csv")
    }
    else if((appState.activeObject === 2 && appState.activeGraphic === 2) || (appState.activeObject === 2 && appState.activeGraphic === 7)){
      return(location.state.electricgenerationcosts.title+".csv")
    }
    else if((appState.activeObject === 2 && appState.activeGraphic === 3) || (appState.activeObject === 2 && appState.activeGraphic === 8)){
      return(location.state.electriccapacity.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 4){
      return(location.state.electricgeneration.title+".csv")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 5){
      return(location.state.electrictotal.title+".csv")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 1){
      return(location.state.employment.title+".csv")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 2){
      return(employment_data[0][0] + " " + capitalize(values.country) + " " + values.year +".csv")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 3){
      return(employment_data[1][0] + " " + capitalize(values.country) + " " + values.year +".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 1){
      return("Emisiones directas por tecnologías " + capitalize(values.country) + " " + values.year +".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 2){
      return("Emisiones de CO2 por energía producida para diferentes tecnologías " + capitalize(values.country) + " " + values.year +".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 3){
      return("Destino de electricidad " + capitalize(values.country) + " " + values.year +".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 4){
      return(location.state.carbonfootprint.title +".csv")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 5){
      return(location.state.importedcarbon.title +".csv")
    }
    else{
      return("")
    }
  }

  function ButtonsDisplay(){
    if(appState.activeObject === 1){
      return(
          <div className="ml-auto"> 
            <button onClick={() => {toggleActive(null, 0)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid"}}>MIP Resumida</button>
        </div>
        
      )
    }
    else if(appState.activeObject === 2){
      return(
        <div className="grid grid-cols-4 ml-auto"> 
            <button onClick={() => {toggleActive(null, 0)}} className={`max-w-28 h-14 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px"}}>Tabla Generación Eléctrica</button>
            <button onClick={() => {toggleActive(null, 1)}} className={`max-w-28 h-14 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px"}}>Tabla Generación Eléctrica Costos</button>
            <button onClick={() => {toggleActive(null, 2)}} className={`max-w-28 h-14 m-1 ${toggleActiveStylesGraph(2)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize:"12px"}}>Tabla Capacidad Eléctrica</button>
            <button onClick={() => {toggleActive(null, 3)}} className={`max-w-28 h-14 m-1 ${toggleActiveStylesGraph(3)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Características Generación</button>
            <button onClick={() => {toggleActive(null, 4)}} className={`max-w-28 h-14 m-1 ${toggleActiveStylesGraph(4)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Costos totales y porcentajes</button>
            <button onClick={() => {toggleActive(null, 5)}} className={`max-w-28 h-14 m-1 ${toggleActiveStylesGraph(5)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Gráfico Generación Eléctrica</button>
            <button onClick={() => {toggleActive(null, 6)}} className={`max-w-28 h-14 m-1 ${toggleActiveStylesGraph(6)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Gráfico Generación Eléctrica Costos</button>
            <button onClick={() => {toggleActive(null, 7)}} className={`max-w-28 h-14 m-1 ${toggleActiveStylesGraph(7)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Gráfico Capacidad Eléctrica</button>
        </div>
      )
    }
    else if(appState.activeObject === 3){
      return(
        <div className='flex ml-auto'>
          <button onClick={() => {toggleActive(null, 0)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "16px"}}>Datos de Empleo</button>
          <button onClick={() => {toggleActive(null, 1)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "16px"}}>N° de Empleados</button>
          <button onClick={() => {toggleActive(null, 2)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(2)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "14px"}}>Remuneraciones pagadas</button>
        </div>
      )
    }
    else if(appState.activeObject === 4){
      return(
        <div className='flex ml-auto'>
          <button onClick={() => {toggleActive(null, 0)}} className={`w-20 h-14 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Emisiones Directas</button>
          <button onClick={() => {toggleActive(null, 1)}} className={`w-20 h-14 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Factores de Emisión</button>
          <button onClick={() => {toggleActive(null, 2)}} className={`w-20 h-14 m-1 ${toggleActiveStylesGraph(2)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Destino Electricidad</button>
          <button onClick={() => {toggleActive(null, 3)}} className={`w-20 h-14 m-1 ${toggleActiveStylesGraph(3)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Huella Demanda</button>
          <button onClick={() => {toggleActive(null, 4)}} className={`w-20 h-14 m-1 ${toggleActiveStylesGraph(4)}`} style={{ borderRadius:"10px", border: "1px solid", fontSize: "12px"}}>Huella Importada</button>
        </div>
      )
    }
    else{
      return(<div></div>)
    }
  }

  function TextDisplay(){
    if(appState.activeObject === 1 && appState.activeGraphic === 1){
      return("MIP Resumida: La tabla muestra la MIP o Matriz Insumo Producto del año y país ingresado desarrollada por el banco central. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores corresponden a la moneda nacional medida en " + coins[values.country] + ". Fuente: "+location.state.miporiginal.source + ".")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 1){
      return("Tabla Generación Eléctrica: La tabla muestra los datos sobre la generación eléctrica medidas en GW por hora para el año y país seleccionados. Las columnas corresponden a los tipos de tecnologías y los valores de almacenamiento de corto y largo plazo junto al total de estos. Fuente: "+location.state.electric.source + ".")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 2){
      return("Tabla Generación Eléctrica Costos: La tabla muestra los datos sobre la generación electrica para el año base considerada para obtener la desagregación de costos medidas en GW por hora para el año y país seleccionados. Las columnas corresponden a los tipos de tecnologías. Fuente: "+location.state.electric.source + ".")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 3){
      return("Tabla Capacidad Eléctrica: La tabla muestra los datos sobre la capacidad eléctrica instalada medidas en MW para el año y país seleccionados. Las columnas corresponden a los tipos de tecnologías y los valores de almacenamiento de corto y largo plazo junto al total de estos. Fuente: "+location.state.electriccapacity.source + ".")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 4){
      return("Características Generación: La tabla muestra las características de las tecnologías específicas consideradas para realizar la desagregación del sector eléctrico del año y país seleccionados. Las columnas corresponden a las tecnologías, mientras que las filas corresponden a las características de costos y/o precios, cada una con diferentes unidades de medidas. Fuente: "+location.state.electricgeneration.source + ".")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 5){
      return("Costos totales y porcentajes: La tabla muestra las cantidades, costos de los insumos y la participación de los costos de insumos para diferentes tecnologías consideradas para realizar la desagregación del sector eléctrico para el año y país seleccionados. Las columnas corresponden a las tecnologías, mientras que las filas corresponden a las cantidades, costos y participaciones, cada una con diferentes unidades de medidas. Fuente: "+location.state.electrictotal.source + ".")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 6){
      return("Gráfico Generación Eléctrica: El gráfico muestra la generación eléctrica medidas en GW por hora para los diferentes tipos de tecnología junto al almacenamiento corto y largo plazo para el año y país seleccionados. Fuente: "+location.state.electric.source + ".")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 7){
      return("Gráfico Generación Eléctrica Costos: El gráfico muestra la generación electrica para el año base considerada para obtener la desagregación de costos medidas en GW por hora para los diferentes tipos de tecnologías. Fuente: "+location.state.electric.source + ".")
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 8){
      return("Gráfico Capacidad Eléctrica: El gráfico muestra la generación eléctrica medidas en GW por hora para los diferentes tipos de tecnología junto al almacenamiento corto y largo plazo para el año y país seleccionados. Fuente: "+location.state.electriccapacity.source + ".")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 1){
      return("Datos de Empleo: La tabla muestra los datos de empleo utilizados para calcular los multiplicadores e indicadores relacionados con el empleo para el país y año ingresados. Las columnas de la tabla corresponden a los tipos de tecnologías, mientras que las filas corresponden al número de empleados y las remuneraciones pagadas medidas en " + coins[values.country] + ". Fuente: "+ location.state.employment.source +".")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 2){
      return("N° de Empleados: El gráfico muestra el número de empleados existentes para cada tecnología del año y país ingresados. Fuente: "+ location.state.employment.source +".")
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 3){
      return("Remuneraciones pagadas: El gráfico muestra el total de remuneraciones pagadas a los empleados medida en " + coins[values.country] + " para cada tecnología del año y país ingresados. Fuente: "+ location.state.employment.source +".")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 1){
      return("Emisiones Directas: El gráfico indica las emisiones en toneladas de CO2 equivalentes generadas por " + coins[values.country] + " de producción para diferentes tipos de tecnologías. Fuente: "+location.state.directemissions.source+".")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 2){
      return("Factores de Emisión: El gráfico muestra los factores de emisiones del año y país seleccionados, es decir, las emisiones de CO2 generadas medidas en toneladas por GWh de energía producida para las tecnologías de generación. Fuente: "+location.state.emissionsfactor.source+ ".")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 3){
      return("Destino Electricidad: El gráfico muestra el destino de la energía por tipo de tecnología de generación del año y país seleccionados. Cada fila del gráfico corresponde a una tecnología de generación y sus valores corresponden al porcentaje de destino eléctrico para cada área, siendo la suma de todos estos un 100%. Fuente: "+location.state.electricitydestiny.source+".")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 4){
      return("Huella Demanda: El gráfico muestra la huella de carbono por demanda final para el año y país seleccionado. Cada fila del gráfico corresponde a un sector de origen de emisión de CO2, mientras que sus valores corresponden a la cantidad total de emisiones medidas en Mega Toneladas de CO2 equivalente para diferentes demandas. Fuente: "+location.state.carbonfootprint.source+".")
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 5){
      return("Huella Importada: El gráfico muestra la huella de carbono importada y el destino de la actividad económica para el año y país seleccionado. Cada columna del gráfico corresponde a una actividad económica, mientras que sus valores corresponden a la huella de carbono importada medida en mega toneladas de CO2 equivalente. Fuente: "+location.state.importedcarbon.source+ ".")
    }
    else{
      return("Selecciona uno de los 4 indicadores de la zona superior (MIP, Sector Eléctrico, Empleo y Emisiones). Posteriormente selecciona uno de los módulos disponibles en la zona derecha para poder ver los gráficos o tablas del año y país ingresado de la base de datos de EcoKinesis.")
    }

  }

  function TitleDisplay(){
    if (appState.activeObject === 1){
      return("MIP Expandida " + capitalize(location.state.values.country) + " Línea Base")
    }
    else if (appState.activeObject === 2){
      return("Parámetros del sector eléctrico " + capitalize(location.state.values.country) + " Línea Base")
    }
    else if (appState.activeObject === 3){
      return("Parámetros de empleo " + capitalize(location.state.values.country) + " Línea Base")
    }
    if (appState.activeObject === 4){
      return("Parámetros de emisiones " + capitalize(location.state.values.country) + " Línea Base")
    }
    else{
      return("Base de datos " + capitalize(location.state.values.country) + " Línea Base")
    }
  }
 
  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex'> 
        <div className='w-3/5 h-14 grid grid-cols-4 gap-x-3 p-2' style={{backgroundColor: "white", border: "1px solid", borderRadius:"10px", borderColor: "gray"}}>
          <button onClick={() => {toggleActive(0)}} className={`max-h-10 max-w-36 rounded-lg ${toggleActiveStyles(0)}`}>MIP</button>
          <button onClick={() => {toggleActive(1)}} className={`max-h-10 max-w-36 rounded-lg ${toggleActiveStyles(1)}`}>Sector Eléctrico</button>
          <button onClick={() => {toggleActive(2)}} className={`max-h-10 max-w-36 rounded-lg ${toggleActiveStyles(2)}`}>Empleo</button>
          <button onClick={() => {toggleActive(3)}} className={`max-h-10 max-w-36 rounded-lg ${toggleActiveStyles(3)}`}>Emisiones</button>
        </div>
        <div className='grid grid-cols-5 ml-auto w-1/4 gap-x-4'>

          <div className='justify-center items-center text-center ' style={{fontSize: "10px"}} ><button onClick={() => {setIsModalOpen(true)}}><img src={expand} className='w-10 h-10' alt='' title='Pulsa para abrir la tabla o gráfico actual en pantalla completa'/>Expandir pantalla</button></div>

          <div className='justify-center items-center text-center ' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/database/database_reports", {state: {values, miporiginal, employment, electric, electricgenerationcosts, electriccapacity, electricgeneration, electrictotal, directemissions, emissionsfactor, electricitydestiny, carbonfootprint, importedcarbon, miporiginal_columns, miporiginal_data, employment_columns, employment_data, electric_columns, electric_data, electric_capacity_columns, electric_capacity_data, electric_generation_costs_columns, electric_generation_costs_data, electric_generation_columns, electric_generation_data, electric_total_columns, electric_total_data, direct_emissions_columns, direct_emissions_data, emissions_factors_data, emissions_factor_columns, electricity_destiny_columns, electricity_destiny_datasets, carbon_footprint_columns, carbon_footprint_datasets, imported_carbon_columns, imported_carbon_data, coins}})}} title='Pulsa para ir al menú de guardado y descarga de reportes. Esta opción sólo está disponible para usuarios premium'><img src={save} className='w-10 h-10' alt=''/>Guardar reporte</button></div>

          <div className='justify-center items-center text-center ' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/database/database_reports", {state: {values, miporiginal, employment, electric, electricgenerationcosts, electriccapacity, electricgeneration, electrictotal, directemissions, emissionsfactor, electricitydestiny, carbonfootprint, importedcarbon, miporiginal_columns, miporiginal_data, employment_columns, employment_data, electric_columns, electric_data, electric_capacity_columns, electric_capacity_data, electric_generation_costs_columns, electric_generation_costs_data, electric_generation_columns, electric_generation_data, electric_total_columns, electric_total_data, direct_emissions_columns, direct_emissions_data, emissions_factors_data, emissions_factor_columns, electricity_destiny_columns, electricity_destiny_datasets, carbon_footprint_columns, carbon_footprint_datasets, imported_carbon_columns, imported_carbon_data, coins}})}} title='Pulsa para ir al menú de guardado y descarga de reportes. Esta opción sólo está disponible para usuarios premium'><img src={download}  className='w-10 h-10' alt=''/>Descargar reporte</button></div>

          {CSVRender(user_type)}

          <div className='justify-center items-center text-center ' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/database")}} title='Pulsa para volver a la pantalla de selección de datos'><img src={edit} className='w-10 h-10' alt=''/>Editar variables</button></div>

        </div>
      </div>
      <div className='mt-2 mb-1'><p className='text-3xl font-extrabold tracking-tight text-slate-900'>{TitleDisplay()}</p></div>
      <div className='flex'>
        <h4 className='font-bold text-gray-400 w-3/5'>{TextDisplay()}</h4>
        <div className="ml-auto">
          {ButtonsDisplay()}
        </div>
      </div>
      <div className='mt-3 mb-12' style={{maxWidth:"1460px", maxHeight: "490px"}}>
          {GraphicDisplay()}
      </div>
      <FullScreen isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} item={GraphicDisplay()}/>
    </div>
  )
}

export default ResultsDatabase