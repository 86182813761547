import {React, useState, useEffect, useContext} from 'react'
import { Link } from 'react-router-dom';
import { Header, Button } from '../../../../components';
import capitalize from '../../../../hook/capitalize';
import { API_PORT } from '../../../../API_PORT';
import { UserContext } from '../../../../contexts/UserContext';
import MUIDataTable from 'mui-datatables';
import LoadingScreen from '../../../../components/LoadingScreen';

const AvailableMIP = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;

  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  const mip_columns = ["Año","País"]
  var mip_data= [];
  var mipess_data = []

  const options={
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15,20,25,30],
    selectableRows: "none",
    tableBodyMaxHeight: "400px",
    print: "false",
    download: "false",
    filter: "false",
    viewColumns: "false",
    responsive: "vertical"
  }


  const [mips, setMips] = useState([])

  const link= API_PORT + "/admin/database_information/get-avaible-MIPs?token="+token

  useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setMips(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      })
    }, []);

  var mip_values = mips.MIPbase
  var ess_values = mips.MIP_ESSH2

  mip_values?.forEach((element) =>
    {mip_data.push([element[0],capitalize(element[1])])
      }
  )

  ess_values?.forEach((element) =>
   {mipess_data.push([element[0],capitalize(element[1])])}
  )
  

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-10'>
            <Link to="/dashboard/adminbd" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  
      <Header title="MIPs disponibles"/>
      <div className='grid grid-cols-2 gap-2'>
          <div style={{maxHeight:"1/3"}}>
            <MUIDataTable
            title="Lista de MIP bases"
            data={mip_data}
            columns={mip_columns}
            options={options}
            ></MUIDataTable>
          </div>
          <div style={{maxHeight:"1/3"}}>
            <MUIDataTable
            title="Lista de MIP ESS-H2"
            data={mipess_data}
            columns={mip_columns}
            options={options}
            ></MUIDataTable>
          </div>
      </div>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default AvailableMIP