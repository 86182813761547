import {React, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import FullScreen from '../../../../components/FullScreen';
import capitalize from '../../../../hook/capitalize';
import filedownload from "../../../../assets/file.svg"
import download from "../../../../assets/download_black.svg"
import expand from "../../../../assets/expand.svg"
import edit from "../../../../assets/edit.svg"
import save from "../../../../assets/save_black.svg"
import { CSVLink } from 'react-csv';
import MUIDataTable from "mui-datatables";
import { useStateContext } from '../../../../contexts/ContextProvider';

const DirectDemandSetResults = () => {
  const {activeMenu} = useStateContext();
  const maxWidth = activeMenu ? '1080px' : '100%';
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const values = location.state.values
  const technologies = location.state.technologies
  const directdemandset = location.state.directdemandset
  const coins = location.state.coins

  const options={
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,15,20,25,30],
    selectableRows: "none",
    tableBodyMaxHeight: "400px",
    print: "false",
    download: "false",
    filter: "false",
    responsive: "standard"
  }

  //Indicators and graphic-table options
  const [appState, changeState] = useState({
    activeObject: null,
    activeGraphic: null,
    objects: [1,2,3],
  })

  function toggleActive(index){
    if (index === 0 || index === 1 || index === 2){
      changeState({...appState, activeObject: appState.objects[index]})
    }
    

  }
  function toggleActiveStyles(index){
    if(appState.objects[index] === appState.activeObject){
      return "box-active"
    }
    else {
      return "box-inactive"
    }
  }

  //Colums and data
  var MIP_base_year_columns = [""]
  var MIP_base_year_data = []
  var MIP_scenario_columns = [""]
  var MIP_scenario_data = []
  var MIP_Growth_rates_columns = [""]
  var MIP_Growth_rates_data = []
  var array_data;

  //Set the data on arrays
  location.state.directdemandset.MIP_base_year.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!MIP_base_year_columns.includes(y)){
      MIP_base_year_columns.push(capitalize(y));
    }
  })
  location.state.directdemandset.MIP_base_year.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var new_array= [x].concat(Array(MIP_base_year_columns.length-1).fill(null))
    var flag = true
    MIP_base_year_data.forEach((element2) => {
      if (element2[0] === x){
        flag=false
      }
    })
    if (flag){
      MIP_base_year_data.push(new_array)
    }
  })
  location.state.directdemandset.MIP_base_year.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    MIP_base_year_data.forEach((element2,index2) =>{
      if (x === element2[0] && v!== null){
        MIP_base_year_data[index2][MIP_base_year_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })


  location.state.directdemandset.MIP_scenario.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!MIP_scenario_columns.includes(y)){
      MIP_scenario_columns.push(capitalize(y));
    }
  })
  location.state.directdemandset.MIP_scenario.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var new_array= [x].concat(Array(MIP_scenario_columns.length-1).fill(null))
    var flag = true
    MIP_scenario_data.forEach((element2) => {
      if (element2[0] === x){
        flag=false
      }
    })
    if (flag){
      MIP_scenario_data.push(new_array)
    }
  })
  location.state.directdemandset.MIP_scenario.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    MIP_scenario_data.forEach((element2,index2) =>{
      if (x === element2[0] && v!== null){
        MIP_scenario_data[index2][MIP_scenario_columns.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.directdemandset.MIP_Growth_rates.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!MIP_Growth_rates_columns.includes(y)){
      MIP_Growth_rates_columns.push(capitalize(y));
    }
  })
  location.state.directdemandset.MIP_Growth_rates.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var new_array= [x].concat(Array(MIP_Growth_rates_columns.length-1).fill(null))
    var flag = true
    MIP_Growth_rates_data.forEach((element2) => {
      if (element2[0] === x){
        flag=false
      }
    })
    if (flag){
      MIP_Growth_rates_data.push(new_array)
    }
  })
  location.state.directdemandset.MIP_Growth_rates.data?.forEach((element) => {
    var x = capitalize(element.x_index)
    var v= element.value
    var y= capitalize(element.y_index)
    MIP_Growth_rates_data.forEach((element2,index2) =>{
      if (x === element2[0] && v!== null){
        MIP_Growth_rates_data[index2][MIP_Growth_rates_columns.indexOf(y)] = v.toFixed(5)
      }
    })
  })


 


  //Display graphics and tables function
  function GraphicDisplay(){
    if(appState.activeObject === 1){
      return(<div className='mt-3 flex justify-center items-center'>
        <div style={{ maxWidth }}>
        <MUIDataTable
        title={location.state.directdemandset.MIP_base_year.title}
        data={MIP_base_year_data}
        columns={MIP_base_year_columns}
        options={options}
        ></MUIDataTable></div></div>
      )
    }
    else if(appState.activeObject === 2){
      return(<div className='mt-3 flex justify-center items-center'>
        <div style={{ maxWidth }}>
        <MUIDataTable
        title={location.state.directdemandset.MIP_scenario.title}
        data={MIP_scenario_data}
        columns={MIP_scenario_columns}
        options={options}
        ></MUIDataTable></div></div>
      )
    }
    else if(appState.activeObject === 3){
      return(<div className='mt-3 flex justify-center items-center'>
        <div style={{ maxWidth }}>
        <MUIDataTable
        title={location.state.directdemandset.MIP_Growth_rates.title}
        data={MIP_Growth_rates_data}
        columns={MIP_Growth_rates_columns}
        options={options}
        ></MUIDataTable></div></div>
      )
    }
    else{
      return(<div></div>)
    }
  }

  function CSVData(){
    if(appState.activeObject === 1 ){
      array_data = []
      MIP_base_year_data.forEach((element) =>{
        MIP_base_year_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Actividad económica X','Actividad económica Y','['+location.state.technologies.Unities+']']].concat(array_data))
    }
    else if(appState.activeObject === 2){
      array_data = []
      MIP_scenario_data.forEach((element) =>{
        MIP_scenario_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Actividad económica X','Actividad económica Y','['+location.state.technologies.Unities+']']].concat(array_data))
    }
    else if(appState.activeObject === 3 ){
      array_data = []
      MIP_Growth_rates_data.forEach((element) =>{
        MIP_Growth_rates_columns.forEach((element2,index2)=>{
          if(index2 !== 0){
            array_data.push([element[0],element2,element[index2]])
          }
        })
      })
      return([['Actividad económica X','Actividad económica Y','Variación porcentual '+location.state.technologies.Unities]].concat(array_data))
    }
    else{
      return([[""]])
    }
  }



  function CSVTitle(){
    if(appState.activeObject === 1){
      return(location.state.directdemandset.MIP_base_year.title+".csv")
    }
    else if(appState.activeObject === 2 ){
      return(location.state.directdemandset.MIP_scenario.title+".csv")
    }
    else if(appState.activeObject === 3){
      return(location.state.directdemandset.MIP_Growth_rates.title+".csv")
    }
    else{
      return("")
    }
  }


  function TextDisplay(){
    if (appState.activeObject === 1){
      return("MIP Año Base: La tabla muestra la MIP o matriz insumo producto base del año y país ingresado. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores se miden en " + coins[values.country] + ".")
    }
    else if (appState.activeObject === 2){
      return("MIP Escenario: La tabla muestra la MIP o matriz insumo producto escenario del año y país ingresado. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores se miden en " + coins[values.country] + ".")
    }
    else if (appState.activeObject === 3){
      return("MIP Ratio Crecimiento: La tabla muestra el crecimiento porcentual de la MIP o matriz insumo producto del año y país ingresado en base a los valores de porcentaje de conjunto de demanda y precios seleccionados. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores se miden en variación porcentual de conjunto de demanda y precios de " + location.state.technologies.Unities + ".")
    }
    else {
      return("")
    }
  }


  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex'> 
        <div className='w-3/5 h-14 grid grid-cols-3 gap-x-3 p-2' style={{backgroundColor: "white", border: "1px solid", borderRadius:"10px", borderColor: "gray"}}>
          <button onClick={() => {toggleActive(0)}} className={`max-h-10 max-w-56 rounded-lg ${toggleActiveStyles(0)}`}>MIP Año Base</button>
          <button onClick={() => {toggleActive(1)}} className={`max-h-10 max-w-56 rounded-lg ${toggleActiveStyles(1)}`}>MIP Escenario</button>
          <button onClick={() => {toggleActive(2)}} className={`max-h-10 max-w-56 rounded-lg ${toggleActiveStyles(2)}`}>MIP Ratio Crecimiento</button>
        </div>
        <div className='grid grid-cols-5 ml-auto w-1/4 gap-x-4'>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {setIsModalOpen(true)}} title='Pulsa para abrir la tabla o gráfico actual en pantalla completa'><img src={expand} className='w-10 h-10' alt=''/>Expandir pantalla</button></div>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button title='Pulsa para ir al menú de guardado y descarga de reportes' onClick={() => {navigate("/dashboard/simulations/main/leontieff_scenary/direct_demand_set_reports", {state: {MIP_base_year_columns, MIP_base_year_data, MIP_scenario_columns, MIP_scenario_data, MIP_Growth_rates_columns
            , MIP_Growth_rates_data, values, technologies, directdemandset, coins}})}}><img src={save} className='w-10 h-10' alt=''/>Guardar reporte</button></div>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button title='Pulsa para ir al menú de guardado y descarga de reportes' onClick={() => {navigate("/dashboard/simulations/main/leontieff_scenary/direct_demand_set_reports", {state: {MIP_base_year_columns, MIP_base_year_data, MIP_scenario_columns, MIP_scenario_data, MIP_Growth_rates_columns
            , MIP_Growth_rates_data, values, technologies, directdemandset, coins}})}}><img src={download} className='w-10 h-10' alt=''/>Descargar reporte</button></div>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button title='Pulsa para descargar la tabla o gráfico actual en formato CSV'><CSVLink data={CSVData()} filename={CSVTitle()}><img src={filedownload} className='w-10 h-10' alt=''/>Convertir a CSV</CSVLink></button></div>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button title='Pulsa para volver a la pantalla de selección de datos' onClick={() => {navigate("/dashboard/simulations/main/leontieff_scenary/direct_demand_set_selector2", {state: {values, technologies, coins}})}}><img src={edit} className='w-10 h-10' alt=''/>Editar variables</button></div>
        </div>
      </div>
      <div className='mt-2 mb-1'><p className='text-3xl font-extrabold tracking-tight text-slate-900'>Conjuntos de demanda y precios {capitalize(values.country)} {values.year}</p></div>
      <div className='flex'>
        <h4 className='font-bold text-gray-400 w-4/5'>{TextDisplay()}</h4>
      </div>
      <div className='mt-3 mb-12' style={{maxWidth:"1460px", maxHeight: "490px"}}>
        {GraphicDisplay()}
      </div>
      <FullScreen isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} item={GraphicDisplay()}/>
    </div>
  )
}

export default DirectDemandSetResults