import {React, useState, useContext, useRef, useEffect} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header } from '../../../../components';
import { useNavigate, useLocation } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import { Link } from 'react-router-dom';
import DynamicTableMin from '../../../../hook/DynamicTableMin';
import ModalLoader from '../../../../components/ModalLoader';
import LoadingScreen from '../../../../components/LoadingScreen';
import ecokinesis from "../../../../assets/ecokinesis.png"
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { API_PORT } from '../../../../API_PORT';
import anid from "../../../../assets/anid_rojo_azul.png"
import utfsm from "../../../../assets/utfsm.png"
import { useStateContext } from '../../../../contexts/ContextProvider';


const DirectDemandSetPDF = () => {
  const {activeMenu, setActiveMenu} = useStateContext();
  const maxWidth = activeMenu ? '1080px' : '100%';
  const [progress, setProgress] = useState(0);
  const [msg, setMsg] = useState(null);
  const [msgporcentage, setMsgPorcentage] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  const [usersReports, setUsersReports] = useState([])
  
    const userContext = useContext(UserContext);
    const user_type= userContext.value.user_type;
    const token = userContext.value.access_token;
  
    const navigate = useNavigate();
    const location = useLocation();
  
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
  
    //Define const of location
    const values = location.state.values
    const coins = location.state.coins
    const technologies = location.state.technologies
    const directdemandset = location.state.directdemandset
    const MIP_base_year_columns = location.state.MIP_base_year_columns
    const MIP_base_year_data = location.state.MIP_base_year_data
    const MIP_scenario_columns = location.state.MIP_scenario_columns
    const MIP_scenario_data = location.state.MIP_scenario_data
    const MIP_Growth_rates_columns = location.state.MIP_Growth_rates_columns
    const MIP_Growth_rates_data = location.state.MIP_Growth_rates_data
  
    //Data variables
    const [name, setName] = useState({
      "pdf_name": "Conjuntos de demanda y precios " + capitalize(values.country) + " " + values.year
    }); 
  
    const handleInput = (event) =>{
      setName(prev => ({...prev, [event.target.name]:event.target.value}))
    };
  
    const responsereport = (response) => {
      if (response === true) {
          setMsg("¡Reporte guardado exitosamente!")
          alert("Se ha guardado tu reporte exitosamente. Revisa la sección de reportes de la barra izquierda para poder visualizarlo.")
        }
      else {
        alert("No se ha podido guardar el reporte.")
        setMsg("No se ha podido guardar tu reporte")
      }
      setMsgPorcentage(null);
      setLoaderScreen(prevState => {
        return {...prevState, started: false}
      })
    }
  
    useEffect(() => {
      fetch(API_PORT+'/admin/reports/user/get_reports_list/?token=' + token, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json()).then(data => {
          setUsersReports(data);
          setOpen(false);
          setLoadingScreen(prevState => {return {...prevState, started: false}});
        });
      }, [token]);
  
    const chartRefs = useRef([]);
    const pages = 4
  

     const SaveReport = async () =>{
      // Verifica si el nombre del reporte ya existe
      const reportExists = usersReports.some(report => report.report_name === name.pdf_name);
      if (reportExists) {
          alert("El nombre del reporte ya existe. Cambia el nombre de este por otro que no esté guardado en tu cuenta.");
          return; // Detiene la ejecución si el nombre ya existe
      }
      setActiveMenu(false);
      const pdf2 = new jsPDF();
      setIsModalOpen(true)
      setProgress(0); // Inicia el progreso en 0%
      setMsg("Generando el reporte. No cierres la ventana hasta completar el proceso.")
      setLoaderScreen(prevState => {
        return {...prevState, started: true}
      })
      for (let i = 0; i < pages; i++) {
        const chartRef = chartRefs.current[i];
        const canvas = await html2canvas(chartRef);
        const imageData = canvas.toDataURL('image/png');
        const pdfWidth = pdf2.internal.pageSize.getWidth()
        const pdfHeight = pdf2.internal.pageSize.getHeight()
        const imgWidth = canvas.width
        const imgHeight = canvas.height
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
        pdf2.addImage(imageData, 'PNG', 16, 16, imgWidth*ratio*0.85, imgHeight*ratio*0.85);
        if (i < pages - 1) {
          pdf2.addPage();
        }
        setProgress(((i + 1) / pages) * 100);
        setMsgPorcentage((((i + 1) / pages) * 100).toFixed(0) + " %");
        
      }
      const reportData = new FormData();
      reportData.append('report_name', name.pdf_name);
      reportData.append('report_type', 'Conjuntos de demanda y precios / Escenario Leontieff');
      reportData.append('report_description', 'Conjuntos de demanda y precios / Escenario Leontieff');
      reportData.append('file', new Blob([pdf2.output('blob')]), name.pdf_name+'.pdf');
      setMsgPorcentage(null);
      setMsg("Guardando el reporte en tu cuenta. No cierres la ventana hasta completar el proceso.")

      try {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${API_PORT}/admin/reports/user/save_report/?token=${token}`, true);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setProgress(percentComplete);
                setMsgPorcentage(`${percentComplete.toFixed(0)} %`);
            }
        };

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                console.log('Archivo subido con éxito');
                responsereport(true);
            } else {
                throw new Error('Error al guardar el reporte');
            }
        };

        xhr.onerror = () => {
            console.error('Ocurrió un error al intentar guardar el reporte');
            responsereport(false);
        };

        xhr.send(reportData);
    } catch (error) {
        console.error('Ocurrió un error al intentar guardar el reporte:', error);
        setMsgPorcentage(null);
        responsereport(false);
    } 
  }

  const generatePDF = async () => {
    setActiveMenu(false);
    const pdf = new jsPDF();
    setIsModalOpen(true)
    setProgress(0); // Inicia el progreso en 0%
    setMsg("Generando el reporte para ser descargado. No cierres la ventana hasta completar el proceso.")
    setLoaderScreen(prevState => {
      return {...prevState, started: true}
    })
    for (let i = 0; i < pages; i++) {
      const chartRef = chartRefs.current[i];
      const canvas = await html2canvas(chartRef);
      const imageData = canvas.toDataURL('image/png');
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      pdf.addImage(imageData, 'PNG', 16, 16, imgWidth*ratio*0.85, imgHeight*ratio*0.85);
      if (i < pages - 1) {
        pdf.addPage();
      }
      // Actualiza el progreso
      setProgress(((i + 1) / pages) * 100);
      setMsgPorcentage((((i + 1) / pages) * 100).toFixed(0) + " %");
    }
    setMsg("¡Reporte generado!")
    setMsgPorcentage(null);
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
    pdf.save(name.pdf_name);
  };
  
    function Render(user){
      if(user === "premium" || user === "admin"){
          return(
              <div>
                  <div className='mt-10'><Header title="Coloca el nombre de tu reporte"/></div>
                      <div className='grid grid-cols-3 gap-3'>
                          <div className='columns-1'>
                              <h1 className='mb-3'><strong>Nombre del reporte</strong></h1>
                              <input type='string' placeholder={name.pdf_name} name='pdf_name' onChange={handleInput} className='form-control rounded-0 w-96 h-10 p-1' style={{border:"1px solid" ,borderRadius: "8px", color: "gray", backgroundColor:"white"}}></input>
                          </div>
                      </div>
                      <div className='flex gap-5 mt-16'>
                          <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={SaveReport}>Guardar Reporte</button>
                          <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={generatePDF}>Descargar Reporte</button>
                      </div>
                      <div key={0} ref={(ref) => (chartRefs.current[0] = ref)} style={{border: "1px solid", width: "100%", padding: "30px", marginTop: "120px"}}>
                          <div className='flex justify-center items-center'>
                            <img style={{maxWidth:"320px", marginBottom:"30px"}} src={ecokinesis} alt=''/>
                          </div>
                          <h1 className='flex justify-center items-center'><strong>Resultados Reporte Escenario Leontieff: Conjuntos de demanda y precio</strong></h1>
                          <h1 className='flex justify-center items-center'>País Ingresado: {capitalize(values.country)}</h1>
                          <h1 className='flex justify-center items-center'>Año MIP Ingresado: {values.year}</h1>
                          <h1 className='flex justify-center items-center'>Fecha de creación del reporte: {date}/{month}/{year}</h1>
  
                          <h1 className='flex justify-center items-center mt-16'><strong>Valores Ingresados</strong></h1>
                          <h1 className='flex justify-center items-center mt-4'><strong>Unidades</strong></h1>
                          <h1 className='flex justify-center items-center'>Factores de entrada: {technologies.Unities}</h1>
                          <h1 className='flex justify-center items-center'>Unidades de medición demanda final: {technologies.InputModel}</h1>
                          <h1 className='flex justify-center items-center mt-4'><strong>Demanda final interna</strong></h1>
                            <h1 className='flex justify-center items-center'>Fósil: {technologies.Fosil} {technologies.Unities}</h1>
                            <h1 className='flex justify-center items-center'>Hidráulica: {technologies.Hidraulica} {technologies.Unities}</h1>
                            <h1 className='flex justify-center items-center'>Biomasa y Biogas: {technologies.BiomasaBiogas} {technologies.Unities}</h1>
                            <h1 className='flex justify-center items-center'>Solar: {technologies.Solar} {technologies.Unities}</h1>
                            <h1 className='flex justify-center items-center'>Eólica: {technologies.Eolica} {technologies.Unities}</h1>
                            <h1 className='flex justify-center items-center'>Geotermia: {technologies.Geotermia} {technologies.Unities}</h1>
                            <h1 className='flex justify-center items-center'>Almacenamiento Largo Plazo: {technologies.AlmacenamientoLargo} {technologies.Unities}</h1>
                            <h1 className='flex justify-center items-center'>Almacenamiento Corto Plazo: {technologies.AlmacenamientoCorto} {technologies.Unities}</h1>
                            <h1 className='flex justify-center items-center'>H2: {technologies.H2} {technologies.Unities}</h1>
                          <h1 className='flex justify-center items-center mt-4'><strong>Porcentajes de Factores</strong></h1>
                          <h1 className='flex justify-center items-center'>Fósil: {technologies.FosilPorcentage}%</h1>
                          <h1 className='flex justify-center items-center'>Hidráulica: {technologies.HidraulicaPorcentage}%</h1>
                          <h1 className='flex justify-center items-center'>Biomasa y Biogas: {technologies.BiomasaBiogasPorcentage}%</h1>
                          <h1 className='flex justify-center items-center'>Solar: {technologies.SolarPorcentage}%</h1>
                          <h1 className='flex justify-center items-center'>Eólica: {technologies.EolicaPorcentage}%</h1>
                          <h1 className='flex justify-center items-center'>Geotermia: {technologies.GeotermiaPorcentage}%</h1>
                          <h1 className='flex justify-center items-center'>Almacenamiento Largo Plazo: {technologies.AlmacenamientoLargoPorcentage}%</h1>
                          <h1 className='flex justify-center items-center'>Almacenamiento Corto Plazo: {technologies.AlmacenamientoCortoPorcentage}%</h1>
                          <h1 className='flex justify-center items-center'>H2: {technologies.H2Porcentage}%</h1>
  
                          <h1 className='flex justify-center items-center mt-16'>EcoKinesis Proyecto Fondef Idea ID22I10341 es propiedad de la Universidad Técnica Federico Santa María y ANID</h1>
                          <div className='flex justify-center items-center gap-10 mt-6'>
                            <img style={{maxWidth:"100px"}} src={anid} alt=''/>
                            <img style={{maxWidth:"150px"}} src={utfsm} alt=''/>
                          </div>
                          <h1 className='flex justify-center items-center mt-12'>Disclaimer: Este reporte ha sido generado automáticamente en base a los datos ingresados del usuario en la plataforma de EcoKinesis. EcoKinesis no se hace responsable del uso o vericidad de los datos en este reporte.</h1>
                      </div>
                      <div className="mt-10" key={1} ref={(ref) => (chartRefs.current[1] = ref)}>
                          <h1 className='mb-6' ><strong>MIP Año Base:</strong> La tabla muestra la MIP o matriz insumo producto base del año y país ingresado. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores se miden en {coins[values.country]}.</h1>
                          <div className='flex justify-center items-center'>
                          <div style={{ maxWidth }}>
                          <DynamicTableMin title={directdemandset.MIP_base_year.title}
                              rows={MIP_base_year_data}
                              columns={MIP_base_year_columns}
                              cellSize='min'/>
                          </div></div>
                      </div>
                      <div className="mt-10" key={2} ref={(ref) => (chartRefs.current[2] = ref)}>
                          <h1 className='mb-6' ><strong>MIP Escenario:</strong> La tabla muestra la MIP o matriz insumo producto escenario del año y país ingresado. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores se miden en {coins[values.country]}.</h1>
                          <div className='flex justify-center items-center'>
                          <div style={{ maxWidth }}>
                          <DynamicTableMin 
                            title={directdemandset.MIP_scenario.title}
                            rows={MIP_scenario_data}
                            columns={MIP_scenario_columns}
                              cellSize='min'/>
                          </div></div>
                      </div>
                      <div className="mt-10" key={3} ref={(ref) => (chartRefs.current[3] = ref)}>
                          <h1 className='mb-6' ><strong>MIP Ratio Crecimiento:</strong> La tabla muestra el crecimiento porcentual de la MIP o matriz insumo producto del año y país ingresado en base a los valores de porcentaje de conjunto de demanda y precios seleccionados. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores se miden en variación porcentual de conjunto de demanda y precios de {technologies.Unities}.</h1>
                          <div className='flex justify-center items-center'>
                          <div style={{ maxWidth }}>
                          <DynamicTableMin title={directdemandset.MIP_Growth_rates.title}
                              rows={MIP_Growth_rates_data}
                              columns={MIP_Growth_rates_columns}
                              cellSize='min'/>
                          </div></div>
                      </div>
  
                </div>
          )
      }
      else{
          return(
              <div className='mt-16'>
                  <h1 className='flex justify-center'><strong>Contrata la suscripción premium de EcoKinesis para desbloquear la posibilidad de guardar y descargar reportes</strong></h1>
                  <div className='flex justify-center'>
                      <div className='mt-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", width:"540px", height:"180px"}}>
                          <div className='justify-center align-items-center text-center'>
                              <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
                              <div className="text-4xl" style={{color:"blue"}}><strong>$4.99 USD</strong></div>
                              <div className="text-sm"style={{color:"gray"}}><strong>Renovación mensual</strong></div>
                              <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", width:"260px", height:"54px", marginTop:"10px"}} onClick={() => (navigate("/dashboard/premium"))}>Contratar</button>
                          </div>
                      </div>
                  </div>
              </div>
          )
      }
    }
  
    return (
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex justify-between items-center mb-6'>
          <button onClick={() => {navigate("/dashboard/simulations/main/leontieff_scenary/direct_demand_set_results", {state: {technologies,values,directdemandset, coins}})}} style={{color:"white",backgroundColor:"gray",borderRadius:"10px", width:"200px", height: "60px"}}>Volver a mi simulación</button>
    </div>  
    <Header title="Generar reporte Escenario Leontieff: Conjuntos de demanda y precios"/>
    <p className='text-gray-500'>En esta sección podrás guardar y descargar los resultados de tu escenario Leontieff para conjuntos de demanda y precios. Ingresa el nombre de tu reporte. Si pulsas el botón de descarga, se descargará un PDF con tus resultados. Si pulsas la opción de guardar reporte, este se guardará en la zona de reportes. Un reporte no puede ser guardado si es que ya existe uno en tu cuenta con el mismo nombre. En la zona inferior verás la lista de tablas y gráficos de tu simulación que se almacenarán en tu reporte. Estas opciones sólo están disponible para usuarios que cuenten con la <Link to= "/dashboard/premium" className='text-blue-500'>suscripción premium de EcoKinesis</Link>.</p>
  {Render(user_type)}
  <ModalLoader msg={msg} msgporcentage={msgporcentage} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
  <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
  </div>
    )
}

export default DirectDemandSetPDF