import {React, useContext} from 'react'
import { Header, Button } from '../../../../components';
import { useNavigate, Link } from 'react-router-dom';
import { UserContext } from '../../../../contexts/UserContext';
import { useStateContext } from '../../../../contexts/ContextProvider';
import mip from "../../../../assets/list_FILL0_wght300_GRAD0_opsz24.svg"
import bd_logo from '../../../../assets/bd_logo.svg';

const SimulationOperationMenu = () => {
  const userContext = useContext(UserContext);
  const user_type= userContext.value.user_type;
  const { currentColor }= useStateContext();

  const navigate = useNavigate();

  function ScenaryRender(user){
    if(user === "premium" || user === "admin"){
      return(<div className='flex grid-cols-2 gap-32 mt-10 justify-center items-center'>
        <div className='max-w-64'>
            <div className='h-32 bg-light-gray rounded-lg'>
              <div className='grid grid-cols-1 justify-items-center'>
                <img src={mip} width="80px" height="80px" className='mt-3' alt='mip'/>
                <div className='text-gray-400' style={{fontSize:"13px"}}><strong>Realizar simulación</strong></div>
              </div>
            </div>
            <div className="flex justify-center items-center mb-4">
              <Link to="/dashboard/simulations/main/simulation_operation_selector1" >
                  <Button
                    color="white"
                    bgColor={currentColor}
                    text="Simular"
                    borderRadius="10px"
                    size="md"
                    width="max-w-64"
                  />
              </Link>
            </div>
            <div className='max-w-64'>
            Simula tus propios escenarios calculando niveles de generación por tecnología con MSO.
            </div>
          </div>
  
          <div className='max-w-64'>
            <div className='h-32 bg-light-gray rounded-lg'>
              <div className='grid grid-cols-1 justify-items-center'>
                <img src={bd_logo} width="80px" height="80px" className='mt-3' alt='mip'/>
                <div className='text-gray-400' style={{fontSize:"13px"}}><strong>Lista de MSO</strong></div>
              </div>
            </div>
            <div className="flex justify-center items-center mb-4">
              <Link to="/dashboard/simulations/main/simulation_operation_list" >
                  <Button
                    color="white"
                    bgColor={currentColor}
                    text="Simular"
                    borderRadius="10px"
                    size="md"
                    width="max-w-64"
                  />
              </Link>
            </div>
            <div className='max-w-64'>
                Revisa los resultados de tus escenarios de operación simulada generados.
            </div>
          </div>
        </div>)
    }
    else{
      return(
        <div className='mt-16'>
                <h1 className='flex justify-center mb-3'><strong>Contrata la suscripción	premium de EcoKinesis para desbloquear los escenarios de operación simulada</strong></h1>
                <div className='flex justify-center'>
                    <div className='mb-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", maxWidth:"540px", maxHeight:"380px"}}>
                      <div className='justify-center align-items-center text-center'>
                        <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
                        <div className="text-sm mt-3" style={{color:"gray"}}><strong>Contrata la suscripción premium de EcoKinesis para poder usar esta sección</strong></div>
                        <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", minWidth: "120px", maxWidth:"260px", height:"54px", marginTop:"10px", marginBottom: "10px"}} onClick={()=>{navigate("/dashboard/premium")}}>Contratar</button>
                      </div>
                    </div>
                </div>
            </div>
      )
    }
  }


  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/simulations" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver a Simulaciones"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <div className='flex gap-4'><Header title="Escenarios operación simulada"></Header></div>
      <p className='text-gray-500'>En esta sección podrás generar tus propias simulaciones de operación calculando niveles de generación por tecnología con MSO. Pulsa el botón de "Realizar simulación" para comenzar con el proceso de escenario de operación simulada. La zona de "Lista de MSO" te permitirá ver los resultados de todos los escenarios de operación simulada que hayas calculado. Las simulaciones de escenarios operación simulada sólo están disponibles para usuarios que cuenten con la <Link to= "/dashboard/premium" className='text-blue-500'>suscripción premium de EcoKinesis</Link>.</p>
      {ScenaryRender(user_type)}
    </div>
  )
}

export default SimulationOperationMenu