// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.syncfusion.com/ej2/material.css);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar {
  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;
}
.nav-item,
.navbar {
  z-index: 10000;
}
@media  screen and (max-width:800px) {
  .sidebar{
    z-index: 10000000;
  }
}

.e-dlg-center-center, .e-quick-popup-wrapper.e-device{
  z-index: 1000000 !important;
}

::-webkit-scrollbar {
  width: 6px;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(216, 216, 216);
  border-radius: 40px;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* color-picker style  */

 #preview {
  background: transparent
    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')
    no-repeat;
  display: inline-block;
  height: 80px;
  margin: 10px 0;
  min-width: 300px;
  max-width: 600px;
  background-color: #008000;
}

.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAEA;EACE,mDAAmD;AACrD;AACA;;EAEE,cAAc;AAChB;AACA;EACE;IACE,iBAAiB;EACnB;AACF;;AAEA;EACE,2BAA2B;AAC7B;;AAEA;EACE,UAAU;AACZ;AACA;EACE,oCAAoC;EACpC,mBAAmB;AACrB;AACA;EACE,6BAA6B;AAC/B;;AAEA,wBAAwB;;CAEvB;EACC;;aAEW;EACX,qBAAqB;EACrB,YAAY;EACZ,cAAc;EACd,gBAAgB;EAChB,gBAAgB;EAChB,yBAAyB;AAC3B;;AAEA;EACE,YAAY;AACd","sourcesContent":["@import url('https://cdn.syncfusion.com/ej2/material.css');\r\n\r\n.sidebar {\r\n  box-shadow: rgb(113 122 131 / 11%) 0px 7px 30px 0px;\r\n}\r\n.nav-item,\r\n.navbar {\r\n  z-index: 10000;\r\n}\r\n@media  screen and (max-width:800px) {\r\n  .sidebar{\r\n    z-index: 10000000;\r\n  }\r\n}\r\n\r\n.e-dlg-center-center, .e-quick-popup-wrapper.e-device{\r\n  z-index: 1000000 !important;\r\n}\r\n\r\n::-webkit-scrollbar {\r\n  width: 6px;\r\n}\r\n::-webkit-scrollbar-thumb {\r\n  background-color: rgb(216, 216, 216);\r\n  border-radius: 40px;\r\n}\r\n::-webkit-scrollbar-track {\r\n  background-color: transparent;\r\n}\r\n\r\n/* color-picker style  */\r\n\r\n #preview {\r\n  background: transparent\r\n    url('https://ej2.syncfusion.com/react/demos/src/color-picker/images/pen.png')\r\n    no-repeat;\r\n  display: inline-block;\r\n  height: 80px;\r\n  margin: 10px 0;\r\n  min-width: 300px;\r\n  max-width: 600px;\r\n  background-color: #008000;\r\n}\r\n\r\n.e-input-group:not(.e-float-icon-left), .e-input-group.e-success:not(.e-float-icon-left), .e-input-group.e-warning:not(.e-float-icon-left), .e-input-group.e-error:not(.e-float-icon-left), .e-input-group.e-control-wrapper:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left){\r\n  border: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
