import { React, useState, useContext} from 'react'
import { Button } from '../../../components';
import { Header } from '../../../components';
import { Link, useNavigate } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen';
import user_icon from '../../../assets/person.png'
import { RegisterValidation } from '../../LoginPage/Validation';
import { UserContext } from '../../../contexts/UserContext';
import { API_PORT } from '../../../API_PORT';


const DeleteAccount = () => {
  const [loadingscreen, setLoadingScreen] = useState({started: false});
  const [open, setOpen] = useState(false);

  const [values, setValues] = useState({
    "username": "",
  });

  const userContext = useContext(UserContext);

  const user = userContext.value.username;

  const token = userContext.value.access_token;


const navigate = useNavigate();

const [errors, setErrors] = useState({});

const link= API_PORT + "/admin/users_accounts/delete/?token="

const responsenavigate = (response) =>{
  if(response.detail === "Usuario eliminado exitosamente"){
    userContext.setValue({logged: false});
    userContext.setValue({access_token: ""});
    userContext.setValue({username: "", email: "", first_name: "", last_name: "", user_type: ""});
    alert("Su usuario ha sido eliminado exitosamente. Gracias por haber usado EcoKinesis.")
    navigate("/")
  }
  else{
    alert(response.detail)
  }
  setOpen(false);
  setLoadingScreen(prevState => {return {...prevState, started: false}});
}

const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
};

const handleSubmit = (event) =>{
    event.preventDefault();
    setErrors(RegisterValidation(values));
    if(errors.username === ""){
      if (values.username === user){
        setOpen(true);
        setLoadingScreen(prevState => {return {...prevState, started: true}});
        fetch(link+token, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify(token)
            }).then((res => res.json()))
            .catch((error) => console.log(error))
            .then((response) => responsenavigate(response)
            );
      }
      else {
        alert("El nombre de usuario ingresado es incorrecto")
      }
  }
}

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      
        <div className='flex justify-between items-center mb-10'>
            <Link to="/dashboard/account" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  
      <Header title="Borrar cuenta"/>

      <h1 className='mb-6'>Ingresa tu nombre de usuario para borrar tu cuenta. Al hacerlo, saldrás de EcoKinesis y todos tus datos serán eliminados.</h1>
      <form action = "" onSubmit={handleSubmit}>
                <div className='inputs'>
                  <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='username'></label>
                        <input type='string' placeholder='Nombre de Usuario' name='username'
                        onChange={handleInput} className='form-control rounded-0'></input>
                        {errors.username && <span className='text-danger'>{errors.username}</span>}
                    </div>
                </div>
                <div className='flex justify-center items-center gap-20 flex-wrap'>
                  <button className="submit bg-red" type="submit">Eliminar mi cuenta</button>
                </div>     
        </form>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>


    </div>
  )
}

export default DeleteAccount