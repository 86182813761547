//Imports
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

//Use Google Analytics ID
const measurementId = 'G-5QHN1NHWJH';
ReactGA.initialize(measurementId);

//Function to use GoogleAnalytics
function PageViewTracker({ children }) {
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location.pathname]);

  return <>{children}</>;
}

export default PageViewTracker;