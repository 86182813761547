import React from "react";
import "./Modal.css";
import loader from "../assets/blue_loading.svg"

export default function LoadingScreen({ isOpen, closeModal, loaderscreen }) {
  if(isOpen) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  if (!isOpen) return null;

  //Return loading screen for screen transitions
  return (
    <>
        <div className="modal">
          <div className="overlay"></div>
          <div>
            <div className='flex justify-center items-center h-screen'>
                {loaderscreen.started && <img src={loader} alt=""></img>}
            </div>
          </div>
        </div>
    </>
  );
}

