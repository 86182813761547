//Imports
import React, { createContext, useState } from "react";

// User values default
export const defaultUserContext = {
    email: "",
    username: "",
    first_name: "",
    last_name: "",
    user_type: "",
    access_token: "",
    user_photo: "",
    logged: false, //VERY IMPORTANT
    country: "Sin asignar",
    year: "Sin asignar"
};

// Define contexts
export const UserContext = createContext({
    value: defaultUserContext,
    setValue: () => {},
});

//Export user context
export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(defaultUserContext);

    const updateUser = (newValues) => {
        setUser(prevState => ({ ...prevState, ...newValues }));
    };

    return (
        <UserContext.Provider value={{ value: user, setValue: updateUser }}>
            {children}
        </UserContext.Provider>
    );
};