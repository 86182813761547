import {React, useState, useEffect, useContext} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header, Button } from '../../../../components';
import { API_PORT } from '../../../../API_PORT';
import { useNavigate, Link } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import Modal from '../../../../components/Modal';
import LoadingScreen from '../../../../components/LoadingScreen';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const PredefinedScenarySelector = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const { value } = useContext(UserContext);
  const selected_country = value?.country ?? "Sin asignar";
  const selected_year = value?.year ?? "Sin asignar";
  const link= API_PORT + "/admin/database_information/get-avaible-MIPs?token="+token
  const link2= API_PORT + "/admin/database_information/get-avaible-PELP-scenarios?token="+token
  const coin_endpoint = API_PORT + "/admin/database_information/get-coin-name?token=" + token

  const navigate = useNavigate();

  const [mips, setMips] = useState([])
  const [pelps, setPelps] = useState([])

  const [values, setValues] = useState({
    "year": selected_year,
    "country": selected_country, 
    "pelp_year": "",
    "year_simulation": "",
    "scenary": ""
  });

  const [progress] = useState({started: false, pc: 0});
  const [msg, setMsg] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  //Variables para almacenar los endpoints
  //Resumen
  const [summary, setsumary] = useState([])

  //Indicadores económicos
  const [componentsmip, setcomponentsmip] = useState([])
  const [detailcompositionmip, setdetailcompositionmip] = useState([])
  const [impactscontributionmip, setimpactscontributionmip] = useState([])
  const [exportimportsmip, setexportsimportsmip] = useState([])
  const [capitaldistributionmip, setcapitaldistributionmip] = useState([])
  const [remunerationdistributionmip, setremunerationdistributionmip] = useState([])
  const [fiscalimpactmip, setfiscalimpactmip] = useState([])

  //Indicadores de emisiones
  const [directemissionsmip, setdirectemissionsmip] = useState([])
  const [carbonfootprintmip, setcarbonfootprintmip] = useState([])

  //Indicadores de Empleo
  const [inducedemploymentmip, setinducedemploymentmip] = useState([])
  const [remunerationanalisismip, setremunerationanalisismip] = useState([])
  const [pibemploymentmip, setpibemploymentmip] = useState([])
  const [finaldemandmip, setfinaldemandmip] = useState([])

  //Coins
  const [coins, setCoins] = useState([])

  const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setMips(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      })
    fetch(coin_endpoint, {
      method: 'GET',
      headers: {'Content-type': 'application/json'}
      }).then(res => res.json()).then(data => {setCoins(data);})
    fetch(link2, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json())
      .then(data => {
        setPelps(data);
      })
    }, [link, link2, coin_endpoint]);

  var mip_values = mips.MIPbase
  var pelp_year_values = pelps.years
  var scenary_values = pelps.scenarios
  var year_data= [{value:selected_year,label:selected_year}];
  var country_data= [{value:selected_country,label:capitalize(selected_country)}];
  var pelp_year_data= [{value:"",label:"Sin asignar"}];
  var scenary_data  = [];
  

  mip_values?.forEach((element) =>
    {year_data.push({value: element[0], label: element[0]});
    country_data.push({value: element[1], label: capitalize(element[1])})}
  )

  pelp_year_values?.sort()
  pelp_year_values?.forEach((element)=>{
    pelp_year_data.push({value: element, label: element});
  })

  scenary_values?.forEach((element) =>
    {scenary_data.push(element)}
  )

  const endpoints = [
    //Summary Endpoints
    API_PORT + "/scene/pelp/resume/Resumen-Tablelike/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/resume/Resumen-StarPlot-PELP/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,

    //Economics Endpoints
    API_PORT + "/scene/pelp/economic/components/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/economic/detail-composition/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/economic/impacts-contribution/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/economic/export-imports/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/economic/rate-fosil-renovable/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/economic/capital-distribution/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/economic/remuneration-distribution/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/economic/fiscal-impact/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/economic/Investment-requirements/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,

    //Emissions Endpoints
    API_PORT + "/scene/pelp/emission/direct-emissions-and-carbon-footprint/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/emission/carbon-footprint-aggregate-value-ratio/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,

    //Empolyment Endpoints
    API_PORT + "/scene/pelp/labor/induced-and-direct-employment/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/labor/remuneration-analisis/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/labor/historical-employees/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/labor/pib-per-employment/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token,
    API_PORT + "/scene/pelp/labor/empleo-demanda-final/" + values.country + "/" + values.year + "/" + values.pelp_year + "?token="+token
  ];

  const [data, setData] = useState({});

  const fetchData = async (scenary_data) => {
    if (values.year !== "Sin asignar" && values.country !== "Sin asignar" && values.pelp_year !== ""){
      setIsModalOpen(true)
      setMsg("Generado la simulación, no cierre esta pestaña hasta completar el proceso.")
      setLoaderScreen(prevState => {
        return {...prevState, started: true}
      })
      try {
        // Crear un array de promesas para cada combinación de escenario y endpoint
        const promises = [];
        scenary_data.forEach((scenary, scenaryIndex) => {
          endpoints.forEach((endpoint, endpointIndex) => {
            const promise = fetch(endpoint, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(scenary),
            })
            .then(res => {
              return res.json();
            })
            .then(responseData => {
              //console.log(`Data from ${endpoint} for scenario ${scenaryIndex}:`, responseData); // Verifica los datos obtenidos
              return {
                scenaryIndex,
                endpointIndex,
                data: responseData,
              };
            });

            promises.push(promise);
          });
        });

        // Ejecutar todas las promesas concurrentemente
        const results = await Promise.all(promises);
        
        // Organizar los datos en un objeto por escenario y endpoint
        const organizedData = {};
        results.forEach(({ scenaryIndex, endpointIndex, data }) => {
          if (!organizedData[scenaryIndex]) {
            organizedData[scenaryIndex] = {};
          }
          organizedData[scenaryIndex][endpointIndex] = data;
        });
        
        //console.log('All organized data:', organizedData); // Verifica los datos organizados
        
        // Actualizar el estado con los datos organizados
        setData(organizedData);
        
        // Navegar a la nueva pantalla después de cargar los datos
        //navigate('/dashboard/home'); // Reemplaza '/new-screen' con la ruta a la que quieras navegar
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
      //Summary Endpoints
      const mip_summary = API_PORT + "/resumen/" + values.country + "/" + values.year + "?token="+token

      //Economics Endpoints
      const components_mip = API_PORT + "/expandedmip/economic/components/" + values.country + "/" + values.year + "?token="+token
      const detail_composition_mip = API_PORT + "/expandedmip/economic/detail-composition/" + values.country + "/" + values.year + "?token="+token
      const impacts_contribution_mip = API_PORT + "/expandedmip/economic/impacts-contribution/" + values.country + "/" + values.year + "?token="+token
      const export_imports_mip = API_PORT + "/expandedmip/economic/export-imports/" + values.country + "/" + values.year + "?token="+token
      const capital_distribution_mip = API_PORT + "/expandedmip/economic/capital-distribution/" + values.country + "/" + values.year + "?token="+token
      const remuneration_distribution_mip = API_PORT + "/expandedmip/economic/remuneration-distribution/" + values.country + "/" + values.year + "?token="+token
      const fiscal_impact_mip = API_PORT + "/expandedmip/economic/fiscal-impact/" + values.country + "/" + values.year + "?token="+token

      //Emissions Endpoints
      const direct_emissions_mip = API_PORT + "/expandedmip/emissions/direct-emissions-and-carbon-footprint/" + values.country + "/" + values.year + "?token="+token
      const carbon_footprint_mip = API_PORT + "/expandedmip/emissions/carbon-footprint-aggregate-value-ratio/" + values.country + "/" + values.year + "?token="+token

      //Empolyment Endpoints
      const induced_employment_mip = API_PORT + "/expandedmip/labor/induced-and-direct-employment/" + values.country + "/" + values.year + "?token="+token
      const remuneration_analisis_mip = API_PORT + "/expandedmip/labor/remuneration-analisis/" + values.country + "/" + values.year + "?token="+token
      const pib_employment_mip = API_PORT + "/expandedmip/labor/pib-per-employment/" + values.country + "/" + values.year + "?token="+token
      const final_demand_mip = API_PORT + "/expandedmip/labor/empleo-demanda-final/" + values.country + "/" + values.year + "?token="+token

      //Other endpoints
      const coin_endpoint = API_PORT + "/admin/database_information/get-coin-name?token=" + token

      //Summary Fetch
        fetch(mip_summary, {
        method: 'POST',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setsumary(data);})
    
        //Economics Fetch
        fetch(components_mip, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcomponentsmip(data);})
      fetch(detail_composition_mip, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setdetailcompositionmip(data);})
      fetch(impacts_contribution_mip, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setimpactscontributionmip(data);})
      fetch(export_imports_mip, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setexportsimportsmip(data);})
      fetch(capital_distribution_mip, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setcapitaldistributionmip(data);})
      fetch(remuneration_distribution_mip, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setremunerationdistributionmip(data);})
      fetch(fiscal_impact_mip, {
        method: 'GET',
        headers: {'Content-type': 'application/json'}
        }).then(res => res.json()).then(data => {setfiscalimpactmip(data);})
      
      //Emissions Fetch
      fetch(direct_emissions_mip, {
      method: 'GET',
      headers: {'Content-type': 'application/json'}
      }).then(res => res.json()).then(data => {setdirectemissionsmip(data);})
    fetch(carbon_footprint_mip, {
      method: 'GET',
      headers: {'Content-type': 'application/json'}
      }).then(res => res.json()).then(data => {setcarbonfootprintmip(data);})
    
      //Employment Fetch
      fetch(induced_employment_mip, {
            method: 'GET',
            headers: {'Content-type': 'application/json'}
            }).then(res => res.json()).then(data => {setinducedemploymentmip(data);})
        fetch(remuneration_analisis_mip, {
            method: 'GET',
            headers: {'Content-type': 'application/json'}
            }).then(res => res.json()).then(data => {setremunerationanalisismip(data);})
        fetch(pib_employment_mip, {
            method: 'GET',
            headers: {'Content-type': 'application/json'}
            }).then(res => res.json()).then(data => {setpibemploymentmip(data);})
        fetch(final_demand_mip, {
            method: 'GET',
            headers: {'Content-type': 'application/json'}
            }).then(res => res.json()).then(data => {setfinaldemandmip(data);})
        //Other Endpoints
        fetch(coin_endpoint, {
          method: 'GET',
          headers: {'Content-type': 'application/json'}
          }).then(res => res.json()).then(data => {setCoins(data);})
    }
    else {
      alert("No has ingresado todos los datos requeridos.")
  }
  };


  const ExportScenary = () => {
    if (values.year !== "Sin asignar" && values.country !== "Sin asignar" && values.pelp_year !== ""){
      values.year_simulation= values.pelp_year
      navigate("/dashboard/simulations/main/basic_scenary_import", {state: {values, coins}})
    }
    else {
      alert("No has ingresado los datos requeridos.")
    }
  }

  const NavigateResults = () => {
    if(summary.title && componentsmip.description && detailcompositionmip.title && impactscontributionmip.description && exportimportsmip.title && capitaldistributionmip.title && remunerationdistributionmip.title && fiscalimpactmip.title && directemissionsmip.title && carbonfootprintmip.title && inducedemploymentmip.title && remunerationanalisismip.title && pibemploymentmip.title && finaldemandmip.title && coins[values.country] && data){
      alert("Se ha calculado la simulación. Pulsa para continuar.")
      navigate("/dashboard/simulations/main/predefined_scenary_results", {state: {values, scenary_data, data,summary, componentsmip, detailcompositionmip,impactscontributionmip, exportimportsmip, capitaldistributionmip, remunerationdistributionmip, fiscalimpactmip, directemissionsmip, carbonfootprintmip, inducedemploymentmip, remunerationanalisismip, pibemploymentmip, finaldemandmip, coins}})
    }
  }

  NavigateResults()


  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/simulations" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver a Simulaciones"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <Header title="Escenarios Predefinidos"/>
      <p className='text-gray-500'>En esta sección podrás ver los resultados de simulaciones de escenarios predefinidos. Ingresa un país, un año MIP y un año PELP para ver los resultados. También puedes pulsar el botón de exportar datos a escenario personalizado si quieres utilizar los datos de un escenario predefinido en uno personalizado.</p>
      <div className='mt-10'><Header title="Selecciona 1 opción disponible para cada valor"/></div>
      <div className='grid grid-cols-4 gap-3'>
      <div className='columns-1'>
          <h1 className='mb-3'><strong>País</strong></h1>
          <label htmlFor='country'></label>
            <select type='string' name='country'
              onChange={handleInput} className='form-select rounded-0 min-w-44 max-w-56 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {country_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>Año MIP Base</strong></h1>
            <label htmlFor='year'></label>
            <select type='string' name='year'
              onChange={handleInput} className='form-select rounded-0 min-w-44 max-w-56 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {year_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
        <div className='columns-1'>
          <h1 className='mb-3'><strong>Año PELP</strong></h1>
            <label htmlFor='pelp_year'></label>
            <select type='string' name='pelp_year'
              onChange={handleInput} className='form-select rounded-0 min-w-44 max-w-56 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
              {pelp_year_data.map(option =>(
                <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                ))}
            </select>
        </div>
      </div>
      <div className='mt-56 flex gap-3'>
        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={()=>{fetchData(scenary_data)}}>Ver Resultados</button>
        <TooltipComponent content="Al pulsar este botón se exportarán los datos del escenario predefinido seleccionado al escenario personalizado. Para poder utilizar estos datos, deberás seleccionar un escenario disponible para el escenario predefinido seleccionado y luego utilizar el botón de 'Cargar datos' del escenario predefinido en la pantalla de selección de datos del escenario personalizado."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={ExportScenary}>Exportar datos a escenario personalizado</button></TooltipComponent>
      </div>
      <Modal msg={msg} progress={progress} isOpen={isModalOpen} loaderscreen={loaderscreen} closeModal={() => setIsModalOpen(false)}/>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default PredefinedScenarySelector