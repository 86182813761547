import {React, useState, useContext} from 'react'
import { Header, Button } from '../../../../components';
import DataTable from 'react-data-table-component';
import {API_PORT} from "../../../../API_PORT"
import { UserContext } from '../../../../contexts/UserContext';
import Modal from '../../../../components/Modal';
import { Link } from 'react-router-dom';
import notification from "../../../../assets/help.png"
import { TooltipComponent } from '@syncfusion/ej2-react-popups';


const MSO = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;

  const [progress] = useState({started: false, pc: 0});
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [msg, setMsg] = useState(null);

  const country_options = [
    { value: "", label: "Selecciona el país"},
    { value: "chile", label: "Chile"}]
  ;

  const [values, setValues] = useState({
    "country_name": "",
  });

  const columns = [
    {
      name: "Nombre",
      selector: row => row.name
    },
    {name: "País",
    selector: row => row.country},
    {name: "Archivo",
    selector: row => row.action},
    {name: "Acción",
    selector: row => row.upload}
  ];

  const responsenavigate = (response) => {
    if (response.estado === "pendiente") {
        setMsg("Carga de archivo MSO en progreso")
        alert("Se ha iniciado el proceso de carga del MSO. Este proceso se realizará de manera interna y dura alrededor de 3 minutos. Ya puede cerrar esta ventana.")
      }
    else if (response.state === "Tablas MSO eliminadas") {
      setMsg("Tablas MSO eliminadas correctamente")
      alert("Se han eliminado correctamente todas las tablas MSO de la base de datos")
    }
    else {
        console.log(response)
        setMsg("Proceso fallido")
        alert(response.detail)
    }
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
  }

  const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  const [file, setFile] = useState();

  const handleFileUpload = (event) => {
    setFile(event.target.files[0])
  }

  async function handleSubmit({ endpoint, file_type }) {
    var new_link= ""
    try {
        if (file && endpoint === "/admin/scenarios/save-MSO-direct-csv-load-Celery/") {
            new_link = API_PORT + endpoint + values.country_name + "?token=" + token;
            const fd = new FormData();
            fd.append(file_type, file);

            setIsModalOpen(true);
            setMsg("Cargando las tablas. No cierres esta venta hasta completar el proceso.");
            setLoaderScreen(prevState => ({ ...prevState, started: true }));

            const response = await fetch(new_link, {
                method: 'POST',
                body: fd,
                // headers: undefined // Comentar o ajustar según sea necesario
            });

            const data = await response.json();
            responsenavigate(data);
        } else if (endpoint === "/admin/scenarios/delete-all-MSO-files/{country_name}?token=") {
            new_link = API_PORT + endpoint + token;

            setIsModalOpen(true);
            setMsg("Eliminando las tablas. No cierres esta venta hasta completar el proceso.");
            setLoaderScreen(prevState => ({ ...prevState, started: true }));

            const response = await fetch(new_link, {
                method: 'DELETE',
                headers: {
                    "Content-Type": "application/json"
                }
            });

            const data = await response.json();
            responsenavigate(data);
        }
    } catch (error) {
      
        console.error(error)
        alert("No se ha podido realizar el proceso. Recarga tu sesión o inténtalo nuevamente más tarde.")
        setMsg("Proceso fallido")
        setLoaderScreen(prevState => {
          return {...prevState, started: false}
        })
      
    }
}

  const data = [
    {id: 1,
    name: <div className='flex gap-2'>
    <TooltipComponent content="En esta zona se debe cargar el archivo 'BD PELP V2.zip' que contiene toda la información del MSO. El proceso de carga puede demorar varios segundos, por lo que una vez que pulse el botón para subir los archivos, espere a que el proceso finalize antes de salir de la pantalla para evitar errores."><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
    Cargar MSO
  </div>,
    country: 
      <select type='string' placeholder='País' name='country_name'
      onChange={handleInput} className='form-select rounded-0'>
      {country_options.map(option =>(<option value={option.value}>{option.label}</option>))}</select>,
    action: <input type="file"accept=".zip"onChange={handleFileUpload}/>,
    upload: <button className="submit blue" onClick={()=> handleSubmit({endpoint: "/admin/scenarios/save-MSO-direct-csv-load-Celery/", file_type: "MSO_zip_input"})}>Subir</button>},

    {id: 2,
      name: <div className='flex gap-2'>
      <TooltipComponent content="En esta zona se pueden eliminar los archivos MSO subidos a la base de datos previamente. El proceso de eliminación puede demorar varios minutos, por lo que una vez que pulse el botón, espere a que el proceso finalize antes de salir de la pantalla para evitar errores"><img src={notification} className='w-4 h-4' alt=""></img></TooltipComponent>
      Eliminar MSO
    </div>,
      country: "",
      action: "",
      upload: <button className="submit bg-red" onClick={()=> handleSubmit({endpoint: "/admin/scenarios/delete-all-MSO-files/{country_name}?token="})}>Eliminar tablas MSO</button>}
  ]

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/adminbd" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <Header title="Subir archivos MSO"/>
      <div className='mt-5'>
        <DataTable 
          columns = {columns}
          data= {data}
        >
        </DataTable>
        <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      </div>
    </div>
  )
}

export default MSO