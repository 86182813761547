// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-container {
    border: 1px solid #ccc;
    padding: 10px;
  }
  
  .data-table {
    border-collapse: collapse;
    width: 100%;
  }
  
  .data-table th,
  .data-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  `, "",{"version":3,"sources":["webpack://./src/hook/DynamicTable.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;EACf;;EAEA;IACE,yBAAyB;IACzB,WAAW;EACb;;EAEA;;IAEE,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;EAClB","sourcesContent":[".table-container {\r\n    border: 1px solid #ccc;\r\n    padding: 10px;\r\n  }\r\n  \r\n  .data-table {\r\n    border-collapse: collapse;\r\n    width: 100%;\r\n  }\r\n  \r\n  .data-table th,\r\n  .data-table td {\r\n    border: 1px solid #ddd;\r\n    padding: 8px;\r\n    text-align: left;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
