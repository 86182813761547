//Imports
import {createContext} from "react";

// Default values of app
export const defaultAppContext = {
    loading: false,
    initialized: false,
}

// Define contexts 
export const AppContext = createContext({
    value: defaultAppContext,
    setValue: () => {},
});