function ProfesionCodeText2(level){
    const ISCOCodes = {
        0: "Sin definir",
        1: "Ingeniería",
        2: "Sector Económico",
        3: "Ciencias de la administración",
        4: "Ciencias sociales",
        5: "Sector medioambiental"
      }
    return (ISCOCodes[level])

  }

export default ProfesionCodeText2