import React, { useEffect, useState } from 'react';
import './LoginSignup.css'
import logo from '../../assets/white_logo.png'
import { Link } from 'react-router-dom'
import { API_PORT } from '../../API_PORT'
import { useParams, useNavigate} from 'react-router-dom';

//Verify email for register an user on database
const VerifyEmail = () => {
    const navigate = useNavigate();
    const [verificationStatus, setVerificationStatus] = useState('Verificando...');
    const { token } = useParams(); // Obtener el token de la URL dinámica
        
    useEffect(() => {
        console.log(token)

        // Endpoint de verificación con el formato y headers especificados
        const verificationEndpoint = API_PORT + `/admin/users_accounts/verify_email/${token}`;
        
        // Configuración de la solicitud fetch
        const requestOptions = {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        };

        // Realizar la solicitud GET al endpoint
        fetch(verificationEndpoint, requestOptions)
            .then(response => {
                if (!response.ok) {
                    throw new Error('No se pudo verificar el correo.');
                }
                return response.json(); // Parsear la respuesta JSON
            })
            .then(data => {
                // Manejar la respuesta
                console.log('Correo verificado correctamente:', data);
                setVerificationStatus('Se ha verificado tu correo correctamente. Ya puede cerrar esta ventana. Solicite autorización de acceso a un administrador para poder usar la plataforma de EcoKinesis');
            })
            .catch(error => {
                // Manejar el error
                console.error('Error al verificar el correo:', error);
                setVerificationStatus(`Error al verificar el correo: ${error.message}`);
            });
    }, [token]); // El segundo argumento [] asegura que useEffect se ejecute solo una vez (al montar el componente)


    return (
        <div>
        <div className='container2 text-white'>
            <h1 style={{position:"absolute", left:"73%", transform: "translateX(-50%);"}} title='Volver a ecokinesis.cl'><Link to="https://ecokinesis.cl/"><strong>Volver al inicio</strong></Link></h1>
        </div>
        <div className='container bg-login'>
            <div className='header'>
                <img src={logo} className="logo"alt=''></img>
                <div className='text'>Verificación de correo</div>
                <div className='subtext white mb-6'><p>{verificationStatus}</p><div>
        </div>
                </div>
            </div>
            <div className='container3a'>
                    <div className='container4'>
                        <button className="submit blue" onClick={() => navigate("/")}>Volver al inicio de sesión</button>
                    </div>     

            </div>
        </div>
    </div>

    );
};

export default VerifyEmail;