import {React, useState, useContext, useEffect} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header, Button } from '../../../../components';
import { API_PORT } from '../../../../API_PORT';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import Modal from '../../../../components/Modal';
import { styled } from '@mui/material/styles';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import notification from "../../../../assets/help.png"
import LoadingScreen from '../../../../components/LoadingScreen';
import { useStateContext } from '../../../../contexts/ContextProvider';

const SimulationOperationSelector2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const link = API_PORT+'/MSO/get-MSO-simulation-list/?token=' + token;
  const Input = styled(MuiInput)`
  `;
  const values = location.state.values
  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);
  const { setReadedNotifications } = useStateContext();
  const [configuration, setConfiguration] = useState({
    "Combustible": 0,
    "Eficiencia": 0,
    "Hidrica": 100,
    "FactorDemanda": 100,
    "DemandaH2": 100,
    "Solver": "Gurobi",
    "CostosEncendido": false,
    "Mintime": false,
    "Ramp": false,
    "name": "Escenario operación simulada " + capitalize(values.country) + " " + values.pelp_year
  });


  const [progress] = useState({started: false, pc: 0});
  const [msg, setMsg] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [msoNames, setMsoNames] = useState([])

  const handleInput = (event) =>{
    setConfiguration(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  useEffect(() => {
    fetch(link, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json()).then(data => {
        setMsoNames(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      });
    }, [link]);

  const SimulationOperationResults = async () => {
    if(configuration.name !== ""){
      const nameExists = msoNames.some(mso => mso.simulation_name === configuration.name);
      if (nameExists) {
          alert("El nombre de la simulación ya existe. Cambia el nombre de este por otro que no esté guardado en tu cuenta.");
          return; // Detiene la ejecución si el nombre ya existe
      }
      const simulation_operation_model = API_PORT + "/MSO/run-MSO-using-celery/" + values.country + "/" + values.pelp_year + "?token="+token
    const new_body = {
      "PelpScenariosInputModel": values.scenary,
      "MSO_user_configuration": {
        "fuel_price_incr": configuration.Combustible/100.0,
        "eff_incr": configuration.Eficiencia/100.0,
        "disp_hidro": configuration.Hidrica/100.0,
        "demand_factor": configuration.FactorDemanda/100.0,
        "demand_H2": configuration.DemandaH2/100.0,
        "solver": configuration.Solver,
        "start_cost": configuration.CostosEncendido,
        "mintime": configuration.Mintime,
        "ramp": configuration.Ramp
      },
      "simulation_name": configuration.name,
      "comments": configuration.name
    }

    setIsModalOpen(true)
    setMsg("Generando el resultado de tu escenario de operación simulada...")
    setLoaderScreen(prevState => {return {...prevState, started: true}});

    try {
      const response = await fetch(simulation_operation_model, {
        method: "POST",
        headers: { "Content-type": "application/json" },
        body: JSON.stringify(new_body),
      });
  
      if (!response.ok) {
        throw new Error("Error al realizar la solicitud.");
      }
  
      const data = await response.json();
      if (data.estado === "pendiente") { 
        alert("Los resultados de tu simulación se están calculando correctamente. Revisa tus notificaciones para saber cuando tu simulación esté lista. Una vez que esto pase, podrás revisar los resultados en la lista de escenarios de operación simulada.")
        setMsg("Los resultados de la simulación se están generando correctamente. Ya puedes cerrar esta ventana.");
        setReadedNotifications(true);
        navigate("/dashboard/simulations/main/simulation_operation_menu")
      } else {
        setMsg("No se ha podido ejecutar el algoritmo. Inténtalo nuevamente o cambia los valores.")
      }
      setLoaderScreen(prevState => ({ ...prevState, started: false }));
    } catch (error) {
      console.error("Error en la solicitud:", error);
      setMsg("No se ha podido ejecutar el algoritmo. Inténtalo nuevamente o cambia los valores.")
      setLoaderScreen(prevState => {return {...prevState, started: false}});
    }
    }
    else{
      alert("El nombre de tu simulación ya existe o está vacío")
    }
  };

  function UpdateValues(){
    if(location.state?.configuration){
      setConfiguration({...configuration, Combustible: location.state.configuration.Combustible, Eficiencia: location.state.configuration.Eficiencia, Hidrica: location.state.configuration.Hidrica, FactorDemanda: location.state.configuration.FactorDemanda, DemandaH2: location.state.configuration.DemandaH2})  
    }
  }

  function UpdatePreviousValues(){
    if (location.state?.configuration){
      return(
        <TooltipComponent content="Al pulsar este botón se cargarán los datos de porcentajes de factores de escenario utilizados en la simulación seleccionado."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={() => {UpdateValues()}}>Cargar los datos de la simulación seleccionada</button></TooltipComponent>
      )
    }
    else{
      return(<div>
      </div>)
    }
  }


  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
    <div className='flex justify-between items-center mb-6'>
        <Link to="/dashboard/simulations/main/simulation_operation_selector1" >
          <Button
            color="white"
            bgColor="gray"
            text="Volver a la selección de país y año"
            borderRadius="10px"
            size="md"
          />
        </Link>
  </div>  
  <div className='flex gap-4'><Header title={"Escenario operación simulada " + capitalize(values.country) + " " + values.pelp_year}/></div>
  <p className='text-gray-500'>En esta sección podrás generar tus propias simulaciones de operación calculando niveles de generación por tecnología con MSO. Ingresa el nombre de tu simulación y los datos de configuración y porcentajes de factores de escenario usando los deslizadores o ingresándolos manualmente; luego pulsa el botón de la parte inferior para guardar los resultados. Al hacer esto se realizará el cálculo del escenario de operación simulada. Cuando este se encuentre listo, podrás ver el resultado de esta en la lista de escenarios de operación simulada. Revisa tus notificaciones para saber cuando tu escenario haya sido calculado. Un escenario no puede ser calculado si tiene el mismo nombre que otro que hayas guardado.</p>

  <div className='mt-10'><Header title="Coloca el nombre de tu simulación"/></div>
  <div className='grid grid-cols-3 gap-3'>
                        <div className='columns-1'>
                            <h1 className='mb-3'><strong>Nombre</strong></h1>
                            <input type='string' placeholder={configuration.name} name='name' onChange={handleInput} className='form-control rounded-0 w-96 h-10 p-1' style={{border:"1px solid" ,borderRadius: "8px", color: "gray", backgroundColor:"white", padding:"6px"}}></input>
                        </div>
  </div>

  <div className='mt-10'><Header title="Configuración de escenario"/></div>
  <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
    <div className='column-span-1'>
      <div className='flex gap-2 mb-3'><TooltipComponent content="El solver es la herramienta utilizada para calcular la simulación de operación simulada. Se recomienda usar Gurobi al ser el más rápido."><img src={notification} className='w-5 h-5' alt=""></img></TooltipComponent>Solver</div>
      <select type='string' name='Solver' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'>
        <option value="Gurobi">Gurobi</option>
      </select>
    </div>
    <div className='column-span-1'>
    <div className='flex gap-2 mb-3'><TooltipComponent content="Activar los costos de encendido mejora la precisión del resultado de la simulación, pero aumenta enormemente el tiempo de carga de este. Se recomienda dejar apagado este en busca de un resultado más rápido."><img src={notification} className='w-5 h-5' alt=""></img></TooltipComponent>Costos de encendido</div>
      <select type='bool' name='CostosEncendido' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'>
        <option value={false}>No incluir costos</option>
        <option value={true}>Incluir costos</option>
      </select>
    </div>
    <div className='column-span-1'>
    <div className='flex gap-2 mb-3'><TooltipComponent content="Activar las restricciones de tiempo mínimo mejora la precisión del resultado de la simulación, pero aumenta enormemente el tiempo de carga de este. Se recomienda dejar apagado este en busca de un resultado más rápido."><img src={notification} className='w-5 h-5' alt=""></img></TooltipComponent>Restricciones de tiempo mínimo</div>
      <select type='bool' name='Mintime' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'>
        <option value={false}>No incluir restricciones</option>
        <option value={true}>Incluir restricciones</option>
      </select>
    </div>
    <div className='columns-1'>
    <div className='flex gap-2 mb-3'><TooltipComponent content="Activar las restricciones de rampa mejora la precisión del resultado de la simulación, pero aumenta enormemente el tiempo de carga de este. Se recomienda dejar apagado este en busca de un resultado más rápido."><img src={notification} className='w-5 h-5' alt=""></img></TooltipComponent>Restricciones de rampa</div>
      <select type='bool' name='Ramp' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'>
        <option value={false}>No incluir restricciones</option>
        <option value={true}>Incluir restricciones</option>
      </select>
    </div>
  </div>

  <div className='mt-10'><Header title="Porcentajes de factores de escenario"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <h1 className='mb-3'>Incremento precio combustible: {configuration.Combustible}%</h1>
          <Input type='string' value={configuration.Combustible} name='Combustible' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -50,
              max: 150,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Combustible" min={-50} max={150} aria-label="input-slider" value={configuration.Combustible} step={1} valueLabelDisplay="auto" marks={[{value: -50, label: '-50%'},{value: 150,label: '150%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Incremento de eficiencia: {configuration.Eficiencia}%</h1>
          <Input type='string' value={configuration.Eficiencia} name='Eficiencia' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: -50,
              max: 150,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Eficiencia" min={-50} max={150} aria-label="input-slider" value={configuration.Eficiencia} step={1} valueLabelDisplay="auto" marks={[{value: -50, label: '-50%'},{value: 150,label: '150%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Disponibilidad Hidráulica:  {configuration.Hidrica}%</h1>
          <Input type='string' value={configuration.Hidrica} name='Hidrica' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: 0,
              max: 200,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="Hidrica" min={0} max={200} aria-label="input-slider" value={configuration.Hidrica} step={1} valueLabelDisplay="auto" marks={[{value: 0, label: '0%'},{value: 200,label: '200%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Factores de demanda general: {configuration.FactorDemanda}%</h1>
          <Input type='string' value={configuration.FactorDemanda} name='FactorDemanda' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: 0,
              max: 200,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="FactorDemanda" min={0} max={200} aria-label="input-slider" value={configuration.FactorDemanda} step={1} valueLabelDisplay="auto" marks={[{value: 0, label: '0%'},{value: 200,label: '200%'}]} onChange={handleInput}/></div>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Factor de Demanda de H2: {configuration.DemandaH2}%</h1>
          <Input type='string' value={configuration.DemandaH2} name='DemandaH2' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2' inputProps={{
              step: 1,
              min: 0,
              max: 200,
              type: 'number',
              'aria-labelledby': 'input-slider'
            }}/>
          <div className='form-control rounded-0 w-full h-10 px-3 py-2'><Slider name="DemandaH2" min={0} max={200} aria-label="input-slider" value={configuration.DemandaH2} step={1} valueLabelDisplay="auto" marks={[{value: 0, label: '0%'},{value: 200,label: '200%'}]} onChange={handleInput}/></div>
        </div>
      </div>

      <div className='mt-12 flex gap-5'>
        <TooltipComponent content="Al pulsar este botón se procederá a calcular el resultado de tu escenario de operación simulada. Deberás esperar una cantidad de tiempo variable dependiendo de los parámetros que ingresaste. Revisa tus notificaciones para saber cuando tu resultado estará listo. Una vez que este se encuentre, podrás revisarlo en la sección de lista de escenarios de operación simulada."><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={SimulationOperationResults}>Calcular resultado</button></TooltipComponent>
        {UpdatePreviousValues()}
      </div>
      <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
  </div>
  )
}

export default SimulationOperationSelector2