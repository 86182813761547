import {React, useState, useContext, useRef, useEffect} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { Header} from '../../../components';
import { useNavigate, useLocation } from 'react-router-dom';
import capitalize from '../../../hook/capitalize';
import { Link } from 'react-router-dom';
import graphColors from '../../../hook/graphColors';
import ModalLoader from '../../../components/ModalLoader';
import ecokinesis from "../../../assets/ecokinesis.png"
import MUIDataTable from "mui-datatables";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import HorizontalTables from '../../../hook/HorizontalTables';
import "./new_styles.css";
import { API_PORT } from '../../../API_PORT';
import anid from "../../../assets/anid_rojo_azul.png"
import utfsm from "../../../assets/utfsm.png"
import DynamicTable from '../../../hook/DynamicTable';
import DynamicTableMin from '../../../hook/DynamicTableMin';
import LoadingScreen from '../../../components/LoadingScreen';
import { Bar, Line, Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, Title, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Filler, Tooltip, Legend, ArcElement} from "chart.js";
ChartJS.register(Title, BarElement, CategoryScale, LinearScale, PointElement, LineElement, ArcElement, Filler, Tooltip, Legend)

const GeneratePDFExpandedMIP = () => {
  const [progress, setProgress] = useState(0);
  const [msg, setMsg] = useState(null);
  const [msgporcentage, setMsgPorcentage] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  const [usersReports, setUsersReports] = useState([])

  const userContext = useContext(UserContext);
  const user_type= userContext.value.user_type;
  const token = userContext.value.access_token;

  const navigate = useNavigate();
  const location = useLocation();

  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();

  //Define const of location
  const values = location.state.values
  const calibratedmip = location.state.calibratedmip
  const inverted = location.state.inverted
  const calibratedmipcomplete = location.state.calibratedmipcomplete
  const summary = location.state.summary
  const components = location.state.components
  const detailcomposition = location.state.detailcomposition
  const impactscontribution = location.state.impactscontribution
  const exportimports = location.state.exportimports
  const capitaldistribution = location.state.capitaldistribution
  const remunerationdistribution = location.state.remunerationdistribution
  const fiscalimpact = location.state.fiscalimpact
  const productionmultipliers = location.state.productionmultipliers
  const gwhcontribution = location.state.gwhcontribution
  const pibmultipliers = location.state.pibmultipliers
  const rentmultipliers = location.state.rentmultipliers
  const inversionmultipliers = location.state.inversionmultipliers
  const techimportance = location.state.techimportance
  const directemissions = location.state.directemissions
  const carbonfootprint = location.state.carbonfootprint
  const carbonsource = location.state.carbonsource
  const carbonessh2 = location.state.carbonessh2
  const carbonfinaldemand = location.state.carbonfinaldemand
  const carbonfinaldemandessh2 = location.state.carbonfinaldemandessh2
  const inducedemployment= location.state.inducedemployment
  const remunerationanalisis = location.state.remunerationanalisis
  const historicalemployees = location.state.historicalemployees
  const pibemployment= location.state.pibemployment
  const finaldemand = location.state.finaldemand
  const employmentgwh= location.state.employmentgwh
  const labormultipliers = location.state.labormultipliers
  const ratefosilrenovable = location.state.ratefosilrenovable
  const coins = location.state.coins


  var resumed_mip_columns = location.state.resumed_mip_columns
  var resumed_mip_data = location.state.resumed_mip_data
  var inverted_mip_columns = location.state.inverted_mip_columns
  var inverted_mip_data = location.state.inverted_mip_data
  var summary_columns = location.state.summary_columns
  var summary_data = location.state.summary_data

  var direct_emissions_columns = location.state.direct_emissions_columns
  var direct_emissions_data = location.state.direct_emissions_data
  var carbon_footprint_columns = location.state.carbon_footprint_columns
  var carbon_footprint_data = location.state.carbon_footprint_data
  var carbon_source_columns = location.state.carbon_source_columns
  var carbon_source_data = location.state.carbon_source_data
  var carbon_essh2_columns = location.state.carbon_essh2_columns
  var carbon_essh2_datasets = location.state.carbon_essh2_datasets
  var carbon_final_demand_columns = location.state.carbon_final_demand_columns
  var carbon_final_demand_data = location.state.carbon_final_demand_data
  var carbon_final_demand_essh2_columns = location.state.carbon_final_demand_essh2_columns
  var carbon_final_demand_essh2_datasets = location.state.carbon_final_demand_essh2_datasets

  var agregate_components_columns = location.state.agregate_components_columns
  var agregate_components_datasets = location.state.agregate_components_datasets
  var porcentage_components_columns = location.state.porcentage_components_columns
  var porcentage_components_datasets = location.state.porcentage_components_datasets
  var porcentage_participation_columns = location.state.porcentage_participation_columns
  var porcentage_participation_datasets = location.state.porcentage_participation_datasets
  var detail_compositions_columns = location.state.detail_compositions_columns
  var detail_compositions_data = location.state.detail_compositions_data
  var pib_total_columns = location.state.pib_total_columns
  var pib_total_data = location.state.pib_total_data
  var porcentual_direct_contribution_columns = location.state.porcentual_direct_contribution_columns
  var porcentual_direct_contribution_data = location.state.porcentual_direct_contribution_data
  var porcentual_indirect_contribution_columns = location.state.porcentual_indirect_contribution_columns
  var porcentual_indirect_contribution_data = location.state.porcentual_indirect_contribution_data
  var porcentual_total_contribution_columns = location.state.porcentual_total_contribution_columns
  var porcentual_total_contribution_data = location.state.porcentual_total_contribution_data
  var export_imports_columns = location.state.export_imports_columns
  var export_imports_datasets = location.state.export_imports_datasets
  var capital_distribution_columns = location.state.capital_distribution_columns
  var capital_distribution_data = location.state.capital_distribution_data
  var remuneration_distribution_columns = location.state.remuneration_distribution_columns
  var remuneration_distribution_data = location.state.remuneration_distribution_data
  var fiscal_impacts_columns = location.state.fiscal_impacts_columns
  var fiscal_impacts_data = location.state.fiscal_impacts_data
  var production_multipliers_columns = location.state.production_multipliers_columns
  var production_multipliers_data = location.state.production_multipliers_data
  var gwh_contribution_columns = location.state.gwh_contribution_columns
  var gwh_contribution_data = location.state.gwh_contribution_data
  var pib_multipliers_columns = location.state.pib_multipliers_columns
  var pib_multipliers_datasets = location.state.pib_multipliers_datasets
  var rent_multipliers_columns = location.state.rent_multipliers_columns
  var rent_multipliers_datasets = location.state.rent_multipliers_datasets
  var inversion_multipliers_columns = location.state.inversion_multipliers_columns
  var inversion_multipliers_data = location.state.inversion_multipliers_data
  var tech_importance_columns = location.state.tech_importance_columns
  var tech_importance_data = location.state.tech_importance_data

  var induced_employment_columns = location.state.induced_employment_columns
  var induced_employment_datasets = location.state.induced_employment_datasets
  var remuneration_analisis_columns = location.state.remuneration_analisis_columns
  var remuneration_analisis_data = location.state.remuneration_analisis_data
  var historical_employees_columns = location.state.historical_employees_columns
  var historical_employees_datasets = location.state.historical_employees_datasets
  var pib_employment_columns = location.state.pib_employment_columns
  var pib_employment_data = location.state.pib_employment_data
  var final_demand_columns = location.state.final_demand_columns
  var final_demand_data = location.state.final_demand_data
  var employment_gwh_columns = location.state.employment_gwh_columns
  var employment_gwh_data = location.state.employment_gwh_data
  var labor_multipliers_columns = location.state.labor_multipliers_columns
  var labor_multipliers_data = location.state.labor_multipliers_data

  //Graph 3
  const ChartData1 = {
    labels: agregate_components_columns,
    datasets: agregate_components_datasets
  };
  const graph_options1 = {
    plugins:{
      title: {
      display: true,
      text: location.state.components.coponentes_PIB_values.title,
      align: "center",
      },
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: coins[values.country], color:"black"}, stacked: true},
          y: {stacked: true}
      }};

  //Graph 4
  const ChartData2 = {
    labels: porcentage_components_columns,
    datasets: porcentage_components_datasets
  };
  const graph_options2 = {
    plugins:{
      title: {
      display: true,
      text: components.componentes_PIB_por_sector.title,
      align: "center",
      },
      
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: "Porcentaje de participación", color:"black"}, stacked: true, max:100},
          y: {stacked: true}
      }};

  //Graph 5
  const ChartData3 = {
    labels: porcentage_participation_columns,
    datasets: porcentage_participation_datasets
  };
  const graph_options3 = {
    plugins:{
      title: {
      display: true,
      text: components.componentes_PIB_por_componentes_valor_agregado.title,
      align: "center",
      },
      
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: "Porcentaje de participación", color:"black"}, stacked: true, max:100},
          y: {stacked: true}
      }};

  //Graph 7
  const ChartData4 = {
    labels: pib_total_columns,
    datasets: [
      {
      label: pib_total_data[0].name,
      data: pib_total_data[0].data,
      backgroundColor: graphColors([pib_total_data[0].name]),
      borderWidth: 0,
      },
      {
        label: pib_total_data[1].name,
        data: pib_total_data[1].data,
        backgroundColor: graphColors([pib_total_data[1].name]),
        borderWidth: 0,
        }
    ]
  };
  const graph_options4 = {
    plugins:{
      title: {
      display: true,
      text: impactscontribution.PIBsDirectosIndirectosTotales.title,
      align: "center",
      },
      
  },
  indexAxis: 'y',
      scales: {
          x: {title: {display: "true", text: coins[values.country], color:"black"}, stacked: true},
          y: {stacked: true}
      }};
  
    //Graph 8
    const ChartData5 = {
      labels: porcentual_direct_contribution_columns,
      datasets: [{
        label: "Contribución porcentual",
        data: porcentual_direct_contribution_data,
        backgroundColor: graphColors(porcentual_direct_contribution_columns),
        borderWidth: 1
      } ]
    }
    const graph_options5 = {
      plugins : {
        title: {
          display: true,
          text: impactscontribution.DirectContributionPercentage.title,
          align: "center",
        }}
    }
  
  //Graph 9
  const ChartData6 = {
    labels: porcentual_indirect_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: porcentual_indirect_contribution_data,
      backgroundColor: graphColors(porcentual_indirect_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options6 = {
    plugins : {
      title: {
        display: true,
        text: impactscontribution.InducedContributionPercentage.title,
        align: "center",
      }}
  }

  //Graph 10
  const ChartData7 = {
    labels: porcentual_total_contribution_columns,
    datasets: [{
      label: "Contribución porcentual",
      data: porcentual_total_contribution_data,
      backgroundColor: graphColors(porcentual_total_contribution_columns),
      borderWidth: 1
    } ]
  }
  const graph_options7 = {
    plugins : {
      title: {
        display: true,
        text: impactscontribution.TotalContributionPercentage.title,
        align: "center",
      }}
  }

  //Graph 11
  const ChartData8 = {
    labels: export_imports_columns,
    datasets: export_imports_datasets}
  const graph_options8 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: "Exportaciones e importaciones ["+coins[values.country]+"]"}}
    },
    plugins : {
      title: {
        display: true,
        text: exportimports.title,
        align: "center",
      }
    }
  }

  //Graph 12
  const ChartData9 = {
    labels: capital_distribution_columns,
    datasets: [{
      label: capitaldistribution.data[0].x_index,
      data: capital_distribution_data,
      backgroundColor: graphColors(capital_distribution_columns),
      borderWidth: 0,
      }]
  }
  const graph_options9 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías", color: "black"}},
      y: {title:{display: true, text: capitaldistribution.data[0].x_index, color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: location.state.capitaldistribution.title,
        align: "center",
      }
    }
  }

  //Graph 13
  const ChartData10 = {
    labels: remuneration_distribution_columns,
    datasets: [{
      label: remunerationdistribution.data[0].x_index,
      data: remuneration_distribution_data,
      backgroundColor: graphColors(remuneration_distribution_columns),
      borderWidth: 0,
      }]
  }
  const graph_options10= {
    scales: {
      x: {title:{display: true, text: "Tecnologías", color: "black"}},
      y: {title:{display: true, text: remunerationdistribution.data[0].x_index, color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: remunerationdistribution.title,
        align: "center",
      }
    }
  }

  //Graph 15
  const ChartData11 = {
    labels: production_multipliers_columns,
    datasets: [{
      label: "Multiplicadores del producto",
      data: production_multipliers_data,
      backgroundColor: graphColors(production_multipliers_columns),
      borderWidth: 0,
      }]
  }
  const graph_options11 = {
    scales: {
      x: {title:{display: true, text: "Actividades económicas", color: "black"}},
      y: {title:{display: true, text: "Multiplicadores del producto", color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: productionmultipliers.title,
        align: "center",
      }
    }
  }

  //Graph 16
  const ChartData12 = {
    labels: gwh_contribution_columns,
    datasets: [{
      label: gwhcontribution.data[0].x_index,
      data: gwh_contribution_data,
      backgroundColor: graphColors(gwh_contribution_columns),
      borderWidth: 0,
      }]
  }
  const graph_options12 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías", color: "black"}},
      y: {title:{display: true, text: gwhcontribution.data[0].x_index, color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: gwhcontribution.title,
        align: "center",
      }
    }
  }

  //Graph 17
  const ChartData13 = {
    labels: pib_multipliers_columns,
    datasets: pib_multipliers_datasets
  };
  const graph_options13 = {
    plugins:{
      title: {
      display: true,
      text: pibmultipliers.title,
      align: "center",
      },
  },
      scales: {
          x: {title: {display: "true", text: "Tecnologías", color:"black"}},
          y: {title: {display: "true", text: "Multiplicadores del PIB", color:"black"}}
      }};

  //Graph 18
  const ChartData14 = {
    labels: rent_multipliers_columns,
    datasets: rent_multipliers_datasets
  };
  const graph_options14 = {
    plugins:{
      title: {
      display: true,
      text: rentmultipliers.title,
      align: "center",
      },
  },
      scales: {
          x: {title: {display: "true", text: "Tecnologías", color:"black"}},
          y: {title: {display: "true", text: "Multiplicadores de renta", color:"black"}}
      }};

  //Graph 19
  const ChartData15 = {
    labels: inversion_multipliers_columns,
    datasets: [{
      label: inversionmultipliers.data[0].x_index,
      data: inversion_multipliers_data,
      backgroundColor: graphColors(inversion_multipliers_columns),
      borderWidth: 0,
      }]
  }
  const graph_options15 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: inversionmultipliers.data[0].x_index}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: inversionmultipliers.title,
        align: "center",
      }
    }
  }

  //Graph 21
  const ChartData16 = {
    labels: direct_emissions_columns,
    datasets: [
      {
      label: direct_emissions_data[0].name,
      data: direct_emissions_data[0].data,
      backgroundColor: graphColors([direct_emissions_data[0].name]),
      borderWidth: 0,
      categoryPercentage: 0.7
      },
      {
        label: direct_emissions_data[1].name,
        data: direct_emissions_data[1].data,
        backgroundColor: graphColors([direct_emissions_data[1].name]),
        borderWidth: 0,
        categoryPercentage: 0.7
        }
    ]
  }
  const graph_options16= {
    scales: {
      x: {title:{display: true, text: "Actividades Económicas"}},
      y: {title:{display: true, text: "Emisiones Directas y Huella de Carbono [Mt CO2 eq]"}}
    },
    plugins : {
      title: {
        display: true,
        text: directemissions.title,
        align: "center",
      }
    }
  }

  //Graph 22
  const ChartData17 = {
    labels: carbon_footprint_columns,
    datasets: [{
      label: "[Kt CO2 eq/"+coins[values.country]+"]",
      data: carbon_footprint_data,
      backgroundColor: graphColors(carbon_footprint_columns),
      borderWidth: 0,
      }]
  }
  const graph_options17 = {
    scales: {
      x: {title:{display: true, text: "Actividades Económicas"}},
      y: {title:{display: true, text: "Huella de carbono sobre valor agregado "+ "[Kt CO2 eq/"+coins[values.country]+"]"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: carbonfootprint.title,
        align: "center",
      }
    }
  }

  //Graph 24
  const ChartData18 = {
    labels: carbon_essh2_columns,
    datasets: carbon_essh2_datasets
  };
  const graph_options18 = {
    plugins:{
      title: {
      display: true,
      text: location.state.carbonessh2.title,
      align: "center",
      }},
  indexAxis: 'x',
      scales: {
          x: {title: {display: "true", text: "Actividades económicas", color:"black"}, stacked: true},
          y: {title: {display: "true", text: "Huella de carbono [MT CO2eq]", color:"black"}, stacked: true}
      }};

  //Graph 26
  const ChartData19 = {
    labels: carbon_final_demand_essh2_columns,
    datasets: carbon_final_demand_essh2_datasets
  };
  const graph_options19 = {
    plugins:{
      title: {
      display: true,
      text: carbonfinaldemandessh2.title,
      align: "center",
      }},
  indexAxis: 'x',
      scales: {
          x: {title: {display: "true", text: "Actividades económicas", color:"black"}, stacked: true},
          y: {title: {display: "true", text: "[Kt CO2 eq/"+coins[values.country]+"]", color:"black"}, stacked: true}
      }};

  //Graph 27
  const ChartData20 = {
    labels: induced_employment_columns,
    datasets: induced_employment_datasets
  };
  const graph_options20 = {
    plugins:{
      title: {
      display: true,
      text: inducedemployment.title,
      align: "center",
      }},
  indexAxis: 'x',
      scales: {
          x: {title: {display: "true", text: "Tecnologías", color:"black"}, stacked: true},
          y: {title: {display: "true", text: "Empleos", color:"black"}, stacked: true}
      }};

  //Graph 29
  const ChartData21 = {
    labels: historical_employees_columns,
    datasets: historical_employees_datasets
  };
  const graph_options21 = {
    responsive: true,
    plugins:{
      title: {
      display: true,
      text: historicalemployees.title,
      align: "center",
      }},
      scales: {
          x: {title: {display: "true", text: "Años", color:"black", stacked: true}},
          y: {title: {display: "true", text: "Empleos directos", color:"black", stacked: true}}
      }};

  //Graph 30
  const ChartData22 = {
    labels: pib_employment_columns,
    datasets: [{
      label: "PIB / Empleados ["+coins[values.country]+"]",
      data: pib_employment_data,
      backgroundColor: graphColors(pib_employment_columns),
      borderWidth: 0,
      }]
  }
  const graph_options22 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: "PIB / Empleados ["+coins[values.country]+"]"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: pibemployment.title,
        align: "center",
      }
    }
  }

  //Graph 31
  const ChartData23 = {
    labels: final_demand_columns,
    datasets: [
      {label: final_demand_data[0].name,
      data: final_demand_data[0].data,
      backgroundColor: graphColors([final_demand_data[0].name]),
      borderWidth: 0,
      categoryPercentage: 0.8},
      {label: final_demand_data[1].name,
        data: final_demand_data[1].data,
        backgroundColor: graphColors([final_demand_data[1].name]),
        borderWidth: 0,
        categoryPercentage: 0.8},
    ]}
  const graph_options23 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: "Empleos/Demanda final[-/"+coins[values.country]+"]"}}
    },
    plugins : {
      title: {
        display: true,
        text: finaldemand.title,
        align: "center",
      }
    }
  }

  //Graph 32
  const ChartData24 = {
    labels: employment_gwh_columns,
    datasets: [{
      label: "Empleos/GWh",
      data: employment_gwh_data,
      backgroundColor: graphColors(employment_gwh_columns),
      borderWidth: 0,
      }]
  }
  const graph_options24 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: "Empleos/GWh"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: employmentgwh.title,
        align: "center",
      }
    }
  }

  //Graph 33
  const ChartData25 = {
    labels: labor_multipliers_columns,
    datasets: [
      {label: labor_multipliers_data[0].name,
      data: labor_multipliers_data[0].data,
      backgroundColor: graphColors([labor_multipliers_data[0].name]),
      borderWidth: 0,
      categoryPercentage: 0.8},
      {label: labor_multipliers_data[1].name,
        data: labor_multipliers_data[1].data,
        backgroundColor: graphColors([labor_multipliers_data[1].name]),
        borderWidth: 0,
        categoryPercentage: 0.8},
      {label: labor_multipliers_data[2].name,
      data: labor_multipliers_data[2].data,
      backgroundColor: graphColors([labor_multipliers_data[2].name]),
      borderWidth: 0,
      categoryPercentage: 0.8},
    ]}
  const graph_options25 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías"}},
      y: {title:{display: true, text: "Multiplicadores de empleo"}}
    },
    plugins : {
      title: {
        display: true,
        text: labormultipliers.title,
        align: "center",
      }
    }
  }

   //Data variables
   const [name, setName] = useState({
    "pdf_name": "MIP Expandida " + capitalize(values.country) + " " + values.year
  }); 

   const options={
    rowsPerPage: 30,
    selectableRows: "none",
    print: "false",
    download: "false",
    filter: "false",
    search: "false",
    resizableColumns: "true",

    responsive: "vertical",
    pagination: false,
    scroll: false,
    viewColumns: false,

    customHeadRender: (columnMeta, handleToggleColumn) => {
      return (
        <th key={columnMeta.index} style={{ fontSize: "16px" }}>
          {columnMeta.label}
        </th>
      );
    },
  }


  const handleInput = (event) =>{
    setName(prev => ({...prev, [event.target.name]:event.target.value}))
  };


  const chartRefs = useRef([]);
  const pages = 35
      
  const responsereport = (response) => {
    if (response === true) {
        setMsg("¡Reporte guardado exitosamente!")
        alert("Se ha guardado tu reporte exitosamente. Revisa la sección de reportes de la barra izquierda para poder visualizarlo.")
      }
    else {
      alert("No se ha podido guardar el reporte.")
      setMsg("No se ha podido guardar tu reporte")
    }
    setMsgPorcentage(null);
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
  }

  useEffect(() => {
    fetch(API_PORT+'/admin/reports/user/get_reports_list/?token=' + token, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json()).then(data => {
        setUsersReports(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      });
    }, [token]);


    const SaveReport = async () =>{
      // Verifica si el nombre del reporte ya existe
      const reportExists = usersReports.some(report => report.report_name === name.pdf_name);
      if (reportExists) {
          alert("El nombre del reporte ya existe. Cambia el nombre de este por otro que no esté guardado en tu cuenta.");
          return; // Detiene la ejecución si el nombre ya existe
      }

      const pdf2 = new jsPDF();
      setIsModalOpen(true)
      setProgress(0); // Inicia el progreso en 0%
      setMsg("Generando el reporte. No cierres la ventana hasta completar el proceso.")
      setLoaderScreen(prevState => {
        return {...prevState, started: true}
      })
      for (let i = 0; i < pages; i++) {
        const chartRef = chartRefs.current[i];
        const canvas = await html2canvas(chartRef);
        const imageData = canvas.toDataURL('image/png');
        const pdfWidth = pdf2.internal.pageSize.getWidth()
        const pdfHeight = pdf2.internal.pageSize.getHeight()
        const imgWidth = canvas.width
        const imgHeight = canvas.height
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
        pdf2.addImage(imageData, 'PNG', 16, 16, imgWidth*ratio*0.85, imgHeight*ratio*0.85);
        if (i < pages - 1) {
          pdf2.addPage();
        }
        setProgress(((i + 1) / pages) * 100);
        setMsgPorcentage((((i + 1) / pages) * 100).toFixed(0) + " %");
        
      }
      const reportData = new FormData();
      reportData.append('report_name', name.pdf_name);
      reportData.append('report_type', 'MIP Expandida');
      reportData.append('report_description', 'Reporte MIP Expandida');
      reportData.append('file', new Blob([pdf2.output('blob')]), name.pdf_name+'.pdf');
      setMsgPorcentage(null);
      setMsg("Guardando el reporte en tu cuenta. No cierres la ventana hasta completar el proceso.")

      try {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${API_PORT}/admin/reports/user/save_report/?token=${token}`, true);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setProgress(percentComplete);
                setMsgPorcentage(`${percentComplete.toFixed(0)} %`);
            }
        };

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                responsereport(true);
            } else {
                throw new Error('Error al guardar el reporte');
            }
        };

        xhr.onerror = () => {
            console.error('Ocurrió un error al intentar guardar el reporte');
            responsereport(false);
        };

        xhr.send(reportData);
    } catch (error) {
        console.error('Ocurrió un error al intentar guardar el reporte:', error);
        setMsgPorcentage(null);
        responsereport(false);
    } 
  }

  const generatePDF = async () => {
    const pdf = new jsPDF();
    setIsModalOpen(true)
    setProgress(0); // Inicia el progreso en 0%
    setMsg("Generando el reporte para ser descargado. No cierres la ventana hasta completar el proceso.")
    setLoaderScreen(prevState => {
      return {...prevState, started: true}
    })
    for (let i = 0; i < pages; i++) {
      const chartRef = chartRefs.current[i];
      const canvas = await html2canvas(chartRef);
      const imageData = canvas.toDataURL('image/png');
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      pdf.addImage(imageData, 'PNG', 16, 16, imgWidth*ratio*0.85, imgHeight*ratio*0.85);
      if (i < pages - 1) {
        pdf.addPage();
      }
      // Actualiza el progreso
      setProgress(((i + 1) / pages) * 100);
      setMsgPorcentage((((i + 1) / pages) * 100).toFixed(0) + " %");
    }
    setMsg("¡Reporte generado!")
    setMsgPorcentage(null);
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
    pdf.save(name.pdf_name);
  };


  function Render(user){
    if(user === "premium" || user === "admin"){
        return(
            <div>
                <div className='mt-10'><Header title="Coloca el nombre de tu reporte"/></div>
                    <div className='grid grid-cols-3 gap-3'>
                        <div className='columns-1'>
                            <h1 className='mb-3'><strong>Nombre del reporte</strong></h1>
                            <input type='string' placeholder={name.pdf_name} name='pdf_name' onChange={handleInput} className='form-control rounded-0 w-60 h-10 p-1' style={{border:"1px solid" ,borderRadius: "8px", color: "gray", backgroundColor:"white"}}></input>
                        </div>
                    </div>
                    <div className='flex gap-5 mt-16'>
                        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={SaveReport}>Guardar Reporte</button>
                        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={generatePDF}>Descargar Reporte</button>
                    </div>
                    <div key={0} ref={(ref) => (chartRefs.current[0] = ref)} style={{border: "1px solid", width: "100%", padding: "30px", marginTop: "120px"}}>
                        <div className='flex justify-center items-center'>
                          <img style={{maxWidth:"320px", marginBottom:"30px"}} src={ecokinesis} alt=''/>
                        </div>
                        <h1 className='flex justify-center items-center'><strong>Resultados Reporte MIP Expandida</strong></h1>
                        <h1 className='flex justify-center items-center'>País Ingresado: {capitalize(values.country)}</h1>
                        <h1 className='flex justify-center items-center'>Año Ingresado: {values.year}</h1>
                        <h1 className='flex justify-center items-center'>Fecha de creación del reporte: {date}/{month}/{year}</h1>

                        <h1 className='flex justify-center items-center mt-12'>EcoKinesis Proyecto Fondef Idea ID22I10341 es propiedad de la Universidad Técnica Federico Santa María y ANID</h1>
                        <div className='flex justify-center items-center gap-10 mt-6'>
                          <img style={{maxWidth:"100px"}} src={anid} alt=''/>
                          <img style={{maxWidth:"150px"}} src={utfsm} alt=''/>
                        </div>
                        <h1 className='flex justify-center items-center mt-12'>Disclaimer: Este reporte ha sido generado automáticamente en base a los datos ingresados del usuario en la plataforma de EcoKinesis. EcoKinesis no se hace responsable del uso o vericidad de los datos en este reporte.</h1>
                    </div>
                    <div className="mt-10" key={1} ref={(ref) => (chartRefs.current[1] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>MIP Resumida: La tabla muestra la MIP o Matriz Insumo Producto resumida del año y país ingresado resultante de la expansión de la actividad de generación eléctrica y posterior calibración según los datos bottom-up del sector eléctrico. Esta se agrupa por los sectores de actividades económicas para los ejes y sus valores corresponden a la moneda nacional medida en {coins[values.country]}.</h1>
                        <div style={{maxWidth: "100%"}}>
                           <DynamicTableMin title={calibratedmip.title} columns={resumed_mip_columns} rows={resumed_mip_data} cellSize="min"/>
                        </div>
                    </div>
                    <div className="mt-10" key={2} ref={(ref) => (chartRefs.current[2] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>MIP Leontieff: La tabla muestra la MIP o Matriz Insumo Producto inversa de Leontieff. Esta es resultante de la Matriz Insumo Producto expandida  de generación eléctrica y posteriormente calibrada según los datos bottom-up del sector eléctrico. Se agrupa por sector de la actividad económica para sus ejes y la suma de cada columna corresponde a los multiplicadores de la producción, teniendo de esta manera un valor en el orden de 1.</h1>
                        <div style={{width: "100%"}}>
                          <DynamicTable title={inverted.title} columns={inverted_mip_columns} rows={inverted_mip_data} cellSize="small"/>
                        </div>
                    </div>
                    <div className="mt-10" key={3} ref={(ref) => (chartRefs.current[3] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Resumen Indicadores: La tabla muestra un resumen de los indicadores de la MIP Expandida. Las columnas de esta tabla corresponden a diferentes tecnologías, mientras que las filas son indicadores MIP y sus valores varían para cada una de estas.</h1>
                        <div style={{width: "100%"}}>
                        <MUIDataTable
                          title={summary.title}
                          data={summary_data}
                          columns={summary_columns}
                          options={options}
                          ></MUIDataTable></div>
                    </div>
                    <div className="mt-10" key={4} ref={(ref) => (chartRefs.current[4] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Componentes valor agregado: En el gráfico pueden verse las relaciones del valor agregado, específicamente, el aporte al PIB de diferentes tecnologías para los componentes de remuneraciones de asalariados, excedente bruto de importación e impuestos netos sobre la producción.</h1>
                      <div style={{width: "1000px", height: "520px"}}>
                        {HorizontalTables(ChartData1, graph_options1)}
                      </div>
                    </div>
                    <div className="mt-10" key={5} ref={(ref) => (chartRefs.current[5] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Porcentaje participación componentes: En el gráfico pueden verse las relaciones del valor agregado, específicamente, los porcentajes de participación de diferentes tecnologías para los componentes de remuneraciones de asalariados, excedente bruto de importación e impuestos netos sobre la producción.</h1>
                      <div style={{width: "1000px", height: "520px"}}>
                        {HorizontalTables(ChartData2, graph_options2)}
                      </div>
                    </div>
                    <div className="mt-10" key={6} ref={(ref) => (chartRefs.current[6] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Porcentaje participación total: En el gráfico pueden verse las relacionados del valor agregado, específicamente los porcentajes de participación de los componentes del valor agregado (remuneraciones de aslariados, excedente bruto de explotación, impuestos netos sobre la producción y valor agregado) para diferentes tecnologías.</h1>
                      <div style={{width: "1000px", height: "520px"}}>
                        {HorizontalTables(ChartData3, graph_options3)}
                      </div>
                    </div>
                    <div className="mt-10" key={7} ref={(ref) => (chartRefs.current[7] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Detalle valor agregado: En esta tabla pueden observarse los detalles de demanda y valor agregado del sector de generación eléctrica. Las columnas de esta tabla corresponden a diferentes tipos de tecnologías, mientras que sus filas corresponden a los indicadores medidos en {coins[values.country]}.</h1>
                        <div style={{width: "100%"}}>
                        <MUIDataTable
                        title={detailcomposition.title}
                        data={detail_compositions_data}
                        columns={detail_compositions_columns}
                        options={options}
                        ></MUIDataTable></div>
                    </div>
                    <div className="mt-10" key={8} ref={(ref) => (chartRefs.current[8] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Impacto PIB directo e indirecto: En este gráfico se muestra el efecto directo e indirecto en el PIB. Las columnas representan diferentes tecnologías de los sectores de generación, almacenamiento y H2, mientras que sus valores corresponden a los impactos medidos en {coins[values.country]}.</h1>
                      <div style={{width: "1000px", height: "520px"}}>
                        {HorizontalTables(ChartData4, graph_options4)}
                      </div>
                    </div>
                    <div className="mt-10" key={9} ref={(ref) => (chartRefs.current[9] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Contribución porcentual PIB directo: En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB directo para el año y país ingresado.</h1>
                      <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
                        <Doughnut data={ChartData5} options={graph_options5}/>
                      </div>
                    </div>
                    <div className="mt-10" key={10} ref={(ref) => (chartRefs.current[10] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Contribución porcentual PIB indirecto: En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB indirecto para el año y país ingresado.</h1>
                      <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
                        <Doughnut data={ChartData6} options={graph_options6}/>
                      </div>
                    </div>
                    <div className="mt-10" key={11} ref={(ref) => (chartRefs.current[11] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Contribución porcentual PIB total: En este gráfico se observa la contribución porcentual de diferentes tecnologías de los sectores de generación, almacenamiento y H2 respecto al PIB total (sumando el directo e indirecto) para el año y país ingresado.</h1>
                      <div style={{width: "740px", height: "480px", display:"flex", alignItems: "center", justifyContent: "center"}}>
                        <Doughnut data={ChartData7} options={graph_options7}/>
                      </div>
                    </div>
                    <div className="mt-10" key={12} ref={(ref) => (chartRefs.current[12] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Exportaciones e importaciones: En este gráfico se muestran las exportaciones, importaciones y exportaciones netas para diferentes tecnologías del año y país ingresados medidas en {coins[values.country]}.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData8} options={graph_options8}/>
                      </div>
                    </div>
                    <div className="mt-10" key={13} ref={(ref) => (chartRefs.current[13] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Distribución capital: En este gráfico se muestra el excedente bruto de capital medido en {coins[values.country]} de diferentes tecnologías para el año y país ingresados.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData9} options={graph_options9}/>
                      </div>
                    </div>
                    <div className="mt-10" key={14} ref={(ref) => (chartRefs.current[14] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Distribución remuneraciones: En este gráfico se muestra el total de remuneraciones medido en {coins[values.country]} de diferentes tecnologías para el año y país ingresados.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData10} options={graph_options10}/>
                      </div>
                    </div>
                    <div className="mt-10" key={15} ref={(ref) => (chartRefs.current[15] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Impacto fiscal: En esta tabla se muestra un resumen del impacto fiscal para el año y país seleccionado. Sus columnas corresponden a diferentes tecnologías, mientras que sus filas son diversos indicadores de impactos fiscales, cada uno con diferentes unidades de medidas.</h1>
                        <div style={{width: "100%"}}>
                        <MUIDataTable
                          title={fiscalimpact.title}
                          data={fiscal_impacts_data}
                          columns={fiscal_impacts_columns}
                          options={options}
                          ></MUIDataTable></div>
                    </div>
                    <div className="mt-10" key={16} ref={(ref) => (chartRefs.current[16] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Multiplicadores del producto: En este gráfico se muestran los multiplicadores del producto de los sectores de generación, almacenamiento y H2. Este indicador indica cuanto aumenta la producción al aumentar en 1 unidad la demanda final. Las columnas del gráfico representan diferentes tecnologías y sus valores corresponden a los multiplicadores del producto.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData11} options={graph_options11}/>
                      </div>
                    </div>
                    <div className="mt-10" key={17} ref={(ref) => (chartRefs.current[17] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Contribución GWh: En este gráfico se muestra la contribución al PIB de diferentes tecnologías por GWh. Las columnas representan las tecnologías de generación y sus valores corresponden a la razón del valor agregado dividido por la generación, medida en {coins[values.country]}.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData12} options={graph_options12}/>
                      </div>
                    </div>
                    <div className="mt-10" key={18} ref={(ref) => (chartRefs.current[18] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Multiplicadores del PIB: En este gráfico se muestran los multiplicadores del PIB para diferentes tipos de tecnologías. Estas indican el aumento del PIB cuando la demanda final aumenta en 1 unidad. Se observan 3 tipos tipos de multiplicadores, el directo, el indirecto y el total, siendo este una suma de los 2 anteriores.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData13} options={graph_options13}/>
                      </div>
                    </div>
                    <div className="mt-10" key={19} ref={(ref) => (chartRefs.current[19] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Multiplicadores de renta: En este gráfico se muestran los multiplicadores del efecto renta para diferentes tipos de tecnologías. Estas indican el aumento de la renta cuando la demanda final aumenta en 1 unidad. Se observan 2 tipos de multiplicadores, el de producción de renta total y el consumos intermedios de renta.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData14} options={graph_options14}/>
                      </div>
                    </div>
                    <div className="mt-10" key={20} ref={(ref) => (chartRefs.current[20] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Multiplicadores de inversión: En este gráfico se muestran los multiplicadores de inversión para diferentes tipos de tecnologías. Estas indican el aumento de la inversión cuando la demanda final aumenta en 1 unidad.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData15} options={graph_options15}/>
                      </div>
                    </div>
                    <div className="mt-10" key={21} ref={(ref) => (chartRefs.current[21] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Clasificación sectorial: La tabla muestra la clasificación sectorial de Rasmussen. Esta asigna diferentes sectores para cada tipo de tecnología en base a la matriz inversa de Leontieff.</h1>
                        <div style={{width: "100%"}}>
                        <MUIDataTable
                        title={techimportance.title}
                        data={tech_importance_data}
                        columns={tech_importance_columns}
                        options={options}
                        ></MUIDataTable></div>
                    </div>
                    <div className="mt-10" key={22} ref={(ref) => (chartRefs.current[22] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Emisiones directas y huella de carbono: El gráfico muestra las emisiones directas y la huella de carbono medidas en mega toneladas de CO2 equivalente para diferentes actividades económicas del año y país ingresados.</h1>
                      <div style={{width: "1200px", height: "560px"}}>
                        <Bar data={ChartData16} options={graph_options16}/>
                      </div>
                    </div>
                    <div className="mt-10" key={23} ref={(ref) => (chartRefs.current[23] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Huella de carbono sobre valor agregado: El gráfico muestra el ratio de huella de carbon sobre el valor agregado para diferentes tecnologías, es decir, la huella de carbono de cada sector por cada mil de millones de pesos de aporte al PIB. Esta se mida en kilo toneladas de CO2 equivalente dividido en {coins[values.country]}.</h1>
                      <div style={{width: "1200px", height: "560px"}}>
                        <Bar data={ChartData17} options={graph_options17}/>
                      </div>
                    </div>
                    <div className="mt-10" key={24} ref={(ref) => (chartRefs.current[24] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Huella de carbono por fuente: En este gráfico se muestra el origen de la huella de carbono para todas las actividades económicas consideradas en las emisiones directas. Las columnas corresponden a la actividad económica que realiza la emisión, las filas corresponden a la actividad económica cuya demanda final provoca la emisión, y sus valores corresponden al resultado de estas medidas en mega toneladas de CO2 equivalente.</h1>
                        <div style={{width: "100%"}}>
                          <DynamicTable title={carbonsource.title} columns={carbon_source_columns} rows={carbon_source_data} cellSize="small"/>
                        </div>
                    </div>
                    <div className="mt-10" key={25} ref={(ref) => (chartRefs.current[25] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Huella de carbono ESS+H2: En el gráfico se muestra el origen de las emisiones de la huella de carbono de los sectores de generación eléctrica, almacenamiento e hidrogeno verde. Las columnas representan las actividades económicas y sus valores la huella de carbono de diversos sectores medida en mega toneladas de CO2 equivalente.</h1>
                      <div style={{width: "1100px", height: "520px"}}>
                        <Bar data={ChartData18} options={graph_options18}/>
                      </div>
                    </div>
                    <div className="mt-10" key={26} ref={(ref) => (chartRefs.current[26] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Huella demanda final por fuente: En este gráfico se muestra el origen de la huella de carbono para todas las actividades económicas consideradas en las emisiones directas por cada {coins[values.country]} de demanda final. Las columnas corresponden a la actividad económica que realiza la emisión, las filas corresponden a la actividad económica cuya demanda final provoca la emisión, y sus valores corresponden al resultado de estas medidas en mega toneladas de CO2 equivalente por {coins[values.country]}.</h1>
                      <div style={{width: "100%"}}>
                        <DynamicTable title={carbonfinaldemand.title} columns={carbon_final_demand_columns} rows={carbon_final_demand_data} cellSize="small"/>
                      </div>
                    </div>
                    <div className="mt-10" key={27} ref={(ref) => (chartRefs.current[27] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Huella demanda final por fuente ESS+H2: En el gráfico se muestra el origen de las emisiones de la huella de carbono de los sectores de generación eléctrica, almacenamiento y H2 por cada {coins[values.country]} de demanda final. Las columnas representan las actividades económicas y sus valores la huella de carbono de diversos sectores medida en kilo toneladas de CO2 equivalente por {coins[values.country]}.</h1>
                      <div style={{width: "1100px", height: "520px"}}>
                        <Bar data={ChartData19} options={graph_options19}/>
                      </div>
                    </div>
                    <div className="mt-10" key={28} ref={(ref) => (chartRefs.current[28] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Empleo directo e indirecto: En el gráfico se observa la cantidad total de empleos directos y totales generados para diferentes tecnologías del año y país seleccionados.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData20} options={graph_options20}/>
                      </div>
                    </div>
                    <div className="mt-10" key={29} ref={(ref) => (chartRefs.current[29] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Análisis de remuneraciones: En la tabla se muestra un análisis de remuneraciones de empleo del año y país ingresado. Las columnas de esta tabla corresponden a diversos tipos de tecnologías y las filas corresponden a los indicadores, cada una con diferentes unidades de medida.</h1>
                      <div style={{width: "100%"}}>
                      <MUIDataTable
                        title={remunerationanalisis.title}
                        data={remuneration_analisis_data}
                        columns={remuneration_analisis_columns}
                        options={options}
                        ></MUIDataTable></div>
                    </div>
                    <div className="mt-10" key={30} ref={(ref) => (chartRefs.current[30] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Empleo histórico: El gráfico muestra el avance histórico de los empleos directos generados para diferentes tipos de tecnologías.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Line data={ChartData21} options={graph_options21}/>
                      </div>
                    </div>
                    <div className="mt-10" key={31} ref={(ref) => (chartRefs.current[31] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>PIB por empleo: El gráfico muestra el PIB promedio generado por empleados medido en {coins[values.country]} para diferentes tipos de tecnologías del año y país seleccionados.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData22} options={graph_options22}/>
                      </div>
                    </div>
                    <div className="mt-10" key={32} ref={(ref) => (chartRefs.current[32] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Demanda final por tecnología: El gráfico muestra el ratio número de empleos totales y directos generados divididos por la demanda final para diferentes tipos de tecnologías del año y país seleccionados.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData23} options={graph_options23}/>
                      </div>
                    </div>
                    <div className="mt-10" key={33} ref={(ref) => (chartRefs.current[33] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Empleos por Gwh: El gráfico muestra la cantidad de empleos por GWh generado para diferentes tipos de tecnologías del año y país seleccionados.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData24} options={graph_options24}/>
                      </div>
                    </div>
                    <div className="mt-10" key={34} ref={(ref) => (chartRefs.current[34] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Multiplicadores de empleo: El gráfico muestra los multiplicadores de empleo directo, indirecto y totales (suma de los 2 anteriores) para diferentes tecnologías del año y país ingresados.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                        <Bar data={ChartData25} options={graph_options25}/>
                      </div>
                    </div>
                  </div>
        )
    }
    else{
        return(
          <div className='mt-16'>
          <h1 className='flex justify-center mb-3'><strong>Contrata la suscripción	premium de EcoKinesis para poder guardar y descargar reportes</strong></h1>
          <div className='flex justify-center'>
              <div className='mb-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", maxWidth:"540px", maxHeight:"380px"}}>
                <div className='justify-center align-items-center text-center'>
                  <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
                  <div className="text-sm mt-3" style={{color:"gray"}}><strong>Contrata la suscripción premium de EcoKinesis para poder usar esta sección</strong></div>
                  <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", minWidth: "120px", maxWidth:"260px", height:"54px", marginTop:"10px", marginBottom: "10px"}} onClick={()=>{navigate("/dashboard/premium")}}>Contratar</button>
                </div>
              </div>
          </div>
      </div>
        )
    }
  }

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <button onClick={() => {navigate("/dashboard/expanded_mip/results", {state: {values,calibratedmip, inverted, calibratedmipcomplete, summary, components, detailcomposition,impactscontribution, exportimports, ratefosilrenovable, capitaldistribution, remunerationdistribution, fiscalimpact, productionmultipliers, gwhcontribution, pibmultipliers, rentmultipliers, inversionmultipliers, techimportance, directemissions, carbonfootprint, carbonsource, carbonessh2, carbonfinaldemand, carbonfinaldemandessh2, inducedemployment, remunerationanalisis, historicalemployees, pibemployment, finaldemand, employmentgwh, labormultipliers, coins}})}} style={{color:"white",backgroundColor:"gray",borderRadius:"10px", width:"200px", height: "60px"}}>Volver a mi resultado de MIP Expandida</button>
      </div>  
      <Header title="Generar reporte MIP Expandida"/>
      <p className='text-gray-500' style={{maxWidth: "85%"}}>En esta sección podrás guardar y descargar los resultados de la MIP Expandida. Ingresa el nombre de tu reporte. Si pulsas el botón de descarga, se descargará un PDF con tus resultados. Si pulsas la opción de guardar reporte, este se guardará en la zona de reportes. Un reporte no puede ser guardado si es que ya existe uno en tu cuenta con el mismo nombre. En la zona inferior verás la lista de tablas y gráficos de la MIP Expandida que se almacenarán en tu reporte. Estas opciones sólo están disponible para usuarios que cuenten con la <Link to= "/dashboard/premium" className='text-blue-500'>suscripción premium de EcoKinesis</Link>.</p>
      {Render(user_type)}
      <ModalLoader msg={msg} msgporcentage={msgporcentage} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
      <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default GeneratePDFExpandedMIP