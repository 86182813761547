import {React, useState, useContext} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header, Button } from '../../../../components';
import { API_PORT } from '../../../../API_PORT';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import Modal from '../../../../components/Modal';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

const DirectFinalDemandSelector2 = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const navigate = useNavigate();
  const location = useLocation()
  const values = location.state.values
  const coins = location.state.coins

  const [technologies, setTechnologies] = useState({
    "Fosil": 0,
    "Hidraulica": 0,
    "BiomasaBiogas": 0,
    "Solar": 0,
    "Eolica": 0,
    "Geotermia": 0,
    "AlmacenamientoLargo": 0,
    "AlmacenamientoCorto": 0,
    "H2": 0,

    "FosilExport": 0,
    "HidraulicaExport": 0,
    "BiomasaBiogasExport": 0,
    "SolarExport": 0,
    "EolicaExport": 0,
    "GeotermiaExport": 0,
    "AlmacenamientoLargoExport": 0,
    "AlmacenamientoCortoExport": 0,
    "H2Export": 0,

    "Unities": "GWh"
  });

  const [progress] = useState({started: false, pc: 0});
  const [msg, setMsg] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [directfinaldemand, setdirectfinaldemand] = useState([])

  const handleInput = (event) =>{
    setTechnologies(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  const DirectFinalDemandSelector = () => {
    const direct_final_demand = API_PORT + "/scene/quantitative_and_price_models/quantitative-model/direct-final-demand-set/" + values.country + "/" + values.year + "?token="+token
    const new_body = {
      "InternalFinalDemandInput": {
        "fósil": technologies.Fosil,
        "hidráulica": technologies.Hidraulica,
        "biomasa y biogas": technologies.BiomasaBiogas,
        "solar": technologies.Solar,
        "eólica": technologies.Eolica,
        "geotermia": technologies.Geotermia,
        "almacenamiento largo plazo": technologies.AlmacenamientoLargo,
        "almacenamiento corto plazo": technologies.AlmacenamientoCorto,
        "H2": technologies.H2
      },
      "ExportsFinalDemandInput": {
        "fósil": technologies.FosilExport,
        "hidráulica": technologies.HidraulicaExport,
        "biomasa y biogas": technologies.BiomasaBiogasExport,
        "solar": technologies.SolarExport,
        "eólica": technologies.EolicaExport,
        "geotermia": technologies.GeotermiaExport,
        "almacenamiento largo plazo": technologies.AlmacenamientoLargoExport,
        "almacenamiento corto plazo": technologies.AlmacenamientoCortoExport,
        "H2": technologies.H2Export
      },
      "Unities": technologies.Unities
    }

    setIsModalOpen(true)
    setMsg("Calculando la simulación, no cierre esta pestaña hasta completar el proceso.")
    setLoaderScreen(prevState => {return {...prevState, started: true}});

    fetch(direct_final_demand, {
    method: 'POST',
    headers: {'Content-type': 'application/json'},
    body: JSON.stringify(new_body)
    }).then(res => res.json()).then(data => {setdirectfinaldemand(data);})
  }

  function UpdateValues(){
    if(location.state?.technologies){
      setTechnologies({...technologies, Fosil : location.state.technologies.Fosil, Hidraulica: location.state.technologies.Hidraulica, BiomasaBiogas: location.state.technologies.BiomasaBiogas, Solar: location.state.technologies.Solar, Eolica: location.state.technologies.Eolica, Geotermia: location.state.technologies.Geotermia, AlmacenamientoCorto: location.state.technologies.AlmacenamientoCorto, AlmacenamientoLargo: location.state.technologies.AlmacenamientoLargo, H2: location.state.technologies.H2, FosilExport : location.state.technologies.FosilExport, HidraulicaExport: location.state.technologies.HidraulicaExport, BiomasaBiogasExport: location.state.technologies.BiomasaBiogasExport, SolarExport: location.state.technologies.SolarExport, EolicaExport: location.state.technologies.EolicaExport, GeotermiaExport: location.state.technologies.GeotermiaExport, AlmacenamientoCortoExport: location.state.technologies.AlmacenamientoCortoExport, AlmacenamientoLargoExport: location.state.technologies.AlmacenamientoLargoExport, H2Export: location.state.technologies.H2Export})  
    }
  }

  function UpdatePreviousValues(){
    if (location.state?.technologies){
      return(
        <TooltipComponent content='Al pulsar este botón se cargarán los valores de la última simulación realizada'><button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={() => {UpdateValues()}}>Cargar los datos de la última simulación realizada</button></TooltipComponent>
      )
    }
    else{
      return(<div>
      </div>)
    }
  }

  const NavigateResults = () => {
    if(directfinaldemand.MIP_base_year?.title){
      alert("Se han calculados los datos de la simulación. Pulsa para continuar.")
      navigate("/dashboard/simulations/main/leontieff_scenary/direct_final_demand_results", {state: {technologies,values,directfinaldemand, coins}})
    }
  }

  NavigateResults()

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-6'>
            <Link to="/dashboard/simulations/main/leontieff_scenary/direct_final_demand_selector1" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver a la selección de país y años"
                borderRadius="10px"
                size="md"
              />
            </Link>
      </div>  
      <Header title={"Shock demanda final directa " + capitalize(values.country) + " " + values.year}/>
      <p className='text-gray-500'>En esta sección podrás generar tus propias simulaciones de escenarios Leontieff para el shock de demanda final directa. Ingresa los datos de unidad, demanda final interna y de exportación para ver los resultados.</p>
      <div className='mt-10'><Header title="Unidad de resultado"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <h1 className='mb-3'>Unidad</h1>
          <select type='number' placeholder='' name='Unities' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'>
            <option value="GWh">GWh</option>
            <option value="Miles de millones de moneda nacional">MMCLP</option>
          </select>
        </div>
      </div>

      <div className='mt-10'><Header title="Demanda final interna"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <h1 className='mb-3'>Fósil</h1>
          <label htmlFor='Fosil'></label>
          <input type='number' placeholder={technologies.Fosil} name='Fosil' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Hidráulica</h1>
          <label htmlFor='Hidraulica'></label>
          <input type='number' placeholder={technologies.Hidraulica} name='Hidraulica' onChange={handleInput}className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Biomasa y Biogas</h1>
          <label htmlFor='BiomasaBiogas'></label>
          <input type='number' placeholder={technologies.BiomasaBiogas} name='BiomasaBiogas' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Solar</h1>
          <label htmlFor='Solar'></label>
          <input type='number' placeholder={technologies.Solar} name='Solar' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Eólica</h1>
          <label htmlFor='Eolica'></label>
          <input type='number' placeholder={technologies.Eolica} name='Eolica' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Geotermia</h1>
          <label htmlFor='Geotermia'></label>
          <input type='number' placeholder={technologies.Geotermia} name='Geotermia' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Almacenamiento Largo Plazo</h1>
          <label htmlFor='AlmacenamientoLargo'></label>
          <input type='number' placeholder={technologies.AlmacenamientoLargo} name='AlmacenamientoLargo' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Almacenamiento Corto Plazo</h1>
          <label htmlFor='AlmacenamientoCorto'></label>
          <input type='number' placeholder={technologies.AlmacenamientoCorto} name='AlmacenamientoCorto' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h3 className='mb-3'>H2</h3>
          <label htmlFor='H2'></label>
          <input type='number' placeholder={technologies.H2} name='H2' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
      </div>

      <div className='mt-10'><Header title="Demanda final exportación"/></div>
      <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-5'>
        <div className='column-span-1'>
          <h1 className='mb-3'>Fósil</h1>
          <label htmlFor='FosilExport'></label>
          <input type='number' placeholder={technologies.FosilExport} name='FosilExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Hidráulica</h1>
          <label htmlFor='HidraulicaExport'></label>
          <input type='number' placeholder={technologies.HidraulicaExport} name='HidraulicaExport' onChange={handleInput}className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Biomasa y Biogas</h1>
          <label htmlFor='BiomasaBiogasExport'></label>
          <input type='number' placeholder={technologies.BiomasaBiogasExport} name='BiomasaBiogasExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Solar</h1>
          <label htmlFor='SolarExport'></label>
          <input type='number' placeholder={technologies.SolarExport} name='SolarExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Eólica</h1>
          <label htmlFor='EolicaExport'></label>
          <input type='number' placeholder={technologies.EolicaExport} name='EolicaExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Geotermia</h1>
          <label htmlFor='GeotermiaExport'></label>
          <input type='number' placeholder={technologies.GeotermiaExport} name='GeotermiaExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Almacenamiento Largo Plazo</h1>
          <label htmlFor='AlmacenamientoLargoExport'></label>
          <input type='number' placeholder={technologies.AlmacenamientoLargoExport} name='AlmacenamientoLargoExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h1 className='mb-3'>Almacenamiento Corto Plazo</h1>
          <label htmlFor='AlmacenamientoCortoExport'></label>
          <input type='number' placeholder={technologies.AlmacenamientoCortoExport} name='AlmacenamientoCortoExport' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
        <div className='column-span-1'>
          <h3 className='mb-3'>H2</h3>
          <label htmlFor='H2Export'></label>
          <input type='number' placeholder={technologies.H2Export} name='H2Export' onChange={handleInput} className='form-control rounded-0 w-full h-10 border border-gray-300 px-3 py-2'></input>
        </div>
      </div>
      
      <div className='mt-16 flex gap-5'>
        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={DirectFinalDemandSelector}>Ver Resultados</button>
        {UpdatePreviousValues()}
      </div>
      <Modal msg={msg} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
    </div>
  )
}

export default DirectFinalDemandSelector2