import {React, useState, useContext, useRef, useEffect} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { Header } from '../../../../components';
import { useNavigate, useLocation } from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import { Link } from 'react-router-dom';
import graphColors from '../../../../hook/graphColors';
import ModalLoader from '../../../../components/ModalLoader';
import LoadingScreen from '../../../../components/LoadingScreen';
import ecokinesis from "../../../../assets/ecokinesis.png"
import MUIDataTable from "mui-datatables";
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { API_PORT } from '../../../../API_PORT';
import anid from "../../../../assets/anid_rojo_azul.png"
import utfsm from "../../../../assets/utfsm.png"
import { Bar} from "react-chartjs-2";
import { Chart as ChartJS, Title, BarElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, LineElement, Filler, Tooltip, Legend, ArcElement} from "chart.js";
ChartJS.register(Title, BarElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, LineElement, ArcElement, Filler, Tooltip, Legend)

const SimulationOperationsPDF = () => {
  const [progress, setProgress] = useState(0);
  const [msg, setMsg] = useState(null);
  const [msgporcentage, setMsgPorcentage] = useState(null);
  const [loaderscreen, setLoaderScreen] = useState({started: false});
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [loadingscreen, setLoadingScreen] = useState({started: true});
  const [open, setOpen] = useState(true);

  const [usersReports, setUsersReports] = useState([])
    const userContext = useContext(UserContext);
    const user_type= userContext.value.user_type;
    const token = userContext.value.access_token;
  
    const navigate = useNavigate();
    const location = useLocation();
  
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();
  
    //Define const of location
    const values = location.state.values
    const configuration = location.state.configuration
    const mso = location.state.mso
    const energy_production_columns = location.state.energy_production_columns
    const energy_production_columns2 = location.state.energy_production_columns2
    const energy_production_data = location.state.energy_production_data
    const energy_production_data2 = location.state.energy_production_data2
    const specific_energy_columns = location.state.specific_energy_columns
    const specific_energy_data = location.state.specific_energy_data
    const specific_energy_columns2 = location.state.specific_energy_columns2
    const specific_energy_data2 = location.state.specific_energy_data2
    const capacity_tech_columns = location.state.capacity_tech_columns
    const capacity_tech_columns2 = location.state.capacity_tech_columns2
    const capacity_tech_data = location.state.capacity_tech_data
    const capacity_tech_data2 = location.state.capacity_tech_data2
    const specific_capacity_columns = location.state.specific_capacity_columns
    const specific_capacity_columns2 = location.state.specific_capacity_columns2
    const specific_capacity_data = location.state.specific_capacity_data
    const specific_capacity_data2 = location.state.specific_capacity_data2
    const coins = location.state.coins

     //Data variables
  const [name, setName] = useState({
    "pdf_name": "Escenario operación simulada " + capitalize(values.country) + " " + values.pelp_year
  }); 

   const options={
    rowsPerPage: 30,
    selectableRows: "none",
    print: "false",
    download: "false",
    filter: "false",
    search: "false",
    resizableColumns: "true",
    responsive: "vertical",
    pagination: false,
    scroll: false,
    viewColumns: false,
  }

  const handleInput = (event) =>{
    setName(prev => ({...prev, [event.target.name]:event.target.value}))
  };

  //Graph 3
  const ChartData1 = {
    labels: energy_production_columns2,
    datasets: [{
      label: mso.Energy_production_by_tech.data[0].x_index,
      data: energy_production_data2[0],
      backgroundColor: graphColors(energy_production_columns2),
      borderWidth: 0,
      }]
  }
  const graph_options1 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías", color: "black"}},
      y: {title:{display: true, text: mso.Energy_production_by_tech.data[0].x_index, color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: mso.Energy_production_by_tech.title,
        align: "center",
      }
    }
  }

  //Table 4
  var columns1 = []
  specific_energy_columns.forEach((element) => {
    columns1.push({
      name: element,
      label: element,
      options: {
        setCellProps: () => ({ style: { padding: 4, fontSize: 13 }}),
        setCellHeaderProps: () => ({ style: { padding: 4, fontSize: 13 }}),
      }
    })
  })

  //Graph 5
  const ChartData2 = {
    labels: specific_energy_columns2,
    datasets: [{
      label: mso.Specific_energy_production_by_tech.data[0].x_index,
      data: specific_energy_data2[0],
      backgroundColor: graphColors(specific_energy_columns2),
      borderWidth: 0,
      }]
  }
  const graph_options2 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías", color: "black"}},
      y: {title:{display: true, text: mso.Specific_energy_production_by_tech.data[0].x_index, color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: mso.Specific_energy_production_by_tech.title,
        align: "center",
      }
    }
  }

  //Graph 7
  const ChartData3 = {
    labels: capacity_tech_columns2,
    datasets: [{
      label: mso.Capacity_by_tech.data[0].x_index,
      data: capacity_tech_data2[0],
      backgroundColor: graphColors(capacity_tech_columns2),
      borderWidth: 0,
      }]
  }
  const graph_options3 = {
    scales: {
      x: {title:{display: true, text: "Tecnologías", color: "black"}},
      y: {title:{display: true, text: mso.Capacity_by_tech.data[0].x_index, color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: mso.Capacity_by_tech.title,
        align: "center",
      }
    }
  }

  //Table 8
  var columns2 = []
  specific_capacity_columns.forEach((element) => {
    columns2.push({
      name: element,
      label: element,
      options: {
        setCellProps: () => ({ style: { padding: 4, fontSize: 14 }}),
        setCellHeaderProps: () => ({ style: { padding: 4, fontSize: 14 }}),
      }
    })
  })

  //Graph 9
  const ChartData4 = {
    labels: specific_capacity_columns2,
    datasets: [{
      label: mso.Specific_capacity_by_tech.data[0].x_index,
      data: specific_capacity_data2[0],
      backgroundColor: graphColors(specific_capacity_columns2),
      borderWidth: 0,
      }]
  }
  const graph_options4= {
    scales: {
      x: {title:{display: true, text: "Tecnologías", color: "black"}},
      y: {title:{display: true, text: mso.Specific_capacity_by_tech.data[0].x_index, color: "black"}}
    },
    plugins : {
      legend: {display: false},
      title: {
        display: true,
        text: mso.Specific_capacity_by_tech.title,
        align: "center",
      }
    }
  }


  const chartRefs = useRef([]);
  const pages = 9

  const responsereport = (response) => {
    if (response === true) {
        setMsg("¡Reporte guardado exitosamente!")
        alert("Se ha guardado tu reporte exitosamente. Revisa la sección de reportes de la barra izquierda para poder visualizarlo.")
      }
    else {
      alert("No se ha podido guardar el reporte.")
      setMsg("No se ha podido guardar tu reporte")
    }
    setMsgPorcentage(null);
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
  }

  useEffect(() => {
    fetch(API_PORT+'/admin/reports/user/get_reports_list/?token=' + token, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
      })
      .then(res => res.json()).then(data => {
        setUsersReports(data);
        setOpen(false);
        setLoadingScreen(prevState => {return {...prevState, started: false}});
      });
    }, []);

    const SaveReport = async () =>{
      // Verifica si el nombre del reporte ya existe
      const reportExists = usersReports.some(report => report.report_name === name.pdf_name);
      if (reportExists) {
          alert("El nombre del reporte ya existe. Cambia el nombre de este por otro que no esté guardado en tu cuenta.");
          return; // Detiene la ejecución si el nombre ya existe
      }

      const pdf2 = new jsPDF();
      setIsModalOpen(true)
      setProgress(0); // Inicia el progreso en 0%
      setMsg("Generando el reporte. No cierres la ventana hasta completar el proceso.")
      setLoaderScreen(prevState => {
        return {...prevState, started: true}
      })
      for (let i = 0; i < pages; i++) {
        const chartRef = chartRefs.current[i];
        const canvas = await html2canvas(chartRef);
        const imageData = canvas.toDataURL('image/png');
        const pdfWidth = pdf2.internal.pageSize.getWidth()
        const pdfHeight = pdf2.internal.pageSize.getHeight()
        const imgWidth = canvas.width
        const imgHeight = canvas.height
        const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
        pdf2.addImage(imageData, 'PNG', 16, 16, imgWidth*ratio*0.85, imgHeight*ratio*0.85);
        if (i < pages - 1) {
          pdf2.addPage();
        }
        setProgress(((i + 1) / pages) * 100);
        setMsgPorcentage((((i + 1) / pages) * 100).toFixed(0) + " %");
        
      }
      const reportData = new FormData();
      reportData.append('report_name', name.pdf_name);
      reportData.append('report_type', 'Escenario Operación Simulada');
      reportData.append('report_description', 'Reporte Escenario Operación Simulada');
      reportData.append('file', new Blob([pdf2.output('blob')]), name.pdf_name+'.pdf');
      setMsgPorcentage(null);
      setMsg("Guardando el reporte en tu cuenta. No cierres la ventana hasta completar el proceso.")

      try {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', `${API_PORT}/admin/reports/user/save_report/?token=${token}`, true);

        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setProgress(percentComplete);
                setMsgPorcentage(`${percentComplete.toFixed(0)} %`);
            }
        };

        xhr.onload = () => {
            if (xhr.status >= 200 && xhr.status < 300) {
                console.log('Archivo subido con éxito');
                responsereport(true);
            } else {
                throw new Error('Error al guardar el reporte');
            }
        };

        xhr.onerror = () => {
            console.error('Ocurrió un error al intentar guardar el reporte');
            responsereport(false);
        };

        xhr.send(reportData);
    } catch (error) {
        console.error('Ocurrió un error al intentar guardar el reporte:', error);
        setMsgPorcentage(null);
        responsereport(false);
    } 
  }

  const generatePDF = async () => {
    const pdf = new jsPDF();
    setIsModalOpen(true)
    setProgress(0); // Inicia el progreso en 0%
    setMsg("Generando el reporte para ser descargado. No cierres la ventana hasta completar el proceso.")
    setLoaderScreen(prevState => {
      return {...prevState, started: true}
    })
    for (let i = 0; i < pages; i++) {
      const chartRef = chartRefs.current[i];
      const canvas = await html2canvas(chartRef);
      const imageData = canvas.toDataURL('image/png');
      const pdfWidth = pdf.internal.pageSize.getWidth()
      const pdfHeight = pdf.internal.pageSize.getHeight()
      const imgWidth = canvas.width
      const imgHeight = canvas.height
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight)
      pdf.addImage(imageData, 'PNG', 16, 16, imgWidth*ratio*0.85, imgHeight*ratio*0.85);
      if (i < pages - 1) {
        pdf.addPage();
      }
      // Actualiza el progreso
      setProgress(((i + 1) / pages) * 100);
      setMsgPorcentage((((i + 1) / pages) * 100).toFixed(0) + " %");
    }
    setMsg("¡Reporte generado!")
    setMsgPorcentage(null);
    setLoaderScreen(prevState => {
      return {...prevState, started: false}
    })
    pdf.save(name.pdf_name);
  };

  function Encendido(costos){
    if(costos === "true"){return("Incluidos")}
    else{return("No incluidos")}
  }

  function Tiempo(tiempos){
    if(tiempos === "true"){return("Incluidos")}
    else{return("No incluidos")}
  }

  function Rampa(ramp){
    if(ramp === "true"){return("Incluidos")}
    else{return("No incluidos")}
  }

  function Render(user){
    if(user === "premium" || user === "admin"){
        return(
            <div>
                <div className='mt-10'><Header title="Coloca el nombre de tu reporte"/></div>
                    <div className='grid grid-cols-3 gap-3'>
                        <div className='columns-1'>
                            <h1 className='mb-3'><strong>Nombre del reporte</strong></h1>
                            <input type='string' placeholder={name.pdf_name} name='pdf_name' onChange={handleInput} className='form-control rounded-0 w-96 h-10 p-1' style={{border:"1px solid" ,borderRadius: "8px", color: "gray", backgroundColor:"white", padding:"6px"}}></input>
                        </div>
                    </div>
                    <div className='flex gap-5 mt-16'>
                        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={SaveReport}>Guardar Reporte</button>
                        <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={generatePDF}>Descargar Reporte</button>
                    </div>
                    <div key={0} ref={(ref) => (chartRefs.current[0] = ref)} style={{border: "1px solid", width: "100%", padding: "30px", marginTop: "120px"}}>
                        <div className='flex justify-center items-center'>
                          <img style={{maxWidth:"320px", marginBottom:"30px"}} src={ecokinesis} alt=''/>
                        </div>
                        <h1 className='flex justify-center items-center'><strong>Resultados Reporte Escenario Operación Simulada</strong></h1>
                        <h1 className='flex justify-center items-center'>País Ingresado: {capitalize(values.country)}</h1>
                        <h1 className='flex justify-center items-center'>Año PELP Ingresado: {values.pelp_year}</h1>
                        <h1 className='flex justify-center items-center'>Escenario Ingresado: {values.scenary}</h1>
                        <h1 className='flex justify-center items-center'>Fecha de creación del reporte: {date}/{month}/{year}</h1>

                        <h1 className='flex justify-center items-center mt-16'><strong>Valores Ingresados</strong></h1>
                        <h1 className='flex justify-center items-center mt-4'><strong>Configuración de Escenario</strong></h1>
                        <h1 className='flex justify-center items-center'>Solver: {configuration.Solver}</h1>
                        <h1 className='flex justify-center items-center'>Costos de encedido: {Encendido(configuration.CostosEncendido)}</h1>
                        <h1 className='flex justify-center items-center'>Restricciones de tiempo mínimo: {Tiempo(configuration.Mintime)}</h1>
                        <h1 className='flex justify-center items-center'>Restricciones de rampa: {Rampa(configuration.Ramp)}</h1>
                        <h1 className='flex justify-center items-center mt-4'><strong>Porcentajes de factores de escenario</strong></h1>
                        <h1 className='flex justify-center items-center'>Incremento precio combustible: {configuration.Combustible}%</h1>
                        <h1 className='flex justify-center items-center'>Incremento de eficiencia: {configuration.Eficiencia}%</h1>
                        <h1 className='flex justify-center items-center'>Disponibilidad Hídrica: {configuration.Hidrica}%</h1>
                        <h1 className='flex justify-center items-center'>Factor de Demanda: {configuration.FactorDemanda}%</h1>
                        <h1 className='flex justify-center items-center'>Factor de Demanda de H2: {configuration.DemandaH2}%</h1>


                        <h1 className='flex justify-center items-center mt-16'>EcoKinesis Proyecto Fondef Idea ID22I10341 es propiedad de la Universidad Técnica Federico Santa María y ANID</h1>
                        <div className='flex justify-center items-center gap-10 mt-6'>
                          <img style={{maxWidth:"100px"}} src={anid} alt=''/>
                          <img style={{maxWidth:"150px"}} src={utfsm} alt=''/>
                        </div>
                        <h1 className='flex justify-center items-center mt-12'>Disclaimer: Este reporte ha sido generado automáticamente en base a los datos ingresados del usuario en la plataforma de EcoKinesis. EcoKinesis no se hace responsable del uso o vericidad de los datos en este reporte.</h1>
                    </div>
                    <div className="mt-10" key={1} ref={(ref) => (chartRefs.current[1] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Tabla energía producida por tecnología escenario operación simulada: La tabla muestra los resultados de la simulación de energía o producción medida en GWh para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.</h1>
                        <div style={{maxWidth: "100%"}}>
                        <MUIDataTable
                            title={mso.Energy_production_by_tech.title}
                            data={energy_production_data}
                            columns={energy_production_columns}
                            options={options}
                        ></MUIDataTable>
                        </div>
                    </div>
                    <div className="mt-10" key={2} ref={(ref) => (chartRefs.current[2] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Gráfico energía producida por tecnología escenario operación simulada: El gráfico muestra los resultados de la simulación de energía o producción medida en GWh para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                            <Bar data={ChartData1} options={graph_options1}/>
                        </div>
                    </div>
                    <div className="mt-10" key={3} ref={(ref) => (chartRefs.current[3] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Tabla energía específica producida por tecnología escenario operación simulada: La tabla muestra los resultados de la simulación de energía o producción específica medida en GWh para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.</h1>
                        <div style={{maxWidth: "100%"}}>
                        <MUIDataTable
                            title={mso.Specific_energy_production_by_tech.title}
                            data={specific_energy_data}
                            columns={columns1}
                            options={options}
                            ></MUIDataTable>
                        </div>
                    </div>
                    <div className="mt-10" key={4} ref={(ref) => (chartRefs.current[4] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Gráfico energía específica producida por tecnología escenario operación simulada: El gráfico muestra los resultados de la simulación de energía o producción específica medida en GWh para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                            <Bar data={ChartData2} options={graph_options2}/>
                        </div>
                    </div>
                    <div className="mt-10" key={5} ref={(ref) => (chartRefs.current[5] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Tabla capacidad instalada por tecnología escenario operación simulada: La tabla muestra los resultados de la simulación de capacidad instalada en MW para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.</h1>
                        <div style={{maxWidth: "100%"}}>
                        <MUIDataTable
                        title={mso.Capacity_by_tech.title}
                        data={capacity_tech_data}
                        columns={capacity_tech_columns}
                        options={options}
                        ></MUIDataTable>
                        </div>
                    </div>
                    <div className="mt-10" key={6} ref={(ref) => (chartRefs.current[6] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Gráfico capacidad instalada por tecnología escenario operación simulada: El gráfico muestra los resultados de la simulación de capacidad instalada en MW para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                            <Bar data={ChartData3} options={graph_options3}/>
                        </div>
                    </div>
                    <div className="mt-10" key={7} ref={(ref) => (chartRefs.current[7] = ref)}>
                        <h1 className='mb-6' style={{maxWidth:"80%"}}>Tabla capacidad instalada específica por tecnología escenario operación simulada: La tabla muestra los resultados de la simulación de capacidad instalada específica en MW para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.</h1>
                        <div style={{maxWidth: "100%"}}>
                        <MUIDataTable
                        title={mso.Specific_capacity_by_tech.title}
                        data={specific_capacity_data}
                        columns={columns2}
                        options={options}
                        ></MUIDataTable>
                        </div>
                    </div>
                    <div className="mt-10" key={8} ref={(ref) => (chartRefs.current[8] = ref)}>
                      <h1 className='mb-6' style={{maxWidth:"80%"}}>Gráfico capacidad instalada específica por tecnología escenario operación simulada: El gráfico muestra los resultados de la simulación de capacidad instalada específica en MW para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.</h1>
                      <div style={{width: "1000px", height: "450px"}}>
                            <Bar data={ChartData4} options={graph_options4}/>
                        </div>
                    </div>
                  </div>
        )
    }
    else{
        return(
            <div className='mt-16'>
                <h1 className='flex justify-center'><strong>Contrata la suscripción	premium de EcoKinesis para desbloquear la posibilidad de guardar y descargar reportes</strong></h1>
                <div className='flex justify-center'>
                    <div className='mt-8' style={{backgroundColor:"#E0E2E2", borderRadius: "20px", width:"540px", height:"180px"}}>
                        <div className='justify-center align-items-center text-center'>
                            <div className="text-3xl" style={{color: "black"}}><strong>Suscripción Premium</strong></div>
                            <div className="text-4xl" style={{color:"blue"}}><strong>$4.99 USD</strong></div>
                            <div className="text-sm"style={{color:"gray"}}><strong>Renovación mensual</strong></div>
                            <button className="text-lg" style={{color:"white",backgroundColor:"#425df9",borderRadius:"20px", width:"260px", height:"54px", marginTop:"10px"}} onClick={() => (navigate("/dashboard/premium"))}>Contratar</button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
  }



  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
    <div className='flex justify-between items-center mb-6'>
        <button onClick={() => {navigate("/dashboard/simulations/main/simulation_operation_results", {state: {values,configuration,mso, coins}})}} style={{color:"white",backgroundColor:"gray",borderRadius:"10px", width:"200px", height: "60px"}}>Volver a mi simulación</button>
  </div>  
  <Header title="Generar reporte simulación escenario personalizado"/>
  <p className='text-gray-500' style={{maxWidth: "85%"}}>En esta sección podrás guardar y descargar los resultados de tu escenario de operación simulada. Ingresa el nombre de tu reporte. Si pulsas el botón de descarga, se descargará un PDF con tus resultados. Si pulsas la opción de guardar reporte, este se guardará en la zona de reportes. Un reporte no puede ser guardado si es que ya existe uno en tu cuenta con el mismo nombre. En la zona inferior verás la lista de tablas y gráficos de tu simulación que se almacenarán en tu reporte. Estas opciones sólo están disponible para usuarios que cuenten con la <Link to= "/dashboard/premium" className='text-blue-500'>suscripción premium de EcoKinesis</Link>.</p>
  {Render(user_type)}
  <ModalLoader msg={msg} msgporcentage={msgporcentage} progress={progress} loaderscreen={loaderscreen} isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)}/>
  <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
</div>
  )
}

export default SimulationOperationsPDF