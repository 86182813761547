// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* styles.css */

.custom-mui-datatable th {
    font-size: 10px; /* Ajustar el tamaño de fuente de los encabezados de las columnas */
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/Dashboard/ExpandedMIP/new_styles.css"],"names":[],"mappings":"AAAA,eAAe;;AAEf;IACI,eAAe,EAAE,mEAAmE;EACtF","sourcesContent":["/* styles.css */\r\n\r\n.custom-mui-datatable th {\r\n    font-size: 10px; /* Ajustar el tamaño de fuente de los encabezados de las columnas */\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
