import {React, useState} from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import FullScreen from '../../../../components/FullScreen';
import capitalize from '../../../../hook/capitalize';
import graphColors from '../../../../hook/graphColors';
import filedownload from "../../../../assets/file.svg"
import download from "../../../../assets/download_black.svg"
import expand from "../../../../assets/expand.svg"
import edit from "../../../../assets/edit.svg"
import save from "../../../../assets/save_black.svg"
import MUIDataTable from "mui-datatables";
import { Bar} from "react-chartjs-2";
import { CSVLink } from 'react-csv';
import { Chart as ChartJS, Title, BarElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, LineElement, Filler, Tooltip, Legend, ArcElement} from "chart.js";

ChartJS.register(Title, BarElement, CategoryScale, LinearScale, PointElement, RadialLinearScale, LineElement, ArcElement, Filler, Tooltip, Legend)

const SimulationOperationResults = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const values = location.state.values
  const mso = location.state.mso
  const coins = location.state.coins
  const configuration = location.state.configuration

  const options={
    rowsPerPage: 10,
    rowsPerPageOptions: [1,2,3,4,5,6,7,8,9,10,11,12,13],
    selectableRows: "none",
    tableBodyMaxHeight: "400px",
    print: "false",
    download: "false",
    filter: "false",
    responsive: "standard"
  }

  //Indicators and graphic-table options
  const [appState, changeState] = useState({
    activeObject: null,
    activeGraphic: null,
    objects: [1,2,3,4],
    graphicObjects: [1,2,3,4]
  })

  function toggleActive(index,graphicindex){
    if (index === 0 || index === 1 || index === 2 || index === 3){
      changeState({...appState, activeObject: appState.objects[index]})
    }
    if (graphicindex === 0 || graphicindex === 1 || graphicindex === 2 || graphicindex === 3){
      changeState({...appState, activeGraphic: appState.graphicObjects[graphicindex]})
    }
    
  }
  function toggleActiveStyles(index){
    if(appState.objects[index] === appState.activeObject){
      return "box-active"
    }
    else {
      return "box-inactive"
    }
  }

  function toggleActiveStylesGraph(graphicindex){
    if(appState.graphicObjects[graphicindex] === appState.activeGraphic){
      return "box-active"
    }
    else {
      return "box-inactive"
    }
  }


  //Colums and data
  var energy_production_columns = [""]
  var energy_production_columns2 = []
  var energy_production_data = []
  var energy_production_data2 = []
  var specific_energy_columns = [""]
  var specific_energy_data = []
  var specific_energy_columns2 = []
  var specific_energy_data2 = []
  var total_energy_columns = [""]
  var total_energy_columns2 = []
  var total_energy_data = []
  var total_energy_data2 = []

  var capacity_tech_columns = [""]
  var capacity_tech_columns2 = []
  var capacity_tech_data = []
  var capacity_tech_data2 = []
  var specific_capacity_columns = [""]
  var specific_capacity_columns2 = []
  var specific_capacity_data = []
  var specific_capacity_data2 = []

  //Set the data on arrays
  location.state.mso.Energy_production_by_tech.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!energy_production_columns.includes(y)){
      energy_production_columns.push(y);
      energy_production_columns2.push(y);
      total_energy_columns.push(y)
      total_energy_columns2.push(y)
    }
  })
  location.state.mso.Energy_production_by_tech.data?.forEach((element) => {
    var x = element.x_index
    var new_array= [x].concat(Array(energy_production_columns.length-1).fill(0))
    var new_array2 = Array(energy_production_columns2.length).fill(0)
    var new_array3= [x].concat(Array(energy_production_columns.length-1).fill(0))
    var new_array4 = Array(energy_production_columns2.length).fill(0)
    var flag = true
    energy_production_data.forEach((element2) => {
      if (element2[0] === x){
        flag=false
      }
    })
    if (flag){
      energy_production_data.push(new_array)
      energy_production_data2.push(new_array2)
      total_energy_data.push(new_array3)
      total_energy_data2.push(new_array4)
    }
  })
  location.state.mso.Energy_production_by_tech.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    energy_production_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        energy_production_data[index2][energy_production_columns.indexOf(y)] = v.toFixed(2)
        energy_production_data2[index2][energy_production_columns2.indexOf(y)] = v.toFixed(2)
        total_energy_data[index2][total_energy_columns.indexOf(y)] = v.toFixed(2)
        total_energy_data2[index2][total_energy_columns2.indexOf(y)] = v.toFixed(2)
      }
    })
  })

  location.state.mso.Specific_energy_production_by_tech.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!specific_energy_columns.includes(y)){
      specific_energy_columns.push(y);
      specific_energy_columns2.push(y);
    }
    if(!total_energy_columns.includes(y)){
      total_energy_columns.push(y)
      total_energy_columns2.push(y)
      total_energy_data[0].push(0)
      total_energy_data2[0].push(0)
    }
  })
  location.state.mso.Specific_energy_production_by_tech.data?.forEach((element) => {
    var x = element.x_index
    var new_array= [x].concat(Array(specific_energy_columns.length-1).fill(0))
    var new_array2 = Array(specific_energy_columns2.length).fill(0)
    var flag = true
    specific_energy_data.forEach((element2) => {
      if (element2[0] === x){
        flag=false
      }
    })
    if (flag){
      specific_energy_data.push(new_array)
      specific_energy_data2.push(new_array2)
    }
  })
  location.state.mso.Specific_energy_production_by_tech.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    specific_energy_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        specific_energy_data[index2][specific_energy_columns.indexOf(y)] = v.toFixed(2)
        specific_energy_data2[index2][specific_energy_columns2.indexOf(y)] = v.toFixed(2)
        total_energy_data[index2][total_energy_columns.indexOf(y)] = v.toFixed(2)
        total_energy_data2[index2][total_energy_columns2.indexOf(y)] = v.toFixed(2)
      }
    })
  })


  location.state.mso.Capacity_by_tech.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!capacity_tech_columns.includes(y)){
      capacity_tech_columns.push(y);
      capacity_tech_columns2.push(y)
    }
  })
  location.state.mso.Capacity_by_tech.data?.forEach((element) => {
    var x = element.x_index
    var new_array= [x].concat(Array(capacity_tech_columns.length-1).fill(0))
    var new_array2 = Array(capacity_tech_columns2.length).fill(0)
    var flag = true
    capacity_tech_data.forEach((element2) => {
      if (element2[0] === x){
        flag=false
      }
    })
    if (flag){
      capacity_tech_data.push(new_array)
      capacity_tech_data2.push(new_array2)
    }
  })
  location.state.mso.Capacity_by_tech.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    capacity_tech_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        capacity_tech_data[index2][capacity_tech_columns.indexOf(y)] = v.toFixed(2)
        capacity_tech_data2[index2][capacity_tech_columns2.indexOf(y)] = v.toFixed(2)
      }
    })
  })


  location.state.mso.Specific_capacity_by_tech.data?.forEach((element) => {
    var y = capitalize(element.y_index)
    if (!specific_capacity_columns.includes(y)){
      specific_capacity_columns.push(y);
      specific_capacity_columns2.push(y)
    }
  })
  location.state.mso.Specific_capacity_by_tech.data?.forEach((element) => {
    var x = element.x_index
    var new_array= [x].concat(Array(specific_capacity_columns.length-1).fill(0))
    var new_array2 = Array(specific_capacity_columns2.length).fill(0)
    var flag = true
    specific_capacity_data.forEach((element2) => {
      if (element2[0] === x){
        flag=false
      }
    })
    if (flag){
      specific_capacity_data.push(new_array)
      specific_capacity_data2.push(new_array2)
    }
  })
  location.state.mso.Specific_capacity_by_tech.data?.forEach((element) => {
    var x = element.x_index
    var v= element.value
    var y= capitalize(element.y_index)
    specific_capacity_data.forEach((element2,index2) =>{
      if (x === element2[0]){
        specific_capacity_data[index2][specific_capacity_columns.indexOf(y)] = v.toFixed(2)
        specific_capacity_data2[index2][specific_capacity_columns2.indexOf(y)] = v.toFixed(2)
      }
    })
  })


   //Display graphics and tables function
   function GraphicDisplay(){
    if(appState.activeObject === 1 && appState.activeGraphic === 1){
      return(<div style={{maxWidth: "1600px"}}>
        <MUIDataTable
        title={location.state.mso.Energy_production_by_tech.title}
        data={energy_production_data}
        columns={energy_production_columns}
        options={options}
        ></MUIDataTable></div>
      )
    }
    else if(appState.activeObject === 1 && appState.activeGraphic === 2){
      const ChartData = {
        labels: energy_production_columns2,
        datasets: [{
          label: location.state.mso.Energy_production_by_tech.data[0].x_index,
          data: energy_production_data2[0],
          backgroundColor: graphColors(energy_production_columns2),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías", color: "black"}},
          y: {title:{display: true, text: location.state.mso.Energy_production_by_tech.data[0].x_index, color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.mso.Energy_production_by_tech.title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}/>
      </div>)
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 1){
      return(<div style={{maxWidth: "1600px"}}>
        <MUIDataTable
        title={location.state.mso.Specific_energy_production_by_tech.title}
        data={specific_energy_data}
        columns={specific_energy_columns}
        options={options}
        ></MUIDataTable></div>
      )
    }
    else if(appState.activeObject === 2 && appState.activeGraphic === 2){
      const ChartData = {
        labels: specific_energy_columns2,
        datasets: [{
          label: location.state.mso.Specific_energy_production_by_tech.data[0].x_index,
          data: specific_energy_data2[0],
          backgroundColor: graphColors(specific_energy_columns2),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías", color: "black"}},
          y: {title:{display: true, text: location.state.mso.Specific_energy_production_by_tech.data[0].x_index, color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.mso.Specific_energy_production_by_tech.title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 1){
      return(<div style={{maxWidth: "1600px"}}>
        <MUIDataTable
        title={location.state.mso.Capacity_by_tech.title}
        data={capacity_tech_data}
        columns={capacity_tech_columns}
        options={options}
        ></MUIDataTable></div>
      )
    }
    else if(appState.activeObject === 3 && appState.activeGraphic === 2){
      const ChartData = {
        labels: capacity_tech_columns2,
        datasets: [{
          label: location.state.mso.Capacity_by_tech.data[0].x_index,
          data: capacity_tech_data2[0],
          backgroundColor: graphColors(capacity_tech_columns2),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías", color: "black"}},
          y: {title:{display: true, text: location.state.mso.Capacity_by_tech.data[0].x_index, color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.mso.Capacity_by_tech.title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}/>
      </div>)
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 1){
      return(<div style={{maxWidth: "1600px"}}>
        <MUIDataTable
        title={location.state.mso.Specific_capacity_by_tech.title}
        data={specific_capacity_data}
        columns={specific_capacity_columns}
        options={options}
        ></MUIDataTable></div>
      )
    }
    else if(appState.activeObject === 4 && appState.activeGraphic === 2){
      const ChartData = {
        labels: specific_capacity_columns2,
        datasets: [{
          label: location.state.mso.Specific_capacity_by_tech.data[0].x_index,
          data: specific_capacity_data2[0],
          backgroundColor: graphColors(specific_capacity_columns2),
          borderWidth: 0,
          }]
      }
      const options = {
        scales: {
          x: {title:{display: true, text: "Tecnologías", color: "black"}},
          y: {title:{display: true, text: location.state.mso.Specific_capacity_by_tech.data[0].x_index, color: "black"}}
        },
        plugins : {
          legend: {display: false},
          title: {
            display: true,
            text: location.state.mso.Specific_capacity_by_tech.title,
            align: "center",
          }
        }
      }
      return(
      <div style={{width: "55vw"}}>
        <Bar data={ChartData} options={options}/>
      </div>)
    }
    else{
      return(<h1></h1>)
    }
  }


  function CSVData(){
    if((appState.activeObject === 1 && appState.activeGraphic === 1) || (appState.activeObject === 1 && appState.activeGraphic === 2)){
      var array_data = []
      energy_production_columns.forEach((element,index) =>{
        if (index !== 0){
          array_data.push([element,energy_production_data[0][index]])
        }
      })
      return([['Tecnologías',energy_production_data[0][0]]].concat(array_data))
    }
    else if((appState.activeObject === 2 && appState.activeGraphic === 1) || (appState.activeObject === 2 && appState.activeGraphic === 2)){
      var array_data = []
      specific_energy_columns.forEach((element,index) =>{
        if (index !== 0){
          array_data.push([element,specific_energy_data[0][index]])
        }
      })
      return([['Tecnologías',specific_energy_data[0][0]]].concat(array_data))
    }
    else if((appState.activeObject === 3 && appState.activeGraphic === 1) || (appState.activeObject === 3 && appState.activeGraphic === 2)){
      var array_data = []
      capacity_tech_columns.forEach((element,index) =>{
        if (index !== 0){
          array_data.push([element,capacity_tech_data[0][index]])
        }
      })
      return([['Tecnologías',capacity_tech_data[0][0]]].concat(array_data))
    }
    else if((appState.activeObject === 4 && appState.activeGraphic === 1) || (appState.activeObject === 4 && appState.activeGraphic === 2)){
      var array_data = []
      specific_capacity_columns.forEach((element,index) =>{
        if (index !== 0){
          array_data.push([element,specific_capacity_data[0][index]])
        }
      })
      return([['Tecnologías',specific_capacity_data[0][0]]].concat(array_data))
    }
    else{
      return([[""]])
    }
  }

  function CSVTitle(){
    if((appState.activeObject === 1 && appState.activeGraphic === 1) || (appState.activeObject === 1 && appState.activeGraphic === 2)){
      return(location.state.mso.Energy_production_by_tech.title+".csv")
    }
    else if((appState.activeObject === 2 && appState.activeGraphic === 1) || (appState.activeObject === 2 && appState.activeGraphic === 2)){
      return(location.state.mso.Specific_energy_production_by_tech.title+".csv")
    }
    else if((appState.activeObject === 3 && appState.activeGraphic === 1) || (appState.activeObject === 3 && appState.activeGraphic === 2)){
      return(location.state.mso.Capacity_by_tech.title+".csv")
    }
    else if((appState.activeObject === 4 && appState.activeGraphic === 1) || (appState.activeObject === 4 && appState.activeGraphic === 2)){
      return(location.state.mso.Specific_capacity_by_tech.title+".csv")
    }
    else{
      return("")
    }
  }


  function ButtonsDisplay(){
    if(appState.activeObject === 1){
      return(
        <div className="ml-auto"> 
          <button onClick={() => {toggleActive(null, 0)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid"}}>Tabla</button>
          <button onClick={() => {toggleActive(null, 1)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid"}}>Gráfico</button>
        </div>
      )
    }
    else if(appState.activeObject === 2){
      return(
        <div className="ml-auto"> 
          <button onClick={() => {toggleActive(null, 0)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid"}}>Tabla</button>
          <button onClick={() => {toggleActive(null, 1)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid"}}>Gráfico</button>
        </div>
      )
    }
    else if(appState.activeObject === 3){
      return(
        <div className="ml-auto"> 
          <button onClick={() => {toggleActive(null, 0)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid"}}>Tabla</button>
          <button onClick={() => {toggleActive(null, 1)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid"}}>Gráfico</button>
        </div>
      )
    }
    else if(appState.activeObject === 4){
      return(
        <div className="ml-auto"> 
          <button onClick={() => {toggleActive(null, 0)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(0)}`} style={{ borderRadius:"10px", border: "1px solid"}}>Tabla</button>
          <button onClick={() => {toggleActive(null, 1)}} className={`w-28 h-14 m-1 ${toggleActiveStylesGraph(1)}`} style={{ borderRadius:"10px", border: "1px solid"}}>Gráfico</button>
        </div>
      )
    }
    else{
      return(<div></div>)
    }
  }

  function TextDisplay(){
    if (appState.activeObject === 1 && appState.activeGraphic === 1){
      return("Tabla energía producida por tecnología escenario operación simulada: La tabla muestra los resultados de la simulación de energía o producción medida en GWh para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.")
    }
    else if (appState.activeObject === 1 && appState.activeGraphic === 2){
      return("Gráfico energía producida por tecnología escenario operación simulada: El gráfico muestra los resultados de la simulación de energía o producción medida en GWh para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 1){
      return("Tabla energía específica producida por tecnología escenario operación simulada: La tabla muestra los resultados de la simulación de energía o producción específica medida en GWh para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.")
    }
    else if (appState.activeObject === 2 && appState.activeGraphic === 2){
      return("Gráfico energía específica producida por tecnología escenario operación simulada: El gráfico muestra los resultados de la simulación de energía o producción específica medida en GWh para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.")
    }
    else if (appState.activeObject === 3 && appState.activeGraphic === 1){
      return("Tabla capacidad instalada por tecnología escenario operación simulada: La tabla muestra los resultados de la simulación de capacidad instalada en MW para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.")
    }
    else if (appState.activeObject === 3 && appState.activeGraphic === 2){
      return("Gráfico capacidad instalada por tecnología escenario operación simulada: El gráfico muestra los resultados de la simulación de capacidad instalada en MW para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.")
    }
    else if (appState.activeObject === 4 && appState.activeGraphic === 1){
      return("Tabla capacidad instalada específica por tecnología escenario operación simulada: La tabla muestra los resultados de la simulación de capacidad instalada específica en MW para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.")
    }
    else if (appState.activeObject === 4 && appState.activeGraphic === 2){
      return("Gráfico capacidad instalada específica por tecnología escenario operación simulada: El gráfico muestra los resultados de la simulación de capacidad instalada específica en MW para diferentes tecnologías de generación en base a los datos de país, año, escenario, datos de configuración y porcentajes de factores ingresados en el algoritmo MSO.")
    }
    else {
      return("")
    }
  }

  function TitleDisplay(){
    if (appState.activeObject === 1){
      return("Energía producida por tecnología escenario operación simulada "+capitalize(values.country) + " " + values.pelp_year)
    }
    else if (appState.activeObject === 2){
      return("Energía específica producida por tecnología escenario operación simulada "+capitalize(values.country) + " " + values.pelp_year)
    }
    else if (appState.activeObject === 3){
      return("Capacidad instalada por tecnología escenario operación simulada "+capitalize(values.country) + " " + values.pelp_year)
    }
    else if (appState.activeObject === 4){
      return("Capacidad específica instalada por tecnología escenario operación simulada "+capitalize(values.country) + " " + values.pelp_year)
    }
    else{
      return("Escenario Operación Simulada")
    }
  }


  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      <div className='flex'> 
        <div className='w-3/5 h-14 grid grid-cols-4 gap-x-3 p-2' style={{backgroundColor: "white", border: "1px solid", borderRadius:"10px", borderColor: "gray"}}>
            <button onClick={() => {toggleActive(0)}} className={`max-h-10 max-w-48 rounded-lg ${toggleActiveStyles(0)} text-sm`}>Energía producida</button>
            <button onClick={() => {toggleActive(1)}} className={`max-h-10 max-w-48 rounded-lg ${toggleActiveStyles(1)} text-sm`}>Energía específica producida</button>
            <button onClick={() => {toggleActive(2)}} className={`max-h-10 max-w-48 rounded-lg ${toggleActiveStyles(2)} text-sm`}>Capacidad instalada</button>
            <button onClick={() => {toggleActive(3)}} className={`max-h-10 max-w-48 rounded-lg ${toggleActiveStyles(3)} text-sm`}>Capacidad específica instalada</button>
        </div>

        <div className='grid grid-cols-5 ml-auto w-1/4 gap-x-4'>
          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {setIsModalOpen(true)}}><img src={expand} className='w-10 h-10' alt=''title='Pulsa para abrir la tabla o gráfico actual en pantalla completa'/>Expandir pantalla</button></div>

          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/simulations/main/simulation_operation_reports",{state: {energy_production_columns, energy_production_columns2, energy_production_data, energy_production_data2, specific_energy_columns, specific_energy_data, specific_energy_columns2, specific_energy_data2, total_energy_columns, total_energy_data, total_energy_data2, capacity_tech_columns, capacity_tech_columns2, capacity_tech_data, capacity_tech_data2, specific_capacity_columns, specific_capacity_columns2, specific_capacity_data, specific_capacity_data2,mso, values, coins, configuration}})}}><img src={save} className='w-10 h-10' alt=''title='Pulsa para ir al menú de guardado y descarga de reportes'/>Guardar reporte</button></div>

          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/simulations/main/simulation_operation_reports",{state: {energy_production_columns, energy_production_columns2, energy_production_data, energy_production_data2, specific_energy_columns, specific_energy_data, specific_energy_columns2, specific_energy_data2, total_energy_columns, total_energy_data, total_energy_data2, capacity_tech_columns, capacity_tech_columns2, capacity_tech_data, capacity_tech_data2, specific_capacity_columns, specific_capacity_columns2, specific_capacity_data, specific_capacity_data2,mso, values, coins, configuration}})}}><img src={download} className='w-10 h-10' alt=''title='Pulsa para ir al menú de guardado y descarga de reportes'/>Descargar reporte</button></div>

          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button title='Pulsa para descargar la tabla o gráfico actual en formato CSV'><CSVLink data={CSVData()} filename={CSVTitle()}><img src={filedownload} className='w-10 h-10' alt='' />Convertir a CSV</CSVLink></button></div>

          <div className='justify-center items-center text-center' style={{fontSize: "10px"}}><button onClick={() => {navigate("/dashboard/simulations/main/simulation_operation_list")}}><img src={edit} className='w-10 h-10' alt=''title='Pulsa para volver a la lista de escenarios de operación simulada'/>Volver a lista</button></div>
        </div>
      </div>
      <div className='mt-2 mb-1'><p className='text-3xl font-extrabold tracking-tight text-slate-900'>{TitleDisplay()}</p></div>
      <div className='flex'>
        <h4 className='font-bold text-gray-400 w-3/5'>{TextDisplay()}</h4>
        <div className="ml-auto">
          {ButtonsDisplay()}
        </div>
      </div>
      <div className='mt-3 mb-12' style={{maxWidth:"1460px", maxHeight: "490px"}}>
        {GraphicDisplay()}
      </div>
      <FullScreen isOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} item={GraphicDisplay()}/>
    </div>
  )
}

export default SimulationOperationResults