export const API_PORT = "https://api.ecokinesis.cl"

//Change API_PORT to detect Backend Port

//For Local Development use:
//http://127.0.0.1:8000
//http://localhost:8000

//For AWS Work use:
//https://api.ecokinesis.cl
//Public IP: http://100.25.190.111:8000
//Private IP: 172.31.54.35