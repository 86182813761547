import { React, useState} from 'react'
import { Button } from '../../../components';
import { Header } from '../../../components';
import { Link, useNavigate } from 'react-router-dom';
import { RegisterValidation } from '../../LoginPage/Validation';
import user_icon from '../../../assets/person.png'
import email_icon from '../../../assets/email.png'
import password_icon from '../../../assets/password.png'
import { API_PORT } from '../../../API_PORT';
import ISCOCodes from '../../LoginPage/ISCOCodes';
import LoadingScreen from '../../../components/LoadingScreen';
import { IoEye, IoEyeOff } from 'react-icons/io5'; // Importa los íconos

const CreateUser = () => {
  const [loadingscreen, setLoadingScreen] = useState({started: false});
  const [open, setOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false); // Estado para visibilidad de contraseña

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

  const [values, setValues] = useState({
    "email": "",
    "username": "",
    "first_name": "",
    "last_name": "",
    "password": "",
    "user_type": "admin",
    "profesion_level": "0",
    "profesion_code": "0",
    "institution_name": "Sin definir",
    "country": "Sin definir", 
});

const options = [
  { value: "", label: "Selecciona el tipo de usuario"},
  { value: "basic", label: "Usuario Básico"},
  { value: "premium", label: "Usuario Premium"},
  { value: "admin", label: "Admin"},
];

const options2 = [
  { value: "0", label: "Selecciona su área ocupacional"},
  { value: "1", label: "Academia"}, 
  { value: "2", label: "Sector Público"},
  { value: "3", label: "Sector Privado"},
  { value: "4", label: "Organización no gubernamental"},
];

const options3 = [
  { value: "Sin definir", label: "Selecciona el país"},
  { value: "Argentina", label: "Argentina"},
  { value: "Bolivia", label: "Bolivia"},
  { value: "Brasil", label: "Brasil"},
  { value: "Chile", label: "Chile"},
  { value: "Colombia", label: "Colombia"},
  { value: "Costa Rica", label: "Costa Rica"},
  { value: "Ecuador", label: "Ecuador"},
  { value: "España", label: "España"},
  { value: "Estados Unidos", label: "Estados Unidos"},
  { value: "México", label: "México"},
  { value: "Paraguay", label: "Paraguay"},
  { value: "Perú", label: "Perú"},
  { value: "Uruguay", label: "Uruguay"},
  { value: "Venezuela", label: "Venezuela"},
];

const navigate = useNavigate();

const [errors, setErrors] = useState({});

const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
};

 //Register Endpoint
 const handleSubmit = async (e) => {
  e.preventDefault();
  setErrors(RegisterValidation(values));
      if(errors.username === "" && errors.email === "" && errors.password === "" && errors.first_name === "" && errors.last_name === ""){
          if(values.profesion_level !== "0" && values.profesion_code !== "0"){
              const url = API_PORT + '/admin/users_accounts/';
      const data = JSON.stringify(values);
      setOpen(true);
      setLoadingScreen(prevState => {return {...prevState, started: true}});
      try {
      const response = await fetch(url, {
          method: 'POST',
          headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
          },
          body: data
      });
  
      if (!response.ok) {
          throw new Error('Error al crear usuario');
      }
  
      const responseData = await response.json();
      console.log('Respuesta del servidor:', responseData);
  
      // Verifica la respuesta del servidor
      if (responseData.detail === 'Usuario creado exitosamente') {
          // Realiza la navegación a la página principal
          alert("El usuario ha sido creado exitosamente. Valida la cuenta pulsando en el enlace envíado al email y luego habilita a este usando el panel de admin.")
          navigate("/dashboard/adminuser/")
      } else {
          // Maneja otros casos de respuesta del servidor si es necesario
          console.log('Respuesta no esperada del servidor:', responseData);
          alert(responseData.detail)
          setOpen(false);
          setLoadingScreen(prevState => {return {...prevState, started: false}});
      }
      } catch (error) {
      console.error('Error al intentar crear usuario:', error.message);
          alert("Este usuario ya está registrado en la plataforma. Por favor cambie el email y/o nombre de usuario.")
          setOpen(false);
          setLoadingScreen(prevState => {return {...prevState, started: false}});
      // Aquí puedes manejar el error como necesites (por ejemplo, mostrar un mensaje de error al usuario)
      }
          }
          else{
              alert("Ingresa un área ocupacional y una profesión")
          }
  }
};

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      

        <div className='flex justify-between items-center mb-10'>
            <Link to="/dashboard/adminuser" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  

      <Header title="Crear usuario"/>

      <h1>Ingresa los datos para crear una nueva cuenta en EcoEnergy. Debes llenar todos los campos y la contraseña debe contar con el formato de por lo menos 8 dígitos, 1 número, 1 mayúscula y 1 minúscula. Una vez crees la cuenta, el usuario debe verificarla vía email.</h1>
      <form action = "" onSubmit={handleSubmit}>
                <div className='inputs'>
                <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='first_name'></label>
                        <input type='string' placeholder='Nombre' name='first_name'
                        onChange={handleInput} className='form-control rounded-0'></input>
                        {errors.first_name && <span style={{fontSize: "10px"}}>{errors.first_name}</span>}
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='last_name'></label>
                        <input type='string' placeholder='Apellido' name='last_name'
                        onChange={handleInput} className='form-control rounded-0'></input>
                        {errors.last_name && <span style={{fontSize: "10px"}}>{errors.last_name}</span>}
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='username'></label>
                        <input type='string' placeholder='Nombre de Usuario' name='username'
                        onChange={handleInput} className='form-control rounded-0'></input>
                        {errors.username && <span style={{fontSize: "10px"}}>{errors.username}</span>}
                    </div>
                    <div className='input'>
                            <img src={user_icon} alt='' />
                            <label htmlFor='profesion_level'></label>
                            <select type='string' placeholder='Área Ocupacional' name='profesion_level'
                            onChange={handleInput} className='form-select rounded-0'>
                            {options2.map(option =>(
                                <option value={option.value}>{option.label}</option>
                            ))}
                            </select>
                        </div>
                    <div className='input'>
                            <img src={user_icon} alt='' />
                            <label htmlFor='profesion_code'></label>
                            <select type='string' placeholder='Profesión' name='profesion_code'
                            onChange={handleInput} className='form-select rounded-0'>
                            <option value="0">Selecciona la profesión</option>
                            {ISCOCodes[values.profesion_level].map(option =>(
                                <option value={option.value}>{option.label}</option>
                            ))}
                            </select>
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='institution_name'></label>
                        <input type='string' placeholder='Nombre de Institución' name='institution_name'
                        onChange={handleInput} className='form-control rounded-0'></input>
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='country'></label>
                        <select type='string' placeholder='País' name='country'
                        onChange={handleInput} className='form-select rounded-0'>
                          {options3.map(option =>(
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                    </div>
                    <div className='input'>
                        <img src={email_icon} alt='' />
                        <label htmlFor='email'></label>
                        <input type='email' placeholder='Correo Electrónico' name='email'
                        onChange={handleInput} className='form-control rounded-0'/>
                        {errors.email && <span style={{fontSize: "10px"}}>{errors.email}</span>}
                    </div>
                    <div className='input'>
                        <img src={password_icon} alt='' />
                        <label htmlFor='password'></label>
                        <input type={showPassword ? 'text' : 'password'} placeholder='Contraseña' name='password'
                        onChange={handleInput} className='form-control rounded-0'/>
                        {errors.password && <span style={{fontSize: "10px"}}>{errors.password}</span>}
                        <button 
                            type='button' 
                            onClick={togglePasswordVisibility}
                            title={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                            style={{ background: 'none', border: 'none', cursor: 'pointer' }} // Estilos para el botón
                        >
                            {showPassword ? <IoEyeOff size={20} /> : <IoEye size={20} />}
                        </button>
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='user_type'></label>
                        <select type='string' placeholder='Tipo de Usuario' name='user_type'
                        onChange={handleInput} className='form-select rounded-0'>
                          {options.map(option =>(
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                    </div>
                </div>
                <div className='flex justify-center items-center gap-20 flex-wrap'>
                  <button className="submit blue" type="submit" title='Pulsa doble click para crear el usuario'>Crear Cuenta</button>
                </div>     
        </form>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default CreateUser