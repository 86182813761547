// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 10px 20px;
    display: block;
    margin: 100px auto 0;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    border-radius: 0px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.overlay {
    background: rgba(49,49,49,0.8);
}
.modal-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    background: #f1f1f1;
    padding: 14px 28px;
    border-radius: 20px;
    min-width: 30%;
    min-height: 30%;
    max-width: 1800px;
    border: 1px solid;
    box-shadow: 10px 10px 25px 0px rgba(0,0,0,0.5);
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
    background-color: red;
    color: white;
    border-radius: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,oBAAoB;IACpB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,eAAe;AACnB;;AAEA;IACI,8BAA8B;AAClC;AACA;IACI,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;IACd,eAAe;IACf,iBAAiB;IACjB,iBAAiB;IAGjB,8CAA8C;AAClD;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,qBAAqB;IACrB,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":["body.active-modal {\r\n    overflow-y: hidden;\r\n}\r\n\r\n.btn-modal {\r\n    padding: 10px 20px;\r\n    display: block;\r\n    margin: 100px auto 0;\r\n    font-size: 18px;\r\n}\r\n\r\n.modal, .overlay {\r\n    width: 100vw;\r\n    height: 100vh;\r\n    border-radius: 0px;\r\n    top: 0;\r\n    left: 0;\r\n    right: 0;\r\n    bottom: 0;\r\n    position: fixed;\r\n}\r\n\r\n.overlay {\r\n    background: rgba(49,49,49,0.8);\r\n}\r\n.modal-content {\r\n    position: absolute;\r\n    top: 50%;\r\n    left: 50%;\r\n    transform: translate(-50%, -50%);\r\n    line-height: 1.4;\r\n    background: #f1f1f1;\r\n    padding: 14px 28px;\r\n    border-radius: 20px;\r\n    min-width: 30%;\r\n    min-height: 30%;\r\n    max-width: 1800px;\r\n    border: 1px solid;\r\n    -webkit-box-shadow: 10px 10px 25px 0px rgba(0,0,0,0.5);\r\n    -moz-box-shadow: 10px 10px 25px 0px rgba(0,0,0,0.5);\r\n    box-shadow: 10px 10px 25px 0px rgba(0,0,0,0.5);\r\n}\r\n\r\n.close-modal {\r\n    position: absolute;\r\n    top: 10px;\r\n    right: 10px;\r\n    padding: 5px 7px;\r\n    background-color: red;\r\n    color: white;\r\n    border-radius: 10px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
