import { React, useState, useContext} from 'react'
import { Header, Button } from '../../../components'
import { UserContext } from '../../../contexts/UserContext';
import { API_PORT } from '../../../API_PORT';
import { Link, useNavigate } from 'react-router-dom';
import LoadingScreen from '../../../components/LoadingScreen';

const EditPhoto = () => {
  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;
  const { setValue } = useContext(UserContext);

  const [loadingscreen, setLoadingScreen] = useState({started: false});
  const [open, setOpen] = useState(false);

  const link= API_PORT + "/admin/users_accounts/user/upload_user_photo/?token="
  const link2 = API_PORT + "/admin/users_accounts/user/delete_photo/?token="

  const navigate = useNavigate()

  const [file, setFile] = useState();

  const handleFileUpload = (event) => {
    setFile(event.target.files[0])
  }

  const responsenavigate = (response) => {
    if (response.detail === "Imagen subida exitosamente") {
        alert("Se ha subido la imagen correctamente")
        navigate("/dashboard/account")
      }
    else if (response.detail === "Imagen eliminada exitosamente"){
      setValue({user_photo: null});
      alert("Se ha eliminado su foto de perfil correctamente");
      navigate("/dashboard/account");
    }
    else {
        alert(response.detail)
    }
    setOpen(false);
    setLoadingScreen(prevState => {return {...prevState, started: false}});
  }

  const handleSubmit = (event) =>{
    event.preventDefault();
    if(file){
      setOpen(true);
      setLoadingScreen(prevState => {return {...prevState, started: true}});
      const fd = new FormData()
      fd.append("file", file)
      fetch(link+token, {
            method: 'POST',
            body: fd
            }).then((res => res.json()))
            .catch((error) => {
              console.log(error)
              alert("No se ha podido subir tu foto correctamente. Recarga tu sesión y vuelve a intentarlo.")})
            .then((response) => responsenavigate(response)
            );
      }
    else {
      alert("No has subido una imagen para cargar")
  }
}

function deletePhoto() {
  setOpen(true);
  setLoadingScreen(prevState => {return {...prevState, started: true}});
  fetch(link2+token, {
          method: 'DELETE',
          headers: {
              'Content-type': 'application/json'
          }
          }).then((res => res.json()))
          .catch((error) => {
            console.log(error)
            alert("No se ha podido eliminar tu foto correctamente. Recarga tu sesión y vuelve a intentarlo.")})
          .then((response) => responsenavigate(response)
          );
  }


  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
      
        <div className='flex justify-between items-center mb-10'>
            <Link to="/dashboard/account" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  

      <Header title="Actualizar o eliminar foto de perfil"/>

      <h1 className='mb-6'>Para actualizar tu foto de perfil, selecciona una imagen en formato <strong>.png</strong> o <strong>.jpg</strong> para subir. Luego pulsa el botón de actualizar foto. Si deseas eliminar tu foto de perfil, oprime el botón rojo de eliminar foto. Puede que tu foto de perfil no se actualice correctamente en algunas partes de la plataforma hasta que vuelvas a iniciar sesión.</h1>
      <form action = "" onSubmit={handleSubmit}>
                <div className='inputs justify-center items-center'>
                      <input type="file"accept=".jpg, .png"onChange={handleFileUpload}/>
                </div>
                <div className='flex justify-center items-center gap-20 flex-wrap mt-20'>
                  <button className="submit bg-blue" type="submit">Actualizar foto de perfil</button>
                </div>     
        </form>
        <div className='flex justify-center items-center gap-20 flex-wrap mt-6'>
          <button className="submit bg-red" onClick={() => {deletePhoto()}}>Eliminar foto de perfil</button>
        </div>   
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default EditPhoto