//Index of all routes and elements

//Home
export { default as Home } from './Dashboard/Home';

//Account
export { default as Account } from './Dashboard/Account/Account';
export { default as ModifyEmail } from './Dashboard/Account/ModifyEmail';
export { default as ModifyPass } from './Dashboard/Account/ModifyPass';
export { default as DeleteAccount} from './Dashboard/Account/DeleteAccount';
export { default as AccountNotifications} from './Dashboard/Account/AccountNotifications';
export {default as EditPhoto} from "./Dashboard/Account/EditPhoto"
export {default as EvaluatePlatform} from "./Dashboard/Account/EvaluatePlatform"

//AdminUser
export { default as AdminUser } from './Dashboard/AdminUser/AdminUser';
export { default as CreateUser} from './Dashboard/AdminUser/CreateUser';
export { default as DeleteUser} from './Dashboard/AdminUser/DeleteUser';
export { default as ModifyUser} from './Dashboard/AdminUser/ModifyUser';
export { default as ActivateUser} from "./Dashboard/AdminUser/ActivateUser"
export { default as AdminReports} from "./Dashboard/AdminUser/AdminReports"
export {default as ChangeUserStatus} from "./Dashboard/AdminUser/ChangeUserStatus"


//AdminBD
export { default as AdminBD } from './Dashboard/AdminBD/AdminBD';
export { default as Calibration } from './Dashboard/AdminBD/Calibration/Calibration';
export { default as Scenarios } from './Dashboard/AdminBD/Scenarios/Scenarios';
export { default as AvailableMIP } from './Dashboard/AdminBD/Database/AvailableMIP';
export { default as AvailablePELP } from './Dashboard/AdminBD/Database/AvailablePELP';
export {default as ResetBD} from "./Dashboard/AdminBD/ResetBD"
export {default as MSO} from "./Dashboard/AdminBD/MSO/MSO"
export {default as AvailableCoins} from "./Dashboard/AdminBD/Database/AvailableCoins"


//Database
export { default as Database } from './Dashboard/Database/Database';
export { default as ResultsDatabase } from './Dashboard/Database/ResultsDatabase';
export {default as GeneratePDFDatabase} from "./Dashboard/Database/GeneratePDFDatabase"

//ExpandedMIP
export { default as ExpandedMIP } from './Dashboard/ExpandedMIP/ExpandedMIP';
export { default as ExpandedMIPResults } from './Dashboard/ExpandedMIP/ExpandedMIPResults';
export {default as GeneratePDFExpandedMIP} from "./Dashboard/ExpandedMIP/GeneratePDFExpandedMIP"

//Premiums
export { default as Premium } from './Dashboard/Premium/Premium';

//Reports
export { default as Reports } from './Dashboard/Reports/Reports';

//Selector
export {default as Selector} from './Dashboard/Selector/Selector'

//Services
export { default as Services } from './Dashboard/Services/Services';

//Simulations
export { default as Simulations } from './Dashboard/Simulations/Simulations';
export {default as BasicScenarySelector1} from './Dashboard/Simulations/BasicScenary/BasicScenarySelector1'
export {default as BasicScenarySelector2} from './Dashboard/Simulations/BasicScenary/BasicScenarySelector2'
export {default as BasicScenaryImport} from './Dashboard/Simulations/BasicScenary/BasicScenaryImport'
export {default as BasicScenaryResults} from './Dashboard/Simulations/BasicScenary/BasicScenaryResults'
export {default as BasicScenaryPDF} from './Dashboard/Simulations/BasicScenary/BasicScenaryPDF'
export {default as PredefinedScenarySelector} from './Dashboard/Simulations/PredefinedScenary/PredefinedScenarySelector'
export {default as PredefinedScenaryResults} from './Dashboard/Simulations/PredefinedScenary/PredefinedScenaryResults'
export {default as PredefinedScenaryPDF} from './Dashboard/Simulations/PredefinedScenary/PredefinedScenaryPDF'
export {default as LeontieffScenary} from "./Dashboard/Simulations/LeontieffScenary/LeontieffScenary"
export {default as DirectFinalDemandSelector1} from "./Dashboard/Simulations/LeontieffScenary/DirectFinalDemandSelector1"
export {default as DirectFinalDemandSelector2} from "./Dashboard/Simulations/LeontieffScenary/DirectFinalDemandSelector2"
export {default as DirectFinalDemandResults} from "./Dashboard/Simulations/LeontieffScenary/DirectFinalDemandResults"
export {default as DirectFinalDemandPDF} from "./Dashboard/Simulations/LeontieffScenary/DirectFinalDemandPDF"
export {default as PercentageDemandSetSelector1} from "./Dashboard/Simulations/LeontieffScenary/PercentageDemandSetSelector1"
export {default as PercentageDemandSetSelector2} from "./Dashboard/Simulations/LeontieffScenary/PercentageDemandSetSelector2"
export {default as PercentageDemandSetResults} from "./Dashboard/Simulations/LeontieffScenary/PercentageDemandSetResults"
export {default as PercentageDemandSetPDF} from "./Dashboard/Simulations/LeontieffScenary/PercentageDemandSetPDF"
export {default as PriceModelSelector1} from "./Dashboard/Simulations/LeontieffScenary/PriceModelSelector1"
export {default as PriceModelSelector2} from "./Dashboard/Simulations/LeontieffScenary/PriceModelSelector2"
export {default as PriceModelResults} from "./Dashboard/Simulations/LeontieffScenary/PriceModelResults"
export {default as PriceModelPDF} from "./Dashboard/Simulations/LeontieffScenary/PriceModelPDF"
export {default as DirectDemandSetSelector1} from "./Dashboard/Simulations/LeontieffScenary/DirectDemandSetSelector1"
export {default as DirectDemandSetSelector2} from "./Dashboard/Simulations/LeontieffScenary/DirectDemandSetSelector2"
export {default as DirectDemandSetResults} from "./Dashboard/Simulations/LeontieffScenary/DirectDemandSetResults"
export {default as DirectDemandSetPDF} from "./Dashboard/Simulations/LeontieffScenary/DirectDemandSetPDF"
export {default as PriceDemandModelSelector1} from "./Dashboard/Simulations/LeontieffScenary/PriceDemandModelSelector1"
export {default as PriceDemandModelSelector2} from "./Dashboard/Simulations/LeontieffScenary/PriceDemandModelSelector2"
export {default as PriceDemandModelResults} from "./Dashboard/Simulations/LeontieffScenary/PriceDemandModelResults"
export {default as PriceDemandModelPDF} from "./Dashboard/Simulations/LeontieffScenary/PriceDemandModelPDF"
export {default as ChangeProductPriceSelector1} from "./Dashboard/Simulations/LeontieffScenary/ChangeProductPriceSelector1"
export {default as ChangeProductPriceSelector2} from "./Dashboard/Simulations/LeontieffScenary/ChangeProductPriceSelector2"
export {default as ChangeProductPriceResults} from "./Dashboard/Simulations/LeontieffScenary/ChangeProductPriceResults"
export {default as ChangeProductPricePDF} from "./Dashboard/Simulations/LeontieffScenary/ChangeProductPricePDF"
export {default as SimulationOperationMenu} from "./Dashboard/Simulations/SimulationOperationScenary/SimulationOperationMenu"
export {default as SimulationOperationList} from "./Dashboard/Simulations/SimulationOperationScenary/SimulationOperationList"
export {default as SimulationOperationInformation} from "./Dashboard/Simulations/SimulationOperationScenary/SimulationOperationInformation"
export {default as SimulationOperationSelector1} from "./Dashboard/Simulations/SimulationOperationScenary/SimulationOperationSelector1"
export {default as SimulationOperationSelector2} from "./Dashboard/Simulations/SimulationOperationScenary/SimulationOperationSelector2"
export {default as SimulationOperationResults} from "./Dashboard/Simulations/SimulationOperationScenary/SimulationOperationResults"
export {default as SimulationOperationsPDF} from "./Dashboard/Simulations/SimulationOperationScenary/SimulationOperationsPDF"

//Others
export { default as Recover } from './LoginPage/Recover';
export { default as Area } from './Charts/Area';
export { default as Bar } from './Charts/Bar';
export { default as ColorMapping } from './Charts/ColorMapping';
export { default as Financial } from './Charts/Financial';
export { default as Line } from './Charts/Line';
export { default as Pie } from './Charts/Pie';
export { default as Pyramid } from './Charts/Pyramid';
export { default as Stacked } from './Charts/Stacked';