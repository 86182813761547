import { React, useState, useEffect, useContext} from 'react'
import { UserContext } from '../../../contexts/UserContext';
import { Button } from '../../../components';
import { Header } from '../../../components';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import user_icon from '../../../assets/person.png'
import email_icon from '../../../assets/email.png'
import password_icon from '../../../assets/password.png'
import { API_PORT } from '../../../API_PORT';
import capitalize from "../../../hook/capitalize"
import ISCOCodes from '../../LoginPage/ISCOCodes';
import ProfesionCodeText3 from './ProfesionCodeText3';
import LoadingScreen from '../../../components/LoadingScreen';
import { IoEye, IoEyeOff } from 'react-icons/io5'; // Importa los íconos

const ModifyUser = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [loadingscreen, setLoadingScreen] = useState({started: false});
  const [open, setOpen] = useState(false);

  const [showPassword, setShowPassword] = useState(false); // Estado para visibilidad de contraseña

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

  const userContext = useContext(UserContext);
  const token = userContext.value.access_token;

  const [values, setValues] = useState({
    "email": "Nuevo email",
    "username": "Nuevo nombre de usuario",
    "first_name": "Nuevo nombre",
    "last_name": "Nuevo apellido",
    "password": "Nueva contraseña",
    "user_type": "Selecciona el tipo de usuario",
    "institution_name": "Nueva institución",
    "profesion_level": "0",
    "profesion_code": "0",
    "country": "Selecciona el país",
    "Identifier": "ID de usuario",
});

function ProfesionLevel(level){
  if (level === 1){
      return("Academia")
  }
  else if (level === 2){
      return("Sector Público")
  }
  else if (level === 3){
      return("Sector Privado")
  }
  else if (level === 4){
      return("Organización no gubernamental")
  }
  else {
      return("Selecciona el área ocupacional")
  }
}

const options = [
  { value: values.user_type, label: values.user_type},
  { value: "basic", label: "Usuario Básico"},
  { value: "premium", label: "Usuario Premium"},
  { value: "admin", label: "Admin"},
];

const options2 = [
  { value: values.profesion_level, label: ProfesionLevel(values.profesion_level)},
  { value: "1", label: "Academia"}, 
  { value: "2", label: "Sector Público"},
  { value: "3", label: "Sector Privado"},
  { value: "4", label: "Organización no gubernamental"},
];

const options3 = [
  { value: values.country, label: values.country},
  { value: "Argentina", label: "Argentina"},
  { value: "Bolivia", label: "Bolivia"},
  { value: "Brasil", label: "Brasil"},
  { value: "Chile", label: "Chile"},
  { value: "Colombia", label: "Colombia"},
  { value: "Costa Rica", label: "Costa Rica"},
  { value: "Ecuador", label: "Ecuador"},
  { value: "España", label: "España"},
  { value: "Estados Unidos", label: "Estados Unidos"},
  { value: "México", label: "México"},
  { value: "Paraguay", label: "Paraguay"},
  { value: "Perú", label: "Perú"},
  { value: "Uruguay", label: "Uruguay"},
  { value: "Venezuela", label: "Venezuela"},
];

const responsenavigate = (response) =>{
  if(response.detail === "Usuario modificado exitosamente"){
    alert("Los datos del usuario han sido modificados correctamente")
    navigate("/dashboard/adminuser")
  }
  else{
    alert(response.detail)
  }
  setOpen(true);
  setLoadingScreen(prevState => {return {...prevState, started: true}});
}

const [errors] = useState({});

useEffect(() => {
  if(location.state?.user){
    values.Identifier= location.state.user.id
    values.email = location.state.user.email
    values.username = location.state.user.username
    values.first_name = location.state.user.first_name
    values.last_name = location.state.user.last_name
    values.user_type = location.state.user.user_type
    values.profesion_level = location.state.user.profesion_level
    values.profesion_code = location.state.user.profesion_code
    values.institution_name = location.state.user.institution_name
    values.country = capitalize(location.state.user.country)
  }  
}, [location.state,values])

const handleInput = (event) =>{
    setValues(prev => ({...prev, [event.target.name]:event.target.value}))
};

function null_values(values){
  var email = null
  var username = null
  var first_name= null
  var last_name = null
  var password = null
  var user_type=null
  var institution_name = null
  var profesion_level = null
  var profesion_code= null
  var country = null
  if (values.email !== ""){email =values.email}
  if (values.username !== ""){username =values.username}
  if (values.first_name !== ""){first_name =values.first_name}
  if (values.last_name !== ""){last_name =values.last_name}
  if (values.password !== ""){password =values.password}
  if (values.user_type !== ""){user_type =values.user_type}
  if (values.institution_name !== ""){institution_name =values.institution_name}
  if (values.profesion_level !== ""){profesion_level =values.profesion_level}
  if (values.profesion_code !== ""){profesion_code =values.profesion_code}
  if (values.country !== ""){country =values.country}
  return(
    ({
      "email": email,
      "username": username,
      "first_name": first_name,
      "last_name": last_name,
      "password": password,
      "user_type": user_type,
      "profesion_level": profesion_level,
      "profesion_code": profesion_code,
      "institution_name": institution_name,
      "country": country
    })
  )
  
}

const handleSubmit = (event) =>{
  event.preventDefault();
    if(values.email !== "Nuevo email" && values.password !=="Nueva contraseña" && values.Identifier !== "ID de usuario" && values.Identifier !== "" && values.username !== "Nuevo nombre de usuario" && values.first_name !== "Nuevo nombre" && values.last_name !== "Nuevo apellido" && values.institution_name !== "Nueva institución" && values.profesion_level !== "Selecciona el área ocupacional" && values.profesion_code !== "Selecciona la profesión" && values.country !== "Selecciona el país"){
      setOpen(true);
      setLoadingScreen(prevState => {return {...prevState, started: true}});
        fetch(API_PORT + '/admin/users_accounts/admin/modify_user/?token='+token, {
            method: 'PATCH',
            headers: {
                'Content-type': 'application/json'
            },
            body: JSON.stringify({
              "IdentifierType": "id",
              "Identifier": values.Identifier,
              "new_user_data": null_values(values)})
            }).then((res => res.json()))
            .catch((error) => {
              console.log(error)
              alert("No se ha podido modificar el usuario. Recarga tu sesión o inténtalo nuevamente más tarde.")
            })
            .then((response) => responsenavigate(response)
            );
    }
}

  return (
    <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
        <div className='flex justify-between items-center mb-10'>
            <Link to="/dashboard/adminuser" >
              <Button
                color="white"
                bgColor="gray"
                text="Volver"
                borderRadius="10px"
                size="md"
              />
            </Link>
        </div>  
      <Header title="Modificar Usuario"/>
      <h1>En esta sección puedes modificar los datos de un usuario ya existente. Debes ingresar la id de un usuario y sus datos para poder actualizarlos con el botón de la zona inferior. Si dejas un campo vacío, no se modificará ese dato del usuario.</h1>
      <form action = "" onSubmit={handleSubmit}>
                <div className='inputs'>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='Identifier'></label>
                        <input type='string' placeholder={values.Identifier} name='Identifier'
                        onChange={handleInput} className='form-control rounded-0'/>
                    </div>
                    <div className='input'>
                        <img src={email_icon} alt='' />
                        <label htmlFor='email'></label>
                        <input type='email' placeholder={values.email} name='email'
                        onChange={handleInput} className='form-control rounded-0'/>
                        {errors.email && <span className='text-danger'>{errors.email}</span>}
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='username'></label>
                        <input type='string' placeholder={values.username} name='username'
                        onChange={handleInput} className='form-control rounded-0'></input>
                        {errors.username && <span className='text-danger'>{errors.username}</span>}
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='first_name'></label>
                        <input type='string' placeholder={values.first_name} name='first_name'
                        onChange={handleInput} className='form-control rounded-0'></input>
                        {errors.first_name && <span className='text-danger'>{errors.first_name}</span>}
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='last_name'></label>
                        <input type='string' placeholder={values.last_name} name='last_name'
                        onChange={handleInput} className='form-control rounded-0'></input>
                        {errors.last_name && <span className='text-danger'>{errors.last_name}</span>}
                    </div>
                    <div className='input'>
                        <img src={password_icon} alt='' />
                        <label htmlFor='password'></label>
                        <input type={showPassword ? 'text' : 'password'} placeholder={values.password} name='password'
                        onChange={handleInput} className='form-control rounded-0'/>
                        {errors.password && <span className='text-danger'>{errors.password}</span>}
                        <button 
                            type='button' 
                            onClick={togglePasswordVisibility}
                            title={showPassword ? 'Ocultar contraseña' : 'Mostrar contraseña'}
                            style={{ background: 'none', border: 'none', cursor: 'pointer' }} // Estilos para el botón
                        >
                            {showPassword ? <IoEyeOff size={20} /> : <IoEye size={20} />}
                        </button>
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='user_type'></label>
                        <select type='string' placeholder='Nuevo Tipo de Usuario' name='user_type'
                        onChange={handleInput} className='form-select rounded-0'>
                          {options.map(option =>(
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                    </div>
                    <div className='input'>
                            <img src={user_icon} alt='' />
                            <label htmlFor='profesion_level'></label>
                            <select type='string' placeholder='Área Ocupacional' name='profesion_level'
                            onChange={handleInput} className='form-select rounded-0'>
                            {options2.map(option =>(
                                <option value={option.value}>{option.label}</option>
                            ))}
                            </select>
                    </div>
                    <div className='input'>
                            <img src={user_icon} alt='' />
                            <label htmlFor='profesion_code'></label>
                            <select type='string' placeholder='Profesión' name='profesion_code'
                            onChange={handleInput} className='form-select rounded-0'>
                            <option value={values.profesion_code}>{ProfesionCodeText3(values.profesion_code)}</option>
                            {ISCOCodes[values.profesion_level].map(option =>(
                                <option value={option.value}>{option.label}</option>
                            ))}
                            </select>
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='institution_name'></label>
                        <input type='string' placeholder={values.institution_name} name='institution_name'
                        onChange={handleInput} className='form-control rounded-0'></input>
                    </div>
                    <div className='input'>
                        <img src={user_icon} alt='' />
                        <label htmlFor='country'></label>
                        <select type='string' placeholder='País' name='country'
                        onChange={handleInput} className='form-select rounded-0'>
                          {options3.map(option =>(
                            <option value={option.value}>{option.label}</option>
                          ))}
                        </select>
                    </div>
                </div>
                <div className='flex justify-center items-center gap-20 flex-wrap'>
                  <button className="submit blue" type="submit">Modificar datos</button>
                </div>     
        </form>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
    </div>
  )
}

export default ModifyUser