import {React, useState, useEffect, useContext} from 'react'
import { UserContext } from '../../../../contexts/UserContext';
import { useLocation } from 'react-router-dom';
import { Header} from '../../../../components';
import { API_PORT } from '../../../../API_PORT';
import { useNavigate} from 'react-router-dom';
import capitalize from '../../../../hook/capitalize';
import Modal from '../../../../components/Modal';
import LoadingScreen from '../../../../components/LoadingScreen';

const BasicScenaryImport = () => {
    const location = useLocation();
    const userContext = useContext(UserContext);
    const token = userContext.value.access_token;
    const link2= API_PORT + "/admin/database_information/get-avaible-PELP-scenarios?token="+token
  
    const navigate = useNavigate();
  
    const [pelps, setPelps] = useState([])
    const [pelpcapacity, setpelpcapacity] = useState([])
    const [pelpgeneration, setpelpgeneration] = useState([])

    const values = location.state.values;
    const coins = location.state.coins;
  
    const [progress] = useState({started: false, pc: 0});
    const [msg, setMsg] = useState(null);
    const [loaderscreen, setLoaderScreen] = useState({started: false});
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const [loadingscreen, setLoadingScreen] = useState({started: true});
    const [open, setOpen] = useState(true);

    const [scenary, setValues] = useState({
        "scenary": "",
    })
  
    const handleInput = (event) =>{
      setValues(prev => ({...prev, [event.target.name]:event.target.value}))
    };
  
    useEffect(() => {
      fetch(link2, {
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
        })
        .then(res => res.json())
        .then(data => {
          setPelps(data);
          setOpen(false);
          setLoadingScreen(prevState => {return {...prevState, started: false}});
        })
      }, []);
  
    var scenary_values = pelps.scenarios
    var scenary_data  = [{value:"",label:"Selecciona un escenario"}];
  
    scenary_values?.forEach((element) =>
      {scenary_data.push({value: element, label: element})}
    )
  
    const ExportScenary = () => {
      if (scenary.scenary !== ""){
        //Export endpoints
        const PELP_generation= API_PORT + "/scene/pelp/resume/get-PELP-generation/" + values.country + "/" + values.pelp_year + "?token="+token
        const PELP_capacity= API_PORT + "/scene/pelp/resume/get-PELP-capacity/" + values.country + "/" + values.pelp_year + "?token="+token

        values.scenary = scenary.scenary
  
        setIsModalOpen(true)
        setMsg("Exportando los datos, no cierre esta pestaña hasta completar el proceso.")
        setLoaderScreen(prevState => {
          return {...prevState, started: true}
        })
  
        //Summary Fetch
        fetch(PELP_generation, {
        method: 'POST',
        headers: {'Content-type': 'application/json'},
        body: JSON.stringify(values.scenary)
        }).then(res => res.json()).then(data => {setpelpgeneration(data);})
        fetch(PELP_capacity, {
        method: 'POST',
        headers: {'Content-type': 'application/json'},
        body: JSON.stringify(values.scenary)
        }).then(res => res.json()).then(data => {setpelpcapacity(data);})
      }
      else {
        alert("No has ingresado un escenario.")
      }
    }
  
    const NavigateResults = () => {
      if (Object.keys(pelpgeneration).length > 0 && Object.keys(pelpcapacity).length > 0){
        navigate("/dashboard/simulations/main/basic_scenary_selector_2", {state: {values, pelpgeneration, pelpcapacity, coins}})
      }
    }
  
    NavigateResults()
  
  
    return (
      <div className='m-2 md:m-10 p-2 md:p-10 bg-white rounded-3xl'>
          <div className='flex gap-6 mb-6'>
            <button style={{color:'white', backgroundColor:"gray", borderRadius: "10px"}} className="text-lg p-3" onClick={()=>{navigate("/dashboard/simulations/main/basic_scenary_selector_2", {state: {values, coins}})}}>Volver a selección de datos de escenario personalizado</button>
            <button style={{color:'white', backgroundColor:"gray", borderRadius: "10px"}} className="text-lg p-3" onClick={()=>{navigate("/dashboard/simulations/main/predefined_scenary_selector")}}>Volver a selección de datos de escenario predefinido</button>
        </div>  
        <Header title={"Importar datos de escenario predefinido " + capitalize(values.country) + " " + values.pelp_year }/>
        <p className='text-gray-500'>En esta sección podrás importar un escenario predefinido existente a un escenario personalizado para ocupar sus datos en este tipo de simulaciones. Ingresa un escenario disponible para importar los datos.</p>
        <div className='mt-10'><Header title="Selecciona 1 opción"/></div>
        <div className='grid grid-cols-3 gap-2'>
          <div className='columns-1'>
            <h1 className='mb-3'><strong>Escenario</strong></h1>
              <label htmlFor='scenary'></label>
              <select type='string' name='scenary'
                onChange={handleInput} className='form-select rounded-0 w-60 h-10' style={{color: "white", backgroundColor: "#425df9", borderRadius: "8px"}}>
                {scenary_data.map(option =>(
                  <option value={option.value} style={{border:"1px solid", color: "gray", backgroundColor: "white"}}>{option.label}</option>
                  ))}
              </select>
          </div>
        </div>
        <div className='mt-40 flex gap-3'>
          <button style={{color:'white', backgroundColor:"#425df9", borderRadius: "10px"}} className="text-lg p-3" onClick={ExportScenary}>Importar los datos del escenario predefinido al personalizado</button>
        </div>
        <Modal msg={msg} progress={progress} isOpen={isModalOpen} loaderscreen={loaderscreen} closeModal={() => setIsModalOpen(false)}/>
        <LoadingScreen loaderscreen={loadingscreen} isOpen={open} closeModal={() => setOpen(false)}/>
      </div>
    )
}

export default BasicScenaryImport