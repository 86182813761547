//Imports
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ContextProvider} from './contexts/ContextProvider';
import { UserContextProvider } from './contexts/UserContext';

//App Render
ReactDOM.render(
    <ContextProvider>
        <UserContextProvider>
            <App />
        </UserContextProvider>
    </ContextProvider>
    , document.getElementById('root'));


