import React from "react";
import "./Modal.css";

export default function FullScreen({ isOpen, closeModal, item }) {
  if(isOpen) {
    document.body.classList.add('active-modal')
  } else {
    document.body.classList.remove('active-modal')
  }

  if (!isOpen) return null;

  //Use FullScreen for open graph and tables on fullscreen mode
  return (
    <>
        <div className="modal">
          <div onClick={closeModal} className="overlay"></div>
          <div className="modal-content" style={{ maxWidth: "90%" }}>
            <button className="close-modal" onClick={closeModal}>
              Cerrar
            </button>
            <div className='flex justify-center items-center flex-wrap mt-10'>
              <div style={{width: "100%" }}>{item}</div>
            </div>
          </div>
        </div>
    </>
  );
}